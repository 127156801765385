import React, { useRef, useCallback, useEffect, useState, useMemo, memo } from 'react'
import styled from 'styled-components'

import { Popover, Modal } from 'antd'
import { Flex, Box, Grid, Text, Image } from '@/packages/'
import { toNonExponential, someTimesAgo, accMul, openWindow } from '@/utils/tools'

import { Row, Col } from '@/components/Layout/Layout'

import { gasDetail, openseaWhite, changeDown, changeUp, close } from '@/assets/img'
import { ListingProfit, iBoxItem, iBoxItemNftInfo } from './ListingProfit'
import { iVolume } from '@/types/store'
import { ColorRank } from '@/components/ColorRank'
import { useMatchBreakpoints } from '@/hooks/useMatchBreakpoints'

interface iLeftCompnent {
  rank: number
  price: number
  nftTokenVO: iBoxItemNftInfo
  royalty: number
  chain?: string

  lastPrice: iVolume
  lastPriceTime: number
  createTime: number
}

export const LeftComponent: React.FC<iLeftCompnent> = ({
  rank,
  price,
  nftTokenVO,
  royalty,
  chain,
  lastPrice,
  lastPriceTime,
  createTime
}) => {
  const { isMobile } = useMatchBreakpoints()
  const [profitInfoStatus, setProfitInfoStatus] = useState(false)
  let _nftAddress: string = ''
  let _tokenId: string = ''
  if (nftTokenVO) {
    _nftAddress = nftTokenVO.nftAddress
    _tokenId = nftTokenVO.tokenId
  }
  const onProfitInfo = () => {
    setProfitInfoStatus(true)
  }
  return (
    <Box className="">
      <ColorRank rankValue={rank} />
      {!isMobile ? (
        <div>
          <Box className="fx-row ai-ct" mt="6px">
            {_nftAddress && _tokenId && (
              <Image
                height={18}
                width={18}
                src={openseaWhite}
                className="click"
                onClick={() => openWindow(`https://opensea.io/assets/${chain}/${_nftAddress}/${_tokenId}`)}
              />
            )}
            <Image height={18} width={18} src={gasDetail} className="click ml8" onClick={onProfitInfo} />
            <Modal
              closable={false}
              visible={profitInfoStatus}
              footer={null}
              bodyStyle={{
                width: '578px',
                height: '604px',
                background: '#252525',
                borderRadius: 12,
                padding: '40px'
              }}
              maskClosable={false}
              wrapClassName="profitInfoModul"
            >
              <Box className="pa click" top="16px" right="-40px">
                <Image src={close} height={10} width={10} onClick={() => setProfitInfoStatus(false)} />
              </Box>

              <ListingProfit price={price} nftTokenVO={nftTokenVO} royalty={royalty} />
            </Modal>
            {/* <Popover
              content={<ListingProfit price={price} nftTokenVO={nftTokenVO} royalty={royalty} />}
              trigger="click"
              placement="bottomRight"
            >
              <Image height={18} width={18} src={gasDetail} className="click ml8" />
            </Popover> */}
          </Box>
          {lastPrice ? (
            <Row className="beyondHide " mt="10px">
              <Row mr="4px">
                <Text color="rgba(255, 255, 255, 0.38)" fontSize="12px">
                  Was
                </Text>
                <Text color="rgba(255, 255, 255, 0.6)" fontSize="12px" mx="4px">
                  Ξ {toNonExponential(lastPrice.value)}
                </Text>
                <Text color="rgba(255, 255, 255, 0.38)" fontSize="12px">
                  {someTimesAgo(lastPriceTime)}
                </Text>
              </Row>
              {lastPrice.percent !== 0 && lastPrice.percent && (
                <Row>
                  <Image src={lastPrice.increase ? changeUp : changeDown} height={8} width={8} />
                  <Text ml="4px" fontSize="14px" color={lastPrice.increase ? '#34C77B' : '#EB484C'}>
                    {`${accMul(toNonExponential(lastPrice.percent), 100)}%`}
                  </Text>
                </Row>
              )}
            </Row>
          ) : (
            <Col mt="10px">
              <Text fontSize="12px" color="#A0A1A3" mt="2px" mr="4px">
                Was ({someTimesAgo(lastPriceTime)})
              </Text>
              <Text fontSize="12px" color="#5F6165" mt="4px">
                {`Listed ${someTimesAgo(createTime)}`}
              </Text>
            </Col>
          )}
        </div>
      ) : (
        <Box mt="22px">
          {_nftAddress && _tokenId && (
            <Image
              height={18}
              width={18}
              src={openseaWhite}
              className="click"
              onClick={() => openWindow(`https://opensea.io/assets/${chain}/${_nftAddress}/${_tokenId}`)}
            />
          )}
        </Box>
      )}
    </Box>
  )
}
