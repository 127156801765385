import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { Flex, Box, Grid, Text, Image, Input } from '@/packages/'
import { iHotCollectionRecords, iHotMints, iFeedList } from '@/types/store'
import { whale, mintHigh, mintMiddle, mintLow, linkIcon, feedBuy, feedSell, feedMint, viewMore } from '@/assets/img'
import { Chart } from '@/components/Charts/Chart'
import { HotCollectionOption } from '@/components/Charts/options/index'
import { Row, Col, NFTImage, PeweeTooltip } from '@/components/Layout/Layout'
import {
  splitAddress,
  someTimesAgo,
  valumeUnit,
  beyondStr,
  toNonExponential,
  accMul,
  accDiv,
  openWindow
} from '@/utils/tools'
import { LiveFeedDot } from '@/components/LiveFeedDot'

interface iHotCard {
  children: React.ReactNode
}
interface iAvatarName {
  avatar: string
  name: string
  nftId: number
  link?: string
}
interface iValueTitle {
  value: string | number
  title: string
}

interface iHotIcon {
  count: number
}
interface iMintedProgress {
  minted: number
}
const Container = styled(Box)`
  height: 200px;
  border-radius: 8px;
  overflow: scroll;
`
const HotCardContainer: React.FC<iHotCard> = ({ children }) => {
  return (
    <Box className="" background="#17191F" height="212px" px={[24, 10, 0, 24]} pt={[24, 24, 4, 24]} mt="12px">
      {children}
    </Box>
  )
}
// Avatar + Name
const AvatarName: React.FC<iAvatarName> = ({ avatar, name, link, nftId }) => {
  const navigate = useNavigate()

  const handleDetail = (nId: number) => {
    if (nId) {
      navigate(`/trending/liveview/${nId}`)
    }
  }
  return (
    <Row className="">
      <NFTImage src={avatar} width={44} height={44} classN="circle click" onHandle={() => handleDetail(nftId)} />
      <Col ml={[12, 12, 0, 12]}>
        <Text
          title={name}
          fontSize={[16, 16, 14, 16]}
          width={[135, 135, 75, 135]}
          className="fff beyondHide click"
          mb="4px"
          onClick={() => handleDetail(nftId)}
        >
          {name}
        </Text>
        {link && <Image src={linkIcon} height={16} width={16} className="click" onClick={() => openWindow(link)} />}
      </Col>
    </Row>
  )
}
// Value + Title
const ValueTitle: React.FC<iValueTitle> = ({ value, title }) => {
  return (
    <Box className="fx-col ai-ct" ml="">
      <Text color="#fff" fontSize="14px" ml="2px">
        {value}
      </Text>
      <Text color="#5F6165" fontSize="10px" mt="8px">
        {title}
      </Text>
    </Box>
  )
}
const HotIcon: React.FC<iHotIcon> = ({ count }) => {
  // A. 低等热门标记：5分钟内，铸造数≦5。
  // B. 中等热门标记：5分钟内，6个≦ 铸造数≦20个。
  // C. 高等热门标记：5分钟内，铸造数>20个。
  let src = ''
  if (count < 6) src = mintLow
  if (count < 21) src = mintMiddle
  if (count > 20) src = mintHigh
  return <Image width={28} height={28} src={src} />
}

const ProgressContainer = styled(Box)`
  width: 100%;
  height: 8px;
  background: #111218;
  border-radius: 6px;
  position: relative;
  margin-top: 8px;
`
const Progressed = styled(Box)<{ w: number }>`
  width: ${({ w }) => `${w}%`};
  height: 8px;
  background-image: linear-gradient(to right, #ffe800, #00b2b5);
  position: absolute;
  left: 0;
`
const MintedProgress: React.FC<iMintedProgress> = ({ minted }) => {
  return (
    <ProgressContainer>
      <Progressed w={minted} />
    </ProgressContainer>
  )
}
export const CollectionCard: React.FC<iHotCollectionRecords> = ({
  coin,
  volume5min,
  sales5min,
  dates,
  volumes,
  avgPrices,
  floors,
  collection
}) => {
  const option = HotCollectionOption({
    dates,
    volumes,
    avgPrices,
    floors,
    collection
  })
  return (
    <HotCardContainer>
      <Grid gridTemplateColumns={['1fr 1fr']} className="w100">
        <AvatarName avatar={collection.imageUrl} name={collection.name} nftId={collection.nftId} />
        <Grid gridTemplateColumns={['1fr 1fr']}>
          <ValueTitle value={`Ξ ${toNonExponential(volume5min)}`} title="Volume 15mins" />
          <ValueTitle value={toNonExponential(sales5min)} title="Sales 15mins" />
        </Grid>
      </Grid>
      <Box height={112}>
        <Chart option={option} />
      </Box>
    </HotCardContainer>
  )
}
export const HotMintsCard: React.FC<iHotMints> = ({
  collection,
  coin,
  mintPrice,
  mint5m,
  minters,
  whaleMinter,
  smartMoneyMinter,
  minted,
  totalSupply,
  nftId,
  loadFinished
}) => {
  let progressRate = 0
  if (minted && totalSupply) {
    if (minted > totalSupply || minted === totalSupply) {
      progressRate = 100
    } else {
      progressRate = accMul(accDiv(minted, totalSupply || 0), 100)
    }
  }
  return (
    <HotCardContainer>
      <Grid gridTemplateColumns={['1fr 1fr']}>
        <AvatarName
          avatar={collection.imageUrl}
          name={collection.name}
          link={collection.officialUrl}
          nftId={Number(nftId)}
        />
        <Grid gridTemplateColumns={['2fr 2fr 1fr']}>
          <ValueTitle value={mintPrice === 0 ? 'Free' : `Ξ ${toNonExponential(mintPrice)}`} title="Mint Price" />
          <ValueTitle value={toNonExponential(mint5m)} title="15m Mints" />
          <HotIcon count={mint5m} />
        </Grid>
      </Grid>
      {loadFinished && (
        <>
          <Box className="fx-row ai-ct jc-sb" mt={[30, 30, 10, 30]}>
            <Row>
              <PeweeTooltip title="Whales: the address which holding NFTs' estimate value more than Ξ 100 based on floor price, and ranking top 4000 in the wallet list of the platform.">
                <Image src={whale} height={20} width={20} className="click" />
              </PeweeTooltip>
              <Text className="f14 a0a" ml="8px">
                {whaleMinter}
              </Text>
            </Row>
            <Text className="f14 a0a">{minters} Minters</Text>
          </Box>
          <Box className="fx-row ai-ct jc-sb" mt={[20, 20, 10, 20]}>
            <Text className="f14 c5f6">Minted</Text>
            <Text className="f14 a0a">{`${minted}`}</Text>
            {/* <Text className="f14 c5f6">Minted/Total</Text> */}
            {/* <Text className="f14 a0a">{`${minted}/${totalSupply}`}</Text> */}
          </Box>
          <MintedProgress minted={progressRate} />
        </>
      )}
    </HotCardContainer>
  )
}
const TextBox = styled(Box)`
  position: relative;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  transform: scale(1);
  box-shadow: rgb(76 181 93) 0px 0px 0px 0px;
`
const FeedItem = styled(Box)`
  width: 342px;
  height: 112px;
  background: #17191f;
`
const TradeTypeBtnWrapper = styled(Box)<{ bg: string }>`
  width: 41px;
  height: 18px;
  background-color: ${({ bg }) => `${bg}`};
`
interface iTradeTypeBtn {
  type: number
}
const TradeTypeBtn: React.FC<iTradeTypeBtn> = ({ type }) => {
  let color = ''
  let bg = 'rgba(56, 199, 66, 0.16)'
  let typeText = ''
  let imgUrl: string = ''
  switch (type) {
    // case 1:
    //   color = '#38C742'
    //   bg = 'rgba(56, 199, 66, 0.16)'
    //   typeText = 'SELL'
    //   break
    case 2:
      color = '#F32626'
      bg = 'rgba(243, 38, 38, 0.16)'
      typeText = 'BUY' // from
      imgUrl = feedBuy
      break
    case 3:
      color = '#38C742'
      bg = 'rgba(56, 199, 66, 0.16)'
      typeText = 'SELL' // to
      imgUrl = feedSell
      break
    case 4:
      color = '#2680F3'
      bg = 'rgba(38, 128, 243, 0.16)'
      typeText = 'MINT' // // to
      imgUrl = feedMint
      break
    default:
      color = '#FFFFFF'
      bg = 'rgba(40, 40, 45, 0.16)'
      typeText = '--'
      imgUrl = ''
      break
  }
  return (
    <Box>{imgUrl ? <Image src={imgUrl} height={18} width={40} className="mt8" /> : <Box height={18} width={40} />}</Box>
  )
  // return (
  //   <TradeTypeBtnWrapper className="center" bg={bg} mt="9px" mb="9px">
  //     <span className="f12" style={{ color }}>
  //       {typeText}
  //     </span>
  //   </TradeTypeBtnWrapper>
  // )
}

export const FeedCard: React.FC<iFeedList> = ({ from, to, time, tradeType, nft, price, coin }) => {
  const [exType, setExtype] = useState('-')
  const navigate = useNavigate()
  // sell from
  // buy to
  // mint to
  // send  from
  // receive to
  useEffect(() => {
    switch (tradeType) {
      case 2:
        setExtype('From')
        break
      case 3:
        setExtype('To')
        break
      case 4:
        setExtype('To')
        break
      default:
        break
    }
  }, [])

  const handleChange = (value: string) => {}

  const handleDetail = (nftId: number) => {
    if (nftId) {
      navigate(`/trending/liveview/${nftId}`)
    }
  }
  return (
    <Box
      className="fx-row ai-ct jc-sb w100"
      height="112px"
      px="16px"
      background="#17191F"
      mt="8px"
      onClick={() => handleDetail(nft.nftId)}
    >
      <Row className="">
        <NFTImage src={nft.imageUrl} height={64} width={64} classN="circle" />
        <Box ml="12px">
          <Text title={nft.name} fontSize="14px" color="#fff" className="beyondHide">{`${beyondStr(
            nft.name
          )}(#${beyondStr(nft.tokenId, 4)})`}</Text>
          <Text fontSize="12px" color="#A0A1A3" className="fx-row ai-ct" mt="10px">
            {exType}
            <Text color="#00B2B5" ml="8px">
              {splitAddress(String(from.address))}
            </Text>
          </Text>
          <Text fontSize="12px" color="#A0A1A3" className="fx-row ai-ct" mt="2px">
            For
            <Text color="#00B2B5" ml="22px">
              {`Ξ ${toNonExponential(price) || '--'}`}
            </Text>
          </Text>
        </Box>
      </Row>
      <Box className="fx-col ai-end">
        <Row>
          <PeweeTooltip title="Whales: the address which holding NFTs' estimate value more than Ξ 100 based on floor price, and ranking top 4000 in the wallet list of the platform.">
            <Image src={whale} height={14} width={14} className="click" />
          </PeweeTooltip>
          <Text fontSize="12px" color="#fff" ml="4px">
            {tradeType === 2 && to.alias}
            {tradeType === 3 && from.alias}
          </Text>
        </Row>
        <TradeTypeBtn type={Number(tradeType)} />
        <Text fontSize="12px" color="#5F6165" className="beyondHide " mt="10px">
          {someTimesAgo(time)}
        </Text>
      </Box>
    </Box>
  )
}

interface iTitleDesc {
  title: string
  subTitle: string
  statusType?: boolean
}

export const TitleDesc: React.FC<iTitleDesc> = ({ title, subTitle, statusType }) => {
  return (
    <Col className="" mt={['32px', '32px', '40px', '40px']}>
      <Text className="white87 fw700" fontSize={['20px', '26px', '30px', '40px']}>
        {title}
      </Text>
      <Grid className="fx jc-sb ai-ct" mb="24px" gridTemplateColumns={['4fr 1fr']}>
        <Text className="white60 " fontSize={['14px', '14px', '16px', '16px']}>
          {subTitle}
        </Text>
        {statusType === true && <LiveFeedDot isLive />}
        {statusType === false && <LiveFeedDot isLive={false} />}
      </Grid>
    </Col>
  )
}
