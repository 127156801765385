import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { Modal, Tooltip } from 'antd'
import Web3 from 'web3'
import { Flex, Box, Grid, Text, Image } from '@/packages/'
import { createAdd, avator } from '@/assets/img'
import { ContentWrapper, Col, CardTitle, Row } from '@/components/Layout/Layout'
import { UpcomingTable } from './UpcomingTable'
import { SubmitInfoApi } from '@/request/Api'
import { iUpcomingPrams } from '@/request/types'
import { EditPeoject } from './components/index'
import { MaskContainer } from '@/components/MaskContainer'

import { InfoTextRow } from './components/InfoTextRow'
import { CreateContainer } from './components/CreateContainer'
import { useEthereum } from '@/hooks/useEthereum'
import { ConnectWalletBtn } from '@/components/ConnectWalletBtn'
import { TitleDesc } from '../Dashboard/component'

import { useMatchBreakpoints } from '@/hooks/useMatchBreakpoints'
import { InvitationEntrance } from '@/views/Invite/components'
import { useAppContext } from '@/hooks/useAppContext'
import { TotalSubmitAction } from '@/store/actions/UpcomingAction'

const InfoBox = styled(Box)`
  height: 86px;
  background: rgba(255, 255, 255, 0.08);
  box-shadow: inset 0px 0px 12px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 0 30px;
`
const init = {
  totalSubmit: '--',
  failedSubmit: '--',
  approvedSubmit: '--',
  newSubmit: 0
}
export const Upcoming = () => {
  const [submitInfo, setSubmitInfo] = useState<iUpcomingPrams>(init)
  const [isAdd, setAdd] = useState<boolean>(true)
  const editPeojectRef: any = useRef()
  const maskRef: any = useRef()
  const tableRef: any = useRef()
  const [isConnect, setConnect] = useState<boolean>(false)
  const { ethereum, provider } = useEthereum()

  const { isMobile, isTablet } = useMatchBreakpoints()
  const { state, dispatch } = useAppContext()

  const fetchList = async () => {
    const d: any = await SubmitInfoApi()
    if (d) {
      const { approvedSubmit, failedSubmit, newSubmit, totalSubmit } = d
      setSubmitInfo({
        totalSubmit,
        failedSubmit,
        approvedSubmit,
        newSubmit
      })
      TotalSubmitAction(totalSubmit, dispatch)
    }
  }
  useEffect(() => {
    if (isConnect) {
      fetchList()
    }
  }, [isConnect])
  useEffect(() => {
    if (ethereum) {
      checkConnection()
      accountsChange()
    }
  }, [ethereum])

  const accountsChange = () => {
    ethereum.on('accountsChanged', (accounts: Array<string>) => {
      if (accounts.length === 0) {
        setConnect(false)
        setSubmitInfo(init)
      }
    })
  }

  const checkConnection = async () => {
    const web3: any = new Web3(ethereum)
    const addr = await web3.eth.getAccounts()
    if (addr && !!addr.length) {
      setConnect(true)
    } else {
      setConnect(false)
    }
  }

  const handleCreate = (v: boolean, type: string, record?: any) => {
    maskRef.current && maskRef.current.onVisible(v)
    setAdd(type === 'add')
    if (v && type === 'edit') {
      setTimeout(() => {
        editPeojectRef.current && editPeojectRef.current.currentModalType(type, record)
      }, 100)
    }
  }
  const handleCancel = () => {
    editPeojectRef.current && editPeojectRef.current.onCancel()
  }
  const handleSubmit = () => {
    editPeojectRef.current && editPeojectRef.current.onSubmit()
  }

  const closeModal = () => {
    maskRef.current && maskRef.current.onVisible(false)
    tableRef.current && tableRef.current.refreshList()
    setAdd(true)
  }
  const connectStatusChange = (c: boolean) => {
    setConnect(c)
  }
  return (
    <ContentWrapper>
      <Col className="w100">
        {process.env.REACT_APP_PEWEE_ENV !== 'prod' && <InvitationEntrance />}
        <Row className="w100 jc-sb">
          <TitleDesc title="Upcoming Projects" subTitle="Carefully picked upcoming projects worth watching!" />
          {!isMobile && !isTablet && (
            <InfoBox className="fx-row jc-sb ai-ct">
              <Row>
                <Image src={avator} height={50} width={50} />
                <Col>
                  <InfoTextRow title="Total Submit" value={submitInfo.totalSubmit}>
                    {submitInfo.newSubmit !== 0 && (
                      <Text color="#34C77B" fontSize="16px" fontWeight={500} mr="6px">
                        {`+${submitInfo.newSubmit}`}
                      </Text>
                    )}
                  </InfoTextRow>
                  <InfoTextRow title="Fail Submit" value={submitInfo.failedSubmit} />
                  <InfoTextRow title="Approve Submit" value={submitInfo.approvedSubmit} />
                </Col>
              </Row>
              {isConnect ? (
                <CreateContainer onCreate={() => handleCreate(true, 'add')} />
              ) : (
                <ConnectWalletBtn connectStatus={connectStatusChange} />
              )}
            </InfoBox>
          )}
        </Row>
        <UpcomingTable uRef={tableRef} onVisibleModal={(type: string, r: any) => handleCreate(true, type, r)} />
      </Col>

      <MaskContainer
        mRef={maskRef}
        width={790}
        title={`${isAdd ? 'Add' : 'Edit'} Upcoming Porject`}
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
      >
        <EditPeoject eRef={editPeojectRef} closeModal={closeModal} />
      </MaskContainer>
    </ContentWrapper>
  )
}
