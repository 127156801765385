import { line1Legend } from '@/assets/img'
import {
  ChartBgColor,
  LegendStyle,
  axisLabel,
  axisLine,
  axisTick,
  splitLine,
  barWidth,
  barColor,
  barBgColor,
  barColor2
} from './types'

const ListingLenthData = [
  {
    name: 'A', // 这里的name需要和series中一一对应
    icon: 'rect'
  },
  {
    name: 'B',
    icon: `image://${line1Legend}`
  }
]

export const OwnershipHoldingsBackateCompOption: any = {
  backgroundColor: ChartBgColor, // 图表背景色
  legend: LegendStyle(ListingLenthData),
  toolbox: {
    show: false
  },
  tooltip: {},
  xAxis: {
    name: '',

    type: 'value',
    axisLabel,
    axisLine,
    axisTick
  },
  yAxis: {
    splitLine,
    axisLabel,
    data: ['持有1', '持有2', '持有3', '持有4', '持有5']
  },
  grid: {
    bottom: 50
  },
  series: [
    {
      name: 'A',
      type: 'bar',
      barWidth: 12, // 柱状图固定宽度 也可设置最大宽度 最小宽度  barMaxWidth barMinWidth
      stack: 'p1',
      color: barColor,
      // showBackground: true,
      // backgroundStyle: barBgColor, // 柱状图背景色
      data: [111, 222, 333, 444, 555]
    },
    {
      name: 'B',
      type: 'bar',
      barWidth: 12, // 柱状图固定宽度 也可设置最大宽度 最小宽度  barMaxWidth barMinWidth
      stack: 'p2',
      color: barColor2,
      // showBackground: true,
      // backgroundStyle: barBgColor, // 柱状图背景色
      data: [66, 166, 266, 366, 466]
    }
  ]
}
