import { Http } from './Http'
import {
  ObjType,
  WalletAddGroupType,
  WalletAddMemberType,
  WalletAttentionType,
  iListingChartQuery,
  iGranularity,
  iFeedPrams,
  iTrendingParams,
  iWhalesActivityParams,
  iTrendingList,
  iHotCollectionsParams,
  iPriceEstimateParams,
  iTradesParams,
  iRealtimeFloorParams,
  iCondition,
  iListingDepth,
  iListingParams,
  iListingCountParams,
  iImgParams,
  ifloorKLineParams,
  iCollectionsV2Prams,
  iMintsV2Prams,
  iWatchListV2Prams,
  iWatchListAlertPrams,
  iTradeRecord,
  iUpcomingListParams,
  iAddUpcomingParams,
  iWalletLoginWithSignParams,
  iUpdateMenmberInfoParams,
  iUpcomingEditorInfoParams
} from './types'
import {} from '@/types/store/index'
import { initHotCollection, initHotMints, initMarketCap, initFeedList } from '@/store/reducers/DashbordReducer'
import { isEmptyObj } from '@/utils/tools'
// ==Dashbord==
export const MarketCapApi = async () => {
  const data = await Http(`/bls/api/v1/dashboard/marketCapAndVolume`, {}, 'get')
  return data || initMarketCap
}
export const HotMintsApi = async () => {
  const data = await Http(`/bls/api/v1/dashboard/hotMints`, {}, 'get')
  return data || null
}
export const HotMintsInfoApi = async (nftAddress: string) => {
  const data = await Http(`/bls/api/v1/dashboard/hotMint/${nftAddress}`, {}, 'get')
  return data || null
}
export const HotCollectionsApi = async (params: iHotCollectionsParams) => {
  const data: any = await Http(`/bls/api/v1/dashboard/hotCollections`, params, 'post')
  return isEmptyObj(data) ? data : initHotCollection
}
export const FeedListApi = async (params: iFeedPrams) => {
  const data = await Http(`/bls/api/v1/dashboard/feed`, params, 'post')
  return data || initFeedList
}
// LiveView
export const isRankApi = async (nftId: number) => {
  const data = await Http(`/bls/api/v1/collection/info/${nftId}`, {}, 'get')
  return data || null
}
export const RealtimeFloorApi = async (params: iRealtimeFloorParams) => {
  const data = await Http(`/bls/api/v1/sniper/realtimeFloor`, params, 'post')
  return data || null
}
export const TradesApi = async (params: iTradesParams) => {
  const data = await Http(`/bls/api/v1/sniper/trades`, params, 'post')
  return data || null
}
export const PriceEstimateApi = async (params: iPriceEstimateParams) => {
  const data = await Http(`/bls/api/v1/sniper/priceEstimate`, params, 'post')
  return data || null
}
export const NftInfoApi = async (nftId: number) => {
  const data = await Http(`/bls/api/v2/collection/overview/${nftId}`, {}, 'get')
  return data || null
}
export const NftInfoAttentionApi = async (nftId: number, status: number) => {
  const data = await Http(`/bls/api/v2/collection/attention/${nftId}/${status}`, {}, 'get')
  return data || null
}
export const CollectionInfoApi = async (nftId: number) => {
  const data = await Http(`/bls/api/v1/collection/info/${nftId}`, {}, 'get')
  return data || null
}
export const ListingDepthApi = async (params: iListingDepth) => {
  const data = await Http(`/bls/api/v1/sniper/depth`, params, 'post')
  return data || null
}
export const RarityScoreApi = async (nftId: number) => {
  const data = await Http(`/bls/api/v1/sniper/rarityScore/${nftId}`, {}, 'get')
  return data || null
}
export const ListingApi = async (params: iListingParams) => {
  const data = await Http(`/bls/api/v1/sniper/listings`, params, 'post')
  return data || null
}
export const ListingCountApi = async (nftId: number, params?: iListingCountParams) => {
  const data = await Http(`/bls/api/v1/commonStatic/listingCount/${nftId}`, params || {}, 'get', true)
  return data || 0
}
export const SalesApi = async (params: iListingParams) => {
  const data = await Http(`/bls/api/v1/sniper/sales`, params, 'post')
  return data || null
}
export const SalesCountApi = async (nftId: number, params?: iListingCountParams) => {
  const data = await Http(`/bls/api/v1/commonStatic/saleCount/${nftId}`, params || {}, 'get', true)
  return data || 0
}
export const ListingPercentageApi = async (nftId: number) => {
  const data = await Http(`/bls/api/v1/commonStatic/listingPercentage/${nftId}`, {}, 'get')
  return data || 0
}
export const FloorCountApi = async (nftId: number) => {
  const data = await Http(`/bls/api/v1/commonStatic/floor/${nftId}`, {}, 'get')
  return data || 0
}
export const TraitCountApi = async (nftId: number) => {
  const data = await Http(`/bls/api/v1/sniper/traits/${nftId}`, {}, 'get')
  return data || 0
}
// 导航栏
export const SearchApi = async (keyword: string) => {
  const data = await Http(`/bls/api/v1/collection/search/${keyword}`, {}, 'get')
  return data || null
}

// Trending
export const TrendingCollectionsApi = async (params: iTrendingParams) => {
  const data = await Http(`/bls/api/v1/trending/trendingCollections`, params, 'post')
  return data || null
}

//  MKT
export const TradersChartApi = async (granularity: string) => {
  const data = await Http(`/bls/api/v1/insight/traders`, { granularity }, 'get', true)
  return data || null
}
export const Top20CollectionApi = async () => {
  const data = await Http(`/bls/api/v1/insight/volumeTop20`, {}, 'get')
  return data || null
}
export const MktCapApi = async (granularity: string) => {
  const data = await Http(`/bls/api/v1/insight/market`, { granularity }, 'get', true)
  return data || null
}
export const GasBurnApi = async (granularity: string) => {
  const data = await Http(`/bls/api/v1/insight/gas`, { granularity }, 'get', true)
  return data || null
}

// whalesActivity
export const WhalesActivityApi = async (params: iWhalesActivityParams) => {
  const data = await Http(`/bls/api/v2/dashboard/whalesActive`, params, 'post')
  return data || null
}

// 通过钱包地址获取基础信息
export const walletInfApi = async () => {
  const data = await Http(`/bls/api/v2/wallet`, {}, 'get')
  if (data) {
    return data
  }
  return null
}
// 通过base64获取图片信息
export const uploadBase64ImageApi = async (params: iImgParams) => {
  const data = await Http(`/bls/api/v1/common/uploadBase64Image`, params, 'post')
  return data || null
}

// ==登录==
// 不需要签名登录
export const walletLoginWithoutSignApi = async (address: String) => {
  const data = await Http(`/au/api/v1/login/address?principal=${address}`, {}, 'post')
  return data || null
}
// 需要签名登录
export const walletLoginWithSignApi = async (params: iWalletLoginWithSignParams) => {
  const { principal, credential, nonce, inviter } = params
  const data = await Http(
    `/au/api/v1/login/address?principal=${principal}&credential=${credential}&nonce=${nonce}&inviter=${inviter}`,
    {},
    'post'
  )
  return data || null
}
export const walletLoginApi = async (address: String) => {
  const data = await Http(`/au/api/v1/login/address?principal=${address}`, {}, 'post')
  if (data) {
    return data
  }
  return null
}
// == NFT ==
// Mint
export const mintOverViewApi = async (nftAddress: String) => {
  const data = await Http(`ca/api/v1/nft/mint/overview/${nftAddress}`, {}, 'get')
  if (data) {
    return data
  }
  return null
}
export const mintPerWalletApi = async (nftAddress: String) => {
  const data = await Http(`ca/api/v1/nft/mint/wallet/${nftAddress}`, {}, 'get')
  if (data) {
    return data
  }
  return null
}
export const mintTopMinterstApi = async (nftAddress: String) => {
  const data = await Http(`ca/api/v1/nft/mint/top5/${nftAddress}`, {}, 'get')
  if (data) {
    return data
  }
  return null
}
// OverView
export const listingChartApi = async (nftAddress: String, params: iListingChartQuery) => {
  const data = await Http(`/pt/api/v1/overview/listing/${nftAddress}`, { params }, 'get')
  if (data) {
    return data
  }
  return null
}
export const priceChartApi = async (nftAddress: String, granularity: String) => {
  const data = await Http(`/pt/api/v1/overview/price/${nftAddress}`, { params: { granularity } }, 'get')
  if (data) {
    return data
  }
  return null
}
// NFT LiveView
export const liveviewSupplyApi = async (nftAddress: String) => {
  const data = await Http(`/pt/api/v1/liveView/data/${nftAddress}`, {}, 'get')
  if (data) {
    return data
  }
  return null
}
export const listingApi = async (nftAddress: String, filter: Number) => {
  const data = await Http(`/pt/api/v1/liveView/listing/${nftAddress}?filter=${filter}`, {}, 'get')
  if (data) {
    return data
  }
  return null
}
export const tradesApi = async (nftAddress: String) => {
  const data = await Http(`/pt/api/v1/liveView/trades/${nftAddress}`, {}, 'get')
  if (data) {
    return data
  }
  return null
}
export const listingTradesChartApi = async (nftAddress: String, params: iGranularity) => {
  const data = await Http(`/pt/api/v1/liveView/listingsAndTrades/${nftAddress}`, { params }, 'get')
  if (data) {
    return data
  }
  return null
}
// v2 dashboard改版
// Collections

export const CollectionsV2Api = async (params: iCollectionsV2Prams) => {
  const data = await Http(`/bls/api/v2/dashboard/collections`, params, 'post')
  return data || null
}
export const MintsV2Api = async (params: iMintsV2Prams) => {
  const data = await Http(`/bls/api/v2/dashboard/hotMints`, params, 'post')
  return data || null
}
export const WatchListV2Api = async (params: iWatchListV2Prams) => {
  const data = await Http(`/bls/api/v2/dashboard/watchList`, params, 'post')
  return data || null
}
export const WatchListAlertApi = async (params: iWatchListAlertPrams) => {
  const data = await Http(`/bls/api/v2/dashboard/watchlist/alert`, params, 'post')
  return data || null
}
export const CancelWatchListAlertApi = async (nftId: number) => {
  const data = await Http(`/bls/api/v2/dashboard/watchlist/alert`, { nftId }, 'delete')
  return data || null
}

// quick buy发送交易后 接口
export const TradeRecordApi = async (params: iTradeRecord) => {
  const data = await Http(`/bls/api/v1/tradeRecord/add`, params, 'post')
  return data || null
}

// 获取gas和eth
export const getGasEthApi = async () => {
  const data = await Http(`/bls/api/v1/dashboard/gas`, {}, 'get')
  return data || null
}

// kline
export const floorKLineApi = async (params: ifloorKLineParams) => {
  const data = await Http(`/bls/api/v1/sniper/floor/klines`, params, 'post')
  return data || null
}
export const ListingUnderFloorApi = async (params: { pageIndex: number; pageSize: number }) => {
  const data = await Http(`/bls/api/v2/dashboard/listingUnderFloor`, params, 'post')
  return data || null
}

//= = 钱包 ==
// 添加分组
export const walletAddGroupApi = async (params: WalletAddGroupType) => {
  const data = await Http(`/pt/api/v1/wallet/group/add`, params, 'post')
  if (data) {
    return data
  }
  return null
}
// 在分组中添加成员
export const walletAddMemberApi = async (params: WalletAddMemberType) => {
  const data = await Http(`/pt/api/v1/wallet/member/add`, params, 'post')
  if (data) {
    return data
  }
  return null
}
// 删除分组
export const walletDelGroupApi = async (id: Number) => {
  const data = await Http(`/pt/api/v1/wallet/group/delete`, { id }, 'delete')
  if (data) {
    return data
  }
  return null
}
// 删除分组内用户
export const walletDelMemberApi = async (id: Number) => {
  const data = await Http(`/pt/api/v1/wallet/member/delete`, { id }, 'delete')
  if (data) {
    return data
  }
  return null
}
// 查询分组
export const walletGroupListApi = async (type: Number) => {
  const data = await Http(`/pt/api/v1/wallet/group/${type}`, {}, 'get')
  if (data) {
    return data
  }
  return null
}
// 查询关注列表分组
export const walletMemberListApi = async (id: Number) => {
  const data = await Http(`/pt/api/v1/wallet/member/${id}`, {}, 'get')
  if (data) {
    return data
  }
  return null
}
// 关注/取关钱包
export const walletAttentionApi = async (params: WalletAttentionType) => {
  const data = await Http(`/pt/api/v1/wallet/attention/`, params, 'post')
  if (data) {
    return data
  }
  return null
}
// 隐藏或显示分组
export const walletGroupVisibleApi = async (id: Number, hide: Number) => {
  // id: 分组列表查询出来的id  hide: 隐藏状态 0或1 0表示显示 1隐藏
  const data = await Http(`/pt/api/v1/wallet/group/hide/${id}/${hide}`, {}, 'put')
  if (data) {
    return data
  }
  return null
}

// Upcoming list
export const UpcomingListApi = async (params: iUpcomingListParams) => {
  const data = await Http(`/bls/api/v1/upcoming/list`, params, 'post')
  return data || null
}

// submintInfo
export const SubmitInfoApi = async () => {
  const data = await Http(`/bls/api/v1/upcoming/getsubmit`, {}, 'get')
  return data || null
}
export const AddUpcomingApi = async (params: iAddUpcomingParams) => {
  const data = await Http(`/bls/api/v1/upcoming/add`, params, 'post')
  return data || null
}

export const EditUpcomingApi = async (params: iAddUpcomingParams) => {
  const data = await Http(`/bls/api/v1/upcoming/update`, params, 'post')
  return data || null
}

export const getNonceApi = async (address: string, nonce: string) => {
  const data = await Http(`/au/api/v1/captcha/nonce/${address}/${nonce}`, {}, 'get')
  return data || null
}

// 会员邀请

export const inviteCountApi = async (pageSize: number, pageIndex: number) => {
  const data = await Http(`/au/api/v1/member/invitation/count?pageSize=${pageSize}&pageIndex=${pageIndex}`, {}, 'get')
  return data || null
}
export const inviteRankApi = async () => {
  const data = await Http(`/au/api/v1/member/invitation/rank`, {}, 'get')
  return data || null
}
export const memberInfoApi = async () => {
  const data = await Http(`/bls/api/v1/member/info`, {}, 'get')
  return data || null
}
export const inviteRecordApi = async (pageSize: number, pageIndex: number) => {
  const data = await Http(`/au/api/v1/member/invitation/record`, { pageSize, pageIndex }, 'post')
  return data || null
}
export const getMenmberInfoApi = async () => {
  const data = await Http(`/bls/api/v1/member/detail`, {}, 'get')
  return data || null
}
export const updateMenmberInfoApi = async (params: iUpdateMenmberInfoParams) => {
  const data = await Http(`/bls/api/v1/member/update/info`, params, 'post')
  return data || null
}

export const rollingMsgApi = async (size: number) => {
  const data = await Http(`/bls/api/v1/rolling/get/${size}`, {}, 'get')
  return data || null
}
export const invitationMemberApi = async () => {
  const data = await Http(`/au/api/v1/member/invitation`, {}, 'get')
  return data || null
}

// 官网首页接口
export const aboutUsApi = async () => {
  const data = await Http(`/bls/api/v1/aboutUs/data`, {}, 'get')
  if (data) {
    return data
  }
  return null
}

export const changeReportApi = async (nftId: number) => {
  const data = await Http(`/bls/api/v2/collection/report/${nftId}`, {}, 'get')
  return data || null
}
export const upcomingEditorInfoApi = async (params: iUpcomingEditorInfoParams) => {
  const data = await Http(`/bls/api/v1/upcoming/editorInfo`, params, 'post')
  return data || null
}
export const upcomingFieldsModifyApi = async (upcomingId: number) => {
  const data = await Http(`/bls/api/v1/upcoming/fieldstatus/${upcomingId}`, {}, 'get')
  return data || null
}
