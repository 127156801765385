import React, { useState, useEffect, useRef } from 'react'
import throttle from 'lodash/throttle'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { Drawer } from 'antd'
import { Flex, Box, Image, Grid, Text, Input } from '@/packages/'
import { MenuItems, MobileMenuItems } from './MenuItems'
import {
  logo,
  mobileClose,
  mobileBack,
  mobileMenu,
  mobileSearch,
  avator,
  discordActive,
  twitterActive
} from '@/assets/img'
import { SearchInput, iResult, ModalBody, SearchResultItem } from './SearchInput'
import { Wallet } from './Wallet'
import { useMatchBreakpoints } from '@/hooks/useMatchBreakpoints'
import { ContentWrapper, Row, Col, LoadingItemWrapper } from '@/components/Layout/Layout'
import { ActiveIcon } from '@/components/ActiveIcon'
import { locaStoreageRemoveItem, splitAddress } from '@/utils/tools'
import useDebounce from '@/hooks/useDebounce'
import { SearchApi, memberInfoApi } from '@/request/Api'

export const Wrapper = styled(Box)<{ h: number }>`
  height: ${({ h }) => `${h}px`};
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #111218;
  // background-color: ${(props) => props.theme.colors.main};
`
export const WrapperFooter = styled(Box)<{ h: number }>`
  height: ${({ h }) => `${h}px`};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #000;
  // background-color: ${(props) => props.theme.colors.main};
`
export const WrapperNav = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`
const FixedContainer = styled.div<{ showMenu: boolean; height: number }>`
  position: fixed;
  top: ${({ showMenu, height }) => (showMenu ? 0 : `-${height}px`)};
  left: 0;
  right: 0;
  margin: 0 auto;
  transition: top 0.3s;
  height: ${({ height }) => `${height}px`};
  width: 100%;
  max-width: 1440px;
  z-index: 20;
  background: rgba(17, 18, 24, 1);
`
const SignOut = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  border: 1px solid rgba(255, 255, 255, 0.6);
  border-radius: 20px;
  cursor: pointer;
  color: #cbcbcb;
  font-weight: 600;
  font-size: 16px;
`

export const Nav = () => {
  const [showMenu, setShowMenu] = useState(true)
  const MenuHeight = 90

  const navigate = useNavigate()

  const refPrevOffset = useRef(typeof window === 'undefined' ? 0 : window.pageYOffset)

  const totalTopMenuHeight = MenuHeight
  useEffect(() => {
    const handleScroll = () => {
      const currentOffset = window.pageYOffset
      const isBottomOfPage = window.document.body.clientHeight === currentOffset + window.innerHeight
      const isTopOfPage = currentOffset === 0
      if (isTopOfPage) {
        setShowMenu(true)
      } else if (!isBottomOfPage) {
        if (currentOffset < refPrevOffset.current || currentOffset <= totalTopMenuHeight) {
          setShowMenu(true)
        } else {
          setShowMenu(false)
        }
      }
      refPrevOffset.current = currentOffset
    }
    const throttledHandleScroll = throttle(handleScroll, 300)

    window.addEventListener('scroll', throttledHandleScroll)
    return () => {
      window.removeEventListener('scroll', throttledHandleScroll)
    }
  }, [totalTopMenuHeight])
  return (
    <FixedContainer showMenu={showMenu} height={totalTopMenuHeight}>
      <Grid height={MenuHeight} gridTemplateColumns={['1fr', '1fr', '1fr 1fr', '1fr 1fr', '1fr 1fr']} className="w100">
        <Grid gridTemplateColumns={['1fr 3fr']} gridRowGap={['16px', '40px']} className="ai-ct">
          <Image src={logo} width={132} height={30} className="click" onClick={() => navigate('/')} />
          <SearchInput />
        </Grid>
        <Grid gridTemplateColumns={['5fr 1fr']} className="fx-row ai-ct h100">
          <MenuItems />
          <Wallet />
        </Grid>
      </Grid>
    </FixedContainer>
  )
}
export const NavMobile = () => {
  const [showMenu, setShowMenu] = useState(true)
  const [menuVisible, setMenuVisible] = useState(false)
  const [searchVisible, setSearchVisible] = useState(false)
  const [searchVal, setSearchVal] = useState<string>('')
  const [isLoading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [result, setResult] = useState<Array<iResult>>([])
  const [userAddr, setUserAddr] = useState<string>('')
  const debouncedValue = useDebounce<string>(searchVal, 500)

  const MenuHeight = 60

  const navigate = useNavigate()

  const refPrevOffset = useRef(typeof window === 'undefined' ? 0 : window.pageYOffset)

  const totalTopMenuHeight = MenuHeight
  useEffect(() => {
    const handleScroll = () => {
      const currentOffset = window.pageYOffset
      const isBottomOfPage = window.document.body.clientHeight === currentOffset + window.innerHeight
      const isTopOfPage = currentOffset === 0
      if (isTopOfPage) {
        setShowMenu(true)
      } else if (!isBottomOfPage) {
        if (currentOffset < refPrevOffset.current || currentOffset <= totalTopMenuHeight) {
          setShowMenu(true)
        } else {
          setShowMenu(false)
        }
      }
      refPrevOffset.current = currentOffset
    }
    const throttledHandleScroll = throttle(handleScroll, 300)

    window.addEventListener('scroll', throttledHandleScroll)
    return () => {
      window.removeEventListener('scroll', throttledHandleScroll)
    }
  }, [totalTopMenuHeight])

  const onMenu = () => {
    setMenuVisible(!menuVisible)
  }
  const onSignOut = () => {
    locaStoreageRemoveItem('tokens')
    setTimeout(() => {
      window.location.reload()
    }, 100)
  }
  const onSearch = () => {
    setSearchVisible(!searchVisible)
  }

  const fetch = async (keyword: string) => {
    const d: any = await SearchApi(keyword)
    setLoading(false)
    if (d) {
      setResult(d)
    }
  }

  useEffect(() => {
    if (debouncedValue) {
      fetch(searchVal)
    }
  }, [debouncedValue])

  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value
    if (val) {
      setSearchVal(val)
      setVisible(true)
      setLoading(true)
    } else {
      setSearchVal('')
      setVisible(false)
      setLoading(false)
    }
  }

  const onNFTDetail = () => {
    onSearch()
  }
  const onClearSearch = () => {
    setSearchVal('')
    setVisible(false)
    setLoading(false)
    setResult([])
  }
  const fetchMemner = async () => {
    const m: any = await memberInfoApi()
    if (m && Object.keys(m).length > 0) {
      setUserAddr(m.address)
    }
  }
  useEffect(() => {
    fetchMemner()
  }, [])
  return (
    <FixedContainer showMenu={showMenu} height={totalTopMenuHeight}>
      <Row height={MenuHeight} className="w100 jc-sb">
        <Image src={menuVisible ? mobileClose : mobileMenu} height={60} width={60} className="click" onClick={onMenu} />
        <Image src={logo} height={24} width={104} className="click" onClick={() => navigate('/')} />
        <Image src={mobileSearch} height={60} width={60} className="click" onClick={onSearch} />
      </Row>
      <Drawer
        placement="left"
        contentWrapperStyle={{ top: MenuHeight }}
        drawerStyle={{ background: '#121212' }}
        mask={false}
        open={menuVisible}
        closable={false}
      >
        <Col>
          <Row my="24px">
            <Image src={avator} height={50} width={50} className="cicle" />
            <Col ml="16px">
              {/* <Text className="white fz16 fw600">开发中</Text> */}
              <Text className="white38 fz12 fw400">{splitAddress(userAddr, 4, 4)}</Text>
            </Col>
          </Row>
          <MobileMenuItems onClickItem={onMenu} />
          <Col className="ai-ct" mt="100%">
            <SignOut onClick={onSignOut}>SIGN OUT</SignOut>
            <Row mt="24px">
              <ActiveIcon
                src={discordActive}
                activeSrc={discordActive}
                targetUrl="https://discord.gg/3QhPyqXdmJ"
                tooltip=""
                h={16}
                w={20}
              />
              <Box ml="24px">
                <ActiveIcon
                  src={twitterActive}
                  activeSrc={twitterActive}
                  targetUrl="https://twitter.com/peweetools"
                  tooltip=""
                  h={16}
                  w={19}
                />
              </Box>
            </Row>
          </Col>
        </Col>
      </Drawer>
      <Drawer
        placement="right"
        width="100%"
        drawerStyle={{ background: '#121212' }}
        mask={false}
        open={searchVisible}
        closable={false}
      >
        <Col>
          <Row>
            <Image src={mobileBack} height={60} width={60} className="click" onClick={onSearch} />
            <Row className="w100">
              <input
                value={searchVal}
                onChange={onChangeSearch}
                placeholder="Search by address or name"
                className="mobile_search_input"
              />
              <Image src={mobileClose} height={60} width={60} className="click" onClick={onClearSearch} />
            </Row>
          </Row>
          {visible && (
            <Box className="hide_scrollbar" height="310px" style={{ overflow: 'scroll' }}>
              {result &&
                !!result.length &&
                result.map((item: iResult) => (
                  <SearchResultItem
                    key={Math.random().toString()}
                    imageUrl={item.imageUrl}
                    name={item.name}
                    nftId={item.nftId}
                    totalVolume={item.totalVolume}
                    type={item.type}
                    address={item.address}
                    onDetail={onNFTDetail}
                  />
                ))}
              {isLoading && (
                <Box className="w100">
                  <LoadingItemWrapper height={62} mt={0} count={5} />
                </Box>
              )}
            </Box>
          )}
        </Col>
      </Drawer>
    </FixedContainer>
  )
}
