import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Checkbox, Modal } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import Web3 from 'web3'
import { Flex, Box, Text, Image, Grid } from '@/packages'
import { metamask, DC, copyIcon } from '@/assets/img'
import { Row, Col } from '@/components/Layout/Layout'
import { useEthereum } from '@/hooks/useEthereum'
import { useAppContext } from '@/hooks/useAppContext'
import { WalletLoginWithoutSignAction } from '@/store/actions/NavAction'
import { locaStoreageRemoveItem, openWindow } from '@/utils/tools'
import useCopyToClipboard from '@/hooks/useCopyToClipboard'
import { PrivacyPolicy } from './PrivacyPolicy'
import { getNonceApi, walletLoginWithSignApi, walletLoginWithoutSignApi } from '@/request/Api'
import { iWalletLogin } from '@/types/store'
import { useMatchBreakpoints } from '@/hooks/useMatchBreakpoints'
import { OpenNotification, CloseNotification } from '@/components/OpenNotification'
import { useChainChanged } from '@/hooks/useChainChanged'
import { isMainNet } from '@/utils/chain'

const ConnectBtn = styled(Box)<{ active: boolean }>`
  height: 44px;
  background: ${({ active }) => (active ? '#00B2B5' : '#2E2E2E')};
  cursor: ${({ active }) => (active ? 'pointer' : 'not-allowed')};
  border-radius: 20px;
`

export const AccessModal = () => {
  const [checked, setChecked] = useState<boolean>(false)
  const { ethereum, provider } = useEthereum()
  const { state, dispatch } = useAppContext()
  const [visible, setVisible] = useState(false)
  const [pVisible, setPVisible] = useState(false)
  const [connectTimes, setConnectTimes] = useState<number>(0)
  const [value, copy] = useCopyToClipboard()
  const { isMobile } = useMatchBreakpoints()
  const chainId = useChainChanged()

  console.log('chainId', chainId)

  const discord = 'https://discord.com/invite/3QhPyqXdmJ'

  const navigate = useNavigate()

  const onChange = (e: CheckboxChangeEvent) => {
    setChecked(e.target.checked)
  }

  useEffect(() => {
    if (chainId === '0x1' && connectTimes !== 0) {
      CloseNotification('switchChainMsg')
      onConnect()
    }
  }, [chainId])
  const onConnect = async () => {
    if (checked && ethereum) {
      const net = await isMainNet()
      setConnectTimes(connectTimes + 1)
      if (net) {
        try {
          const address = await ethereum.request({ method: 'eth_requestAccounts' })
          getNonce(address[0])
        } catch (error: any) {
          OpenNotification(error.message, 'warning')
        }
      } else {
        try {
          await ethereum.request({ method: 'wallet_switchEthereumChain', params: [{ chainId: '0x1' }] })
        } catch (error) {
          OpenNotification('Please switch the network to the Ethereum Mainnet.', 'warning', 'never', 'switchChainMsg')
          console.log('error', error)
        }
      }
    }
  }
  const getNonce = async (addr: string) => {
    const r: string = uuidv4()
    const n: string = r.replace(/-/g, '').substring(0, 16)
    const d: any = await getNonceApi(addr, n)

    if (d?.code === '990004') {
      // 不在白名单
      OpenNotification('User is not found', 'error')
      setVisible(true)
      locaStoreageRemoveItem('tokens')
    }
    if (d) {
      const { message, needSign, nonce } = d
      if (needSign) {
        const credential = await ethereum.request({
          method: 'personal_sign',
          params: [message, addr, '']
        })
        const inviter = getInviterAddress()
        let withData: any
        if (inviter) {
          withData = await walletLoginWithSignApi({ principal: addr, credential, nonce, inviter })
        } else {
          withData = await walletLoginWithSignApi({ principal: addr, credential, nonce })
        }
        walletAction(withData)
      } else {
        const withoutData: any = await walletLoginWithoutSignApi(addr)
        walletAction(withoutData)
      }
    }
  }
  const getInviterAddress = () => {
    // http://localhost:3333/#/?inviter=0x3e20e2a94562672a220ec657b40255cd18dae9f8
    const { href } = window.location
    if (href && href.includes('?inviter=')) {
      const idx = href.indexOf('?inviter=')
      const addr = href.substring(idx + 9)
      if (addr.startsWith('0x') && addr.length === 42) {
        return addr
      }
    }
  }
  const walletAction = (d: iWalletLogin) => {
    if (d) {
      WalletLoginWithoutSignAction(d, dispatch)
    }
  }

  const onCancel = () => {
    setVisible(false)
  }

  return (
    <Box className="w100">
      {!pVisible ? (
        <Col className="w100 center" mt="180px">
          <Text className="white87" fontSize={['20px', '30px', '40px', '40px']}>
            Sign in to Pewee Tools
          </Text>
          <ConnectBtn
            mt={['40px', '50px', '60px', '60px']}
            className="center click"
            active={checked}
            onClick={onConnect}
            width={['340px', '340px', '400px', '400px']}
          >
            <Image src={metamask} width={isMobile ? 16 : 20} height={isMobile ? 16 : 20} />
            <Text color={checked ? '#FFFFFF' : '#636363'} fontSize={['12px', '14px', '16px', '16px']} ml="10px">
              CONNECT WALLET
            </Text>
          </ConnectBtn>
          <Grid mt="24px" gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr', '1fr 1fr']}>
            <Row>
              <Box mt="-8px">
                <Checkbox onChange={onChange} />
              </Box>
              <Text color="#cbcbcb" fontSize={['14px', '14px', '16px', '16px']} ml="12px">
                I have read and agreed Pewee’s
              </Text>
            </Row>
            <Text
              fontSize={['14px', '14px', '16px', '16px']}
              color="#00B2B5"
              className="click ud-line"
              ml={['26px', '4px', '4px', '4px']}
              onClick={() => setPVisible(true)}
            >
              Terms of Service and Privacy Policy
            </Text>
          </Grid>
        </Col>
      ) : (
        <PrivacyPolicy onClose={() => setPVisible(false)} />
      )}
      <Modal
        closeIcon={<CloseOutlined style={{ color: '#fff' }} />}
        visible={visible}
        onCancel={onCancel}
        bodyStyle={{
          background: '#1e2229',
          paddingBottom: 24,
          paddingTop: 44
        }}
        maskClosable={false}
        footer={null}
      >
        <Col className="w100 ai-ct">
          <Text color="#fff">Test quota is full, Welcome join our Discord</Text>
          <Image src={DC} height={60} width={60} className="mt50 click" onClick={() => openWindow(discord)} />
        </Col>
      </Modal>
    </Box>
  )
}
