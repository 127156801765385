import React, { useState, useEffect, ChangeEvent, useImperativeHandle, useRef } from 'react'
import { Input as AntInput } from 'antd'
import { decimalNum } from '@/utils/tools'
import { Col, Row } from '@/components/Layout/Layout'
import { Text, Box, Image } from '@/packages/'
import { upcomingInputHover, upcomingInputClear, upcomingInputSave } from '@/assets/img'
import useDebounce from '@/hooks/useDebounce'
import { UserAvatorPopOver } from './UserAvatorPopOver'
import { iQuery } from './InputContainer'

interface iShortInput {
  placeholder: string
  onChange: (v: string) => void
  inputRef?: any
  suffix?: React.ReactNode
  editValue?: string
  errorMsg: string
  classN?: string
  disabled?: number
  isEdit: boolean
  query: iQuery
  isModifyed: boolean
}
export const ShortInput: React.FC<iShortInput> = ({
  classN,
  errorMsg,
  placeholder,
  onChange,
  inputRef,
  suffix,
  editValue,
  disabled,
  isEdit,
  query,
  isModifyed
}) => {
  const [value, setValue] = useState<string>('')
  const [inputStatus, setInputStatus] = useState<string>('')
  const [inputBg, setInputBg] = useState<string>('rgb(28,28,28)')
  const [inputBd, setInputBd] = useState<string>('1px solid transparent')
  const debouncedValue = useDebounce<string>(value, 500)
  const iRef: any = useRef()

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value
    // 最多支持输入至小数点后4位。
    if (!decimalNum(val, 4)) {
      return
    }
    setValue(val)
    if (editValue) {
      setInputStatus('inputable')
    }
    if (val === '') onChange('')
    if (val && Number.isNaN(Number(val))) {
      setValue('')
      onChange('')
    }
  }
  useEffect(() => {
    if (debouncedValue) {
      onChange(value)
    }
  }, [debouncedValue])

  useEffect(() => {
    if (editValue !== 'null' && editValue !== null) {
      if (editValue || Number(editValue) === 0) {
        // console.log('editValue=======', editValue)
        setValue(`${editValue}`)
        setInputStatus('')
      }
    }
    return () => setValue('')
  }, [editValue])

  useImperativeHandle(inputRef, () => ({
    clearInputValue: () => {
      setValue('')
      onChange('')
    }
  }))

  const onClickSave = () => {
    onChange(value)
    setInputStatus('')
  }
  const onClickClear = () => {
    setValue('')
    onChange('')
  }

  const onClickHover = () => {
    setInputStatus('inputable')
    iRef.current && iRef.current.focus()
  }
  const onMouseOver = () => {
    if (isEdit && inputStatus !== 'inputable') {
      setInputStatus('hover')
    }
  }
  const onMouseLeave = () => {
    // if (inputStatus !== 'inputable' && inputStatus !== 'hover') {
    setInputStatus('')
    // }
  }
  const onInputFocus = () => {
    setInputBd('1px solid #00B2B5')
  }
  const onInputBlur = () => {}
  // console.log('value=======', value)

  return (
    <Col className="" width="200px">
      <Row className="w100">
        <Box className="w100" onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
          <AntInput
            disabled={isEdit ? inputStatus !== 'inputable' : false}
            status={errorMsg ? 'error' : ''}
            suffix={
              <Row>
                {inputStatus === 'hover' && (
                  <Image src={upcomingInputHover} height={18} width={18} className="click mr8" onClick={onClickHover} />
                )}
                {inputStatus === 'inputable' && (
                  <Row>
                    <Image src={upcomingInputSave} height={18} width={18} className="click" onClick={onClickSave} />
                    <Image
                      src={upcomingInputClear}
                      height={18}
                      width={18}
                      className="click ml8 mr8"
                      onClick={onClickClear}
                    />
                  </Row>
                )}
                {suffix}
              </Row>
            }
            bordered
            value={value}
            onChange={handleChange}
            placeholder={placeholder}
            className="mask_input"
            style={{ width: '150px' }}
          />
        </Box>
        <UserAvatorPopOver {...query} isModifyed={isModifyed} />
      </Row>
      {errorMsg ? (
        <Text color="#D84549" className="fz14 fw500 beyondHide" mt="6px">
          {errorMsg}
        </Text>
      ) : (
        <Box height="20px" />
      )}
    </Col>
  )
}
