import React, { useEffect } from 'react'
import { Image, Box, Grid, Text } from '@/packages'
import { Col, Row, ETHValue, PeweeTooltip } from '@/components/Layout/Layout'
import { Chart } from '@/components/Charts/Chart'
import { LiveView7DayVolumeOption } from '@/components/Charts/options/index'
import { useAppContext } from '@/hooks/useAppContext'
import { iCollectionInfo } from '@/types/store/index'
import { valumeUnit, toNonExponential, accMul } from '@/utils/tools'
import { KeyValue } from '../Sniper/OverView'
import { notice } from '@/assets/img'
import { useMatchBreakpoints } from '@/hooks/useMatchBreakpoints'

export const NFTData: React.FC<iCollectionInfo> = ({
  mintPrice,
  nbcp,
  holders,
  max,
  min,
  supply,
  totalVolumeOf7D,
  volumes,
  royalties,
  openSeaRoyalties
}) => {
  const { state, dispatch } = useAppContext()
  const { isMobile, isTablet } = useMatchBreakpoints()

  return (
    <Box height={isMobile ? '164px' : '234px'} className="body_wrapper w100" px={['16px', '24px', '24px', '24px']}>
      <Grid gridTemplateColumns={!isMobile ? 'repeat(4, 1fr)' : 'repeat(3, 1fr)'} mt="24px">
        <KeyValue title="SUPPLY" value={valumeUnit(supply)} />
        <KeyValue title="HOLDERS" value={valumeUnit(holders) || '--'} />
        {isMobile || isTablet ? (
          <KeyValue title="NBCP" value={nbcp ? `${toNonExponential(Number(nbcp) * 100)}%` : '--'} />
        ) : (
          <PeweeTooltip title="Next Blue Chip Probability (NBCP): Percentage of owners of this collection who have at least one Blue Chip NFT. The NBCP displayed here is refreshed daily.">
            <Col className="h100 w100 jc-start click">
              <Row>
                <Text fontSize="12px" className="white38 fw600 beyondHide">
                  NBCP
                </Text>
                <Image src={notice} height={10} width={10} className="ml6" />
              </Row>
              <Text fontSize={['14px', '14px', '16px', '16px']} className="white87 fw600" mt="6px">
                {nbcp ? `${toNonExponential(Number(nbcp) * 100)}%` : '--'}
              </Text>
            </Col>
          </PeweeTooltip>
        )}
        {!isMobile && <KeyValue title="ROYALTY" value={`${accMul(toNonExponential(royalties), 100)}%`} />}
      </Grid>
      <Grid gridTemplateColumns={!isMobile ? 'repeat(4, 1fr)' : 'repeat(3, 1fr)'} mt="20px">
        <KeyValue title="MINT PRICE" values={<ETHValue value={Number(valumeUnit(mintPrice))} fw={600} />} />
        {!isMobile ? (
          <KeyValue
            title="24H MIN / MAX PRICE"
            values={
              <Row>
                <Box mr="12px">
                  <ETHValue value={Number(valumeUnit(min))} fw={600} />
                </Box>
                <ETHValue value={Number(valumeUnit(max))} fw={600} />
              </Row>
            }
          />
        ) : (
          <KeyValue title="ROYALTY" value={`${accMul(toNonExponential(royalties), 100)}%`} />
        )}
      </Grid>
      {!isMobile && (
        <Grid gridTemplateColumns="repeat(4, 1fr)" mt="20px">
          <KeyValue
            title="7D VOLUME"
            values={
              <Row className="">
                <ETHValue fw={600} value={Number(valumeUnit(totalVolumeOf7D))} />
                <Box width="80px" height="40px" ml="10px">
                  <Chart option={LiveView7DayVolumeOption(volumes)} />
                </Box>
              </Row>
            }
          />
        </Grid>
      )}
    </Box>
  )
}
