import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { Box, Text, Image, Grid } from '@/packages/'
import { Row, Col, LoadingItemWrapper, ListingLoadWrapper } from '@/components/Layout/Layout'
import { whale, pewee, isOS, viewMore } from '@/assets/img'
import { WhalesActivityApi } from '@/request/Api'
import { beyondStr, someTimesAgo, splitAddress } from '@/utils/tools'
import { TitleDesc } from './component'
import { iListingRes, iWhalesActivityParams } from '@/request/types'
import { HoverWrapper } from '@/components/Layout/HoverWrapper'
import { NoData } from '@/components/NoData'
import { useMatchBreakpoints } from '@/hooks/useMatchBreakpoints'

const SmallBox = styled(Box)`
  height: 62px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.04);
  margin-bottom: 8px;
`
const SamllBoxlf = styled(Box)`
  height: 62px;
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.87);
`

export const WhalesActivity = (wsSocket: any) => {
  const [list, setList] = useState<Array<any>>([])
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(20)
  const [isLoading, setLoading] = useState<boolean>(true)
  const [statusType, setStatusType] = useState<boolean>(true)
  const [collectionList, setCollectionList] = useState<Array<any>>([])
  const [isInit, setInit] = useState(true)
  const [isPause, setPause] = useState<boolean>(false)
  const navigate = useNavigate()
  const { isMobile, isTablet } = useMatchBreakpoints()

  const [query, setQuery] = useState<iWhalesActivityParams>({
    pageIndex: 1,
    pageSize
  })
  const listRef: any = useRef()
  const pauseRef: any = useRef()
  const fetch = async () => {
    const p = {
      pageIndex,
      pageSize
    }
    setLoading(true)
    const d: any = await WhalesActivityApi(query)
    setInit(false)
    setLoading(false)
    if (d && d.records && d.records.length > 0) {
      const combime = [...list, ...d.records]
      setList(combime)
    }
  }
  useEffect(() => {
    fetch()
  }, [pageIndex])
  useEffect(() => {
    listRef.current = list
    pauseRef.current = isPause
    if (wsSocket && wsSocket.socket) {
      if (isInit) {
        try {
          setTimeout(() => {
            wsSocket.socket.client.subscribe(`/topic/whalesActive-new`, (res: any) => {
              const d = JSON.parse(res.body)
              console.log('ws data==whalesActive-new>>>>>>>>', d)
              if (!pauseRef.current) {
                listRef.current.unshift(d)
                setList([...listRef.current])
              }
            })
          }, 10100)
        } catch (error) {
          console.log('connect error', error)
        }
      }
    }
  }, [list, isPause])
  const onViewMore = () => {}
  const handleDetail = (nId: number) => {
    nId && navigate(`/trending/liveview/${nId}`)
  }
  const onSactivityBottom = () => {
    if (!isLoading) {
      if (pageIndex < 5) {
        const p = JSON.parse(JSON.stringify(query))
        const newIndex = pageIndex + 1
        setPageIndex(newIndex)
        p.pageIndex = newIndex
        setQuery(p)
      }
    }
  }
  const mouseOver = () => {
    if (wsSocket && wsSocket.socket) {
      try {
        setPause(true)
        setStatusType(false)
      } catch (error) {
        console.log('error', error)
      }
    }
  }
  const mouseLeave = () => {
    setStatusType(true)
    setPause(false)
  }
  return (
    <Box className="w100">
      <Box mt={['-32px', '-32px', '-60px', '-60px']}>
        <TitleDesc title="Whales Activity" subTitle="Don’t miss out on the big play." statusType={statusType} />
      </Box>
      <HoverWrapper mouseOver={mouseOver} mouseLeave={mouseLeave}>
        <ListingLoadWrapper onContainerBottom={onSactivityBottom} height="490px">
          {!!list.length &&
            list.map((item: any) => {
              return (
                <SmallBox
                  px={['8px', '20px', '24px', '24px']}
                  className="fx-row ai-ct jc-sb"
                  onClick={() => handleDetail(item.nftId)}
                  key={Math.random()}
                >
                  <SamllBoxlf className="fx ai-ct">
                    <Box mr={['6px', '6px', '12px', '12px']}>
                      <Image height={40} width={40} src={item.imageUrl} className="circle" />
                    </Box>
                    <Grid gridTemplateColumns={['1fr', '3fr 5fr', '3fr 5fr', '3fr 5fr']}>
                      <Row>
                        <Text title={item.address} fontSize="14px" mr="8px" className="" color="#2FA8CC">
                          {item.type === 1 ? `${splitAddress(item.address, 4, 2)}.eth` : item.whales}
                        </Text>

                        <Image height={14} width={20} src={whale} />
                        <Text mx="10px" className="fw400 fz14" color={item.tradeType === 4 ? '#FF8715' : '#FFE800'}>
                          {item.tradeType === 4 ? 'Minted' : 'Bought'}
                        </Text>
                      </Row>
                      <Row>
                        <Text mr="4px" fontSize="14px" className="white87">
                          {item.num}
                        </Text>
                        <Text
                          mx={['4px', '6px', '8px', '8px']}
                          width={['50px', '80px', '100px', '100px']}
                          className="beyondHide white87"
                          fontWeight={600}
                          fontSize="14px"
                        >
                          {item.nftName}
                        </Text>
                        {item.verifyStatus === 1 && <Image height={16} width={16} src={isOS} />}
                        <Text ml="8px" className="fz14 fw400 white60">{`in ${item.timeType}`}</Text>
                      </Row>
                    </Grid>
                  </SamllBoxlf>
                  <Text className="fw400 white38 beyondHide" fontSize={['12px', '14px', '14px', '14px']}>
                    {someTimesAgo(item.time)}
                  </Text>
                </SmallBox>
              )
            })}
          {!isLoading && !list.length && <NoData isLoading={false} />}
          {isLoading && <LoadingItemWrapper height={62} count={7} />}
        </ListingLoadWrapper>
      </HoverWrapper>
    </Box>
  )
}
