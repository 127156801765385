import React, { useReducer } from 'react'
import './App.css'
// import ResponsiveRGL from './components/RGL/Responsive'
import { ThemeProvider, useTheme } from 'styled-components'
import { AppRouter } from './routers'
import { AppContext } from '@/store'
import { AppInitState, AppReducer } from '@/store/reducers'
import { GlobalCheckClaimStatus } from '@/components/GlobalCheckClaimStatus'
import { darkTheme } from '@/theme/dark'
import { lightTheme } from '@/theme/light'

const App = () => {
  const [state, dispatch] = useReducer(AppReducer, AppInitState)
  const isDark = true
  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <ThemeProvider theme={isDark ? darkTheme : lightTheme}>
        <GlobalCheckClaimStatus />
        <AppRouter />
      </ThemeProvider>
    </AppContext.Provider>
  )
}
export default App
