import { toNonExponential } from '@/utils/tools'
import {
  ChartBgColor,
  axisLabel,
  fullGrid,
  axisLine,
  axisTick,
  splitLine,
  barColor,
  axisLabel2,
  tooltipBg
} from './types'

export const ListingDepthOption = (options: any[]): any => {
  const Depth1: Array<any> = []
  const Depth0: Array<any> = []
  const xAxis: Array<string> = []

  const lastBarItem: any = options[options.length - 1]

  options.forEach((item: any, idx: number) => {
    const { priceRange, count } = item
    const { min: _min, max: _max } = priceRange
    const { priceRange: lastPriceRange, count: lastCount } = lastBarItem
    const { min: lastMin, max: lastMax } = lastPriceRange
    if (count === lastCount && _min === lastMin && _max === lastMax) {
      xAxis.push(` `)
      Depth1.push([` `, item.count, item])
      // xAxis.push(`X≥${toNonExponential(_min)}`)
      // Depth1.push([`X≥${toNonExponential(_min)}`, item.count, item])
    } else {
      xAxis.push(`${toNonExponential(_max)}`)
      // xAxis.push(`X<${toNonExponential(_max)}`)
      Depth1.push([`${toNonExponential(_max)}`, item.count, item])
      // Depth1.push([`X<${toNonExponential(_max)}`, item.count, item])
    }
    Depth0.push(0)
  })
  return {
    backgroundColor: ChartBgColor,
    tooltip: {
      backgroundColor: tooltipBg,
      borderRadius: 12,
      borderWidth: 0,
      padding: 0,
      borderColor: 'transparent',
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      },
      formatter(value: any) {
        const item = value[1].value
        const { priceRange, count } = item[2]
        const { min: _min, max: _max } = priceRange
        const { priceRange: lastPriceRange, count: lastCount } = lastBarItem
        const { min: lastMin, max: lastMax } = lastPriceRange
        let rangeItem: string = ''
        if (count === lastCount && _min === lastMin && _max === lastMax) {
          rangeItem = `Price in Ξ ≥ ${toNonExponential(_min)}`
        } else {
          rangeItem = `Price in Ξ < ${toNonExponential(_max)}`
        }
        const temp = `
          <div class="custom_tooltip_container" style="padding: 10px;">
            <div id="tooltip" style="color: rgb(30,47,50);">
               ${rangeItem}
            </div>
            <div class="fx-row jc-sb mt5">
              <div id="tooltip" style="color: rgb(30,47,50);">Count</div>
              <div id="tooltip" style="color: rgb(30,47,50);">${item[1] || 0}</div>
            </div>
          </div>
        `
        return temp
      }
    },
    xAxis: {
      data: xAxis,
      axisLabel: Object.assign(axisLabel, { interval: 0, rotate: 0 }),
      axisLine,
      axisTick
    },
    yAxis: {
      type: 'value',
      splitLine,
      axisLabel
    },
    grid: fullGrid,
    series: [
      {
        name: 'Depth',
        type: 'bar',
        barWidth: 10,
        // minBarWidth: 10,
        // maxBarWidth: 84,
        color: '#0F0F12',
        stack: 'Total',
        itemStyle: {
          borderRadius: [2, 2, 0, 0],
          borderColor: 'transparent',
          color: 'transparent'
        },
        emphasis: {
          itemStyle: {
            borderColor: 'transparent',
            color: 'transparent'
          }
        },
        data: Depth0
      },
      {
        type: 'bar',
        barWidth: 10,
        // minBarWidth: 10,
        // maxBarWidth: 84,
        color: barColor,
        data: Depth1,
        showBackground: false,
        stack: 'Total',
        label: {
          show: false
          // position: 'inside',
          // color: '#00B2B5'
        },
        itemStyle: {
          normal: {
            borderRadius: [2, 2, 0, 0],
            color: '#0B7677'
            // borderColor: '#00B2B5',
            // borderWidth: 1
          }
        }
      }
    ]
  }
}
