import { toNonExponential, ymdhmsFormate, timeFormate2, splitAddress, getRankColor } from '@/utils/tools'
import {
  ChartBgColor,
  axisLine,
  axisTick,
  splitLine,
  tooltipBd1,
  tooltipBd2,
  tooltipBg,
  nameTextStyle,
  axisLabelDecimal,
  tooltipTextColor
} from './types'

function add0(list: Array<string>) {
  // const list = ['0','0.05','0.1','0.15','0.234','0.25']
  let len = 1
  let index = 0
  list.forEach((item: string, idx: number) => {
    if (item.length > len) {
      len = item.length
      index = idx
    }
  })

  const newList: Array<string> = []
  list.forEach((item: string) => {
    const times = len - item.length
    if (item !== '0') {
      if (times > 0) {
        const aa = '0'.repeat(times)
        newList.push(`${item}${aa}`)
      } else {
        newList.push(item)
      }
    } else {
      newList.push('0')
    }
  })

  return newList
}

const yList: Array<string> = []

export const RankTradesOption = (params: any, markPrice?: number): any => {
  const { tradesWithHeightRank, tradesWithLowRank, tradesWithMiddleRank, tradesWithPoorRank, trades, dates, coin } =
    params
  const TradeHeightRank: any[] = []
  const TradeMiddleRank: any[] = []
  const TradeLowRank: any[] = []
  const TradePoorRank: any[] = []

  tradesWithHeightRank.forEach((t: any, i: number) => {
    TradeHeightRank.push([t.dataTime, t.price, t])
  })
  tradesWithMiddleRank.forEach((t: any, i: number) => {
    TradeMiddleRank.push([t.dataTime, t.price, t])
  })
  tradesWithLowRank.forEach((t: any, i: number) => {
    TradeLowRank.push([t.dataTime, t.price, t])
  })
  tradesWithPoorRank.forEach((t: any, i: number) => {
    TradePoorRank.push([t.dataTime, t.price, t])
  })

  return {
    backgroundColor: ChartBgColor,
    tooltip: {
      backgroundColor: tooltipBg,
      borderColor: 'transparent',
      borderRadius: 12,
      borderWidth: 0,
      padding: 0,
      axisPointer: {
        type: 'shadow'
      },
      trigger: 'item',
      formatter(value: any) {
        const item: any = value.value[2]
        const temp = `
          <div id="custom_tooltip_container" style="border:1px solid ${value.color}">
            <div id="tooltip" style="color: rgb(30,47,50);margin-bottom: 5px">${ymdhmsFormate(item.dataTime)}</div>
            <div class="fx-row jc-sb mt5"  style="color:${value.color};font-weight: 600;">
              <div id="tooltip">Rank</div>
              <div id="tooltip">${item?.nftToken?.rank || '--'}</div>
            </div>
            <div class="fx-row jc-sb mt5">
              <div id="tooltip">Sold</div>
              <div id="tooltip" >Ξ ${item?.price ? toNonExponential(item?.price) : 0}</div>
            </div>
            <div class="fx-row jc-sb mt5">
              <div id="tooltip">Token ID</div>
              <div id="tooltip" >#${
                item.nftToken
                  ? item.nftToken.tokenId.length > 10
                    ? splitAddress(item.nftToken.tokenId)
                    : item.nftToken.tokenId
                  : '--'
              }</div>
            </div>
            <img src="${
              item?.nftToken?.imageUrl
            }" style="width: 130px; height: 130px; margin-top: 10px;border-radius: 6px;"/>
          </div>
        `
        return temp
      }
    },
    grid: {
      left: '6%',
      right: 50,
      top: 30,
      bottom: 6
    },
    xAxis: [
      {
        type: 'category',
        axisLine,
        axisTick,
        // axisPointer: {
        //   label: { show: false }
        // },
        axisLabel: {
          show: true,
          textStyle: {
            color: 'rgba(255, 255, 255, 0.6)',
            fontSize: 14
          },
          formatter(value: any) {
            return `${timeFormate2(Number(value))}`
          }
        },
        data: dates,
        min: dates[0],
        max: dates[dates.length - 1],
        interval: dates[1] - dates[0]
      }
    ],
    yAxis: [
      {
        nameTextStyle: {
          color: 'rgba(255, 255, 255, 0.6)',
          fontSize: 14,
          fontWeight: 700,
          padding: 40
        },
        // axisPointer: {
        //   show: true,
        //   snap: true,
        //   label: {
        //     show: true,
        //     precision: 3,
        //     color: '#121212',
        //     fontWeight: 400,
        //     backgroundColor: '#fff',
        //     formatter: (f: any) => {
        //       return `Floor ${f.value.toFixed(3)}`
        //     }
        //   }
        // },
        nameLocation: 'center',
        type: 'value',
        name: 'PRICE Ξ',
        axisLabel: {
          formatter: (value: number) => axisLabelDecimal(value),
          textStyle: {
            color: 'rgba(255, 255, 255, 0.6)',
            fontSize: 14
          }
        },
        splitLine,
        axisTick,
        axisLine
      }
    ],
    series: [
      {
        name: 'tradesWithHeightRank',
        type: 'scatter',
        symbolSize: 8,
        color: '#FFE800',
        data: TradeHeightRank
      },
      {
        name: 'tradesWithLowRank',
        type: 'scatter',
        symbolSize: 8,
        color: '#2D6FF1',
        data: TradeLowRank
      },
      {
        name: 'tradesWithMiddleRank',
        type: 'scatter',
        symbolSize: 8,
        color: '#9452FF',
        data: TradeMiddleRank
      },
      {
        name: 'tradesWithPoorRank',
        type: 'scatter',
        symbolSize: 8,
        color: '#727272',
        data: TradePoorRank,
        markLine: {
          silent: true,
          symbol: 'none',
          precision: 4,
          label: {
            show: true,
            position: 'start',
            color: '#121212',
            fontWeight: 400,
            backgroundColor: '#fff',
            padding: [4, 6, 4, 6],
            borderRadius: 10,
            formatter: (f: any) => {
              return `${f.value.toFixed(3)}`
            }
          },
          lineStyle: {
            color: '#fff'
          },
          data: [
            {
              name: 'Floor Price',
              yAxis: markPrice
            }
          ]
        }
      }
    ]
  }
}
