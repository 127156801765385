import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Box, Text } from '@/packages/'
import { Row } from '@/components/Layout/Layout'

export interface iTabItem {
  id: number
  name: string
  active: boolean
}
interface iTabListSelected {
  list: Array<iTabItem>
  onChange: (item: any) => void
  selectedList: boolean
  ftsz?: number
  ml: number
}

export const TabItem = styled(Box)<{ isActive: boolean }>`
  cursor: pointer;
  background: ${({ isActive }) => (isActive ? 'rgba(0,178,181,0.08)' : 'transparent')};
  border-radius: 20px;
`

export const TabListSelected: React.FC<iTabListSelected> = ({ list, onChange, selectedList, ftsz, ml }) => {
  const [tabList, setTabList] = useState<Array<iTabItem>>(list)

  useEffect(() => {
    setTabList(list)
  }, [list])
  const changeIdx = (index: number) => {
    const _tabList = JSON.parse(JSON.stringify(tabList))
    _tabList.forEach((tab: iTabItem) => {
      if (tab.id === index) {
        tab.active = true
      } else {
        tab.active = false
      }
    })
    onChange(selectedList ? _tabList : _tabList.find((_tab: iTabItem) => _tab.active === true))
    setTabList(_tabList)
  }

  return (
    <Row>
      {tabList.map((item: iTabItem, index: number) => (
        <TabItem
          p={['6px', '6px', '10px', '10px']}
          isActive={item.active}
          key={item.id}
          ml={index === 0 ? 0 : ml}
          onClick={() => changeIdx(index)}
        >
          <Text
            className="tab_text_hover"
            fontSize={ftsz ? `${ftsz}px` : '16px'}
            color={item.active ? '#00B2B5' : '#fff'}
            fontWeight={ftsz ? 500 : 700}
          >
            {item.name}
          </Text>
        </TabItem>
      ))}
    </Row>
  )
}
