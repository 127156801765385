import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { Box, Grid, Text, Image } from '@/packages/'
import { useAppContext } from '@/hooks/useAppContext'
import { MarketCapAction } from '@/store/actions/DashbordAction'
import { IncreaseText } from '@/components/'
import { Chart } from '@/components/Charts/Chart'
import { MarketCap30DOption } from '@/components/Charts/options/index'
import { iVolume } from '@/types/store/index'
import { Row, Col, CardTitle, PeweeTooltip, ChartContainer, ETHValue } from '@/components/Layout/Layout'
import { BoxProps } from '@/packages/Box/types'
import { tooltip, viewAll, downArrow, upArrow } from '@/assets/img'
import { NoData } from '@/components/NoData'
import { MarketCapApi } from '@/request/Api'
import { useMatchBreakpoints } from '@/hooks/useMatchBreakpoints'
import { valumeUnit, locaStoreageSetItem, locaStoreageGetItem } from '@/utils/tools'

const Container = styled(Box)``
const Title = styled(Box)`
  height: 94px;
`

const BgBox = styled(Box)<{ color: string }>`
  background-color: ${({ color }) => color};
  width: 6px;
  height: 6px;
  border-radius: 50%;
`
interface iLegend extends BoxProps {
  color: string
  name: string
  title: string
  value: number
  increase: boolean
  percent: number
  visibleChart: () => void
}
export const Legend: React.FC<iLegend> = ({ color, name, title, value, increase, percent, visibleChart, ...props }) => {
  return (
    <Row {...props} className="">
      <BgBox color={color} />
      <Text mr="10px" fontSize="14px" className="click white87" fontWeight={400} ml="4px" onClick={visibleChart}>
        {name}
      </Text>
      {/* <PeweeTooltip title={title}>
          <Image src={tooltip} height={16} width={16} className="ml10 click" />
        </PeweeTooltip> */}
      <Row>
        <ETHValue value={valumeUnit(value)} fw={600} />
        <IncreaseText increase={increase} percent={percent} ml="8px" />
      </Row>
    </Row>
  )
}
export const MarketCap = () => {
  const { state, dispatch } = useAppContext()
  const [showBar, setShowBar] = useState<boolean>(true)
  const [showLine, setShowLine] = useState<boolean>(true)
  const [temp, setTemp] = useState<any>(null)
  const [reducerMarketCap, setReducerMarketCap] = useState<any>(null)
  const [chartList, setChartList] = useState<any>()
  const [market, setMarket] = useState<iVolume>({
    value: 0,
    increase: false,
    percent: 0
  })
  const [volume, setVolume] = useState<iVolume>({
    value: 0,
    increase: false,
    percent: 0
  })
  const [isHide, setHide] = useState<boolean>(false)
  const [isLoading, setLoading] = useState<boolean>(true)
  const { isMobile } = useMatchBreakpoints()

  const navigate = useNavigate()

  const fetch = async () => {
    setLoading(true)
    const d: any = await MarketCapApi()
    setLoading(false)
    if (d) {
      setMarket(d.marketCap)
      setVolume(d.volume)
      setTemp(d)
      setReducerMarketCap(d)
      setChartList(MarketCap30DOption(d))
    }
  }
  useEffect(() => {
    if (locaStoreageGetItem('marketCapVisible')) {
      setHide(locaStoreageGetItem('marketCapVisible'))
    }
    fetch()
  }, [])

  const visibleChart = (type: string) => {
    if (reducerMarketCap) {
      // const { marketCap } = state.DashbordReducer
      const _marketCap = JSON.parse(JSON.stringify(reducerMarketCap))
      if (type === 'bar') {
        if (showBar) {
          _marketCap.marketCaps = []
          setShowBar(false)
        } else {
          _marketCap.marketCaps = reducerMarketCap.marketCaps
          setShowBar(true)
        }
      }
      if (type === 'line') {
        if (showLine) {
          _marketCap.volumes = []
          setShowLine(false)
        } else {
          _marketCap.volumes = reducerMarketCap.volumes
          setShowLine(true)
        }
      }
      setTemp(_marketCap)
      setChartList(MarketCap30DOption(_marketCap))
    }
  }

  const handleHide = () => {
    setHide(!isHide)
    locaStoreageSetItem('marketCapVisible', !isHide)
  }
  return (
    <Container className="w100">
      <Box className="fx-row jc-sb" height="60px" mb={isHide || isMobile ? '-40px' : 0}>
        <Grid gridTemplateColumns={['1fr', '1fr 1fr 0.5fr', '1fr 1fr 0.5fr', '1fr 1fr 0.5fr']}>
          <Legend
            ml={['0px', '10px', '30px', '30px']}
            color="#00B2B5"
            name="Market Cap 30d:"
            title="The sum of market capitalization of all collections listed on Pewee"
            value={market.value}
            increase={market.increase}
            percent={market.percent}
            visibleChart={() => visibleChart('bar')}
          />
          <Legend
            ml={['0px', '10px', '30px', '30px']}
            color="#FFE800"
            name="Volume 30d:"
            title="The sum of Volume of all collections listed on Pewee"
            value={volume.value}
            increase={volume.increase}
            percent={volume.percent}
            visibleChart={() => visibleChart('line')}
          />
          {!isMobile && (
            <Row
              className="click"
              mr={['0px', '10px', '30px', '30px']}
              ml={['0px', '10px', '24px', '24px']}
              onClick={handleHide}
            >
              <Text className="fz12 white60 fw400" mr="6px">
                {isHide ? 'Show Chart' : 'Hide Chart'}
              </Text>
              <Image src={isHide ? downArrow : upArrow} height={4} width={7} />
            </Row>
          )}
        </Grid>
        {!isMobile && (
          <Row className="click mr30" onClick={() => navigate('/dashboard/mkt')}>
            <Text color="#A0A1A3" fontSize="14px" mr="8px">
              View All
            </Text>
            <Image src={viewAll} height={16} width={16} />
          </Row>
        )}
      </Box>
      {!isMobile && !isHide && (
        <ChartContainer h={380} className="w100">
          <Text color="rgba(255, 255, 255, 0.87)" fontSize="16px" ml="24px" bold>
            30D Market Cap & Volume
          </Text>
          <Box className="w100" mt="20px" height="380px">
            {chartList ? <Chart option={chartList} /> : <NoData isLoading={isLoading} />}
          </Box>
        </ChartContainer>
      )}
    </Container>
  )
}
