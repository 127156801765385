import React, { useImperativeHandle, useRef } from 'react'
import { debounce } from '@/utils/tools'
import { Box, Text, Image } from '@/packages'
import {} from '@/assets/img'

// table滚动到底部
interface iTableLoadMore {
  children: React.ReactNode
  height?: string
  onTableBottom: () => void
  tRef?: any
}
export const TableLoadMore: React.FC<iTableLoadMore> = ({ children, height, onTableBottom, tRef }) => {
  const tableRef: any = useRef()
  const onScrollEvent = () => {
    const tableBody: any = document.querySelector('.ant-table-body')
    const { clientHeight, scrollHeight, scrollTop } = tableBody
    if (scrollHeight - (scrollTop + clientHeight) < 60) {
      onTableBottom()
    }
  }

  useImperativeHandle(tRef, () => ({
    scrollToTop: () => {
      const tableBody: any = document.querySelector('.ant-table-body')
      tableBody.scrollTo(0, 0)
    }
  }))

  return (
    <Box className="" height={height || '426px'} onScrollCapture={debounce(onScrollEvent, 500)} ref={tableRef}>
      {children}
    </Box>
  )
}
