import React from 'react'
import { Box, Text, Grid } from '@/packages/'

interface iWrapper {
  title: string
  require: boolean
  children: React.ReactNode
  pr?: string
  mt?: string
}
export const MaskWrapper: React.FC<iWrapper> = ({ title, require, children, pr, mt }) => {
  return (
    <Grid
      className="w100"
      mt={mt || '20px'}
      pl="20px"
      pr={pr || '50px'}
      gridTemplateColumns={['1fr 4fr']}
      gridColumnGap={[2, 2, 10, 20]}
    >
      <Box className="fx-row" mt="6px">
        <Text className="fz16 fw500 white87">{title}</Text>
        {require && (
          <Text className="fz16 fw500" color="#00B2B5" ml="4px" height="20px">
            *
          </Text>
        )}
      </Box>
      {children}
    </Grid>
  )
}
