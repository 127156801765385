import React, { useState, useEffect, useRef, useCallback } from 'react'
import moment from 'moment'
import { Modal } from 'antd'
import styled from 'styled-components'
import { Box, Image, Text } from '@/packages/'
import { clockGreen, arrowRight, close, eth, avator, websiteActive, twitterActive, discordActive } from '@/assets/img'
import { Row, Col, Divider } from '@/components/Layout/Layout'
import { less0, beyondStr } from '@/utils/tools'
import { useMatchBreakpoints } from '@/hooks/useMatchBreakpoints'
import { InputGroup } from '@/components/Mobile/MobileInput/InputGroup'
import { MobileInput, MobileInputEdit } from '@/components/Mobile/MobileInput'
import { ChangeCell } from '../../Dashboard/Collection/components'

const calculateDuration = (et: number) => moment.duration(Math.max(et - Math.floor(Date.now() / 1000), 0), 'seconds')

const Countdown: React.FC<{ eventTime: number; interval: number }> = ({ eventTime, interval }) => {
  const [duration, setDuration] = useState(calculateDuration(eventTime))
  const timerRef: any = useRef(0)
  const timerCallback = useCallback(() => {
    setDuration(calculateDuration(eventTime))
  }, [eventTime])

  useEffect(() => {
    timerRef.current = setInterval(timerCallback, interval)

    return () => {
      clearInterval(timerRef.current)
    }
  }, [eventTime])

  return (
    <Text color="#00B2B5" className="fz16 fw500" ml="6px">
      {less0(duration.days())}D:{less0(duration.hours())}H:{less0(duration.minutes())}M
    </Text>
  )
}

export const MintDateCell: React.FC<{ value: number | string }> = ({ value }) => {
  return (
    <Box className="w100 fx jc-end">
      {value !== null ? (
        <Box className="fx-col ai-ct">
          <Row>
            <Image src={clockGreen} width={16} height={16} />
            <Countdown eventTime={Number(value) / 1000} interval={1000} />
          </Row>
          <Text className="fz16 fw500 white38">{moment(Number(value)).format('MMM DD HH:mm')}</Text>
        </Box>
      ) : (
        <Text className="fz16 fw500 white87">TBA</Text>
      )}
    </Box>
  )
}

interface iSocialCellMobile {
  valueNum: number
  increase: boolean
  percent: number
}
const SocialCellMobile: React.FC<iSocialCellMobile> = ({ valueNum, increase, percent }) => {
  return (
    <Row>
      <Text className="fz14 fw400 white87" textAlign="right" mr="12px">
        {valueNum || valueNum === 0 ? valueNum : '--'}
      </Text>
      {increase !== null && increase !== undefined && <ChangeCell increase={increase} percent={percent} />}
    </Row>
  )
}
const CountdownMobileText: React.FC<{ count: number | string; name: string }> = ({ count, name }) => {
  return (
    <Col>
      <Text className="fz24 fw600 white87">{count}</Text>
      <Text className="fz12 fw600 white60">{name}</Text>
    </Col>
  )
}
const Dot = styled(Text)`
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: rgba(255, 255, 255, 0.87);
`
const CountdownMobile: React.FC<{ eventTime: number; interval: number }> = ({ eventTime, interval }) => {
  const [duration, setDuration] = useState(calculateDuration(eventTime))
  const timerRef: any = useRef(0)
  const timerCallback = useCallback(() => {
    setDuration(calculateDuration(eventTime))
  }, [eventTime])

  useEffect(() => {
    timerRef.current = setInterval(timerCallback, interval)
    return () => {
      clearInterval(timerRef.current)
    }
  }, [eventTime])

  return (
    <Row className="jc-sb">
      <CountdownMobileText name="DAYS" count={less0(duration.days())} />
      <Dot>:</Dot>
      <CountdownMobileText name="HRS" count={less0(duration.hours())} />
      <Dot>:</Dot>
      <CountdownMobileText name="MINS" count={less0(duration.minutes())} />
      <Dot>:</Dot>
      <CountdownMobileText name="SECS" count={less0(duration.seconds())} />
    </Row>
  )
}
const CountdownMobileContainer = styled(Box)`
  background: rgba(255, 232, 0, 0.08);
  border: 1px solid rgba(255, 232, 0, 0.38);
  border-radius: 12px;
  width: 100%;
  padding: 12px 48px;
`
const ProjectNameWrapper = styled(Box)``
export const MintDateCellMobile: React.FC<{ record: any }> = ({ record }) => {
  const [visible, setVisible] = useState<boolean>(false)
  const {
    updateTime,
    twitterFollowers,
    twitterWhales,
    discordNum,
    supply,
    mintPrice,
    mintTime,
    image,
    name,
    website,
    twitter,
    discord
  } = record
  const { isMobile, isTablet } = useMatchBreakpoints()

  const handleVisible = (v: boolean) => {
    setVisible(v)
  }
  const handleCancel = () => {
    handleVisible(false)
  }
  return (
    <>
      <Box className="w100 fx jc-end ai-ct click" onClick={() => handleVisible(true)}>
        <Text className="fz14 fw400 white87" mr="18px">
          {mintTime ? moment(Number(mintTime)).format('MMM DD HH:mm') : 'TBA'}
        </Text>
        <Image src={arrowRight} height={10} width={6} />
      </Box>

      <Modal
        closable={false}
        className={isMobile || isTablet ? 'modalClassNameMobile' : 'modalClassName'}
        bodyStyle={{ background: 'rgb(18,18,18)', borderRadius: 12 }}
        visible={visible}
        wrapClassName={isMobile || isTablet ? 'wrapClassNameMobile' : ''}
        footer={null}
        maskClosable={false}
      >
        <Col className="body_wrapper w100" pt="20px">
          <Row className="jc-sb">
            <div />
            <Text className="white87 fw700" fontSize={['20px', '20px', '22px', '24px']}>
              Collection Details
            </Text>
            <Image className="click" src={close} height={20} width={20} onClick={handleCancel} />
          </Row>
          <ProjectNameWrapper className="fx-row ai-ct" mt="24px">
            <Image src={image || avator} height={80} width={80} className="circle" />
            <Col ml="20px">
              <Text className="fz20 fw600 white87">{beyondStr(name, 15)}</Text>
              <Row mt="12px">
                {website && <Image src={websiteActive} height={20} width={20} className="mr20" />}
                {twitter && <Image src={twitterActive} height={20} width={20} className="mr20" />}
                {discord && <Image src={discordActive} height={20} width={20} className="mr20" />}
              </Row>
            </Col>
          </ProjectNameWrapper>
          <Box mt="16px">
            <InputGroup title="FOLLOWERS COUNT">
              <MobileInputEdit
                title="Twitter Followers"
                prefix={
                  <SocialCellMobile
                    valueNum={twitterFollowers?.value}
                    increase={twitterFollowers?.increase}
                    percent={twitterFollowers?.percent || 0}
                  />
                }
              />
              <Divider />
              <MobileInputEdit
                title="Whales"
                prefix={
                  <SocialCellMobile
                    valueNum={twitterWhales?.value}
                    increase={twitterWhales?.increase}
                    percent={twitterWhales?.percent || 0}
                  />
                }
              />
              <Divider />
              <MobileInputEdit
                title="Discord"
                prefix={
                  <SocialCellMobile
                    valueNum={discordNum?.value}
                    increase={discordNum?.increase}
                    percent={discordNum?.percent || 0}
                  />
                }
              />
            </InputGroup>
          </Box>

          <InputGroup title="MINT INFOMATION">
            <MobileInputEdit title="Supply" value={supply !== null ? supply : 'TBA'} />
            <Divider />
            <MobileInputEdit
              title="Mint Price"
              value={mintPrice !== null ? mintPrice : 'TBA'}
              prefix={mintPrice !== null ? <Image src={eth} height={10} width={6} /> : null}
            />
            <Divider />
            <MobileInputEdit
              title="Drop Date"
              value={mintTime ? moment(Number(mintTime)).format('MMM DD HH:mm') : 'TBA'}
            />
          </InputGroup>
          {mintTime && (
            <CountdownMobileContainer>
              <CountdownMobile eventTime={Number(mintTime) / 1000} interval={1000} />
            </CountdownMobileContainer>
          )}
        </Col>
      </Modal>
    </>
  )
}
