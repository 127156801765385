import React, { useState } from 'react'
import styled from 'styled-components'
import { Chart } from './Chart'
import { Box, Text, Flex } from '@/packages'
import { ChartBgColor } from './options/types'

export const DefaultTimeList = ['1h', '6h', '1d', '7d', '30d']

interface ChartProps {
  title?: string
  showDimension: boolean
  children: React.ReactNode
  width?: number
}
const Wrapper = styled(Box)<{ w?: number }>`
  width: ${({ w }) => (w ? `${w}px` : '630px')};
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`
const ChartItem = styled(Box)`
  width: 100%;
`

const TitleBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 28px;
  width: 100%;
`
const TimeContainer = styled(Box)`
  height: 28px;
  border: 1px solid #918f99;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
const TimeItem = styled(Box)<{ active: boolean }>`
  width: 30px;
  height: 20px;
  background: ${({ active }) => (active ? '#C4C0FF' : 'transparent')};
  border-radius: 1px;
`

interface SwitchTimeProps {
  selectTime: (val: string) => void
  timeList?: Array<string>
}
export const SwitchTime: React.FC<SwitchTimeProps> = ({ selectTime, timeList = DefaultTimeList }) => {
  const [activeTime, setActiveTime] = useState(0)
  const handleTime = (value: string, idx: number) => {
    setActiveTime(idx)
    selectTime(value)
  }
  return (
    <TimeContainer px="4px">
      {timeList.map((item: string, idx: number) => (
        <TimeItem
          key={item}
          ml="2px"
          mr="2px"
          active={idx === activeTime}
          onClick={() => handleTime(item, idx)}
          className="center click"
        >
          <Text fontSize="14px" color={idx === activeTime ? '#403C8E' : '#918F99'}>
            {item}
          </Text>
        </TimeItem>
      ))}
    </TimeContainer>
  )
}

export const ChartWrapper: React.FC<ChartProps> = ({ title, showDimension, children, width }) => {
  const selectTime = (val: string) => {
    // console.log('selectTime', val)
  }
  return (
    <Wrapper w={width}>
      {title && (
        <TitleBox>
          <Text fontSize="16px" color="#E0E3E3">
            {title}
          </Text>
          {showDimension && <SwitchTime selectTime={(val: string) => selectTime(val)} />}
        </TitleBox>
      )}
      <ChartItem className="center">{children}</ChartItem>
    </Wrapper>
  )
}
