import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import { Flex, Box } from '@/packages/Box'
import { Image } from '@/packages/Image'
import { Text } from '@/packages/Text'
import { WrapperFooter } from '../Nav'

import { discordActive, discord, twitterActive, twitterUrl, gas } from '@/assets/img'
import { ActiveIcon } from '@/components/ActiveIcon'

import { getGasEthApi } from '@/request/Api'
import { toNonExponential } from '@/utils/tools'
import { ContentWrapper, ETHValue, Row, Vertical } from '../Layout'
import { useAppContext } from '@/hooks/useAppContext'

const FooterWrapper = styled(Box)`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
`
const defaultcontent = {
  ethRate: 0,
  gas: 0,
  time: 0
}
interface iBoxItem {
  ethRate: number
  gas: number
  time: number
}
export const Footer = () => {
  const [content, setContent] = useState<iBoxItem>(defaultcontent)
  const [isLiveView, setLiveView] = useState<boolean>(false)
  const [fPrice, setFPrice] = useState<number>(0)
  const [ethPrice, setETHPrice] = useState<number>(0)
  const { state, dispatch } = useAppContext()
  const location = useLocation()

  const fetch = async () => {
    const d: any = await getGasEthApi()
    if (d) {
      setContent(d)
      setETHPrice(d.ethRate)
    }
  }
  useEffect(() => {
    fetch()
    const intervalTimer = setInterval(() => {
      fetch()
    }, 5000 * 60)
    return () => clearInterval(intervalTimer)
  }, [])

  useEffect(() => {
    if (state?.LiveViewReducer?.wsFloorPrice) {
      const { pathname } = location
      if (pathname.includes('/trending/liveview/')) {
        const floorPrice = state.LiveViewReducer.wsFloorPrice.close
        setFPrice(Number(floorPrice))
        setLiveView(true)
      } else {
        setLiveView(false)
      }
    }
  }, [state?.LiveViewReducer?.wsFloorPrice, location])

  return (
    <div>
      {!window.location.hash.includes('aboutUs') && (
        <FooterWrapper>
          <ContentWrapper>
            <Row className="w100 jc-sb" background="#121212" height="40px" px={['20px', '20px', '24px', '24px']}>
              <Row>
                <Row>
                  <Image src={gas} height={14} width={14} />
                  <Text className="fz14 fw600 white87" ml="6px">
                    {toNonExponential(content.gas, 0)}
                  </Text>
                </Row>
                <Box mx="12px">
                  <Vertical h={20} />
                </Box>
                <Row>
                  {isLiveView && (
                    <Text className="fz14 fw600 white87" mr="6px">
                      Floor
                    </Text>
                  )}
                  {isLiveView ? (
                    <ETHValue value={toNonExponential(fPrice, 3)} />
                  ) : (
                    <ETHValue value={toNonExponential(ethPrice, 2)} />
                  )}
                </Row>
              </Row>
              <Row>
                <ActiveIcon
                  src={discord}
                  activeSrc={discordActive}
                  targetUrl="https://discord.gg/3QhPyqXdmJ"
                  tooltip="Discord"
                  h={16}
                  w={20}
                />
                <Box ml={['10px', '16px', '20px', '20px']}>
                  <ActiveIcon
                    src={twitterUrl}
                    activeSrc={twitterActive}
                    targetUrl="https://twitter.com/peweetools"
                    tooltip="Twitter"
                    h={16}
                    w={19}
                  />
                </Box>
              </Row>
            </Row>
          </ContentWrapper>
        </FooterWrapper>
      )}
    </div>
  )
}
