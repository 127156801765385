import React, { useEffect, useState, ChangeEvent, useImperativeHandle, useRef } from 'react'
import styled, { DefaultTheme } from 'styled-components'
import { InputProps, scales } from './types'
import { debounce } from '@/utils/tools'
import useDebounce from '@/hooks/useDebounce'

interface StyledInputProps extends InputProps {
  theme: DefaultTheme
}

const getHeight = ({ scale = scales.MD }: StyledInputProps) => {
  switch (scale) {
    case scales.SM:
      return '32px'
    case scales.LG:
      return '48px'
    case scales.MD:
    default:
      return '40px'
  }
}

export const InputWrapper = styled.input<InputProps & { px?: number; w?: string; h?: string }>`
  background-color: ${({ theme }) => theme.colors.bg10};
  border-radius: 2px;
  color: ${({ theme }) => theme.colors.white};
  border: none;
  display: block;
  font-size: 16px;
  // height: ${getHeight};
  height: ${({ h }) => (h ? `${h}` : '32px')};
  outline: 0;
  padding: ${({ px }) => (px ? `0 ${px}px` : `0 14px`)};
  width: ${({ w }) => (w ? `${w}` : '100%')};

  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.focus};
  }
  &::placeholder {
    color: '#5F6165';
    font-size: 12px;
  }
`

// &:disabled {
//   background-color: ${({ theme }) => theme.colors.backgroundDisabled};
//   box-shadow: none;
//   color: ${({ theme }) => theme.colors.textDisabled};
//   cursor: not-allowed;
// }

// &:focus:not(:disabled) {
//   box-shadow: ${({ theme }) => theme.shadows.focus};
// }

interface iInput extends InputProps {
  px?: number
  w?: string
  h?: string
  onChange: (value: string) => void
  placeholder: string
  type?: string
  iptType?: string
  iptRef?: any
  onBlur?: () => void
}
export const Input: React.FC<iInput> = ({
  px,
  w,
  h,
  onChange,
  placeholder,
  type,
  iptType,
  iptRef,
  onBlur,
  ...props
}) => {
  const [value, setValue] = useState<string>('')
  const debouncedValue = useDebounce<string>(value, 500)
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value
    setValue(val)
    if (val === '') onChange('')
    if (iptType === 'number' && val && Number.isNaN(Number(val))) {
      setValue('')
      onChange('')
    }
  }
  useEffect(() => {
    if (debouncedValue) {
      onChange(value)
    }
  }, [debouncedValue])
  useImperativeHandle(iptRef, () => ({
    clearValue: () => {
      setValue('')
      onChange('')
    }
  }))

  return (
    <InputWrapper
      onBlur={onBlur}
      type={type}
      placeholder={placeholder}
      px={px}
      w={w}
      h={h}
      value={value}
      onChange={handleChange}
      {...props}
    />
  )
}

// Input.defaultProps = {
//   scale: scales.MD,
//   isSuccess: false,
//   isWarning: false
// }
