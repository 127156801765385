import React, { useState, useEffect, useImperativeHandle } from 'react'
import { Modal } from 'antd'
import styled from 'styled-components'
import { Box, Grid, Text, Image } from '@/packages/'
import { Row, Col } from '@/components/Layout/Layout'
import { close } from '@/assets/img'

const Submit = styled(Box)<{ active: boolean }>`
  background: #00b2b5;
  opacity: ${({ active }) => (active ? 1 : 0.4)};
  border-radius: 100px;
  margin-left: 40px;
`

interface iMaskContainer {
  children: React.ReactNode
  title: string
  handleCancel: () => void
  handleSubmit: () => void
  width?: number
  mRef?: any
}

export const MaskContainer: React.FC<iMaskContainer> = ({
  mRef,
  children,
  title,
  width,
  handleCancel,
  handleSubmit
}) => {
  const [visible, setVisible] = useState<boolean>(false)
  const onCancel = () => {
    setVisible(false)
    handleCancel()
  }
  const onSubmit = () => {
    // onCancel()
    handleSubmit()
  }

  useImperativeHandle(mRef, () => ({
    onVisible: (v: boolean) => {
      setVisible(v)
    }
  }))

  return (
    <Modal
      closable={false}
      bodyStyle={{
        background: '#252525',
        borderRadius: 12
      }}
      className="modalClassName"
      onCancel={onCancel}
      width={width}
      visible={visible}
      footer={null}
      maskClosable={false}
    >
      <Col className="body_wrapper w100" pt="20px">
        <Row px="30px" className="jc-sb">
          <div />
          <Text className="white87 fw700" fontSize={['20px', '20px', '22px', '24px']}>
            {title}
          </Text>
          <Image className="click" src={close} height={20} width={20} onClick={onCancel} />
        </Row>
        <Box className="w100" px="50">
          {children}
        </Box>
        <Row px="40px" className="w100 jc-end" mb="40px" mt="34px">
          <Row>
            <Text color="rgba(255, 255, 255, 0.87)" fontSize="16px" className="fw700 click" onClick={onCancel}>
              Cancel
            </Text>
            <Submit className="center click" px="50px" py="10px" active onClick={onSubmit}>
              <Text color="rgba(255, 255, 255, 0.87)" fontSize="16px" className="fw700 ">
                Submit
              </Text>
            </Submit>
          </Row>
        </Row>
      </Col>
    </Modal>
  )
}
