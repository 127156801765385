import React from 'react'
import { Box, Text } from '@/packages/'
import { Col } from '@/components/Layout/Layout'

interface iInputGroup {
  title: string
  children: React.ReactNode
}
export const InputGroup: React.FC<iInputGroup> = ({ title, children }) => {
  return (
    <Col mb="40px">
      <Text className="fz12 fw600 white87">{title}</Text>
      <Box className="body_wrapper" mt="10px" px="0px">
        {children}
      </Box>
    </Col>
  )
}
