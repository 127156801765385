import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Flex, Box, Grid } from '@/packages/'
import { NFTInfo } from './NFTInfo'
import { NFTData } from './NFTData'
import { ContentWrapper } from '@/components/Layout/Layout'
import { NftInfoApi, CollectionInfoApi } from '@/request/Api'
import { inftInfo, iNFTVO, iCollectionInfo } from '@/types/store/index'
import { useAppContext } from '@/hooks/useAppContext'
import { CrtSupplyAction, NftInfoAction, CrtNFTAddressAction } from '@/store/actions/LiveviewAction'

const initNFTInfo = {
  nftId: 0,
  name: '',
  nftAddresses: [],
  imageUrl: '',
  largeImageUrl: '',
  bannerImageUrl: '',
  officialUrl: '',
  discordUrl: '',
  twitterUsername: '',
  createDate: 0,
  royalties: 0,
  openSeaRoyalties: 0,
  slug: '',
  revealed: 0,
  attention: 0,
  verifyStatus: 0, // 0，未认证，1已认证
  description: '',
  type: 0, // 1 垃圾项目 0 非垃圾项目
  rankPercent: 0,
  report: 0 // 0 1 2
}
const initCollection = {
  mintPrice: 0,
  nbcp: 0,
  min: 0,
  holders: 0,
  max: 0,
  openSeaRoyalties: 0,
  royalties: 0,
  supply: 0,
  totalVolumeOf7D: 0,
  nftId: 0,
  volumes: []
}
export const Info = () => {
  const params = useParams()
  const { state, dispatch } = useAppContext()

  const [infoData, setInfoData] = useState<iNFTVO>(initNFTInfo)
  const [collectionData, setCollectionData] = useState<iCollectionInfo>(initCollection)
  const fetch = async () => {
    const NFTID = Number(params.nftId)
    const data: any = await CollectionInfoApi(NFTID)
    if (data) {
      setInfoData(data || initNFTInfo)
      NftInfoAction(data, dispatch)
      CrtNFTAddressAction(data.nftAddresses[0], dispatch)
    }
    const d: any = await NftInfoApi(NFTID)
    if (d) {
      CrtSupplyAction(d.supply, dispatch)
      setCollectionData(d)
    }
  }
  useEffect(() => {
    fetch()
    const intervalTimer = setInterval(() => {
      fetch()
    }, 1000 * 60 * 60)
    return () => clearInterval(intervalTimer)
  }, [])

  // useEffect(() => {
  //   if (state?.LiveViewReducer?.nftInfo) {
  //     if (Object.keys(state.LiveViewReducer.nftInfo).length > 0) {
  //       setInfoData(state.LiveViewReducer.nftInfo)
  //     }
  //   }
  // }, [state?.LiveViewReducer?.nftInfo])

  return (
    <ContentWrapper>
      <Grid
        mt="24px"
        className="w100"
        style={{ height: 'auto' }}
        gridColumnGap={['0', '0', '0', '24px']}
        gridTemplateColumns={['1fr', '1fr', '1fr', '8fr 5fr']}
      >
        <NFTInfo {...infoData} />
        <NFTData {...collectionData} />
      </Grid>
    </ContentWrapper>
  )
}
