import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import CountUp from 'react-countup'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, EffectCoverflow, Autoplay } from 'swiper'
import { useNavigate } from 'react-router-dom'
import { Box, Text, Image, Grid } from '@/packages/'
import { ContentWrapper, Col, Row } from '@/components/Layout/Layout'
import { aboutUsApi } from '@/request/Api'
import { useMatchBreakpoints } from '@/hooks/useMatchBreakpoints'
import {
  abImg,
  Clonex,
  coniun,
  coolcats,
  circle,
  alpha,
  alphagang,
  apelist,
  Ascendants,
  Asianmint,
  blank,
  cyberSnail,
  dre,
  flowerfam,
  gbabies,
  illogics,
  lasercat,
  llamaverse,
  oasisAwakening,
  OmegaAlpha,
  otterverse,
  projectWhitelist,
  RainbowAlpha,
  rektCollective,
  surgence,
  TBDAlpha,
  theOther,
  traitSniper,
  wabisabi,
  whitelistping,
  yogapetz,
  zooClub
} from '@/assets/img'
import 'swiper/css'

const defaultcontent = {
  collections: 0,
  memberCount: 0,
  peweeFans: 0,
  snipeSpeed: 0
}
interface iBoxItem {
  collections: number
  memberCount: number
  peweeFans: number
  snipeSpeed: number
}
const Textcont = styled(Box)`
  display: inline-block;
  margin-right: 6px;
  font-size: 20px;
`
const LaunchApp = styled(Box)`
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #07b0b0 0%, #34c77b 34.84%);
  border-radius: 50px;
  color: #fff;
  text-align: center;
  cursor: pointer;
  line-height: 60px;
`
// const backImg = styled(Box)`
//   width:100%;
//   height:100%
// `
const IconImg = [
  alpha,
  alphagang,
  coniun,
  coolcats,
  circle,
  apelist,
  Ascendants,
  Asianmint,
  blank,
  Clonex,
  cyberSnail,
  dre,
  flowerfam,
  gbabies,
  illogics,
  lasercat,
  llamaverse,
  oasisAwakening,
  OmegaAlpha,
  otterverse,
  projectWhitelist,
  RainbowAlpha,
  rektCollective,
  surgence,
  TBDAlpha,
  theOther,
  traitSniper,
  wabisabi,
  whitelistping,
  yogapetz,
  zooClub
]
export const Tools = () => {
  const navigate = useNavigate()
  const { isMobile } = useMatchBreakpoints()
  const [content, setContent] = useState<iBoxItem>(defaultcontent)
  const fetch = async () => {
    const dataCont: any = await aboutUsApi()
    setContent(dataCont || defaultcontent)
  }
  useEffect(() => {
    fetch()
  }, [])
  return (
    <Box className="w100">
      <Box
        className="pa rg w100"
        top={['1367px', '1490px', '1614px', '1740px']}
        height={['594px', '1129px', '1664px', '2200px']}
      >
        <Image src="https://peweetools.s3.us-west-2.amazonaws.com/png/bigBg.png" className="rh100 rw100" />
      </Box>

      <Grid
        className="w100"
        height={['530px', '683px', '836px', '990px']}
        gridColumnGap={['0', '0', '0', '24px']}
        gridTemplateColumns={['1fr', '1fr', '1fr', '1fr']}
      >
        <Col className="mxw relative">
          <Box
            className="pa tp176 lf-160 zi"
            width={['100px', '140px', '180px', '220px']}
            height={['92px', '128px', '164px', '200px']}
            top={['36px', '82px', '128px', '176px']}
            left={['-30px', '-73px', '-115px', '-160px']}
          >
            <Image src={abImg} className="rw100 rh100" />
          </Box>
          <Text
            fontSize={[30, 41, 52, 64]}
            // fontSize="64px"
            height={['40px', '55px', '69px', '84px']}
            lineHeight={['40px', '55px', '69px', '84px']}
            fontWeight={700}
            marginBottom={['9px', '14px', '19px', '24px']}
            marginTop={['30px', '43px', '56px', '70px']}
            className="mtxt title"
          >
            PEWEE TOOLS
          </Text>
          <Text
            color="#fff"
            fontSize={[21, 34, 47, 60]}
            lineHeight={['25px', '41px', '57px', '75px']}
            width={['316px', '584px', '852px', '1122px']}
            fontWeight={700}
            className="relative title"
            marginBottom={['9px', '14px', '19px', '24px']}
          >
            An NFT Analytical Tool To Make Your Purchase Easier
          </Text>
          <Box className="textBox">
            <Text fontSize={[13, 17, 22, 26]} fontWeight={400} className="mtxt lh34">
              Effective Data | Agile Execution | In-Depth Analysis
            </Text>
            <Text fontSize={[13, 15, 17, 20]} width={['302px', '404px', '506px', '610px']} className="white87 fw400">
              Mission for Pewee is to become a trusted tool for NFT traders
            </Text>
            {/* <Box className="w610 col2 h30x ft20 lh24">
              Mission for Pewee is to become a trusted tool for NFT traders
            </Box> */}
            <Box
              marginTop={['24px', '29px', '34px', '40px']}
              marginBottom="40px"
              width={['120px', '160px', '200px', '240px']}
              height={['30px', '40px', '50px', '60px']}
            >
              <LaunchApp onClick={() => navigate('/')}>
                <Text
                  fontSize={['12px', '15px', '19px', '22px']}
                  className="white"
                  lineHeight={['30px', '40px', '50px', '60px']}
                >
                  Get Started
                </Text>
              </LaunchApp>
            </Box>
          </Box>

          <Grid
            gridTemplateColumns={['1fr 1fr', '1fr 1fr 1fr 1fr', '1fr 1fr 1fr 1fr', '1fr 1fr 1fr 1fr']}
            className="fx ai-ct jc-sb  br30 bgc2 pd65  w100"
            paddingRight={['46px', '52px', '58px', '64px']}
            paddingLeft={['46px', '52px', '58px', '64px']}
            marginBottom={['37px', '75px', '114px', '150px']}
          >
            <Box className={isMobile ? 'tc mb15' : 'tc'}>
              <Text
                fontSize={['14px', '18px', '22px', '26px']}
                fontWeight={400}
                marginBottom={['5px', '8px', '11px', '14px']}
                color="rgba(255, 255, 255, 0.5)"
              >
                Collections
              </Text>
              <Text fontSize={['16px', '21px', '25px', '30px']} fontWeight={500} color="rgba(255, 255, 255, 0.87)">
                <CountUp start={0} end={content.collections} />
              </Text>
            </Box>
            <Box className={isMobile ? 'tc mb15' : 'tc'}>
              <Text
                fontSize={['14px', '18px', '22px', '26px']}
                fontWeight={400}
                marginBottom={['5px', '8px', '11px', '14px']}
                color="rgba(255, 255, 255, 0.5)"
              >
                Snipe Speed
              </Text>
              <Text fontSize={['16px', '21px', '25px', '30px']} fontWeight={500} color="rgba(255, 255, 255, 0.87)">
                {`${content.snipeSpeed}S`}
              </Text>
            </Box>
            <Box className="tc">
              <Text
                fontSize={['14px', '18px', '22px', '26px']}
                fontWeight={400}
                marginBottom={['5px', '8px', '11px', '14px']}
                color="rgba(255, 255, 255, 0.5)"
              >
                Beta Testers
              </Text>
              <Text fontSize={['16px', '21px', '25px', '30px']} fontWeight={500} color="rgba(255, 255, 255, 0.87)">
                <CountUp start={0} end={content.memberCount} />
              </Text>
            </Box>
            <Box className="tc">
              <Text
                fontSize={['14px', '18px', '22px', '26px']}
                fontWeight={400}
                marginBottom={['5px', '8px', '11px', '14px']}
                color="rgba(255, 255, 255, 0.5)"
              >
                Pewee Fans
              </Text>
              <Text fontSize={['16px', '21px', '25px', '30px']} fontWeight={500} color="rgba(255, 255, 255, 0.87)">
                <CountUp start={0} end={content.peweeFans} />
              </Text>
            </Box>
          </Grid>
          <Box width={['340px', '626px', '912px', '1200px']}>
            <Swiper
              modules={[Navigation, Pagination, EffectCoverflow, Autoplay]}
              autoplay={{
                delay: 0,
                stopOnLastSlide: false,
                disableOnInteraction: false
              }}
              loop
              freeMode
              loopFillGroupWithBlank
              normalizeSlideIndex
              speed={3000}
              slidesPerView={isMobile ? 8 : 14}
              spaceBetween={0}
              centeredSlides
              watchSlidesProgress
              onSlideChange={() => console.log('slide change')}
              onSwiper={(swiper) => console.log(swiper)}
            >
              {IconImg.length > 1 &&
                IconImg.map((item: string) => (
                  <SwiperSlide>
                    <Box width={['22px', '30px', '38px', '46px']} height={['22px', '30px', '38px', '46px']}>
                      <Image src={item} className="rh100 rw100" />
                    </Box>
                  </SwiperSlide>
                ))}
            </Swiper>
          </Box>
        </Col>
      </Grid>
    </Box>
  )
}
