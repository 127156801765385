import React, { useState, useEffect, useRef, useCallback } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { Popover, Modal } from 'antd'
import { useAppContext } from '@/hooks/useAppContext'
import { Box, Text, Input, Image } from '@/packages'
import { Chart } from '@/components/Charts/Chart'
import { PriceEstimateOption } from '@/components/Charts/options/index'
import { Col, Row, CardTitle, PeweeTooltip } from '@/components/Layout/Layout'
import { PriceEstimateApi } from '@/request/Api'
import { iPriceEstimateParams } from '@/request/types'
import { DropdownSelect } from '@/components/DropdownSelect'
import { openWindow } from '@/utils/tools'
import { RarityRankAction, QuickBuyVisibleAction } from '@/store/actions/LiveviewAction'
import { inappropriateRank, notice, bulb, bulbActive, trendlineGuidesContent, close } from '@/assets/img'
import { TabListSelected, iTabItem } from '@/components/TabListSelected'
import { SwitchButton } from '@/components/SwitchButton'
import { NumberInput } from '@/components/NumberInput'
import { FilterBtn } from './FilterListing'
import { ChartLegend } from '@/components/ChartLegend'
import { NoData } from '@/components/NoData'
import { OpenNotification, CloseNotification, NotMainNetNotice } from '@/components/OpenNotification'
import { isMainNet } from '@/utils/chain'

interface ifParams {
  max: 0
  min: 0
}
export const PriceEstimate = () => {
  const [granularity, setGranularity] = useState('1d')
  const [temp, setTemp] = useState<any>(null)
  const [initList, setInitList] = useState<any>(null)
  const [isOpen, setOpen] = useState<boolean>(false)
  const [min, setMin] = useState(0)
  const [max, setMax] = useState(0)
  const [period, setPeriod] = useState<Array<iTabItem>>([
    { id: 0, name: '1h', active: false },
    { id: 1, name: '4h', active: false },
    { id: 2, name: '12h', active: false },
    { id: 3, name: '1d', active: true },
    { id: 4, name: '3d', active: false }
  ])
  const [isBulb, setBulb] = useState<boolean>(false)
  const [guidesVisible, setGuidesVisible] = useState<boolean>(false)
  const [isLoading, setLoading] = useState<boolean>(true)

  const minRef: any = useRef()
  const maxRef: any = useRef()

  const salesRef: any = useRef()
  const listingsRef: any = useRef()
  const trendlineRef: any = useRef()

  const { state, dispatch } = useAppContext()

  const [options, setOptions] = useState({
    listings: [],
    trades: [],
    regressionLine: {
      constant: 0,
      coefficient: 0
    },
    rankRange: {
      min: 0,
      max: 0
    },
    priceRange: {
      min: 0,
      max: 0
    }
  })
  const params = useParams()

  const fetch = async (fParams?: ifParams) => {
    setLoading(true)

    let p: any
    if (fParams) {
      p = {
        nftId: Number(params.nftId),
        granularity,
        outliersOn: !isOpen,
        rankRange: {
          min: fParams.min,
          max: fParams.max
        }
      }
    } else {
      p = {
        nftId: Number(params.nftId),
        granularity,
        outliersOn: !isOpen,
        rankRange: {
          min,
          max
        }
      }
    }
    const range = Object.values(p.rankRange)
    if (range[0] === 0) delete p.rankRange.min
    if (range[1] === 0) delete p.rankRange.max
    if (range[0] === 0 && range[1] === 0) delete p.rankRange

    const list: any = await PriceEstimateApi(p)
    setLoading(false)

    if (list) {
      setInitList(list)
      setTemp(list)
      setOptions(list)
      resetBtnStatus()
    }
  }
  useEffect(() => {
    fetch()
  }, [granularity, isOpen])
  const minChange = (val: string) => {
    if (val) {
      if (Number(val) < 1) {
        OpenNotification('The minimum value of Rarity Rank must be greater than 1', 'warning')
      } else {
        RarityRankAction({ min: Number(val), max }, dispatch)
        setMin(Number(val))
      }
    } else {
      setMin(0)
    }
  }
  const maxChange = (val: string) => {
    if (val) {
      setMax(Number(val))
      RarityRankAction({ min, max: Number(val) }, dispatch)
    } else {
      setMax(0)
    }
  }

  const resetBtnStatus = () => {
    salesRef.current && salesRef.current.resetStatus()
    listingsRef.current && listingsRef.current.resetStatus()
    trendlineRef.current && trendlineRef.current.resetStatus()
  }

  const visibleChart = (open: boolean, type: string) => {
    if (initList) {
      const _initList = JSON.parse(JSON.stringify(temp))
      if (type === 'listingprice') {
        _initList.listings = open ? initList.listings : []
      }
      if (type === 'tradeprice') {
        if (open) {
          _initList.trades = initList.trades
        } else {
          _initList.trades = []
        }
      }
      if (type === 'trendline') {
        if (open) {
          _initList.regressionLine = initList.regressionLine
        } else {
          _initList.regressionLine = {
            coefficient: 0,
            constant: 0
          }
        }
      }
      setTemp(_initList)
      setOptions(_initList)
    }
  }
  const onClickDot = async (item: any) => {
    const net = await isMainNet()
    if (net) {
      if (item.seriesName === 'ListingPrice') {
        QuickBuyVisibleAction(item.value[2], 'priceEstimate', dispatch)
      }
    } else {
      NotMainNetNotice()
    }
  }

  const onChangePeriod = (val: iTabItem) => {
    const _period = JSON.parse(JSON.stringify(period))
    _period.forEach((_p: iTabItem) => {
      _p.active = _p.name === val.name
    })
    setPeriod(_period)
    setGranularity(val.name)
  }

  const onSave = useCallback(() => {
    fetch()
  }, [min, max])

  const onClear = () => {
    setMin(0)
    setMax(0)
    minRef.current && minRef.current.clearInputValue()
    maxRef.current && maxRef.current.clearInputValue()
    fetch({ max: 0, min: 0 })
  }
  return (
    <Box className="fx-col body_wrapper " mt="24px" height="550px" mb="24px">
      <Row className="jc-sb" mt="24px">
        <Row mb="16px">
          <CardTitle mb="0px">Fair Price Estimate</CardTitle>
          <PeweeTooltip
            placement="bottomRight"
            title="Compare current listings with fair price generated by the trendline to find undervalued items."
          >
            <Image src={notice} width={22} height={22} className="click ml8" />
          </PeweeTooltip>
        </Row>
        <Row>
          <Row mr="24px">
            <Text fontSize="12px" color="rgba(255, 255, 255, 0.87)" mr="10px">
              Period
            </Text>
            <TabListSelected ml={10} ftsz={12} list={period} onChange={onChangePeriod} selectedList={false} />
          </Row>
          <SwitchButton title="Outliers" onChange={() => setOpen(!isOpen)} defaultStatus={false} />
        </Row>
      </Row>
      <Row className="jc-sb w100">
        <Row>
          <ChartLegend
            type="dot"
            bgColor="#00B2B5"
            title="Sales"
            cRef={listingsRef}
            onChange={(v: boolean) => visibleChart(v, 'tradeprice')}
          />
          <ChartLegend
            type="dot"
            bgColor="#EB48AA"
            title="Listings"
            cRef={salesRef}
            onChange={(v: boolean) => visibleChart(v, 'listingprice')}
          />
          <ChartLegend
            type="line"
            bgColor="#FFE800"
            title="Trendline"
            cRef={trendlineRef}
            onChange={(v: boolean) => visibleChart(v, 'trendline')}
            tooltip={
              <PeweeTooltip
                placement="bottomRight"
                title="Trendline models the relationship between rarity and price of items. It gives the fair price of an item of certain rank, and it is generated by trades of your choice."
              >
                <Image src={notice} width={14} height={14} className="click mr10" />
              </PeweeTooltip>
            }
          />
          <Box ml="20px" className="click" onClick={() => setGuidesVisible(true)}>
            <PeweeTooltip title="Trendline guides" placement="bottomRight">
              <Box
                height="40px"
                width="40px"
                onMouseOver={() => setBulb(true)}
                onMouseLeave={() => setBulb(false)}
                background={isBulb ? '#FFE800' : 'rgba(255, 255, 255, 0.12)'}
                className="center circle"
              >
                <Image src={isBulb ? bulbActive : bulb} height={22} width={16} className="click" />
              </Box>
            </PeweeTooltip>
          </Box>
        </Row>
        <Row className="body_wrapper" py="10px">
          <Text fontSize="12px" color="rgba(255, 255, 255, 0.87)" mr="6px" fontWeight={600}>
            Rank:
          </Text>
          <NumberInput
            min={1}
            max={100000}
            fixed={0}
            inputRef={minRef}
            placeholder="Min"
            onChange={minChange}
            classN="filter_input_style"
          />
          <Text fontSize={12} color="#323336" ml="8px" mr="8px">
            to
          </Text>
          <NumberInput
            min={1}
            max={100000}
            fixed={0}
            inputRef={maxRef}
            placeholder="Max"
            onChange={maxChange}
            classN="filter_input_style"
          />
          <FilterBtn className="center click" ml={[0, 0, 4, 16]} mr="6px" onClick={onSave}>
            SAVE
          </FilterBtn>
          <FilterBtn className="center click" mr="6px" onClick={onClear}>
            CLEAR
          </FilterBtn>
        </Row>
      </Row>
      <Box className="" height="380px" mt="30px">
        {options.trades.length > 0 || options.listings.length > 0 ? (
          <Chart
            option={PriceEstimateOption(options, state.LiveViewReducer.crtSupply)}
            jumpable
            onClickChart={(val: any) => onClickDot(val)}
          />
        ) : (
          <NoData isLoading={isLoading} />
        )}
      </Box>

      <Modal
        bodyStyle={{
          background: '#252525',
          borderRadius: 12
        }}
        className="modalClassName"
        closable={false}
        onCancel={() => setGuidesVisible(false)}
        visible={guidesVisible}
        footer={null}
        width={820}
        maskClosable={false}
      >
        <Box height="548px" width="800" pl="40px" pr="20px" pt="20px" pb="40px">
          <Box className="fx jc-end w100">
            <Image src={close} className="click " width={10} height={10} onClick={() => setGuidesVisible(false)} />
          </Box>
          <Row mb="20px">
            <Text className="white87 fz24 fw600">Trendline Guides - </Text>
            <Text className="fz24 fw600" ml="4px" color="rgb(254,233,55)">
              Linear Model
            </Text>
          </Row>
          <Image src={trendlineGuidesContent} width={720} height={400} />
          <Row mt="20px">
            <Text className="fz14 fw400 white87 ">For more info for linear regression model, please refer to</Text>
            <Text
              className="fz14 fw400 ud-line click"
              color="#00B2B5"
              ml="4px"
              onClick={() => openWindow('https://en.wikipedia.org/wiki/Linear_regression')}
            >
              https://en.wikipedia.org/wiki/Linear_regression.
            </Text>
          </Row>
        </Box>
      </Modal>
    </Box>
  )
}
