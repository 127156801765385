import { timeFormate, unitTime, toNonExponential, valumeUnit } from '@/utils/tools'
import { ChartBgColor, grid, axisLine, axisTick, nameTextStyle, splitLine, axisLabel2, tooltipBg } from './types'

export const TradersOptions = (params: any): any => {
  const { buyers, traders, dates, sellers } = params

  return {
    backgroundColor: ChartBgColor, // 图表背景色
    tooltip: {
      backgroundColor: tooltipBg,
      borderColor: 'transparent',
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      },
      formatter(value: any) {
        let temp = ''
        const time = unitTime(value[0].axisValue)
        if (value.length === 1) {
          temp = `
            <div class="custom_tooltip_container" style="pending: 10px">
              <div id="tooltip" style="color: rgb(30,47,50);margin-bottom: 5px">${time}</div>
              <div class="fx-row jc-sb" style="margin-top: 5px;">
                <div class="fx-row ai-ct">
                  <div style="width: 6px; height: 6px; border-radius: 50%; background:${value[0].color} "></div>
                  <div id="tooltip" style="color: rgb(30,47,50); margin-left: 6px">${value[0].seriesName}</div>
                </div>
                <div id="tooltip" style="color: rgb(30,47,50);">${valumeUnit(value[0].value)}</div>
              </div>
            </div>
          `
        }
        if (value.length === 2) {
          temp = `
            <div class="custom_tooltip_container" style="pending: 10px">
              <div id="tooltip" style="color: rgb(30,47,50);margin-bottom: 5px">${time}</div>
              <div class="fx-row jc-sb" style="margin-top: 5px;">
                <div class="fx-row ai-ct">
                  <div style="width: 6px; height: 6px; border-radius: 50%; background:${value[0].color} "></div>
                  <div id="tooltip" style="color: rgb(30,47,50);margin-left: 6px">${value[0].seriesName}</div>
                </div>
                <div id="tooltip" style="color: rgb(30,47,50);">${valumeUnit(value[0].value)}</div>
              </div>
              <div class="fx-row jc-sb" style="margin-top: 5px;">
                <div class="fx-row ai-ct">
                  <div style="width: 6px; height: 6px; border-radius: 50%; background:${value[1].color} "></div>
                  <div id="tooltip" style="color: rgb(30,47,50); margin-left: 6px">${value[1].seriesName}</div>
                </div>
                <div id="tooltip" style="color: rgb(30,47,50);">${valumeUnit(value[1].value)}</div>
              </div>


            </div>
          `
        }
        if (value.length === 3) {
          temp = `
            <div class="custom_tooltip_container" style="pending: 10px">
              <div id="tooltip" style="color: rgb(30,47,50);margin-bottom: 5px">${time}</div>
              <div class="fx-row jc-sb" style="margin-top: 5px;">
                <div class="fx-row ai-ct">
                  <div style="width: 6px; height: 6px; border-radius: 50%; background:${value[0].color} "></div>
                  <div id="tooltip" style=" color: rgb(30,47,50); margin-left: 6px">${value[0].seriesName}</div>
                </div>
                <div id="tooltip" style="color: rgb(30,47,50);">${valumeUnit(value[0].value)}</div>
              </div>
              <div class="fx-row jc-sb" style="margin-top: 5px;">
                <div class="fx-row ai-ct">
                  <div style="width: 6px; height: 6px; border-radius: 50%; background:${value[1].color} "></div>
                  <div id="tooltip" style="color: rgb(30,47,50); margin-left: 6px">${value[1].seriesName}</div>
                </div>
                <div id="tooltip" style="color: rgb(30,47,50);">${valumeUnit(value[1].value)}</div>
              </div>
              <div class="fx-row jc-sb" style="margin-top: 5px;">
                <div class="fx-row ai-ct">
                  <div style="width: 10px; height: 2px; border-radius: 1px; background:${value[2].color} "></div>
                  <div id="tooltip" style="color: rgb(30,47,50); margin-left: 6px">${value[2].seriesName}</div>
                </div>
                <div id="tooltip" style="color: rgb(30,47,50);">${valumeUnit(value[2].value)}</div>
              </div>

            </div>
          `
        }
        return temp
      }
    },
    xAxis: {
      name: '',
      data: dates,
      axisLabel: {
        show: true,
        textStyle: {
          color: '#A3A4A7',
          fontSize: 14
        },
        formatter(value: any) {
          return `${timeFormate(Number(value), true)}`
        }
      },
      axisLine,
      axisTick
    },
    yAxis: {
      type: 'value',
      nameTextStyle,
      splitLine,
      axisLabel: axisLabel2
    },
    grid,
    series: [
      {
        name: 'Sellers',
        type: 'bar',
        barWidth: 10,
        itemStyle: {
          borderRadius: [2, 2, 0, 0]
        },
        color: '#267F51',
        data: sellers
      },
      {
        name: 'Buyers',
        type: 'bar',
        itemStyle: {
          borderRadius: [2, 2, 0, 0]
        },
        barWidth: 10,
        color: '#943235',
        data: buyers
      },
      {
        name: 'Traders',
        type: 'line',
        color: '#FFE800',
        data: traders
        // yAxisIndex: 1
      }
    ]
  }
}
