import React, { ChangeEvent } from 'react'
import { Input } from 'antd'

interface iPeweeInput {
  min: number
  max: number
  fixed?: number
  handleChange: (v: string) => void
}

// 只支持数字
export const PeweeInput: React.FC<any> = ({ min, max, fixed = 2, handleChange, ...props }) => {
  // Filters：输入范围为0≤price≤100，最多支持输入至小数点后2位。
  // 当输入值超过100时，输入值自动变为100；
  // 当输入值处在输入范围内，但超出小数点后2位时，自动四舍五入至小数点后2位。

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value
    if (val === '' || (val && Number.isNaN(Number(val)))) {
      handleChange('')
    } else if (Number(val) >= min && Number(val) <= max) {
      const dotIdx = val.indexOf('.')
      if (dotIdx !== -1) {
        if (fixed === 0) {
          handleChange(Number(val).toFixed(0))
        } else {
          const decimalLen = val.substring(dotIdx + 1).length
          if (decimalLen > 2) {
            const newVal = Number(val).toFixed(fixed)
            handleChange(newVal)
          } else {
            handleChange(val)
          }
        }
      } else {
        handleChange(val)
      }
    } else if (Number(val) > max) {
      handleChange(`${max}`)
    }
  }
  return <Input onChange={onChange} {...props} />
}
