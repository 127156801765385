import * as echarts from 'echarts'
import { dateList, valumeUnit4Chart, timeFormate, unitTime, toNonExponential, beyondStr } from '@/utils/tools'
import { ChartBgColor, grid } from './types'
import { eth } from '@/assets/img'

export const colorBy: Array<string> = ['#00B2B5', '#34C77B', '#FFE800', '#FF8715', '#EB48AA', '#474747']

export const Top20CollectionOptions = (params: any): any => {
  console.log('params', params)

  const { nfts, percents, volumes } = params
  const percentsList: Array<{ value: number; name: string }> = []
  let othersVal: number = 0
  let othersVolumes: number = 0

  percents.forEach((value: number, idx: number) => {
    if (idx > 4) {
      othersVal += value
      othersVolumes += volumes[idx]
    } else {
      percentsList.push({
        value,
        name: nfts[idx].name
      })
    }
  })
  percentsList.push({
    value: othersVal,
    name: 'Others'
  })
  return {
    backgroundColor: ChartBgColor,
    tooltip: {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      trigger: 'item',
      axisPointer: {
        type: 'shadow'
      },
      position: ['65', '100'],
      formatter(f: any) {
        const { name, value } = f.data
        const volumesIdx: number = nfts.findIndex((n: any) => n.name === name)
        let tooltipVol: number = 0
        tooltipVol = volumesIdx === -1 ? othersVolumes : volumes[volumesIdx]
        othersVolumes
        let temp = ''
        temp = `
          <div style="width: 150px;" class="fx-col center">
            <div style="font-weight: 300;font-size: 40px;line-height: 47px;color: rgba(255, 255, 255, 0.87)">${(
              value * 100
            ).toFixed(2)}%</div>
            <div style="font-weight: 600;font-size: 14px;color: rgba(255, 255, 255, 0.87);" class="center">${beyondStr(
              name,
              20
            )}</div>
            <div class="fx-row ai-ct">
              <img src="${eth}" style="width:6px;height:10px;" />
              <div style="font-weight: 400;font-size: 14px;color: rgba(255, 255, 255, 0.87);margin-left: 4px;">${toNonExponential(
                tooltipVol
              )}</div>
            </div>
          </div>
        `
        return temp
      }
    },
    xAxis: {
      show: false
    },
    yAxis: {
      show: false
    },
    grid,
    color: colorBy,

    series: [
      {
        name: 'Top20Pie',
        type: 'pie',
        radius: ['70%', '94%'],
        avoidLabelOverlap: false,
        label: {
          show: false
        },
        labelLine: {
          show: false
        },
        data: percentsList,
        colorBy: 'data'
      }
    ]
  }
}
