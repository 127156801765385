import React, { useState, useEffect, useCallback, useImperativeHandle } from 'react'
import styled from 'styled-components'
import { Box, Text } from '@/packages'

interface iSwitchButton {
  title?: string
  onChange: (open: boolean) => void
  defaultStatus?: boolean
  children?: React.ReactNode
  sRef?: any
}

export const Btn = styled(Box)<{ active: boolean }>`
  display: flex;
  align-items: center;
  width: 48px;
  height: 30px;
  border: ${({ active }) => (active ? 'double 1px transparent' : '1px solid #1E2229')};
  border-radius: 14px;
  background: ${({ active }) => (active ? ' #0B7677' : '#2E2E2E')};
  // background-image: ${({ active }) =>
    active
      ? `linear-gradient(#17191f, #17191f), radial-gradient(circle at top left, rgba(255, 232, 0, 1), rgba(0, 178, 181, 1))`
      : 'none'};
  // background-origin: border-box;
  // background-clip: content-box, border-box;
`
export const Dot = styled(Box)<{ active: boolean }>`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #fff;
  // background: ${({ active }) => (active ? '#5f6165 linear-gradient(90deg, #ffe800 0%, #00b2b5 100%)' : '#5f6165')};
  margin-left: ${({ active }) => (active ? '22px' : '4px')};
`
export const SwitchButton: React.FC<iSwitchButton> = ({ sRef, title, children, onChange, defaultStatus }) => {
  const [isOpen, setOpen] = useState<boolean>(defaultStatus || false)
  const handleOpen = () => {
    setOpen(!isOpen)
    onChange(!isOpen)
  }
  // const handleOpen = useCallback(() => {
  //   setOpen(!isOpen)
  //   onChange(!isOpen)
  // }, [isOpen])
  useImperativeHandle(sRef, () => ({
    resetOpenVal: () => {
      setOpen(defaultStatus || false)
    }
  }))
  return (
    <Box className="fx-row ai-ct">
      {children || (
        <Text fontSize="12px" color="rgba(255, 255, 255, 0.87)" mr="10px">
          {title}
        </Text>
      )}
      <Btn className="click" active={isOpen} onClick={handleOpen}>
        <Dot active={isOpen} />
      </Btn>
    </Box>
  )
}
