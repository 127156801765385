import { line1Legend } from '@/assets/img'
import {
  ChartBgColor,
  LegendStyle,
  axisLabel,
  grid,
  axisLine,
  axisTick,
  splitLine,
  lineColor1,
  lineColor2
} from './types'

const LengendData = [
  {
    name: 'Floor',
    icon: `image://${line1Legend}`
  },
  {
    name: 'Medium',
    icon: `image://${line1Legend}`
  }
]

export const TradePriceListingPriceOption: any = {
  backgroundColor: ChartBgColor, // 图表背景色
  legend: LegendStyle(LengendData),
  toolbox: {
    show: false
  },
  tooltip: {},
  xAxis: {
    name: '',
    data: ['1月', '2月', '3月', '4月', '5月'],
    axisLabel,
    axisLine,
    axisTick
  },
  yAxis: {
    splitLine,
    axisLabel
  },
  grid,
  series: [
    {
      name: 'Floor',
      data: [620, 732, 701, 534, 1090],
      type: 'line',
      color: lineColor2
    },
    {
      name: 'Medium',
      data: [820, 932, 901, 934, 1290],
      type: 'line',
      color: lineColor1
    }
  ]
}
