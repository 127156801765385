export const combineReducers = (slices: any) => (state: any, action: any) => {
  return Object.keys(slices).reduce(
    (acc, prop) => ({
      ...acc,
      [prop]: slices[prop](acc[prop], action)
    }),
    state
  )
}

// export const combineReducers = (reducers: any) => {
//   return (state: any, action: any) => {
//     const newState: any = {}
//     Object.keys(reducers).forEach((key: any) => {
//       newState[key] = reducers[key](state[key], action)
//     })
//     return newState
//   }
// }
