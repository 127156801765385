import React, { useState, useEffect, ChangeEvent, useImperativeHandle } from 'react'
import { Input as AntInput } from 'antd'
import useDebounce from '@/hooks/useDebounce'
import { decimalNum } from '@/utils/tools'
import { Grid, Text, Box } from '@/packages'
import { MobileNumberInput, iMobileNumberInput } from './MobileNumberInput'
import { Row, Col } from '@/components/Layout/Layout'

interface iMobileInput {
  title: string
}

export const MobileInput: React.FC<iMobileInput & iMobileNumberInput> = ({ title, ...props }) => {
  return (
    <Box height="44px" background="rgb(27,27,27)" borderRadius="12px" className="fx-row ai-ct" px="16px" py="12px">
      <Text className="fz14 fw400 white60" width="30%">
        {title}
      </Text>
      <Box width="70%">
        <MobileNumberInput {...props} />
      </Box>
    </Box>
  )
}

interface iMobileInputEditWrapper {
  title: string
  children: React.ReactNode
}
interface iMobileInputEdit {
  title: string
  suffix?: React.ReactNode
  prefix?: React.ReactNode
  value?: string | number
}
export const MobileInputEditWrapper: React.FC<iMobileInputEditWrapper> = ({ title, children }) => {
  return (
    <Box height="44px" background="rgb(27,27,27)" borderRadius="12px" className="fx-row ai-ct" px="16px" py="12px">
      <Text className="fz14 fw400 white60" width="30%">
        {title}
      </Text>
      <Box width="70%">{children}</Box>
    </Box>
  )
}
export const MobileInputEdit: React.FC<iMobileInputEdit> = ({ title, suffix, prefix, value }) => {
  return (
    <MobileInputEditWrapper title={title}>
      <Row className="jc-sb w100">
        <Row>
          {prefix}
          {value || value === 0 ? (
            <Text className="fz14 fw400 white87" ml={prefix ? '4px' : '0px'}>
              {value}
            </Text>
          ) : null}
        </Row>
        {suffix}
      </Row>
    </MobileInputEditWrapper>
  )
}
