import { toNonExponential, unitTime, dateList2, timeFormate2, timeFormate } from '@/utils/tools'
import { ChartBgColor, axisLine, axisTick, tooltipBg } from './types'

export const TradesVolumeOption = (params: any, granularity: string): any => {
  const { volumes, dates, coin } = params
  const VolumesSource: any[] = []
  volumes.forEach((v: any, i: number) => VolumesSource.push([dates[i], v]))
  const _dates = dateList2(dates)

  return {
    backgroundColor: ChartBgColor,
    tooltip: {
      backgroundColor: tooltipBg,
      borderRadius: 12,
      borderWidth: 0,
      padding: 0,
      borderColor: 'transparent',

      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      },
      formatter(value: any) {
        const { seriesName } = value[0]
        let temp: any
        if (seriesName === 'Volume') {
          const time = unitTime(value[0].axisValue)
          temp = `
            <div class="custom_tooltip_container" style="padding: 10px;">
              <div id="tooltip" style="color: rgb(30,47,50);margin-bottom: 5px">${time}</div>
              <div class="fx-row jc-sb" style="margin-top: 5px;">
                <div id="tooltip" style="color: rgb(30,47,50);">${value[0].seriesName}</div>
                <div id="tooltip" style="color: rgb(30,47,50);">Ξ ${toNonExponential(value[0].value[1])}</div>
              </div>
            </div>
          `
        }
        return temp
      }
    },
    grid: {
      left: '6%',
      right: 50,
      top: 0,
      bottom: 24
    },
    xAxis: [
      {
        type: 'category',
        axisLine,
        axisTick,
        splitLine: {
          show: false
        },
        axisLabel: {
          show: true,
          textStyle: {
            color: 'rgba(255, 255, 255, 0.6)',
            fontSize: 14
          },
          formatter(value: any) {
            return `${timeFormate2(Number(value))}`
          }
          // formatter(value: any) {
          //   return granularity.toLocaleLowerCase() === '1d'
          //     ? `${timeFormate2(Number(value))}`
          //     : `${timeFormate(Number(value), true)}`
          // }
        }
      }
    ],
    yAxis: {
      show: false
    },

    series: [
      {
        name: 'Volume',
        type: 'bar',
        itemStyle: {
          borderRadius: [2, 2, 0, 0]
        },
        color: '#FFE800',
        barWidth: 10,
        data: VolumesSource
      }
    ]
  }
}
