import * as echarts from 'echarts'
import { grid, axisTick, axisLabel, tooltipBg } from './types'
import { unitTime, toNonExponential } from '@/utils/tools'

export const TrendingFloorOption = (params: any): any => {
  const { values, dates } = params

  const isUp: boolean = values[0] > values[values.length - 1]

  return {
    tooltip: {
      backgroundColor: tooltipBg,
      borderRadius: 12,
      borderWidth: 0,
      padding: 0,
      borderColor: 'transparent',
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      },
      position: [10, 54],
      formatter(value: any) {
        let temp = ''
        const time = unitTime(value[0].axisValue)

        temp = `
          <div class="custom_tooltip_container" style="padding: 10px;">
            <div id="tooltip" style="color: rgb(30,47,50);margin-bottom: 5px">${time}</div>
            <div class="fx-row jc-sb" style="margin-top: 5px;">
              <div id="tooltip" style="color: rgb(30,47,50);">${value[0].seriesName}</div>
              <div id="tooltip" style="color: rgb(30,47,50);">${toNonExponential(value[0].value)}</div>
            </div>
          </div>
        `
        return temp
      }
    },
    xAxis: {
      data: dates,
      axisLabel,
      axisTick
    },
    yAxis: {
      show: false
    },
    grid: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0
    },
    series: [
      {
        data: values,
        name: 'Floor',
        type: 'line',
        smooth: true,
        lineStyle: {
          width: 2,
          color: !isUp ? '#34C77B' : '#EB484C'
        },
        showSymbol: false,
        center: ['50%', '50%'],
        areaStyle: {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: !isUp ? 'rgba(52, 199, 123, 0.6)' : 'rgba(235, 72, 76, 0.6)'
            },
            {
              offset: 0.5,
              color: 'rgba(217, 217, 217, 0)'
            },
            {
              offset: 1,
              color: 'rgba(217, 217, 217, 0)'
            }
            // {
            //   offset: 1,
            //   color: !isUp ? 'rgba(52, 199, 123, 0)' : 'rgba(235, 72, 76, 0)'
            // }
          ])
        }
      }
    ]
  }
}
