import { toNonExponential, toNonExponential1, unitTime, splitAddress, getRankColor } from '@/utils/tools'
import {
  ChartBgColor,
  axisLabel,
  fullGrid,
  grid,
  tooltipBg,
  tooltipBd1,
  tooltipBd2,
  nameTextStyle,
  axisLine,
  axisTick,
  splitLine,
  tooltipTextColor
} from './types'

export const PriceEstimateOption = (params: any, supply: number): any => {
  const { listings, regressionLine, trades, rankRange, priceRange } = params
  const ListingPriceData: Array<any> = []
  const TradePriceData: Array<any> = []
  const TrendLineData: Array<any> = []
  let rMIn = 0
  let rMax = 0
  let pMin = 0
  let pMax = 0
  if (rankRange) {
    const { min, max } = rankRange
    rMIn = min
    rMax = max
  }
  if (priceRange) {
    const { min, max } = priceRange
    pMin = min
    pMax = max
  }
  listings.forEach((l: any) => ListingPriceData.push([l.rank, l.price, l]))

  trades.forEach((td: any) => {
    TradePriceData.push([td.rank, td.price, td])
    const rank = td.nftTokenVO ? td.nftTokenVO.rank : 0
  })

  if (regressionLine) {
    const { coefficient, constant } = regressionLine
    if (coefficient && constant) {
      for (let i = rMIn; i < rMax + 1; i++) {
        TrendLineData.push([i, Number(coefficient) * i + Number(constant)])
      }
    }
  }

  return {
    backgroundColor: ChartBgColor,
    tooltip: {
      backgroundColor: tooltipBg,
      borderRadius: 12,
      borderWidth: 0,
      padding: 0,
      borderColor: 'transparent',
      trigger: 'item',
      axisPointer: {
        type: 'shadow'
      },
      formatter(value: any) {
        if (Object.prototype.toString.call(value) === '[object Array]') {
          const item = value[0]
          const { seriesName, value: lineValue } = item
          if (seriesName === 'TrendLine') {
            const rankValue = lineValue[0]
            const rankColor = getRankColor(rankValue, supply)
            return `
              <div id="custom_tooltip_container" style="color: ${tooltipTextColor};border-color: ${item.color} ">
                <div class="fx-row jc-sb mt5" style="color:${rankColor};font-weight: 600;">
                  <div id="tooltip">Rank</div>
                  <div id="tooltip">${rankValue}</div>
                </div>
                <div class="fx-row jc-sb mt5">
                  <div id="tooltip">Fair Price</div>
                  <div id="tooltip">Ξ ${toNonExponential(lineValue[1])}</div>
                </div>
              `
          }
        }
        if (Object.prototype.toString.call(value) === '[object Object]') {
          if (value && value.seriesName !== 'TrendLine') {
            const { seriesName } = value
            const item = value.value[2]
            let nameType = ''
            let bdColor = ''

            if (seriesName === 'TradePrice') {
              nameType = 'Sold'
              bdColor = tooltipBd1
            }
            if (seriesName === 'ListingPrice') {
              nameType = 'Listed'
              bdColor = tooltipBd2
            }

            let rankColor = '#727272'
            if (item?.rank) {
              rankColor = getRankColor(item.rank, supply)
            }

            const temp = `
              <div id="custom_tooltip_container" style="color: ${tooltipTextColor}; border-color: ${value.color}">
                <div class="fx-row jc-sb mt5"  style="color:${rankColor};font-weight: 600;">
                  <div id="tooltip">Rank</div>
                  <div id="tooltip">${item?.rank || '--'}</div>
                </div>
                <div class="fx-row jc-sb mt5">
                  <div id="tooltip">${nameType}</div>
                  <div id="tooltip" >${item?.price ? toNonExponential(item?.price) : 0}</div>
                </div>
                <div class="fx-row jc-sb mt5">
                  <div id="tooltip">Token ID</div>
                  <div id="tooltip" >#${
                    item?.nftTokenVO?.tokenId.length > 12
                      ? splitAddress(item?.nftTokenVO?.tokenId, 4)
                      : item?.nftTokenVO?.tokenId
                  }</div>
                </div>
                <img src="${
                  item?.nftTokenVO?.imageUrl
                }" style="width: 130px; height: 130px; margin-top: 10px;border-radius: 6px;"/>
              </div>
            `
            return temp
          }
        }
      }
    },
    xAxis: {
      name: 'RARITY RANK',
      type: 'value',
      nameTextStyle,
      nameLocation: 'center',
      axisLabel,
      axisLine,
      axisTick,
      splitLine: { show: false },
      min: rMIn,
      max: rMax,
      axisPointer: {
        show: true,
        type: 'line',
        lineStyle: {
          color: '#A09207'
        },
        label: {
          show: false
        }
      }
    },
    yAxis: {
      name: `PRICE Ξ`,
      type: 'value',
      nameTextStyle,
      nameLocation: 'center',
      splitLine,
      axisLabel,
      axisTick,
      axisLine,
      min: toNonExponential1(pMin, 2),
      max: toNonExponential1(pMax, 2),
      axisPointer: {
        show: true,
        snap: false,
        type: 'line',
        lineStyle: {
          color: '#A09207'
        },
        label: {
          show: false
        }
      }
    },
    grid: {
      left: 20,
      right: 20,
      top: 10,
      bottom: 30,
      containLabel: true
    },
    series: [
      {
        name: 'ListingPrice',
        type: 'scatter',
        symbolSize: 8,
        color: '#EB48AA',
        data: ListingPriceData
      },
      {
        name: 'TradePrice',
        type: 'scatter',
        symbolSize: 8,
        color: '#00B2B5',
        data: TradePriceData
      },
      {
        name: 'TrendLine',
        type: 'line',
        color: '#FFE800',
        showSymbol: false,
        symbol: 'none',
        data: TrendLineData,
        tooltip: {
          borderColor: '#FFE800'
        }
      }
    ]
  }
}
