export const WALLET_LOGIN = 'WALLET_LOGIN'
export const CLICK_ROOT = 'CLICK_ROOT'
export const MINT_OVERVIEW = 'MINT_OVERVIEW'
export const MINT_PER_WALLET = 'MINT_PER_WALLET'
export const MINT_TOP_MINTERS = 'MINT_TOP_MINTERS'
export const WALLET_GROUP_LIST = 'WALLET_GROUP_LIST'
export const LIVE_VIEW_SUPPLY = 'LIVE_VIEW_SUPPLY'
export const LISTING = 'LISTING'
export const TRADES = 'TRADES'
export const LISTING_TRADES = 'LISTING_TRADES'
export const LISTING_CHART = 'LISTING_CHART'
export const PRICE_CHART = 'PRICE_CHART'

export const MARKET_CAP = 'MARKET_CAP'
export const HOT_MINTS = 'HOT_MINTS'
export const HOT_COLLECTION = 'HOT_COLLECTION'
export const FEED_LIST = 'FEED_LIST'

export const TRENDING_TABLE = 'TRENDING_TABLE'
export const PRICE_ESTIMATE = 'PRICE_ESTIMATE'
export const NFT_INFO = 'NFT_INFO'
export const LISTING_DEPTH = 'LISTING_DEPTH'
export const NFT_ID_CONTEXT = 'NFT_ID_CONTEXT'
export const CRT_SUPPLY = 'CRT_SUPPLY'
export const LISTING_FILTER = 'LISTING_FILTER'
export const QUICK_BUY_VISIBLE = 'QUICK_BUY_VISIBLE'
export const TRAIT_VISIBLE = 'TRAIT_VISIBLE'
export const ACCESS_AUTH = 'ACCESS_AUTH'
export const RARITY_RANK = 'RARITY_RANK'
export const LISTING_TOTAL = 'LISTING_TOTAL'
export const CHANGE_PERIOD = 'CHANGE_PERIOD'
export const REFRESH_TABLE = 'REFRESH_TABLE'
export const TRENDING_PRICE = 'TRENDING_PRICE'
export const MINTS_PRICE = 'MINTS_PRICE'
export const ADD_ALERT = 'ADD_ALERT'
export const CRT_NFT_ADDRESS = 'CRT_NFT_ADDRESS'
export const WS_FLOOR_PRICE = 'WS_FLOOR_PRICE'
export const MEMBER_INFO = 'MEMBER_INFO'
export const WS_SOLD_OUT = 'WS_SOLD_OUT'
export const UPCOMING_TOTAL_SUBMIT = 'UPCOMING_TOTAL_SUBMIT'
