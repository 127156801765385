import { DefaultTheme } from 'styled-components'
import { commonColors } from './common'

export const darkTheme: DefaultTheme = {
  isDark: true,
  colors: {
    ...commonColors,
    main: '#191C1C',
    primary1: '#04DDDD',
    primary2: '#003738',
    primary3: '#004F50',
    primary4: '#4AFAFA',
    secondary1: '#B0CCCB',
    secondary2: '#1B3534',
    secondary3: '#324B4B',
    secondary4: '#CCE8E7',
    tertiary1: '#B3C8E8',
    tertiary2: '#1C314B',
    tertiary3: '#334863',
    tertiary4: '#D2E4FF',
    error1: '#FFB4A9',
    error2: '#680003',
    error3: '#930006',
    error4: '#FFDAD4',
    bg1: '#191C1C',
    bg2: '#E0E3E2',
    bg3: '#191C1C',
    bg4: '#E0E3E2',
    bg5: '#3F4848',
    bg6: '#BEC9C8',
    bg7: '#889392',
    bg8: '#4CDADB',
    bg9: '#3F494A',
    bg10: '#242929',

    // border color
    focus: '#00B2B5',

    // 文字颜色
    white1: '#E5E1E6'
  }
}
