import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { useAppContext } from '@/hooks/useAppContext'
import { Box, Text, Input } from '@/packages'
import { Chart } from '@/components/Charts/Chart'
import { TradesOption, TradesVolumeOption } from '@/components/Charts/options/index'
import { Col, Row, CardTitle } from '@/components/Layout/Layout'
import { TradesApi } from '@/request/Api'
import { TabListSelected, iTabItem } from '@/components/TabListSelected'
import { NoData } from '@/components/NoData'
import { SwitchButton } from '@/components/SwitchButton'

const Container = styled(Box)`
  height: 400px;
`
const initOption = {
  volumes: [],
  trades: [],
  dates: [],
  coin: {
    symbol: '',
    chain: '',
    address: '',
    precision: 18,
    name: ''
  }
}
export const Trades = () => {
  const [isOpen, setOpen] = useState<boolean>(false)
  const [granularity, setGranularity] = useState('1d')
  const [options, setOptions] = useState<any>(null)
  const [volumesOptions, setVolumeOptions] = useState(null)
  const [latestPrice, setLatestPrice] = useState<number>(0)

  const [temp, setTemp] = useState(initOption)

  const [period, setPeriod] = useState<Array<iTabItem>>([
    { id: 0, name: '1h', active: false },
    { id: 1, name: '4h', active: false },
    { id: 2, name: '12h', active: false },
    { id: 3, name: '1d', active: true },
    { id: 4, name: '3d', active: false }
  ])
  const [isLoading, setLoading] = useState<boolean>(true)
  const { state, dispatch } = useAppContext()

  const params = useParams()
  const fetch = async () => {
    setLoading(true)
    setOptions(null)
    setVolumeOptions(null)
    const p = {
      nftId: Number(params.nftId),
      granularity,
      outliersOn: !isOpen
    }
    const d: any = await TradesApi(p)
    setLoading(false)
    if (d && !!Object.keys(d).length) {
      setTemp(d)
      setOptions(TradesOption(d, latestPrice))
      setVolumeOptions(TradesVolumeOption(d, granularity))
    }
  }
  useEffect(() => {
    fetch()
  }, [granularity, latestPrice, isOpen])

  const onChangePeriod = (val: iTabItem) => {
    const _period = JSON.parse(JSON.stringify(period))
    _period.forEach((_p: iTabItem) => {
      _p.active = _p.name === val.name
    })
    setPeriod(_period)
    setGranularity(val.name)
  }

  useEffect(() => {
    if (state?.LiveViewReducer?.wsFloorPrice) {
      const price = state.LiveViewReducer.wsFloorPrice.close
      setLatestPrice(price)
    }
  }, [state?.LiveViewReducer?.wsFloorPrice])

  return (
    <Box className="fx-col body_wrapper " mt="24px" height="550px" mb="24px">
      <Row className="jc-sb" mt="24px">
        <CardTitle>Trades</CardTitle>
        <Row>
          <Row mr="24px">
            <Text fontSize="12px" color="rgba(255, 255, 255, 0.87)" mr="10px">
              Period
            </Text>
            <TabListSelected ml={10} ftsz={12} list={period} onChange={onChangePeriod} selectedList={false} />
          </Row>
          <SwitchButton title="Outliers" onChange={() => setOpen(!isOpen)} defaultStatus={false} />
        </Row>
      </Row>

      <Container className="">
        <Box height="310px" className="">
          {options ? (
            <Chart option={options} />
          ) : (
            <NoData isLoading={isLoading} msg="No trades in the selected time range." />
          )}
        </Box>
        {volumesOptions && (
          <Box height="90px" className="">
            <Chart option={volumesOptions} />
          </Box>
        )}
      </Container>
    </Box>
  )
}
