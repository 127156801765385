import React from 'react'
import { AppActionType } from '@/types/store'
import { PriceEstimateApi, NftInfoApi, ListingDepthApi } from '@/request/Api'
import {
  PRICE_ESTIMATE,
  NFT_INFO,
  LISTING_DEPTH,
  NFT_ID_CONTEXT,
  CRT_SUPPLY,
  LISTING_FILTER,
  QUICK_BUY_VISIBLE,
  TRAIT_VISIBLE,
  RARITY_RANK,
  LISTING_TOTAL,
  CRT_NFT_ADDRESS,
  WS_FLOOR_PRICE,
  WS_SOLD_OUT
} from '@/store/common'
import { iPriceEstimateParams, iCondition, iListingDepth, iListingFilter, iNFTTokenVO } from '@/request/types'
import { inftInfo } from '@/types/store/index'

export const PriceEstimateAction = async (params: iPriceEstimateParams, dispatch: React.Dispatch<AppActionType>) => {
  const data = await PriceEstimateApi(params)
  return dispatch({
    type: PRICE_ESTIMATE,
    payload: {
      priceEstimate: data
    }
  })
}
export const NftInfoAction = async (nftInfo: inftInfo, dispatch: React.Dispatch<AppActionType>) => {
  return dispatch({
    type: NFT_INFO,
    payload: {
      nftInfo
    }
  })
}
export const ListingDepthAction = async (params: iListingDepth, dispatch: React.Dispatch<AppActionType>) => {
  const data = await ListingDepthApi(params)
  return dispatch({
    type: LISTING_DEPTH,
    payload: {
      listingDepth: data
    }
  })
}

export const NFTIdAction = async (nftId: number, dispatch: React.Dispatch<AppActionType>) => {
  return dispatch({
    type: NFT_ID_CONTEXT,
    payload: {
      crtNftId: nftId
    }
  })
}

export const CrtSupplyAction = async (supply: number, dispatch: React.Dispatch<AppActionType>) => {
  return dispatch({
    type: CRT_SUPPLY,
    payload: {
      crtSupply: supply
    }
  })
}
export const CrtNFTAddressAction = async (addr: string, dispatch: React.Dispatch<AppActionType>) => {
  return dispatch({
    type: CRT_NFT_ADDRESS,
    payload: {
      crtNFTAddr: addr
    }
  })
}

export const ListingFilterAction = async (options: iListingFilter, dispatch: React.Dispatch<AppActionType>) => {
  return dispatch({
    type: LISTING_FILTER,
    payload: {
      listingFilterOptions: options
    }
  })
}

export const QuickBuyVisibleAction = async (buyInfo: any, from: string, dispatch: React.Dispatch<AppActionType>) => {
  return dispatch({
    type: QUICK_BUY_VISIBLE,
    payload: {
      buyInfo,
      quickBuyFrom: from
    }
  })
}
export const TraitModalAction = async (trait: any, dispatch: React.Dispatch<AppActionType>) => {
  return dispatch({
    type: TRAIT_VISIBLE,
    payload: {
      trait
    }
  })
}

export const RarityRankAction = async (
  rankFilter: { min?: number; max?: number },
  dispatch: React.Dispatch<AppActionType>
) => {
  return dispatch({
    type: RARITY_RANK,
    payload: {
      rankFilter
    }
  })
}

export const ListingTotalAction = async (dispatch: React.Dispatch<AppActionType>, total: number | string) => {
  return dispatch({
    type: LISTING_TOTAL,
    payload: {
      total
    }
  })
}

export const WsFloorPriceAction = async (dispatch: React.Dispatch<AppActionType>, wsFloorPrice: any) => {
  return dispatch({
    type: WS_FLOOR_PRICE,
    payload: {
      wsFloorPrice
    }
  })
}

export const SoldOutNFTAction = async (dispatch: React.Dispatch<AppActionType>, wsSoldOut: any) => {
  return dispatch({
    type: WS_SOLD_OUT,
    payload: {
      wsSoldOut
    }
  })
}
