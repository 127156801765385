import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Select, Radio, RadioChangeEvent, Popover, Space } from 'antd'
import { Box, Text, Image } from '@/packages'
import { dropdownIcon } from '@/assets/img/'

// const GroupSizeList = [0.01, 0.02, 0.05, 0.1, 0.2, 0.5, 1, 2, 5]
interface iRadioRender {
  onChangeRadio: (item: string) => void
  list: Array<string>
  initValue: string
}
interface iDropdownSelect {
  onChange: (value: string) => void
  initVal: string
  dataSource: Array<string>
  children?: React.ReactNode
  icon?: string
  onVisibleChange?: (visible: boolean) => void
}
const Container = styled(Box)`
  height: 30px;
  border: 1px solid rgba(255, 255, 255, 0.6);
  padding-left: 12px;
  padding-right: 12px;
  cursor: pointer;
  border-radius: 12px;
`

const RadioRender: React.FC<iRadioRender> = ({ onChangeRadio, list, initValue }) => {
  const [value, setValue] = useState(initValue)
  const onChange = (val: string) => {
    setValue(val)
    onChangeRadio(val)
  }
  return (
    <Box
      maxHeight="200px"
      borderRadius="15px"
      background="#404040"
      className="hide_scrollbar"
      style={{ overflow: 'scroll' }}
    >
      {list.map((label, i) => {
        return (
          <Box
            key={Math.random()}
            height="30px"
            px="12px"
            className="click dropdown_item fx-row ai-ct w100"
            onClick={() => onChange(label)}
          >
            <Text className="white fz12 fw400">{label}</Text>
            <Box ml="16px" />
          </Box>
        )
      })}
    </Box>
  )
}

export const DropdownSelect: React.FC<iDropdownSelect> = ({
  children,
  onChange,
  initVal,
  dataSource,
  icon,
  onVisibleChange
}) => {
  const [value, setValue] = useState(initVal)
  const [visible, setVisible] = useState<boolean>(false)
  const onChangeRadio = (val: string) => {
    setValue(val)
    onChange(val)
    setVisible(false)
  }
  const onVisibleChange1 = (v: boolean) => {
    setVisible(v)
    setTimeout(() => {
      onVisibleChange
    }, 10)
  }
  return (
    <Popover
      visible={visible}
      content={<RadioRender onChangeRadio={onChangeRadio} list={dataSource} initValue={initVal} />}
      trigger="click"
      placement="bottomRight"
      onVisibleChange={onVisibleChange1}
    >
      {children || (
        <Container className="fx-row jc-sb ai-ct">
          <Text color="rgba(255, 255, 255, 0.87)" fontSize="12px">
            {icon}
            {value}
          </Text>
          <Image src={dropdownIcon} width={12} height={12} className="ml4" />
        </Container>
      )}
    </Popover>
  )
}
