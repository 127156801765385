import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Box, Text, Image, Grid } from '@/packages/'
import { Col, Row } from '@/components/Layout/Layout'
import { faqImg, discordUs, twitterUs, discordActive, twitterActive } from '@/assets/img'
import { ActiveIcon } from '@/components/ActiveIcon'
import { useMatchBreakpoints } from '@/hooks/useMatchBreakpoints'

const FqaImg = styled(Box)`
  position: absolute;
  height: 644px;
  left: 0px;
  bottom: 0;
`
const FlashBigImg = styled(Box)`
  position: absolute;
  height: 1450px;
  left: 0px;
  top: 300px;
`
const AnswerBox = styled(Box)``
export const Faq = (scrollNum: any) => {
  const { isMobile } = useMatchBreakpoints()
  return (
    <Box className=" c-sb w100 relative">
      <Box className="w100 bg28 pa" height={['230px', '636px', '1042px', '1450px']} bottom="0px" />
      <Col className="mxw relative">
        {isMobile ? (
          <Text
            fontSize={['20px', '34px', '47px', '60px']}
            fontWeight={700}
            color="#fff"
            textAlign="center"
            height={['26px', '44px', '62px', '80px']}
            marginTop={['30px', '40px', '50px', '60px']}
            marginBottom={['25px', '47px', '71px', '90px']}
            className={
              scrollNum.scrollNum > 3400 && scrollNum.scrollNum < 4400 ? 'isMobileFaqTitle' : 'isMobileFaqTitleNo'
            }
          >
            FAQ
          </Text>
        ) : (
          <Text
            fontSize={['20px', '34px', '47px', '60px']}
            fontWeight={700}
            color="#fff"
            textAlign="center"
            height={['26px', '44px', '62px', '80px']}
            marginTop={['30px', '40px', '50px', '60px']}
            marginBottom={['25px', '47px', '71px', '90px']}
            className={scrollNum.scrollNum > 6900 && scrollNum.scrollNum < 7800 ? 'faqTitle' : 'faqTitleNo'}
          >
            FAQ
          </Text>
        )}
        <AnswerBox>
          <Grid
            gridColumnGap={['0', '0', '0', '30px']}
            gridTemplateColumns={['1fr', '1fr', '1fr', '1fr 1fr']}
            className="fx jc-sb ai-ct"
          >
            <Box
              className="bgc2 br30 mb30"
              paddingTop={['20px', '27px', '34px', '40px']}
              paddingBottom={['20px', '27px', '34px', '40px']}
              paddingLeft={['14px', '18px', '24px', '26px']}
              paddingRight={['14px', '18px', '24px', '26px']}
            >
              <Box className="fx ai-ct ">
                <Box width={['30px', '40px', '60px', '60px']}>
                  <Image src={faqImg} className="rw100 rh100" />
                </Box>
                <Text
                  width={['', '', '', '388px']}
                  fontSize={['12px', '15px', '19px', '22px']}
                  fontWeight={600}
                  color="#fff"
                  ml="15px"
                >
                  Which Network does Pewee.tools all-time pass NFT on?
                </Text>
              </Box>
              <Box paddingLeft={['45px', '57px', '68px', '75px']}>
                <Text fontSize={['12px', '15px', '18', '20px']} fontWeight={600} className="mtxt">
                  A：On Ethereum.
                </Text>
              </Box>
            </Box>
            <Box
              className="bgc2 br30 mb30"
              paddingTop={['20px', '20px', '25px', '25px']}
              paddingBottom={['20px', '20px', '25px', '25px']}
              paddingLeft={['14px', '18px', '24px', '26px']}
              paddingRight={['14px', '18px', '24px', '26px']}
            >
              <Box className="fx ai-ct ">
                <Box width={['30px', '40px', '60px', '60px']}>
                  <Image src={faqImg} className="rw100 rh100" />
                </Box>
                <Text
                  width={['', '', '', '388px']}
                  fontSize={['12px', '15px', '19px', '22px']}
                  fontWeight={600}
                  color="#fff"
                  ml="15px"
                >
                  Do I need to pay for the gas fee for minting NFT？
                </Text>
              </Box>
              <Box paddingLeft={['45px', '57px', '68px', '75px']}>
                <Text fontSize={['12px', '15px', '18', '20px']} fontWeight={600} className="mtxt">
                  A：Yes, you should pay for the gas fee for minting pewee.tools NFT.
                </Text>
              </Box>
            </Box>
          </Grid>
          <Grid
            gridColumnGap={['0', '0', '30px', '30px']}
            gridTemplateColumns={['1fr', '1fr', '1fr', '1fr 1fr']}
            className="fx jc-sb ai-ct"
          >
            <Box
              className="bgc2 br30  mb30"
              paddingTop={['20px', '27px', '34px', '40px']}
              paddingBottom={['20px', '27px', '34px', '40px']}
              paddingLeft={['14px', '18px', '24px', '26px']}
              paddingRight={['14px', '18px', '24px', '26px']}
            >
              <Box className="fx ai-ct ">
                <Box width={['30px', '40px', '60px', '60px']}>
                  <Image src={faqImg} className="rw100 rh100" />
                </Box>
                <Text
                  width={['', '', '', '388px']}
                  fontSize={['12px', '15px', '19px', '22px']}
                  fontWeight={600}
                  color="#fff"
                  ml="15px"
                >
                  When mint and offcial launch？
                </Text>
              </Box>
              <Box paddingLeft={['45px', '57px', '68px', '75px']}>
                <Text fontSize={['12px', '15px', '18', '20px']} fontWeight={600} className="mtxt">
                  A：October
                </Text>
              </Box>
            </Box>
            <Box
              className="bgc2 br30  mb30"
              paddingTop={['20px', '27px', '34px', '40px']}
              paddingBottom={['20px', '27px', '34px', '40px']}
              paddingLeft={['14px', '18px', '24px', '26px']}
              paddingRight={['14px', '18px', '24px', '26px']}
            >
              <Box className="fx ai-ct ">
                <Box width={['30px', '40px', '60px', '60px']}>
                  <Image src={faqImg} className="rw100 rh100" />
                </Box>
                <Text
                  width={['', '', '', '388px']}
                  fontSize={['12px', '15px', '19px', '22px']}
                  fontWeight={600}
                  color="#fff"
                  ml="15px"
                >
                  How to get all-time pass NFT？
                </Text>
              </Box>
              <Box paddingLeft={['45px', '57px', '68px', '75px']}>
                <Text fontSize={['12px', '15px', '18', '20px']} fontWeight={600} className="mtxt">
                  A：Follow our twitter and discord to get a WL.
                </Text>
              </Box>
            </Box>
          </Grid>
          <Grid
            gridColumnGap={['0', '0', '30px', '30px']}
            gridTemplateColumns={['1fr', '1fr', '1fr', '1fr 1fr']}
            className="fx jc-sb ai-ct"
          >
            <Box
              className="bgc2 br30  mb30"
              paddingTop={['20px', '20px', '25px', '25px']}
              paddingBottom={['20px', '20px', '25px', '25px']}
              paddingLeft={['14px', '18px', '24px', '26px']}
              paddingRight={['14px', '18px', '24px', '26px']}
            >
              <Box className="fx ai-ct ">
                <Box width={['30px', '40px', '60px', '60px']}>
                  <Image src={faqImg} className="rw100 rh100" />
                </Box>
                <Text
                  width={['', '', '', '388px']}
                  fontSize={['12px', '15px', '19px', '22px']}
                  fontWeight={600}
                  color="#fff"
                  ml="15px"
                >
                  I already in the whitelist role how can I mint the NFT？
                </Text>
              </Box>
              <Box paddingLeft={['45px', '57px', '68px', '75px']}>
                <Text fontSize={['12px', '15px', '18', '20px']} fontWeight={600} className="mtxt">
                  A：48hours for WL to mint NFT. You can mint NFT with acceptable gas fee.
                </Text>
              </Box>
            </Box>
            <Box
              className="bgc2 br30  mb30"
              paddingTop={['20px', '20px', '25px', '25px']}
              paddingBottom={['20px', '20px', '25px', '25px']}
              paddingLeft={['14px', '18px', '24px', '26px']}
              paddingRight={['14px', '18px', '24px', '26px']}
            >
              <Box className="fx ai-ct ">
                <Box width={['30px', '40px', '60px', '60px']}>
                  <Image src={faqImg} className="rw100 rh100" />
                </Box>
                <Text
                  width={['', '', '', '388px']}
                  fontSize={['12px', '15px', '19px', '22px']}
                  fontWeight={600}
                  color="#fff"
                  ml="15px"
                >
                  How can I get in touch with the team？
                </Text>
              </Box>
              <Box paddingLeft={['45px', '57px', '68px', '75px']}>
                <Text fontSize={['12px', '15px', '18', '20px']} fontWeight={600} className="mtxt">
                  A：You can reach us through a Twitter DM or in our Discord server.
                </Text>
              </Box>
            </Box>
          </Grid>
        </AnswerBox>
        <Box className="x jc-ct ai-ct mt100">
          {isMobile ? (
            <Row>
              <ActiveIcon
                src={twitterUs}
                activeSrc={twitterUs}
                targetUrl="https://twitter.com/peweetools"
                tooltip="Twitter"
                h={20}
                w={20}
              />
              <Box ml={['54px', '54px', '60px', '100px']}>
                <ActiveIcon
                  src={discordUs}
                  activeSrc={discordUs}
                  targetUrl="https://discord.gg/3QhPyqXdmJ"
                  tooltip="Discord"
                  h={20}
                  w={24}
                />
              </Box>
            </Row>
          ) : (
            <Row>
              <ActiveIcon
                src={twitterUs}
                activeSrc={twitterUs}
                targetUrl="https://twitter.com/peweetools"
                tooltip="Twitter"
                h={40}
                w={40}
              />
              <Box ml={['20px', '40px', '60px', '100px']}>
                <ActiveIcon
                  src={discordUs}
                  activeSrc={discordUs}
                  targetUrl="https://discord.gg/3QhPyqXdmJ"
                  tooltip="Discord"
                  h={36}
                  w={46}
                />
              </Box>
            </Row>
          )}
        </Box>
      </Col>
    </Box>
  )
}
