import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Box, Text, Image } from '@/packages/'
import { useEthereum } from '@/hooks/useEthereum'
import { walletWhite } from '@/assets/img'
import { OpenNotification } from '@/components/OpenNotification'

const Btn = styled(Box)`
  height: 40px;
  background: #00b2b5;
  box-shadow: inset 0px 0px 12px rgba(0, 0, 0, 0.05);
  border-radius: 50px;
`
interface iConnectWalletBtn {
  connectStatus: (s: boolean) => void
}
export const ConnectWalletBtn: React.FC<iConnectWalletBtn> = ({ connectStatus }) => {
  const [isConnect, setConnect] = useState<boolean>(false)
  const { ethereum, provider } = useEthereum()

  const connectWallet = async () => {
    if (ethereum) {
      const addr = await ethereum.request({ method: 'eth_requestAccounts' })
      if (addr && addr.length) {
        setConnect(true)
      } else {
        setConnect(false)
      }
    } else {
      OpenNotification('Please install Metamask first.', 'warning')
    }
  }
  useEffect(() => {
    connectStatus(isConnect)
  }, [isConnect])
  return (
    <Btn className="fx-row ai-ct click jc-sb" onClick={connectWallet} px="16px">
      <Image src={walletWhite} height={20} width={20} />
      <Text className="fz16 fw700 white" ml="8px">
        Connect
      </Text>
    </Btn>
  )
}
