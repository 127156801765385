import { iUpcomingState, AppActionType } from '@/types/store'
import { UPCOMING_TOTAL_SUBMIT } from '@/store/common'

export const initUpcomingState: any = {
  upcomingTotalSubmit: 0
}

export function UpcomingReducer(state = initUpcomingState, action: AppActionType): iUpcomingState {
  const { upcomingTotalSubmit } = action.payload
  switch (action.type) {
    case UPCOMING_TOTAL_SUBMIT:
      return {
        ...state,
        upcomingTotalSubmit
      }
    default:
      return state
  }
}
