import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { Box, Image, Text } from '@/packages/'
import {} from '@/assets/img'
import { Row, Col, PeweeTooltip } from '@/components/Layout/Layout'

import { openWindow } from '@/utils/tools'

interface iActiveIcon {
  src: string
  activeSrc: string
  targetUrl: string
  tooltip: string
  w?: number
  h?: number
}
export const ActiveIcon: React.FC<iActiveIcon> = ({ src, activeSrc, targetUrl, tooltip, w, h }) => {
  const [isActive, setActive] = useState<boolean>(false)

  return (
    <div>
      {targetUrl && (
        <Box className="center" onMouseOver={() => setActive(true)} onMouseLeave={() => setActive(false)}>
          <PeweeTooltip title={tooltip}>
            <Image
              src={isActive ? activeSrc : src}
              height={h || 14}
              width={w || 14}
              onClick={() => openWindow(targetUrl)}
              className="click mr8"
            />
          </PeweeTooltip>
        </Box>
      )}
    </div>
  )
}

interface iActiveIconMobile {
  src: string
  title: string
  targetUrl: string
  isLast?: boolean
}
export const ActiveIconMobile: React.FC<iActiveIconMobile> = ({ src, title, targetUrl, isLast }) => {
  return (
    <Row
      height="50px"
      pl="16px"
      className="click"
      onClick={() => openWindow(targetUrl)}
      style={{ borderBottom: isLast ? '1px solid transparent' : '1px solid #474747' }}
    >
      <Image src={src} width={20} height={20} />
      <Text className="fz14 fw400 white87" ml="12px">
        {title}
      </Text>
    </Row>
  )
}
