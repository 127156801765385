import React, { useState, useEffect, useImperativeHandle, useRef } from 'react'
import styled from 'styled-components'
import { Input as AntInput, DatePicker, Dropdown, Menu, Upload, message } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'

import type { DatePickerProps } from 'antd'
import type { RangePickerProps } from 'antd/es/date-picker'
import moment from 'moment'
import type { UploadChangeParam } from 'antd/es/upload'
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface'
import { Box, Image, Text, Grid } from '@/packages/'
import {
  uploadIcon,
  yyyymmdd,
  clock,
  leftArrow,
  rightArrow,
  showLess,
  showMore,
  editImage,
  delImage,
  avator
} from '@/assets/img'
import { Row, Col } from '@/components/Layout/Layout'
import { uploadBase64ImageApi, upcomingFieldsModifyApi, AddUpcomingApi, EditUpcomingApi } from '@/request/Api'
import { iAddUpcomingParams } from '@/request/types'
import { less0, isURL, delQueryFalseNot0, delEmptyObj, getBase64 } from '@/utils/tools'
import { TimeDropdownMenu } from './TimeDropdownMenu'
import { ShortInput } from './ShortInput'
import { InputContainer } from './InputContainer'
import { MaskWrapper } from './MaskWrapper'
import { OpenNotification } from '@/components/OpenNotification'
import { UserAvatorPopOver } from './UserAvatorPopOver'

import './index.css'
import './upcomingInput.css'

const initFormData = {
  projectName: { value: '', errorMsg: '', isModifyed: false },
  websiteLink: { value: '', errorMsg: '', isModifyed: false },
  twitterLink: { value: '', errorMsg: '', isModifyed: false },
  dcLink: { value: '', errorMsg: '', isModifyed: false },
  mintDate: { value: '', errorMsg: '', isModifyed: false },
  mintPrice: { value: '', errorMsg: '', isModifyed: false },
  supply: { value: '', errorMsg: '', isModifyed: false },
  imageUrl: { value: '', errorMsg: '', isModifyed: false }
}
// 弹窗内容
const SplitLine: React.FC<{ name: string }> = ({ name }) => {
  return (
    <Col mt="30px">
      <Text className="fz14 white38 fw500">{name}</Text>
      <Box height="2px" background="#434343" className="w100" mt="16px" />
    </Col>
  )
}
const UploadBtn = styled(Box)`
  width: 96px;
  height: 96px;
  background: rgba(0, 0, 0, 0.2);
  border: 1px dashed rgba(255, 255, 255, 0.2);
  border-radius: 500px;
`
const EditUploadImage = styled(Box)`
  background: #000000;
  opacity: 0.6;
  width: 96px;
  height: 42px;
  border-radius: 0 0 1000px 1000px;
`
interface iEditPeoject {
  eRef: any
  closeModal: () => void
}
interface iData {
  // require: boolean
  value: string
  errorMsg: string
  isModifyed: boolean
}
interface iFormData {
  projectName: iData
  websiteLink: iData
  twitterLink: iData
  dcLink: iData
  mintDate: iData
  mintPrice: iData
  supply: iData
  imageUrl: iData
}

export const EditPeoject: React.FC<iEditPeoject> = ({ eRef, closeModal }) => {
  const [isShowMore, setShowMore] = useState<boolean>(false)
  const [uploadLoading, setUploadLoading] = useState<boolean>(false)
  const [base64, setBase64] = useState<string>('')

  const [formData, setFormData] = useState<iFormData>(initFormData)
  const [editId, setEditId] = useState<number>(0)

  const [mintTime, setMintTime] = useState<string>('00:00')
  const [isMintTime, setIsMintTime] = useState<boolean>(false)
  const [isSubmit, setIsSubmit] = useState<boolean>(false)
  const [uploadHover, setUploadHover] = useState<boolean>(false)
  const [editRecord, setEditRecord] = useState<any>()

  const [mintDate, setMintDate] = useState<string>('')

  const projectNameRef: any = useRef()
  const websiteLinkRef: any = useRef()
  const twitterLinkRef: any = useRef()
  const discordLinkRef: any = useRef()
  const mintPriceRef: any = useRef()
  const supplyRef: any = useRef()

  const handleUpload: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    // Project Image：非必填项，支持jpeg，jpg，png格式，大小不超过1M。上传后支持删除。超出限制大小：输入框下方提示信息为“No more than 1M.”;
    console.log('info file', info.file)
    const { type, size, status, originFileObj, name } = info.file
    if (size && size / 1024 / 1024 > 1) {
      changeFormDataMsg('imageUrl', '', 'No more than 1M.')
      return
    }
    if (status === 'uploading') {
      setUploadLoading(true)
    } else {
      getBase64(originFileObj as RcFile, (url) => {
        setBase64(url)
      })
    }
  }
  const uploadImg = async (img: string) => {
    const imgUrl: any = await uploadBase64ImageApi({ image: img })
    setUploadLoading(false)
    imgUrl && onChangeInput(imgUrl, 'imageUrl')
  }

  // eslint-disable-next-line arrow-body-style
  const disabledDate: DatePickerProps['disabledDate'] = (current: any) => {
    const tomorrow = moment().add(1, 'day').endOf('day')
    const today = moment().endOf('day')
    const yesterday = moment().subtract(1, 'days')
    return current < yesterday
    // return current && current < moment(new Date())
  }

  const onChangeDate: DatePickerProps['onChange'] = (date, dateString) => {
    // console.log('date', date)
    // console.log('dateString', dateString)
    setMintDate(dateString)
    onSelectTime('00:00')
  }
  const onSelectTime = (t: string) => {
    if (mintDate) {
      // 先选择年月日 2022/09/20
      if (moment(`${mintDate} ${t}`).isAfter(moment(new Date()))) {
        console.log('isAfter')
        setMintTime(t)
        setIsMintTime(true)
      }
    }
  }
  const onChangeInput = (v: string, name: string) => {
    setIsSubmit(false)
    setFormData((f: any) => ({
      ...f,
      [name]: { value: v === null || v === 'null' ? '' : v, errorMsg: '', isModifyed: f[name].isModifyed }
    }))
  }
  const changeFormDataMsg = (keyName: string, value: string, msg: string) => {
    setIsSubmit(false)
    setFormData((f: any) => ({ ...f, [keyName]: { value, errorMsg: msg, isModifyed: f[keyName].isModifyed } }))
  }

  const resetStatus = () => {
    setEditRecord(null)
    setShowMore(false)
    setUploadLoading(false)
    setMintTime('00:00')
    setIsMintTime(false)
    setIsSubmit(false)
    setEditId(0)
    setFormData(initFormData)
    clearInputCache()
  }
  const clearInputCache = () => {
    projectNameRef.current && projectNameRef.current.clearInputValue()
    websiteLinkRef.current && websiteLinkRef.current.clearInputValue()
    twitterLinkRef.current && twitterLinkRef.current.clearInputValue()
    discordLinkRef.current && discordLinkRef.current.clearInputValue()
    mintPriceRef.current && mintPriceRef.current.clearInputValue()
    supplyRef.current && supplyRef.current.clearInputValue()
    // mintTime    upload img cache
  }
  useImperativeHandle(eRef, () => ({
    onSubmit: () => {
      const { value: projectNameValue } = formData.projectName
      // const pNameReg: any = /^((?=.*\d\s)|(?=.*[a-zA-Z\s])|(?=.*[:\-.&()|'+\s])|[\da-zA-Z:\-.&()|'+\s]){1,100}$/
      //  /^((?=.*\d\s)|(?=.*[a-zA-Z\s])|(?=.*[\:\-\.&()\|\'\+\s])|[\da-zA-Z\:\-\.&()\|\'\+\s]){0,100}$/.test('012ADSDS')
      // if (!pNameReg.test(projectNameValue)) {
      //   changeFormDataMsg('projectName', projectNameValue, 'Please enter the correct format.')
      // }
      projectNameValue === '' && changeFormDataMsg('projectName', projectNameValue, 'Please enter project name')
      projectNameValue.length > 100 &&
        changeFormDataMsg('projectName', projectNameValue, 'No more than 100 characters.')

      const { value: websiteLinkValue } = formData.websiteLink
      if (websiteLinkValue === '') {
        changeFormDataMsg('websiteLink', websiteLinkValue, 'Please enter website link')
      } else if (!isURL(websiteLinkValue)) {
        changeFormDataMsg('websiteLink', websiteLinkValue, 'Please enter the correct format.')
      }

      const { value: twitterLinkValue } = formData.twitterLink
      if (twitterLinkValue === '') {
        changeFormDataMsg('twitterLink', twitterLinkValue, 'Please enter Twitter link')
      } else if (!twitterLinkValue.startsWith('https://twitter')) {
        changeFormDataMsg('twitterLink', twitterLinkValue, 'Please enter the correct format.')
      }

      const { value: dcLinkValue } = formData.dcLink
      if (dcLinkValue && !dcLinkValue.startsWith('https://discord')) {
        changeFormDataMsg('dcLink', dcLinkValue, 'Please enter the correct format.')
      }

      const { value: mintPriceValue } = formData.mintPrice
      // 填写数字即可，支持输入至小数点后4位。 填写输入数字需≤1000。
      if (mintPriceValue) {
        if (Number(mintPriceValue) > 1000) {
          changeFormDataMsg('mintPrice', mintPriceValue, 'Please enter the correct format.')
        }
      }

      const { value: supplyValue } = formData.supply
      if (supplyValue) {
        // 填写数字即可，仅支持输入正整数，填写输入数字需≤100000。
        if (Number(supplyValue) < 0 || Number(supplyValue) > 100000) {
          changeFormDataMsg('supply', supplyValue, 'Please enter the correct format.')
        }
      }
      setIsSubmit(true)
    },
    onCancel: () => {
      // 取消重置状态
      console.log('onCancel')
      resetStatus()
    },
    currentModalType: (type: string, record?: any) => {
      // console.log('currentModalType record', record)
      setEditRecord(type === 'edit' ? record : null)
    }
  }))

  const AddUpcoming = async () => {
    const { projectName, websiteLink, twitterLink, dcLink, supply, mintPrice, mintDate: _mintDate, imageUrl } = formData
    const p: iAddUpcomingParams = {
      name: projectName.value,
      website: websiteLink.value,
      twitter: twitterLink.value,
      discord: dcLink.value,
      supply: supply.value === '' || supply.value === null ? null : Number(supply.value),
      mintPrice: mintPrice.value === '' || mintPrice.value === null ? null : Number(mintPrice.value),
      mintTime: _mintDate.value === '' || _mintDate.value === null ? null : Number(_mintDate.value),
      image: imageUrl.value
    }
    // console.log('editRecord', editRecord)
    // console.log('1111111', delEmptyObj(delQueryFalseNot0(p)))

    if (editRecord) {
      p.id = editId
      const editRes = await EditUpcomingApi(delEmptyObj(delQueryFalseNot0(p)))
      if (editRes) {
        OpenNotification('Thanks for your request. Please wait for permission', 'success')
        // close modal 并刷新列表
        closeModal()
        resetStatus()
      }
    } else {
      const d = await AddUpcomingApi(delEmptyObj(delQueryFalseNot0(p)))
      if (d) {
        OpenNotification('Thanks for your request. Please wait for permission', 'success')
        // close modal 并刷新列表
        closeModal()
        resetStatus()
      }
    }
  }

  useEffect(() => {
    if (isSubmit) {
      // 所有都合法 再提交
      const isPass: boolean = Object.values(formData).every((f) => f.errorMsg === '')
      // console.log('isPass', isPass)
      if (isPass) {
        AddUpcoming()
      }
    }
  }, [formData, isSubmit])

  useEffect(() => {
    base64 && uploadImg(base64)
  }, [base64])

  useEffect(() => {
    if (mintTime && mintDate) {
      if (isMintTime) {
        // console.log('时间', new Date(`${mintDate} ${mintTime}`))
        onChangeInput(`${new Date(`${mintDate} ${mintTime}`).getTime()}`, 'mintDate')
      }
    }
  }, [mintTime, mintDate, isMintTime])

  const fieldsIsModify = async (editData: any) => {
    const d: any = await upcomingFieldsModifyApi(editData.id)
    if (d) {
      const { name, discord, image, mintPrice, mintTime: __mintTime, supply, twitter, website } = editData
      setFormData({
        projectName: { value: name, errorMsg: '', isModifyed: d.name },
        websiteLink: { value: website, errorMsg: '', isModifyed: d.website },
        twitterLink: { value: twitter, errorMsg: '', isModifyed: d.twitter },
        dcLink: { value: discord, errorMsg: '', isModifyed: d.discord },
        mintDate: { value: __mintTime, errorMsg: '', isModifyed: d.mintTime },
        mintPrice: { value: mintPrice, errorMsg: '', isModifyed: d.mintPrice },
        supply: { value: supply, errorMsg: '', isModifyed: d.supply },
        imageUrl: { value: image, errorMsg: '', isModifyed: d.image }
      })
    }
  }

  useEffect(() => {
    if (editRecord) {
      if (editRecord.mintTime) {
        const d = new Date(editRecord.mintTime)
        setMintTime(`${less0(d.getHours())}:${less0(d.getMinutes())}`)
        setIsMintTime(true)
      }
      // 需要填充 formData 用于最后提交判断
      setEditId(editRecord.id)
      fieldsIsModify(editRecord)
    }
  }, [editRecord])

  const delUploadedImg = (e: any) => {
    e.stopPropagation()
    setFormData((f: iFormData) => ({ ...f, imageUrl: { value: '', errorMsg: '', isModifyed: false } }))
    setBase64('')
    setEditRecord((s: any) => ({ ...s, image: '' }))
  }

  // console.log('isSubmit====>>>', isSubmit)
  // console.log('formData=======', formData)
  // console.log('editRecord=======', editRecord)
  // console.log('formData222=======', formData.projectName)

  return (
    <Col>
      <SplitLine name="Basic information" />
      <InputContainer
        inputRef={projectNameRef}
        editValue={editRecord?.name}
        errorMsg={formData.projectName.errorMsg}
        title="Project Name"
        require
        query={{
          id: editRecord?.id,
          name: 'name'
        }}
        isEdit={!!editId}
        isModifyed={formData.projectName.isModifyed}
        placeholder="Please enter project name"
        onChange={(v: string) => onChangeInput(v, 'projectName')}
      />
      <InputContainer
        isEdit={!!editId}
        disabled={editRecord?.website ? 1 : 0}
        inputRef={websiteLinkRef}
        editValue={editRecord?.website}
        errorMsg={formData.websiteLink.errorMsg}
        title="Website Link"
        require
        query={{
          id: editRecord?.id,
          name: 'website'
        }}
        isModifyed={formData.websiteLink.isModifyed}
        placeholder="Please enter website link"
        onChange={(v: string) => onChangeInput(v, 'websiteLink')}
      />
      <InputContainer
        isEdit={!!editId}
        disabled={editRecord?.twitter ? 1 : 0}
        inputRef={twitterLinkRef}
        editValue={editRecord?.twitter}
        errorMsg={formData.twitterLink.errorMsg}
        title="Twitter Link"
        require
        query={{
          id: editRecord?.id,
          name: 'twitter'
        }}
        isModifyed={formData.twitterLink.isModifyed}
        placeholder="Please enter Twitter link"
        onChange={(v: string) => onChangeInput(v, 'twitterLink')}
      />
      <MaskWrapper title="Project Chain" require={false}>
        <Text className="fz14 white87 fw500" ml="10px" mt="6px">
          Ethereum Chain
        </Text>
      </MaskWrapper>

      {isShowMore && (
        <Col>
          <SplitLine name="Project Details" />
          <InputContainer
            isEdit={!!editId}
            disabled={editRecord?.discord ? 1 : 0}
            inputRef={discordLinkRef}
            editValue={editRecord?.discord}
            errorMsg={formData.dcLink.errorMsg}
            title="Discord Link"
            require={false}
            query={{
              id: editRecord?.id,
              name: 'discord'
            }}
            isModifyed={formData.dcLink.isModifyed}
            placeholder="Please enter discord link"
            onChange={(v: string) => onChangeInput(v, 'dcLink')}
          />
          <MaskWrapper title="Drop Date" require={false}>
            <Row width="400px" className="jc-sb">
              <Row>
                <Box className="mask_input center" width="150px">
                  {editRecord?.mintTime ? (
                    <Row>
                      <DatePicker
                        defaultValue={moment(Number(editRecord?.mintTime))}
                        disabledDate={disabledDate}
                        placeholder="yyyy/mm/dd"
                        format="YYYY/MM/DD"
                        allowClear={false}
                        className="date_picker"
                        disabled={false}
                        onChange={onChangeDate}
                        bordered={false}
                        suffixIcon={<Image src={yyyymmdd} width={18} height={18} className="click" />}
                        prevIcon={
                          <Row ml="20px">
                            <Image src={leftArrow} width={6} height={10} className="click" />
                          </Row>
                        }
                        nextIcon={
                          <Row mr="20px">
                            <Image src={rightArrow} width={6} height={10} className="click" />
                          </Row>
                        }
                        superPrevIcon={
                          <Row ml="20px">
                            <Image src={leftArrow} width={6} height={10} className="click" />
                            <Image src={leftArrow} width={6} height={10} className="click" />
                          </Row>
                        }
                        superNextIcon={
                          <Row mr="20px">
                            <Image src={rightArrow} width={6} height={10} className="click" />
                            <Image src={rightArrow} width={6} height={10} className="click" />
                          </Row>
                        }
                      />
                    </Row>
                  ) : (
                    <DatePicker
                      disabledDate={disabledDate}
                      placeholder="yyyy/mm/dd"
                      format="YYYY/MM/DD"
                      allowClear={false}
                      className="date_picker"
                      disabled={false}
                      onChange={onChangeDate}
                      bordered={false}
                      suffixIcon={<Image src={yyyymmdd} width={18} height={18} className="click" />}
                      prevIcon={
                        <Row ml="20px">
                          <Image src={leftArrow} width={6} height={10} className="click" />
                        </Row>
                      }
                      nextIcon={
                        <Row mr="20px">
                          <Image src={rightArrow} width={6} height={10} className="click" />
                        </Row>
                      }
                      superPrevIcon={
                        <Row ml="20px">
                          <Image src={leftArrow} width={6} height={10} className="click" />
                          <Image src={leftArrow} width={6} height={10} className="click" />
                        </Row>
                      }
                      superNextIcon={
                        <Row mr="20px">
                          <Image src={rightArrow} width={6} height={10} className="click" />
                          <Image src={rightArrow} width={6} height={10} className="click" />
                        </Row>
                      }
                    />
                  )}
                </Box>
              </Row>

              <Row>
                <Dropdown overlay={<TimeDropdownMenu onSelect={onSelectTime} />} placement="bottom">
                  <Row className="mask_input  jc-sb" width="150px" ml="10px" px="16px">
                    <Text className={isMintTime ? 'fz14 fw500 white87' : 'fz14 fw500 white20'}>{mintTime}</Text>
                    <Image src={clock} width={18} height={18} className="click" />
                  </Row>
                </Dropdown>
                <UserAvatorPopOver id={editRecord?.id} name="mintTime" isModifyed={formData.mintDate.isModifyed} />
              </Row>
            </Row>
          </MaskWrapper>
          <MaskWrapper title="Mint Price" require={false}>
            <Row className="jc-sb w100 ">
              <ShortInput
                isEdit={!!editId}
                inputRef={mintPriceRef}
                errorMsg={formData.mintPrice.errorMsg}
                editValue={editRecord?.mintPrice}
                placeholder="0.0001"
                query={{
                  id: editRecord?.id,
                  name: 'mintPrice'
                }}
                isModifyed={formData.mintPrice.isModifyed}
                onChange={(v: string) => onChangeInput(v, 'mintPrice')}
                suffix={<Text className="fz14 white87 fw500">ETH</Text>}
              />
              <MaskWrapper title="Supply" require={false} pr="0px" mt="0px">
                <ShortInput
                  query={{
                    id: editRecord?.id,
                    name: 'supply'
                  }}
                  isEdit={!!editId}
                  errorMsg={formData.supply.errorMsg}
                  inputRef={supplyRef}
                  editValue={editRecord?.supply}
                  isModifyed={formData.supply.isModifyed}
                  placeholder="10000"
                  onChange={(v: string) => onChangeInput(v, 'supply')}
                />
              </MaskWrapper>
            </Row>
          </MaskWrapper>
          <MaskWrapper title="Project Image" require={false}>
            <Col>
              <Upload
                onChange={handleUpload}
                accept=".jpg,.png,jpeg"
                maxCount={1}
                disabled={uploadLoading}
                showUploadList={false}
              >
                <div style={{ position: 'relative' }}>
                  <UploadBtn className="center click fx-col">
                    {formData.imageUrl.value ? (
                      <Box className="" style={{ position: 'relative' }}>
                        <img
                          alt=""
                          src={formData.imageUrl.value}
                          style={{ height: 96, width: 96 }}
                          className="circle"
                        />
                        <Box
                          onMouseOver={() => setUploadHover(true)}
                          onMouseLeave={() => setUploadHover(false)}
                          style={{ position: 'absolute', zIndex: 9, right: 0, top: 0 }}
                        >
                          <Image src={delImage} height={24} width={24} className="click" onClick={delUploadedImg} />
                        </Box>
                        <EditUploadImage
                          className="click center"
                          style={{ position: 'absolute', zIndex: 9, left: 0, bottom: 0 }}
                        >
                          <Image src={editImage} height={20} width={20} />
                        </EditUploadImage>
                      </Box>
                    ) : (
                      <>
                        {!uploadLoading && <Image src={uploadIcon} height={20} width={20} />}
                        <Text className="fz14 fw500" color="rgba(255, 255, 255, 0.2)">
                          {uploadLoading ? 'uploading' : 'upload'}
                        </Text>
                      </>
                    )}
                  </UploadBtn>
                  <Box style={{ position: 'absolute', zIndex: 9, right: 0, bottom: 0 }}>
                    <UserAvatorPopOver id={editRecord?.id} name="image" isModifyed={formData.imageUrl.isModifyed} />
                  </Box>
                </div>
              </Upload>
              {formData.imageUrl.errorMsg && (
                <Text color="#D84549" className="fz14 fw500" mt="6px">
                  {formData.imageUrl.errorMsg}
                </Text>
              )}
            </Col>
          </MaskWrapper>
        </Col>
      )}
      <Row className="w100 center " mt={!isShowMore ? '38px' : '28px'}>
        <Text className="fz16 fw500 ud-line click" color="#00B2B5" mr="6px" onClick={() => setShowMore(!isShowMore)}>
          {!isShowMore ? 'Show more' : 'Show less'}
        </Text>
        <Image src={!isShowMore ? showMore : showLess} height={6} width={7} />
      </Row>
    </Col>
  )
}
