import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { Tooltip } from 'antd'
import { useBottomScrollListener } from 'react-bottom-scroll-listener'
import { TooltipPlacement } from 'antd/es/tooltip'
import { Box, Image, Text, Input, Grid } from '@/packages'
import { eth, ethIcon } from '@/assets/img'

export const Divider = styled(Box)`
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.04);
`
export const Vertical = styled(Box)<{ h: number }>`
  width: 1px;
  height: ${({ h }) => `${h}px`};
  border: 1px solid #2e2e2e;
`
export const BodyWrapper = styled(Box)`
  position: relative;
  display: flex;
  padding-bottom: 32px;
  padding-left: 16px;
  padding-right: 16px;
`
interface iCardTitle {
  mb?: string
  children: React.ReactNode
}
interface iListingCardTitle {
  title: string
  count: number
}
export const CardTitle: React.FC<iCardTitle> = ({ mb, children }) => {
  return (
    <Text className="fw600 white87" mb={mb || '16px'} fontSize={['20px', '20px', '24px', '24px']}>
      {children}
    </Text>
  )
}
export const ListingCardTitle: React.FC<iListingCardTitle> = ({ title, count }) => {
  return (
    <Row>
      <Text className="fw600 white87" fontSize={['20px', '20px', '24px', '24px']}>
        {title}
      </Text>
      <Text className="fw400 white87" fontSize={['20px', '20px', '24px', '24px']} ml="6px">{`(${count})`}</Text>
    </Row>
  )
}
// export const CardTitle = styled(Box)<{ mb?: string }>`
//   font-size: 24px;
//   font-weight: 600;
//   line-height: 28px;
//   margin-bottom: ${({ mb }) => mb || '16px'};
//   color: rgba(255, 255, 255, 0.87);
// `
export const ChartTitle = styled(Box)`
  width: 100%;
  min-width: 1440px;
  height: 94px;
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  line-height: 29px;
  display: flex;
  align-items: flex-end;
  padding-bottom: 16px;
`

export const Row = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`
export const Col = styled(Box)`
  display: flex;
  flex-direction: column;
`

export const Container = styled(Box)`
  max-width: 1440px;
  width: 100%;
  display: flex;
  justify-content: center;
`
interface iContentWrapper {
  children: React.ReactNode
}
interface iETHValue {
  value: number | string
  fw?: number
  mt?: number
  fz?: number
}
export const ContentWrapper: React.FC<iContentWrapper> = ({ children }) => {
  return (
    <Box className="w100 fx jc-ct">
      <Container>{children}</Container>
    </Box>
  )
}

// eth 图标和数值
export const ETHValue: React.FC<iETHValue> = ({ value, fw, mt, fz }) => {
  return (
    <Row>
      <Box className=" center" mt={mt || 0}>
        <Image src={eth} width={6} height={12} />
      </Box>
      {fz ? (
        <Text fontSize={fz} className="white87" fontWeight={fw || 400} ml="6px">
          {value || '--'}
        </Text>
      ) : (
        <Text fontSize={['14px', '14px', '16px', '16px']} className="white87" fontWeight={fw || 400} ml="6px">
          {value || '--'}
        </Text>
      )}
    </Row>
  )
}

interface iNFTImage {
  width: number
  height: number
  classN: string
  src: string
  onHandle?: () => void
  iconHW?: number
}

export const NFTImage: React.FC<iNFTImage> = ({ width, height, classN, src, iconHW, onHandle }) => {
  if (src) {
    return <Image src={src} height={height} width={width} className={classN} onClick={onHandle} />
  }
  return (
    <Box background="#1E2229" className={`center ${classN}`} height={height} width={width} onClick={onHandle}>
      <Image src={ethIcon} height={iconHW || 28} width={iconHW || 28} />
    </Box>
  )
}

interface iTooltip {
  children: React.ReactNode
  title: string
  color?: string
  placement?: TooltipPlacement
  trigger?: string //	触发行为，可选 hover | focus | click | contextMenu，可使用数组设置多个触发行为
}
export const PeweeTooltip: React.FC<iTooltip> = ({ children, title, color, placement, trigger }) => {
  return (
    <Tooltip title={title} color={color || '#474747'} placement={placement || 'top'} trigger={trigger || 'hover'}>
      {children}
    </Tooltip>
  )
}

interface iListingWrapper {
  children: React.ReactNode
  onPageBottom?: () => void
  onContainerBottom: () => void
  height?: string
}
export const ListingLoadWrapper: React.FC<iListingWrapper> = ({
  children,
  onPageBottom,
  onContainerBottom,
  height
}) => {
  const handleOnDocumentBottom = () => {
    onPageBottom && onPageBottom()
  }
  const handleContainerOnBottom = () => {
    onContainerBottom()
  }
  useBottomScrollListener(handleOnDocumentBottom)
  const listRef: any = useBottomScrollListener(handleContainerOnBottom)
  return (
    <Box ref={listRef} className="hide_scrollbar" height={height || `710px`} style={{ overflow: 'auto' }}>
      {children}
    </Box>
  )
}

interface iLoadingItem {
  height: number
  mt: number
}
const LoadingLeft = styled(Box)<{ h: number }>`
  width: ${({ h }) => `${h * 0.5}px`};
  height: ${({ h }) => `${h * 0.5}px`};
  background: #2f3239;
  border-radius: 50%;
`
const LoadingMiddle = styled(Box)<{ h: string; w: string }>`
  height: ${({ h }) => h};
  width: ${({ w }) => w};
  background: #2f3239;
`
const LoadingRight = styled(Box)<{ w: string }>`
  width: ${({ w }) => w};
  height: 24px;
  background: #2f3239;
`
// 从左上角 到右下角的动画
export const LoadingItem: React.FC<iLoadingItem> = ({ height, mt }) => {
  return (
    <Row background="#1A1D23" width="100%" height={`${height}px`} px="14px" className="jc-sb" mt={`${mt}px`}>
      <LoadingLeft h={height} />
      <Col ml="10px" className="" style={{ width: '60%' }}>
        <LoadingMiddle h="16px" w="70%" />
        <LoadingMiddle h="12px" w="100%" mt="10px" />
      </Col>
      <LoadingRight w="15%" />
    </Row>
  )
}
export const LoadingItemWrapper: React.FC<{ count?: number; height: number; mt?: number }> = ({
  count,
  height,
  mt
}) => {
  return (
    <Box className="w100">
      {Array(count || 3)
        .fill('')
        .map((item: string) => (
          <LoadingItem key={Math.random()} height={height} mt={mt || 12} />
        ))}
    </Box>
  )
}
export const ChartContainer = styled(Box)<{ h: number }>`
  background-color: rgba(255, 255, 255, 0.04);
  height: ${({ h }) => `${h + 24}px`};
  padding-top: 24px;
  border-radius: 12px;
`

const GBox = styled(Box)`
  width: 100%;
  height: 36px;
`
interface iGranularity {
  value: string
  active: boolean
}
export const SwitchGranularity: React.FC<{ list: Array<string>; onHandle: (g: string) => void }> = ({
  list,
  onHandle
}) => {
  const [gList, setlist] = useState<Array<iGranularity>>([])
  const initList = () => {
    const arr: Array<iGranularity> = []
    // 去除空格 转成小写
    list.forEach((item: string, idx: number) => {
      arr.push({
        value: item,
        active: idx === 0
      })
    })
    setlist(arr)
  }
  const handleGranularity = (idx: number) => {
    const _gList = JSON.parse(JSON.stringify(gList))
    _gList.forEach((_g: iGranularity, _i: number) => {
      if (_i === idx) {
        const target: string = `${_g.value}`.replace(/\s*/g, '').toLocaleLowerCase()
        onHandle(target)
        _g.active = true
      } else {
        _g.active = false
      }
    })
    setlist(_gList)
  }
  useEffect(() => {
    initList()
  }, [])
  return (
    <Grid gridTemplateColumns={['1fr '.repeat(list.length)]} width="210px" className="fx-row">
      {!!gList.length &&
        gList.map((g: iGranularity, i: number) => (
          <GBox
            key={Math.random().toString()}
            className={g.active ? 'center btn_gradient click' : 'center btn_common click'}
            onClick={() => handleGranularity(i)}
          >
            <Text color="#A3A4A7" fontSize="14px">
              {g.value}
            </Text>
          </GBox>
        ))}
    </Grid>
  )
}

export const ChartLegend: React.FC<{
  color: string
  title: string
  visibleChart?: () => void
  ml: number
}> = ({ color, title, visibleChart, ml }) => {
  return (
    <Box className="fx-row ai-ct" ml={`${ml}px`}>
      <Box background={color} height="8px" width="8px" />
      <Text className="a3a f14 click" ml="4px" onClick={visibleChart && visibleChart}>
        {title}
      </Text>
    </Box>
  )
}

export const BgWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Box background="rgba(255, 255, 255, 0.04)" borderRadius="12px" px="12px" py="12px">
      {children}
    </Box>
  )
}
