import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Box, Text, Grid } from '@/packages/'
import { Col } from '@/components/Layout/Layout'
import { useMatchBreakpoints } from '@/hooks/useMatchBreakpoints'

const ContonBox = styled(Box)`
  margin: 0 auto;
`
const DotLast = styled(Box)`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #fff;
`
const FlashImg = styled(Box)`
  width: 100%;
  height: 100%;
  // position: absolute;
  // right: -60px;
  // top: 188px;
`
const FlashBigImg = styled(Box)`
  position: absolute;
  height: 1450px;
  left: 0px;
  top: 300px;
`
const AgileCodeBox = styled(Box)`
  width: 100%;
  height: 100%;
`
export const Agile = (scrollNum: any) => {
  const { isMobile, isTablet } = useMatchBreakpoints()
  const [visible2, setVisible2] = useState(false)
  return (
    <Box className="c-sb w100 relative" height={['505px', '666px', '940px', '990px']}>
      {isMobile || isTablet ? '' : <FlashBigImg className="w100 bg29" />}
      <Col className="mxw relative">
        <Box className="fx ai-ct  relative" marginTop={['65px', '103px', '141px', '180px']}>
          {isMobile || isTablet ? (
            <Box className="fx ai-ct jc-sb w100">
              <Box
                width={['180px', '350px', '410px']}
                paddingRight="26px"
                className={
                  scrollNum.scrollNum > 1000 && scrollNum.scrollNum < 2100
                    ? 'tr pr60 isMobileAgileEffect'
                    : 'tr pr60 isMobileAgileTest'
                }
              >
                <Box className="fx jc-end">
                  <Box
                    width={['5px', '5px', '6px', '8px']}
                    height={['5px', '5px', '6px', '8px']}
                    marginTop={['8px', '11px', '10px', '10px']}
                    marginLeft={['6px', '6px', '14px', '14px']}
                    marginRight={['6px', '6px', '14px', '14px']}
                  >
                    <DotLast />
                  </Box>
                  <Text
                    marginBottom={['5px', '14px', '22px', '30px']}
                    fontSize={['15px', '20px', '25px']}
                    fontWeight={400}
                    color="#fff"
                  >
                    Flash Buy
                  </Text>
                </Box>
                <Text
                  fontSize={['12px', '20px', '24px']}
                  fontWeight={500}
                  color="rgba(255, 255, 255, 0.5)"
                  textAlign="right"
                >
                  It only takes 4 seconds to complete the purchase.
                </Text>
              </Box>
              <Box
                className={`card ${visible2 ? 'rotated click' : 'click'}`}
                onClick={() => setVisible2(!visible2)}
                height={['115px', '183px', '251px', '320px']}
                width={['160px', '256px', '352px', '450px']}
                marginBottom={['0', '0', '0', '280px']}
              >
                <Box
                  className="front bg22 codeBox"
                  height={['115px', '183px', '251px', '320px']}
                  width={['160px', '256px', '352px', '450px']}
                  paddingTop={['25px', '40px', '55px', '72px']}
                  paddingLeft={['10px', '16px', '24px', '30px']}
                >
                  <AgileCodeBox>
                    <Text fontSize={['16px', '24px', '32px', '40px']} fontWeight={700} color="#fff">
                      Agile Execution
                    </Text>
                    <Box className="pa bm5 rg4 bg34" width="40px" height="10px" />
                  </AgileCodeBox>
                </Box>
                <Box
                  className="back bg31 codeBox"
                  height={['115px', '183px', '251px', '320px']}
                  width={['160px', '256px', '352px', '450px']}
                  paddingTop={['11px', '20px', '30px', '30px']}
                >
                  <AgileCodeBox>
                    <Box className="fx">
                      <Box
                        width={['5px', '5px', '6px', '8px']}
                        height={['5px', '5px', '6px', '8px']}
                        marginTop={['5px', '5px', '10px', '10px']}
                        marginLeft={['6px', '6px', '14px', '14px']}
                        marginRight={['6px', '6px', '14px', '14px']}
                      >
                        <DotLast />
                      </Box>
                      <Text
                        fontSize={['12px', '15px', '19px', '22px']}
                        fontWeight={700}
                        color="#fff"
                        lineHeight={['14px', '19px', '24px', '29px']}
                        marginBottom={['5px', '14px', '22px', '30px']}
                      >
                        Fair Price Estimate (sniper pro)
                      </Text>
                    </Box>
                    <Box className="fx">
                      <Box
                        width={['5px', '5px', '6px', '8px']}
                        height={['5px', '5px', '6px', '8px']}
                        marginTop={['5px', '5px', '10px', '10px']}
                        marginLeft={['6px', '6px', '14px', '14px']}
                        marginRight={['6px', '6px', '14px', '14px']}
                      >
                        <DotLast />
                      </Box>
                      <Text
                        fontSize={['12px', '15px', '19px', '22px']}
                        fontWeight={700}
                        color="#fff"
                        lineHeight={['14px', '19px', '24px', '29px']}
                        marginBottom={['5px', '14px', '22px', '30px']}
                      >
                        Holder Insight (upcoming)
                      </Text>
                    </Box>
                    <Box className="fx">
                      <Box
                        width={['5px', '5px', '6px', '8px']}
                        height={['5px', '5px', '6px', '8px']}
                        marginTop={['5px', '5px', '10px', '10px']}
                        marginLeft={['6px', '6px', '14px', '14px']}
                        marginRight={['6px', '6px', '14px', '14px']}
                      >
                        <DotLast />
                      </Box>
                      <Text
                        fontSize={['12px', '15px', '19px', '22px']}
                        fontWeight={700}
                        color="#fff"
                        lineHeight={['14px', '19px', '24px', '29px']}
                      >
                        Wallets Analyze (upcoming)
                      </Text>
                    </Box>
                  </AgileCodeBox>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box>
              <Box
                className={`card ${visible2 ? 'rotated click' : 'click'}`}
                onMouseOver={() => setVisible2(true)}
                onMouseLeave={() => setVisible2(false)}
                height={['115px', '183px', '251px', '320px']}
                width={['160px', '256px', '352px', '450px']}
                marginBottom={['0', '0', '220px', '280px']}
              >
                <Box
                  className="front bg22 codeBox"
                  height={['115px', '183px', '251px', '320px']}
                  width={['160px', '256px', '352px', '450px']}
                  paddingTop={['25px', '40px', '55px', '72px']}
                  paddingLeft={['10px', '16px', '24px', '30px']}
                >
                  <AgileCodeBox>
                    <Text fontSize={['16px', '24px', '32px', '40px']} fontWeight={700} color="#fff">
                      Agile Execution
                    </Text>
                  </AgileCodeBox>
                </Box>
                <Box
                  className="back bg31 codeBox"
                  height={['115px', '183px', '251px', '320px']}
                  width={['160px', '256px', '352px', '450px']}
                  paddingTop={['11px', '20px', '30px', '30px']}
                >
                  <AgileCodeBox>
                    <Box className="fx">
                      <Box
                        width={['5px', '5px', '6px', '8px']}
                        height={['5px', '5px', '6px', '8px']}
                        marginTop={['5px', '5px', '10px', '10px']}
                        marginLeft={['6px', '6px', '14px', '14px']}
                        marginRight={['6px', '6px', '14px', '14px']}
                      >
                        <DotLast />
                      </Box>
                      <Text
                        fontSize={['12px', '15px', '19px', '22px']}
                        fontWeight={700}
                        color="#fff"
                        lineHeight={['14px', '19px', '24px', '29px']}
                        marginBottom={['5px', '14px', '22px', '30px']}
                      >
                        Fair Price Estimate (sniper pro)
                      </Text>
                    </Box>
                    <Box className="fx">
                      <Box
                        width={['5px', '5px', '6px', '8px']}
                        height={['5px', '5px', '6px', '8px']}
                        marginTop={['5px', '5px', '10px', '10px']}
                        marginLeft={['6px', '6px', '14px', '14px']}
                        marginRight={['6px', '6px', '14px', '14px']}
                      >
                        <DotLast />
                      </Box>
                      <Text
                        fontSize={['12px', '15px', '19px', '22px']}
                        fontWeight={700}
                        color="#fff"
                        lineHeight={['14px', '19px', '24px', '29px']}
                        marginBottom={['5px', '14px', '22px', '30px']}
                      >
                        Holder Insight (upcoming)
                      </Text>
                    </Box>
                    <Box className="fx">
                      <Box
                        width={['5px', '5px', '6px', '8px']}
                        height={['5px', '5px', '6px', '8px']}
                        marginTop={['5px', '5px', '10px', '10px']}
                        marginLeft={['6px', '6px', '14px', '14px']}
                        marginRight={['6px', '6px', '14px', '14px']}
                      >
                        <DotLast />
                      </Box>
                      <Text
                        fontSize={['12px', '15px', '19px', '22px']}
                        fontWeight={700}
                        color="#fff"
                        lineHeight={['14px', '19px', '24px', '29px']}
                      >
                        Wallets Analyze (upcoming)
                      </Text>
                    </Box>
                  </AgileCodeBox>
                </Box>
              </Box>
              <Box
                width="420px"
                className={
                  scrollNum.scrollNum > 3800 && scrollNum.scrollNum < 4600 ? 'tr pr60 agileEffect' : 'tr pr60 agileTest'
                }
              >
                <Box className="fx jc-end">
                  <Box
                    width={['5px', '5px', '6px', '8px']}
                    height={['5px', '5px', '6px', '8px']}
                    marginTop={['5px', '5px', '16px', '16px']}
                    marginLeft={['6px', '6px', '14px', '14px']}
                    marginRight={['6px', '6px', '14px', '14px']}
                  >
                    <DotLast />
                  </Box>
                  <Text fontSize="30px" fontWeight={400} color="#fff" lineHeight="40px">
                    Flash Buy
                  </Text>
                </Box>
                <Text fontSize="28px" fontWeight={500} color="rgba(255, 255, 255, 0.5)" textAlign="right">
                  It only takes 4 seconds to complete the purchase.
                </Text>
              </Box>
            </Box>
          )}
          {isMobile ? (
            <Box
              className={isMobile || isTablet ? 'm pa' : 'pa'}
              width={['340px', '534px', '728px', '920px']}
              height={['252px', '402px', '552px', '700px']}
              top={['160px', '230px', '320px', '188px']}
              left={isMobile || isTablet ? ['20px', '30px', '60px'] : null}
              right={isMobile || isTablet ? null : [0, 0, '-30px', '-60px']}
            >
              <FlashImg
                className={
                  scrollNum.scrollNum > 1200 && scrollNum.scrollNum < 2300
                    ? 'bg23 isMobileAgileImg'
                    : 'bg23 isMobileAgileImgNo'
                }
              />
            </Box>
          ) : (
            <Box
              className={isMobile || isTablet ? 'm pa' : 'pa'}
              width={['340px', '534px', '728px', '920px']}
              height={['252px', '402px', '552px', '700px']}
              top={['160px', '230px', '320px', '188px']}
              left={isMobile || isTablet ? ['20px', '30px', '60px'] : null}
              right={isMobile || isTablet ? null : [0, 0, '-30px', '-60px']}
            >
              <FlashImg
                className={
                  scrollNum.scrollNum > 3000 && scrollNum.scrollNum < 4600 ? 'bg23 agileImg' : 'bg23 agileImgNo'
                }
              />
            </Box>
          )}
        </Box>
      </Col>
    </Box>
  )
}
