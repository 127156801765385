import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Table as AntTable, Spin } from 'antd'
import { Box, Text, Image } from '@/packages/'
import { CollectionItem, AlignRightCell } from './components'
import { MintsV2Api } from '@/request/Api'
import { iMintsV2Prams } from '@/request/types'
import { IncreaseText, iText } from '@/components/IncreaseText'
import { Row, Col, ETHValue } from '@/components/Layout/Layout'
import { toNonExponential, addId4Array, valumeUnit } from '@/utils/tools'
import { useAppContext } from '@/hooks/useAppContext'
import { NoData } from '@/components/NoData'
import { useMatchBreakpoints } from '@/hooks/useMatchBreakpoints'
import { whale } from '@/assets/img'

export const Mints = () => {
  const [list, setList] = useState<Array<any>>([])
  const [query, setQuery] = useState<iMintsV2Prams>({
    size: 10,
    keyword: '',
    granularity: '',
    order: {
      asc: false,
      column: 'periodMints' //  mintPrice;periodMints
    }
  })
  const [isLoading, setLoading] = useState<boolean>(true)
  const { state, dispatch } = useAppContext()
  const { isMobile, isTablet } = useMatchBreakpoints()

  let columns: any = []
  if (isMobile || isTablet) {
    columns = [
      {
        title: 'COLLECTION',
        dataIndex: 'collection',
        width: '45%',
        render: (item: any) => <CollectionItem {...item} subTitle="First mint" hideOfficialUrl />
      },
      {
        title: 'MINT PRICE',
        dataIndex: 'mintPrice',
        width: '30%',
        render: (item: number) => (
          <Box className="w100 fx jc-end">
            <ETHValue value={toNonExponential(item, 3)} />
          </Box>
        ),
        sorter: true,
        sortDirections: ['descend' as 'descend', 'ascend' as 'ascend', 'descend' as 'descend'],
        showSorterTooltip: false
      },
      {
        title: 'MINTS',
        width: '25%',
        sorter: true,
        defaultSortOrder: 'descend' as 'descend',
        sortDirections: ['descend' as 'descend', 'ascend' as 'ascend', 'descend' as 'descend'],
        showSorterTooltip: false,
        render: (item: any) => (
          <Col>
            <AlignRightCell value={item.periodMints} />
            {item.whales > 0 && (
              <Row className="w100 fx jc-end">
                <Text className="fz12 fw400 " color="#2FA8CC" mr="4px">
                  {item.whales}
                </Text>
                <Image src={whale} width={15} height={10} />
              </Row>
            )}
          </Col>
        )
      }
    ]
  } else {
    columns = [
      {
        title: 'COLLECTION',
        dataIndex: 'collection',
        width: '20%',
        render: (item: any) => <CollectionItem {...item} subTitle="First mint" />
      },
      {
        title: 'MINT PRICE',
        dataIndex: 'mintPrice',
        render: (item: number) => (
          <Box className="w100 fx jc-end">
            <ETHValue value={toNonExponential(item)} />
          </Box>
        ),
        sorter: true,
        sortDirections: ['descend' as 'descend', 'ascend' as 'ascend', 'descend' as 'descend'],
        showSorterTooltip: false
      },
      {
        title: 'MINTS',
        dataIndex: 'periodMints',
        render: (item: number) => <AlignRightCell value={item} />,
        sorter: true,
        defaultSortOrder: 'descend' as 'descend',
        sortDirections: ['descend' as 'descend', 'ascend' as 'ascend', 'descend' as 'descend'],
        showSorterTooltip: false
      },
      {
        title: 'TOTAL MINTS/SUPPLY',
        render: (item: any) => {
          const { totalSupply, totalMints } = item
          return (
            <Col className="ai-end">
              <Text
                className="fw400 white87"
                fontSize={['14px', '14px', '16px', '16px']}
              >{`${totalMints}/${totalSupply}`}</Text>
              {totalMints === totalSupply ? (
                <Text className="fw400 white87" fontSize={['14px', '14px', '16px', '16px']}>
                  --
                </Text>
              ) : (
                <IncreaseText increase percent={totalMints / totalSupply} />
              )}
            </Col>
          )
        }
      },
      {
        title: 'UNIQUE HOLDERS',
        dataIndex: 'minters',
        render: (item: number) => <AlignRightCell value={valumeUnit(item)} />
      },
      {
        title: 'WHALES',
        dataIndex: 'whales',
        render: (item: number) => <AlignRightCell value={item} />
      }
    ]
  }

  useEffect(() => {
    if (state.DashbordReducer.period) {
      const { tabName, mintsPeriod } = state.DashbordReducer.period
      tabName === 'Mints' && setQuery((q: iMintsV2Prams) => ({ ...q, granularity: mintsPeriod }))
    }
  }, [state.DashbordReducer.period])

  useEffect(() => {
    if (state.DashbordReducer.refreshTable) {
      const { activeTable } = state.DashbordReducer.refreshTable
      activeTable === 'Mints' && fetch()
    }
  }, [state?.DashbordReducer?.refreshTable])
  useEffect(() => {
    if (state.DashbordReducer.isMintPrice) {
      let k: string = ''
      if (state.DashbordReducer.isMintPrice === 1) k = 'FREE_MINT'
      if (state.DashbordReducer.isMintPrice === 2) k = ''
      setQuery((q: iMintsV2Prams) => ({ ...q, keyword: k }))
    }
  }, [state?.DashbordReducer?.isMintPrice])

  const fetch = async () => {
    if (query.granularity) {
      setLoading(true)
      setList([])
      const d: any = await MintsV2Api(query)
      setLoading(false)
      d && !!d.length && setList(addId4Array(d))
    }
  }

  useEffect(() => {
    fetch()
  }, [query])
  const handleTableChange = (pagination: any, filters: any, sorter: any, extra: any) => {
    const p = JSON.parse(JSON.stringify(query))
    const { field, order } = sorter // order:"ascend"=> up "descend" => down
    p.order.column = field
    p.order.asc = order === 'ascend'
    setQuery(p)
  }

  return (
    <Box className="w100" height="420px" background="rgba(255, 255, 255, 0.04)">
      <AntTable
        locale={{ emptyText: ' ' }}
        rowKey={(record) => record.id}
        pagination={false}
        columns={columns}
        dataSource={list}
        onChange={handleTableChange}
        style={{ marginTop: 20 }}
        scroll={{ y: 370 }}
      />
      {!list.length && <NoData isLoading={isLoading} />}
    </Box>
  )
}
