import React, { useState, useEffect, useRef } from 'react'
import { ChartTitle, ChartContainer, SwitchGranularity, Row } from '@/components/Layout/Layout'
import { Box } from '@/packages/'
import { Chart } from '@/components/Charts/Chart'
import { GasBurnApi } from '@/request/Api'
import { GasBurnOptions } from '@/components/Charts/options/index'
import { TitleAndPeriod } from '@/components/TitleAndPeriod'
import { iTabItem } from '@/components/TabListSelected'
import { ChartLegend } from '@/components/ChartLegend'
import { NoData } from '@/components/NoData'

export const GasBurn = () => {
  const [granularity, setGranularity] = useState<string>('7d')
  const [chartList, setChartList] = useState<any>()
  const [data, setData] = useState(null)
  const [initData, setInitData] = useState<any>(null)
  const [isLoading, setLoading] = useState<boolean>(true)

  const [period, setPeriod] = useState<Array<iTabItem>>([
    { id: 0, name: '7d', active: true },
    { id: 1, name: '30d', active: false }
  ])

  const nftRef: any = useRef()
  const totalRef: any = useRef()

  const fetch = async () => {
    setLoading(true)
    const d: any = await GasBurnApi(granularity)
    setLoading(false)
    if (d) {
      setData(d)
      setInitData(d)
      setChartList(GasBurnOptions(d))
      resetBtnStatus()
    }
  }

  useEffect(() => {
    fetch()
  }, [granularity])

  const onChangePeriod = (val: iTabItem) => {
    const _period = JSON.parse(JSON.stringify(period))
    _period.forEach((_p: iTabItem) => {
      _p.active = _p.name === val.name
    })
    setPeriod(_period)
    setGranularity(val.name)
  }

  const visibleChart = (open: boolean, type: string) => {
    const _data = JSON.parse(JSON.stringify(data))
    _data[type] = open ? initData[type] : []
    setData(_data)
    setChartList(GasBurnOptions(_data))
  }
  const resetBtnStatus = () => {
    nftRef.current && nftRef.current.resetStatus()
    totalRef.current && totalRef.current.resetStatus()
  }
  return (
    <Box className="fx-col body_wrapper " mt="24px" height="486px">
      <TitleAndPeriod title="Market Gas Burn" periodList={period} onChangePeriod={onChangePeriod} />
      <Row>
        <ChartLegend
          cRef={nftRef}
          type="line"
          bgColor="#34C77B"
          title="NFTs Burned"
          onChange={(v: boolean) => visibleChart(v, 'nftGas')}
        />
        <ChartLegend
          cRef={totalRef}
          type="line"
          bgColor="#A4A4A4"
          title="Total Burned"
          onChange={(v: boolean) => visibleChart(v, 'totalGas')}
        />
      </Row>
      <Box className="w100" height="300px" mt="40px">
        {chartList ? <Chart option={chartList} /> : <NoData isLoading={isLoading} />}
      </Box>
    </Box>
  )
}
