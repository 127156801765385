import React, { useState, useEffect, useRef, useImperativeHandle } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { ethers } from 'ethers'
import Web3 from 'web3'
import { io } from 'socket.io-client'
import { Box, Image, Text } from '@/packages/'
import { useAppContext } from '@/hooks/useAppContext'
import { ListCardItem, TraderIn1Min, TitleDesc } from '../component'
import { ascFalse, ascTrue } from '@/assets/img'
import { ListingCardTitle, Col, Row, ListingLoadWrapper, LoadingItemWrapper } from '@/components/Layout/Layout'
import { ListingApi, ListingCountApi } from '@/request/Api'
import { iListingRes, iListingParams } from '@/request/types'
import { DropdownSelect } from '@/components/'
import { beyondStr, getTokenIdFromListing, pendingTokenIdIndex } from '@/utils/tools'
import { HoverWrapper } from '@/components/Layout/HoverWrapper'
import { useEthereum } from '@/hooks/useEthereum'
import { QuickBuyVisibleAction, ListingTotalAction } from '@/store/actions/LiveviewAction'
import { useSeaport } from '@/hooks/useSeaport'
import { RightEle } from './RightEle'
import { MiddleEle } from './MiddleEle'
import { BottomEle } from './BottomEle'
import { LeftComponent } from './LeftComponent'
import { RightComponent } from './RightComponent'
import OpenseaABI from '@/assets/abi/OpenseaABI.json'
import { NoData } from '@/components/NoData'
import { LiveFeedDot, LiveFeedDotMobile } from '@/components/LiveFeedDot'
import { useMatchBreakpoints } from '@/hooks/useMatchBreakpoints'
import { OpenNotification } from '@/components/OpenNotification'

interface iAscImg {
  onChangeAsc: (val: boolean) => void
  ascImgRef?: any
}

const AscImg: React.FC<iAscImg> = ({ onChangeAsc, ascImgRef }) => {
  const [isAsc, setAsc] = useState<boolean>(true)
  useImperativeHandle(ascImgRef, () => ({
    handleAsc: (a: boolean) => {
      setAsc(a)
    }
  }))

  const changeAsc = () => {
    setAsc(!isAsc)
    onChangeAsc(!isAsc)
  }
  return <Image src={isAsc ? ascTrue : ascFalse} width={18} height={18} className="click mr8" onClick={changeAsc} />
}
export const Listing = (wsSocket: any) => {
  const [statusType, setStatusType] = useState<boolean>(true)
  const { state, dispatch } = useAppContext()
  const [listing, setListing] = useState<Array<any>>([])
  const [pageSortsColume, setPageSortsColume] = useState('')
  const [asc, setAsc] = useState<boolean>(true)
  const [tradesNumber, setTradesNumber] = useState(0)
  const [total, setTotal] = useState(0)
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(12)
  const [isLoading, setLoading] = useState(true)
  const [isInit, setInit] = useState(true)
  const { ethereum } = useEthereum()
  const params = useParams()
  const NFTID = Number(params.nftId)
  const [query, setQuery] = useState<iListingParams>({
    nftId: NFTID,
    pageIndex: 1,
    pageSize,
    pageSorts: [{ asc: true, column: 'price' }]
  })
  const INTERVAL = 60
  const [countDown, setCountDown] = useState<number>(INTERVAL)
  const [isPause, setPause] = useState<boolean>(false)
  const listingRef: any = useRef()
  const parseRef: any = useRef()
  const dropdownFilterRef: any = useRef()
  const ascRef: any = useRef()
  const ascImgRef: any = useRef()
  const pageIndexRef: any = useRef()
  const countDownRef: any = useRef()
  const OPENSEAADDRESS: string = '0x00000000006c3852cbef3e08e8df289169ede581'
  const opensea = '0x00000000006c3852cbef3e08e8df289169ede581'
  const { isMobile } = useMatchBreakpoints()
  const [pendingWs, setPendingWs] = useState<any>(null)

  const createPendingWs = () => {
    const { REACT_APP_PEWEE_ENV } = process.env
    let peweeNodeWs: string = ''

    if (REACT_APP_PEWEE_ENV === 'dev' || REACT_APP_PEWEE_ENV === 'pre') {
      peweeNodeWs = 'wss://www.pre.xingjigangwan.com/hQdO6NIQiEtdhPM0/'
    }
    if (REACT_APP_PEWEE_ENV === 'prod') {
      peweeNodeWs = 'wss://www.pewee.tools/hQdO6NIQiEtdhPM1/'
    }
    const socket = new WebSocket(peweeNodeWs)
    socket.addEventListener('open', function (event) {
      console.log('socket is connect')
    })
    setPendingWs(socket)
  }
  const disWsConnect = () => {
    if (pendingWs) {
      try {
        pendingWs.close()
        setPendingWs(null)
      } catch (error) {
        console.log('disconnect ws error', error)
      }
    }
  }
  useEffect(() => {
    createPendingWs()
  }, [])
  useEffect(() => {
    return () => disWsConnect()
  }, [pendingWs])
  useEffect(() => {
    if (state?.LiveViewReducer?.crtNFTAddr && listing.length > 0) {
      const tokenIds: Array<number> = getTokenIdFromListing(listing)
      const MAIN_NET = 'https://mainnet.infura.io/v3/59cc19bcf4a740bba9f19e162c9441ba'
      const web3: any = new Web3(Web3.givenProvider || MAIN_NET)
      const iface = new ethers.utils.Interface(OpenseaABI)
      listingRef.current = listing

      const NFTAddress = state.LiveViewReducer.crtNFTAddr.substring(2).toLocaleLowerCase()
      pendingWs.addEventListener('message', (event: any) => {
        const transaction = JSON.parse(event.data)
        // data开头是 0xe7acab24 => fulfillAdvancedOrder 多个tokenid
        // data开头是 0xfb0f3ee1 => fulfillBasicOrder 单个tokenid
        // data开头是 0xed98a574 => fulfillAvailableOrders 批量token
        if (transaction && transaction.input) {
          const { input, hash: txHash, value } = transaction
          if (input.includes(NFTAddress)) {
            const decodedData = iface.parseTransaction({ data: input, value })
            if (input.startsWith('0xed98a574')) {
              console.log('fulfillAvailableOrders hash', txHash)
              // console.log('decodedData', decodedData)
              const argsList = decodedData.args[0]
              const fulfillAvailableOrdersIds: Array<number> = []
              if (argsList && argsList.length > 0) {
                argsList.forEach((argsItem: any) => {
                  fulfillAvailableOrdersIds.push(web3.utils.hexToNumber(argsItem.parameters[2][0][2]))
                  console.log('fulfillAvailableOrders 批量token==>', fulfillAvailableOrdersIds)
                })
                const targetIdxs = pendingTokenIdIndex(tokenIds, fulfillAvailableOrdersIds)
                if (targetIdxs.length > 0) {
                  targetIdxs.forEach((t: number) => {
                    listingRef.current[t].isPending.hashList.push(txHash)
                    listingRef.current[t].isPending.txList.push(transaction)
                    listingRef.current[t].isPending.pending = true
                  })
                }
                setListing([...listingRef.current])
              }
            }
            if (input.startsWith('0xfb0f3ee1')) {
              // fulfillBasicOrder 单个tokenid
              console.log('fulfillBasicOrder hash', txHash)
              const fulfillBasicOrderTokenId: number = web3.utils.hexToNumber(decodedData.args[0][6])
              console.log('fulfillBasicOrder 的tokenid 单个', fulfillBasicOrderTokenId)
              const idx: number = tokenIds.findIndex((t: number) => t === fulfillBasicOrderTokenId)
              if (idx !== -1) {
                listingRef.current[idx].isPending.hashList.push(txHash)
                listingRef.current[idx].isPending.txList.push(transaction)
                listingRef.current[idx].isPending.pending = true
                setListing([...listingRef.current])
              }
            }
            if (input.startsWith('0xe7acab24')) {
              // fulfillAdvancedOrder 多个tokenid
              console.log('fulfillAdvancedOrder hash', txHash)
              if (decodedData.args[1] && decodedData.args[1].length > 0) {
                // console.log('聚合数据 的tokenid 只有一个', decodedData)
                const fulfillAdvancedOrderId: number = web3.utils.hexToNumber(decodedData.args[1][0][3])

                const idx: number = tokenIds.findIndex((t: number) => t === fulfillAdvancedOrderId)
                if (idx !== -1) {
                  listingRef.current[idx].isPending.hashList.push(txHash)
                  listingRef.current[idx].isPending.txList.push(transaction)
                  listingRef.current[idx].isPending.pending = true
                  setListing([...listingRef.current])
                }
                console.log('fulfillAdvancedOrder 聚合数据 的tokenid 只有一个==>', fulfillAdvancedOrderId)
              } else {
                const tokenList: Array<any> = decodedData.args[0][0][2]
                const fulfillAdvancedOrderIds: Array<number> = []
                // console.log('聚合数据 的tokenid 多个', tokenList)
                tokenList.forEach((item) => fulfillAdvancedOrderIds.push(web3.utils.hexToNumber(item[2])))
                console.log('fulfillAdvancedOrder 聚合数据 的tokenid 有多个==>', fulfillAdvancedOrderIds)

                const targetIdxs = pendingTokenIdIndex(tokenIds, fulfillAdvancedOrderIds)
                if (targetIdxs.length > 0) {
                  targetIdxs.forEach((t: number) => {
                    listingRef.current[t].isPending.hashList.push(txHash)
                    listingRef.current[t].isPending.txList.push(transaction)
                    listingRef.current[t].isPending.pending = true
                  })
                }
                setListing([...listingRef.current])
              }
            }
          }
        }
      })
    }
  }, [state?.LiveViewReducer?.crtNFTAddr, listing, pendingWs])

  const isCreateWs = () => {
    const { pageIndex: _pageIndex, nftId, pageSize: _pageSize, pageSorts } = query
    if (Object.keys(query).length === 3 && _pageIndex === 1 && nftId === NFTID && _pageSize === 12) {
      return true
    }
    return false
  }

  const addValueForListing = (list: Array<any>) => {
    list.forEach((item: any) => {
      item.isPending = {
        hashList: [],
        txList: [],
        pending: false
      }
    })
    return list
  }
  const fetch = async (empty?: boolean) => {
    setLoading(true)
    setInit(false)
    const d: any = await ListingApi(query)
    setLoading(false)
    setPause(false)
    if (d) {
      let combine: Array<any> = []
      if (empty) {
        combine = d.records
      } else {
        combine = [...listing, ...d.records]
      }
      setListing(addValueForListing(combine))
      setTotal(d.total)
      const traders: any = await ListingCountApi(Number(params.nftId), {
        timeunit: 'minute',
        value: 1
      })
      setTradesNumber(traders)
      ListingTotalAction(dispatch, d.total)
    }
  }

  useEffect(() => {
    if (!isPause) {
      countDownRef.current = countDown
      listingRef.current = listing
      const interval = setInterval(() => {
        if (countDownRef.current > 0) {
          setCountDown(countDownRef.current - 1)
        } else {
          setCountDown(INTERVAL)
          const p = JSON.parse(JSON.stringify(query))
          p.pageIndex = 1
          setListing([])
          setPageIndex(1)
          setQuery(p)
          // fetch(true)
          // console.log('countDown======>>', countDownRef.current)
        }
      }, 1000)
      return () => clearInterval(interval)
    }
  }, [countDown, isPause])

  const insertListing = (listingCopy: Array<any>, targetData: any, ascType: any, filter: string) => {
    // asc 默认false 从小到大排序
    if (targetData && (targetData[filter] || targetData[filter] === 0)) {
      const idx = listingCopy.findIndex((item: any) =>
        ascType ? item[filter] > targetData[filter] : item[filter] < targetData[filter]
      )
      listingCopy.splice(idx === -1 ? listingCopy.length : idx, 0, targetData)
    }
    setListing(listingCopy)
  }

  useEffect(() => {
    parseRef.current = isPause
    listingRef.current = listing
    dropdownFilterRef.current = pageSortsColume
    ascRef.current = asc
    pageIndexRef.current = pageIndex
    if (wsSocket && wsSocket.socket && isCreateWs()) {
      if (isInit) {
        try {
          setTimeout(() => {
            wsSocket.socket.client.subscribe(`/topic/listing-new-${NFTID}`, (res: any) => {
              const data = JSON.parse(res.body)
              if (!parseRef.current && pageIndexRef.current === 1) {
                console.log('ws data=listing=>>>>>>>>', data)
                setCountDown(INTERVAL)
                if (dropdownFilterRef.current === 'create_date') {
                  if (ascRef.current) {
                    listingRef.current.push(data)
                  } else {
                    listingRef.current.unshift(data)
                  }
                  setListing([...listingRef.current])
                } else {
                  insertListing(listingRef.current, data, ascRef.current, dropdownFilterRef.current)
                }
              } else {
                console.log('isPause', `${parseRef.current}=${pageIndexRef.current}`)
              }
            })
          }, 10100)
        } catch (error) {
          console.log('connect error', error)
          // createWs()
        }
      }
    }
  }, [listing, isPause, pageSortsColume, asc, pageIndex])

  useEffect(() => {
    fetch()
  }, [query])

  useEffect(() => {
    if (!isInit) {
      const p = JSON.parse(JSON.stringify(query))
      if (state?.LiveViewReducer?.listingFilterOptions) {
        const { tokenId, priceRange, rankRange, traitQueries } = state.LiveViewReducer.listingFilterOptions
        p.pageIndex = 1
        p.tokenId = tokenId
        p.priceRange = priceRange
        p.rankRange = rankRange
        p.traitQueries = traitQueries
      }
      setListing([])
      setPageIndex(1)
      setQuery(p)
    }
  }, [state?.LiveViewReducer?.listingFilterOptions])

  //
  useEffect(() => {
    if (state?.LiveViewReducer?.wsSoldOut) {
      const { price: soldPrice, nftTokenVO: soldNftTokenVO } = state.LiveViewReducer.wsSoldOut
      if (soldNftTokenVO) {
        const { nftAddress: soldNftAddress, nftId: soldNftId, tokenId: soldTokenId } = soldNftTokenVO
        listing.forEach((l: any, listingIdx: number) => {
          const { price: listingPrice, nftTokenVO: listingNftTokenVO } = l
          if (listingNftTokenVO) {
            const { nftAddress: listingNftAddress, nftId: listingNftId, okenId: listingTokenId } = listingNftTokenVO
            if (
              listingPrice === soldPrice &&
              listingNftAddress === soldNftAddress &&
              listingNftId === soldNftId &&
              soldTokenId === listingTokenId
            ) {
              const _listing = JSON.parse(JSON.stringify(listing))
              _listing.splice(listingIdx, 1)
              setListing(_listing)
            }
          }
        })
      }
    }
  }, [state?.LiveViewReducer?.wsSoldOut])

  useEffect(() => {
    if (!isInit) {
      const p = JSON.parse(JSON.stringify(query))
      p.pageIndex = 1
      p.pageSorts = [{ asc, column: pageSortsColume }]
      setListing([])
      setPageIndex(1)
      setQuery(p)
    }
  }, [pageSortsColume, asc])

  const onChangeAsc = (val: boolean) => {
    setAsc(val)
    setPause(true)
  }
  const onChangeSelect = (val: string) => {
    if (val === 'Date') setPageSortsColume('create_date')
    if (val === 'Price') {
      setPageSortsColume('price')
      setAsc(true)
      ascImgRef.current && ascImgRef.current.handleAsc(true)
    }
    if (val === 'Rank') {
      setPageSortsColume('rank')
      setAsc(true)
      ascImgRef.current && ascImgRef.current.handleAsc(true)
    }
  }
  const onListingBottom = () => {
    if (!isLoading) {
      const p = JSON.parse(JSON.stringify(query))
      const newIndex = pageIndex + 1
      setPageIndex(newIndex)
      p.pageIndex = newIndex
      setQuery(p)
    }
  }

  const onVisible = async (item: iListingRes) => {
    if (item) {
      try {
        const account = await ethereum.request({ method: 'eth_requestAccounts' })
        if (account && account.length > 0) {
          QuickBuyVisibleAction(item, 'listing', dispatch)
        } else {
          OpenNotification('Please connect your wallet', 'warning')
        }
      } catch (error) {
        OpenNotification('Please connect your wallet', 'warning')
      }
    }
  }

  const mouseOver = () => {
    if (wsSocket && wsSocket.socket) {
      try {
        setPause(true)
        setStatusType(false)
      } catch (error) {
        console.log('error', error)
      }
    }
  }
  const mouseLeave = () => {
    setPause(false)
    setStatusType(true)
  }

  const onVisibleChange = (v: boolean) => {
    // console.log('onVisibleChange========', v)
    setPause(v)
  }

  useEffect(() => {
    setPageSortsColume('price')
  }, [])

  // console.log('isPause========', isPause)
  // console.log('listing========', listing)

  return (
    <Box className="">
      <TraderIn1Min content={`${tradesNumber} Listing in 1 min`} count={tradesNumber} />
      <Box className="fx-row jc-sb ai-ct" height="34px" mb={isMobile ? '16px' : '0px'}>
        <Row>
          {isMobile && <LiveFeedDotMobile isLive={statusType} />}
          <ListingCardTitle title="Listing" count={total} />
        </Row>
        <Row>
          <AscImg ascImgRef={ascImgRef} onChangeAsc={(val: boolean) => onChangeAsc(val)} />
          <DropdownSelect
            onVisibleChange={onVisibleChange}
            onChange={onChangeSelect}
            initVal="Price"
            dataSource={['Date', 'Price', 'Rank']}
          />
        </Row>
      </Box>
      {!isMobile && <LiveFeedDot isLive={statusType} />}

      <HoverWrapper mouseOver={mouseOver} mouseLeave={mouseLeave} isEmpty={!isLoading && listing.length === 0}>
        <ListingLoadWrapper onContainerBottom={onListingBottom}>
          {listing &&
            !!listing.length &&
            listing.map((item: iListingRes, idx: number) => (
              <ListCardItem
                isFirst={idx === 0}
                key={Math.random().toString()}
                imageUrl={item.nftTokenVO ? item.nftTokenVO.imageUrl : ''}
                tokenId={`#${item.nftTokenVO ? beyondStr(item.nftTokenVO.tokenId, 4) : '--'}`}
                leftCompnent={
                  <LeftComponent
                    rank={item.nftTokenVO ? item.nftTokenVO.rank : 0}
                    chain={item.chain}
                    price={item.price}
                    royalty={item.royalty}
                    nftTokenVO={item?.nftTokenVO}
                    createTime={item.dateTime}
                    lastPriceTime={item.lastPriceTime}
                    lastPrice={item.lastPrice}
                  />
                }
                rightCompnent={
                  <RightComponent
                    price={item.price}
                    createTime={item.dateTime}
                    lastPrice={item.lastPrice}
                    listing={item}
                    isPending={item?.isPending}
                  />
                }
              />
            ))}
          {!isLoading && !listing.length && <NoData isLoading={false} msg="No listings in the project" />}
          {isLoading && <LoadingItemWrapper height={126} count={12} />}
        </ListingLoadWrapper>
      </HoverWrapper>
    </Box>
  )
}
