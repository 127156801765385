import React, { useState, useEffect } from 'react'
import { Popover } from 'antd'
import styled from 'styled-components'
import { Image, Text, Box } from '@/packages/'
import { avator } from '@/assets/img'
import { Row, Col } from '@/components/Layout/Layout'
import { upcomingEditorInfoApi } from '@/request/Api'
import { splitAddress } from '@/utils/tools'
import { useAppContext } from '@/hooks/useAppContext'

const PopoverContainer = styled(Box)`
  background: #252525;
  border-radius: 20px;
  padding: 16px 24px;
`

interface iUserRender {
  wh?: number
  id?: number
  name: string
  isModifyed: boolean
}
interface iPopInfo {
  editorAddress: string
  editorName: string
}
export const UserAvatorPopOver: React.FC<iUserRender> = ({ wh, id, name, isModifyed }) => {
  const [open, setOpen] = useState(false)
  const [totalSub, setTotalSub] = useState<number>(0)
  const [popInfo, setPopInfo] = useState<iPopInfo>()
  // console.log('isModifyed2222222', isModifyed)
  const { state, dispatch } = useAppContext()
  useEffect(() => {
    if (state?.UpcomingReducer?.upcomingTotalSubmit) {
      setTotalSub(state.UpcomingReducer.upcomingTotalSubmit)
    }
  }, [state?.UpcomingReducer?.upcomingTotalSubmit])
  const fetch = async () => {
    const q = {
      upcomingId: id,
      filedName: name
    }

    const d: any = await upcomingEditorInfoApi(q)
    if (d) {
      setPopInfo(d)
    }
  }

  const onOpenChange = (v: boolean) => {
    setOpen(v)
    if (v && id) {
      fetch()
    }
  }
  return (
    <div>
      {id && isModifyed === true && (
        <Popover
          open={open}
          onOpenChange={onOpenChange}
          content={
            <PopoverContainer>
              <Text className="fz12 fw700 white">CONTRIBUTOR</Text>
              {/* <Text className="fz12 fw700 white">{popInfo?.editorName || '--'}</Text> */}
              <Row mt="8px">
                <Image src={avator} width={wh || 46} height={wh || 46} className="circle" />
                <Col ml="16px">
                  <Text className="fz14 fw500 white87">
                    {popInfo?.editorAddress ? splitAddress(popInfo?.editorAddress) : '--'}
                  </Text>
                  <Text className="fz14 fw500 white87">{`Total Submit: ${totalSub}`}</Text>
                </Col>
              </Row>
            </PopoverContainer>
          }
        >
          <Image src={avator} width={30} height={30} className="click ml20" />
        </Popover>
      )}
    </div>
  )
}
