import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Box, Text, Image, Grid } from '@/packages/'
import { Col } from '@/components/Layout/Layout'
import { useMatchBreakpoints } from '@/hooks/useMatchBreakpoints'
import { nene, tom, andy, coco, mia, lsla, flora, tofu, linkedin, twterrIocn } from '@/assets/img'

const ContonBox = styled(Box)`
  margin: 0 auto;
`
const TeamList = styled(Box)``
const TeamImg = styled(Box)`
  position: absolute;
  width: 1285px;
  height: 1038px;
  left: -416px;
  top: 220px;
`
const TeamBox = styled(Box)`
  margin-top: 100px;
`
export const Teams = (scrollNum: any) => {
  const { isMobile, isTablet } = useMatchBreakpoints()
  return (
    <Box className=" c-sb w100 relative">
      <Box
        className="pa bg27"
        left={['-20px', '-20px', '-200px', '-416px']}
        top={['55px', '110px', '165px', '220px']}
        height={['315px', '556px', '798px', '1038px']}
        width={['212px', '569px', '926px', '1285px']}
      />
      <Col className="mxw relative">
        {isMobile ? (
          <Box
            className={
              scrollNum.scrollNum > 2500 && scrollNum.scrollNum < 3500 ? 'isMobileTeamTitle' : 'isMobileTeamTitleNo'
            }
          >
            <Text
              fontSize={['20px', '34px', '47px', '60px']}
              fontWeight={700}
              color="#fff"
              textAlign="center"
              height={['26px', '44px', '62px', '80px']}
              marginTop={['30px', '40px', '50px', '60px']}
              marginBottom={['10px', '12px', '14px', '16px']}
            >
              Management Team
            </Text>
            <Box
              className="col2 tc m"
              width={['', '562px', '782px', '1000px']}
              marginBottom={['22px', '54px', '86px', '120px']}
            >
              <Text fontSize={['12px', '14px', '17px', '20px']} className="white87 fw400">
                We are a team of engineers, financial analysts, NFT enthusiasts and visual artists. Pewee tool is
                founded by NeNe who previously severed as a financial engineer in NYC. We also have financial analysts
                from Morgan Stanley to create financial models for the product.
              </Text>
            </Box>
          </Box>
        ) : (
          <Box className={scrollNum.scrollNum > 5900 && scrollNum.scrollNum < 6800 ? 'teamTitle' : 'teamTitleNo'}>
            <Text
              fontSize={['20px', '34px', '47px', '60px']}
              fontWeight={700}
              color="#fff"
              textAlign="center"
              height={['26px', '44px', '62px', '80px']}
              marginTop={['30px', '40px', '50px', '60px']}
              marginBottom={['10px', '12px', '14px', '16px']}
            >
              Management Team
            </Text>
            <Box
              className="col2 tc m"
              width={['', '562px', '782px', '1000px']}
              marginBottom={['22px', '54px', '86px', '120px']}
            >
              <Text fontSize={['12px', '14px', '17px', '20px']}>
                We are a team of engineers, financial analysts, NFT enthusiasts and visual artists. Pewee tool is
                founded by NeNe who previously severed as a financial engineer in NYC. We also have financial analysts
                from Morgan Stanley to create financial models for the product.
              </Text>
            </Box>
          </Box>
        )}
        <Box marginTop={['10px', '40px', '70px', '100px']}>
          <Grid
            gridColumnGap={['15px', '24px', '48px', '60px']}
            gridTemplateColumns={['1fr 1fr', '1fr 1fr', '1fr 1fr', '1fr 1fr 1fr 1fr']}
            className="fx jc-sb ai-ct"
          >
            <Box marginBottom={['30px', '60px', '90px', '120px']}>
              <Box width={['62px', '78px', '94px', '110px']} className="m">
                <Image src={nene} className="rh100 rw100" />
              </Box>
              <Box className={isMobile ? 'bgc2  br30 mt25 pd12' : 'bgc2  br30 mt25 pd21'}>
                <Box className={isMobile || isTablet ? 'fx ai-ct jc-ct' : 'fx ai-ct'}>
                  <Text fontSize={['12px', '14px', '16px', '18px']} fontWeight={700} color="#fff">
                    NeNe
                  </Text>
                  <Box
                    marginLeft="6px"
                    width={['11px', '14px', '17px', '20px']}
                    height={['11px', '14px', '17px', '20px']}
                  >
                    <Image src={linkedin} className="rh100 rw100" />
                  </Box>
                  <Box
                    marginLeft="6px"
                    width={['9px', '10px', '13px', '16px']}
                    height={['9px', '10px', '13px', '16px']}
                  >
                    <Image src={twterrIocn} className="rh100 rw100" />
                  </Box>
                  <Text fontSize="12px" fontWeight={400} className={isMobile || isTablet ? 'yc mtxt' : 'mtxt'} ml="6px">
                    Founder & CEO
                  </Text>
                </Box>
                <Text
                  fontSize="12px"
                  fontWeight={400}
                  textAlign="center"
                  className={isMobile || isTablet ? 'xs mtxt' : 'mtxt yc'}
                >
                  Founder & CEO
                </Text>
                <Text
                  fontSize="12px"
                  fontWeight={400}
                  color="#fff"
                  textAlign={isMobile || isTablet ? 'center' : 'left'}
                  className={isMobile || isTablet ? 'yc' : ''}
                >
                  Former Financial Engineer in NYC Cat & Dog Lover
                </Text>
              </Box>
            </Box>
            <Box marginBottom={['30px', '60px', '90px', '120px']}>
              <Box width={['62px', '78px', '94px', '110px']} className="m">
                <Image src={tom} className="rh100 rw100" />
              </Box>
              <Box className={isMobile ? 'bgc2  br30 mt25 pd12' : 'bgc2  br30 mt25 pd21'}>
                <Box className={isMobile || isTablet ? 'fx ai-ct jc-ct' : 'fx ai-ct'}>
                  <Text fontSize={['12px', '14px', '16px', '18px']} fontWeight={700} color="#fff">
                    Tom
                  </Text>
                  <Box
                    marginLeft="6px"
                    width={['11px', '14px', '17px', '20px']}
                    height={['11px', '14px', '17px', '20px']}
                  >
                    <Image src={linkedin} className="rh100 rw100" />
                  </Box>
                  <Box
                    marginLeft="6px"
                    width={['9px', '10px', '13px', '16px']}
                    height={['9px', '10px', '13px', '16px']}
                  >
                    <Image src={twterrIocn} className="rh100 rw100" />
                  </Box>
                  <Text fontSize="12px" fontWeight={400} className={isMobile || isTablet ? 'yc mtxt' : 'mtxt'} ml="6px">
                    Tech Manager
                  </Text>
                </Box>
                <Text
                  fontSize="12px"
                  fontWeight={400}
                  textAlign="center"
                  className={isMobile || isTablet ? 'xs mtxt' : 'mtxt yc'}
                >
                  Tech Manager
                </Text>
                <Text
                  fontSize="12px"
                  fontWeight={400}
                  color="#fff"
                  textAlign={isMobile || isTablet ? 'center' : 'left'}
                  className={isMobile || isTablet ? 'yc' : ''}
                >
                  Focus on security cryptography and on-chain data& contract
                </Text>
              </Box>
            </Box>
            <Box marginBottom={['30px', '60px', '90px', '120px']}>
              <Box width={['62px', '78px', '94px', '110px']} className="m">
                <Image src={andy} className="rh100 rw100" />
              </Box>
              <Box className={isMobile ? 'bgc2  br30 mt25 pd12' : 'bgc2  br30 mt25 pd21'}>
                <Box className={isMobile || isTablet ? 'fx ai-ct jc-ct' : 'fx ai-ct'}>
                  <Text fontSize={['12px', '14px', '16px', '18px']} fontWeight={700} color="#fff">
                    Andy
                  </Text>
                  <Text fontSize="12px" fontWeight={400} className={isMobile || isTablet ? 'yc mtxt' : 'mtxt'} ml="6px">
                    PM
                  </Text>
                </Box>
                <Text
                  fontSize="12px"
                  fontWeight={400}
                  textAlign="center"
                  className={isMobile || isTablet ? 'xs mtxt' : 'mtxt yc'}
                >
                  PM
                </Text>
                <Text
                  fontSize="12px"
                  fontWeight={400}
                  color="#fff"
                  textAlign={isMobile || isTablet ? 'center' : 'left'}
                  className={isMobile || isTablet ? 'yc' : ''}
                >
                  Web3 Product Manager DOTA2 Carry
                </Text>
                <Text fontSize="12px" className={isMobile || isTablet ? 'yc' : 'op'}>
                  占位
                </Text>
              </Box>
            </Box>
            <Box marginBottom={['30px', '60px', '90px', '120px']}>
              <Box width={['62px', '78px', '94px', '110px']} className="m">
                <Image src={coco} className="rh100 rw100" />
              </Box>
              <Box className={isMobile ? 'bgc2  br30 mt25 pd12' : 'bgc2  br30 mt25 pd17'}>
                <Box className={isMobile || isTablet ? 'fx ai-ct jc-ct' : 'fx ai-ct'}>
                  <Text fontSize={['12px', '14px', '16px', '18px']} fontWeight={700} color="#fff">
                    CocoReplica
                  </Text>
                  <Text fontSize="12px" fontWeight={400} className={isMobile || isTablet ? 'yc mtxt' : 'mtxt'} ml="6px">
                    Financial Analyst
                  </Text>
                </Box>
                <Text
                  fontSize="12px"
                  fontWeight={400}
                  textAlign="center"
                  className={isMobile || isTablet ? 'xs mtxt' : 'mtxt yc'}
                >
                  Financial Analyst
                </Text>
                <Text
                  fontSize="12px"
                  fontWeight={400}
                  color="#fff"
                  textAlign={isMobile || isTablet ? 'center' : 'left'}
                  className={isMobile || isTablet ? 'yc' : ''}
                >
                  Hedge fund bond trader and former strateist at Morgan Stanley
                </Text>
              </Box>
            </Box>
          </Grid>
          <Grid
            gridColumnGap={['15px', '24px', '48px', '60px']}
            gridTemplateColumns={['1fr 1fr', '1fr 1fr', '1fr 1fr', '1fr 1fr 1fr 1fr']}
            className="fx jc-sb ai-ct"
          >
            <Box marginBottom={['30px', '60px', '90px', '120px']}>
              <Box width={['62px', '78px', '94px', '110px']} className="m">
                <Image src={mia} className="rh100 rw100" />
              </Box>
              <Box className={isMobile ? 'bgc2  br30 mt25 pd12' : 'bgc2  br30 mt25 pd21'}>
                <Box className={isMobile || isTablet ? 'fx ai-ct jc-ct' : 'fx ai-ct'}>
                  <Text fontSize={['12px', '14px', '16px', '18px']} fontWeight={700} color="#fff">
                    Mia
                  </Text>
                  <Text fontSize="12px" fontWeight={400} className={isMobile || isTablet ? 'yc mtxt' : 'mtxt'} ml="6px">
                    OM
                  </Text>
                </Box>
                <Text
                  fontSize="12px"
                  fontWeight={400}
                  textAlign="center"
                  className={isMobile || isTablet ? 'xs mtxt' : 'mtxt yc'}
                >
                  OM
                </Text>
                <Text
                  fontSize="12px"
                  fontWeight={400}
                  color="#fff"
                  textAlign={isMobile || isTablet ? 'center' : 'left'}
                  className={isMobile || isTablet ? 'yc' : ''}
                >
                  A girl with passion and passion fruit for sure xD
                </Text>
              </Box>
            </Box>
            <Box marginBottom={['30px', '60px', '90px', '120px']}>
              <Box width={['62px', '78px', '94px', '110px']} className="m">
                <Image src={lsla} className="rh100 rw100" />
              </Box>
              <Box className={isMobile ? 'bgc2  br30 mt25 pd12' : 'bgc2  br30 mt25 pd21'}>
                <Box className={isMobile || isTablet ? 'fx ai-ct jc-ct' : 'fx ai-ct'}>
                  <Text fontSize={['12px', '14px', '16px', '18px']} fontWeight={700} color="#fff">
                    lsla
                  </Text>

                  <Text fontSize="12px" fontWeight={400} className={isMobile || isTablet ? 'yc mtxt' : 'mtxt'} ml="6px">
                    Community Manager
                  </Text>
                </Box>
                <Text
                  fontSize="12px"
                  fontWeight={400}
                  textAlign="center"
                  className={isMobile || isTablet ? 'xs mtxt' : 'mtxt yc'}
                >
                  Community Manager
                </Text>
                <Text
                  fontSize="12px"
                  fontWeight={400}
                  color="#fff"
                  textAlign={isMobile || isTablet ? 'center' : 'left'}
                  className={isMobile || isTablet ? 'yc' : ''}
                >
                  Massive passion in community Who doesn't love cats?
                </Text>
              </Box>
            </Box>
            <Box marginBottom={['30px', '60px', '90px', '120px']}>
              <Box width={['62px', '78px', '94px', '110px']} className="m">
                <Image src={flora} className="rh100 rw100" />
              </Box>
              <Box className={isMobile ? 'bgc2  br30 mt25 pd12' : 'bgc2  br30 mt25 pd21'}>
                <Box className={isMobile || isTablet ? 'fx ai-ct jc-ct' : 'fx ai-ct'}>
                  <Text fontSize={['12px', '14px', '16px', '18px']} fontWeight={700} color="#fff">
                    Flora
                  </Text>
                  <Text fontSize="12px" fontWeight={400} className={isMobile || isTablet ? 'yc mtxt' : 'mtxt'} ml="6px">
                    BD
                  </Text>
                </Box>
                <Text
                  fontSize="12px"
                  fontWeight={400}
                  textAlign="center"
                  className={isMobile || isTablet ? 'xs mtxt' : 'mtxt yc'}
                >
                  BD
                </Text>
                <Text
                  fontSize="12px"
                  fontWeight={400}
                  color="#fff"
                  textAlign={isMobile || isTablet ? 'center' : 'left'}
                  className={isMobile || isTablet ? 'yc' : ''}
                >
                  To infinity and beyond
                </Text>
                <Text fontSize="12px" className={isMobile || isTablet ? 'yc' : 'op'}>
                  占位
                </Text>
              </Box>
            </Box>
            <Box marginBottom={['30px', '60px', '90px', '120px']}>
              <Box width={['62px', '78px', '94px', '110px']} className="m">
                <Image src={tofu} className="rh100 rw100" />
              </Box>
              <Box className={isMobile ? 'bgc2  br30 mt25 pd12' : 'bgc2  br30 mt25 pd17'}>
                <Box className={isMobile || isTablet ? 'fx ai-ct jc-ct' : 'fx ai-ct'}>
                  <Text fontSize={['12px', '14px', '16px', '18px']} fontWeight={700} color="#fff">
                    Tofu.Mayo yo
                  </Text>
                  <Text fontSize="12px" fontWeight={400} className={isMobile || isTablet ? 'yc mtxt' : 'mtxt'} ml="6px">
                    Artist
                  </Text>
                </Box>
                <Text
                  fontSize="12px"
                  fontWeight={400}
                  textAlign="center"
                  className={isMobile || isTablet ? 'xs mtxt' : 'mtxt yc'}
                >
                  Artist
                </Text>
                <Text
                  fontSize="12px"
                  fontWeight={400}
                  color="#fff"
                  textAlign={isMobile || isTablet ? 'center' : 'left'}
                  className={isMobile || isTablet ? 'yc' : ''}
                >
                  StanleyGraduated from Parsons School of Design
                </Text>
              </Box>
            </Box>
          </Grid>
        </Box>
      </Col>
    </Box>
  )
}
