import React, { useState, useRef, useEffect } from 'react'
import * as echarts from 'echarts'
import useWindowSize from '@/hooks/useWindowSize'

interface EchartProp {
  // option: echarts.EChartOption
  option: any
  jumpable?: boolean
  onClickChart?: (param: any) => void
}

export const Chart: React.FC<EchartProp> = ({ option, jumpable, onClickChart }) => {
  const chartRef = useRef<HTMLDivElement>(null)
  const { width, height } = useWindowSize()
  const [echartsInstance, setEchartsInstance] = useState<echarts.ECharts>()
  useEffect(() => {
    if (chartRef.current) {
      setEchartsInstance(echarts.init(chartRef.current))
    }
  }, [])

  useEffect(() => {
    echartsInstance?.setOption(option)
    if (jumpable) {
      echartsInstance?.on('click', (param: any) => onClickChart && onClickChart(param))
      // echartsInstance?.on('click', (param: any) => {
      //   window.open(param.data.link)
      // })
    }
  }, [echartsInstance, option])

  useEffect(() => {
    echartsInstance?.resize({
      width: 'auto'
    })
  }, [width])
  return <div ref={chartRef} className="w100 h100 center" />
}

Chart.defaultProps = {
  jumpable: false
}
