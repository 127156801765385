import React from 'react'
import styled from 'styled-components'
import { Popover } from 'antd'
import _ from 'lodash'
import { Box, Text, Image } from '@/packages/'
import { toNonExponential, someTimesAgo, valumeUnit, isEmptyObj } from '@/utils/tools'
import { iVolume } from '@/types/store'
import { querkBuy, pendingIcon } from '@/assets/img'
import { BuyButton } from '@/components/BuyButton'
import { iPending } from '@/request/types'

interface iRightCompnent {
  price: number
  createTime: number
  lastPrice: iVolume
  listing: any
  isPending?: iPending
  // slug: string
  // onVisible: () => void
}

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 16px;
  background: #474747;
  border-radius: 12px;
`
const PendingPopover = () => {
  return (
    <Container>
      <Text color="rgba(255, 255, 255, 0.87)" fontSize="12px" className="beyondHide">
        1 prio 23 fee 23 by 0x123.12312 1s ago
      </Text>
    </Container>
  )
}

export const RightComponent: React.FC<iRightCompnent> = ({ price, createTime, lastPrice, listing, isPending }) => {
  return (
    <Box className="fx-col ai-end" pr="10px">
      <BuyButton listing={listing} from="listing" />
      <Box className="fx-row ai-ct" mt="20px">
        {lastPrice && (
          <Text className="fw400 white38 fz12" mr="8px">
            {`${someTimesAgo(createTime)}`}
          </Text>
        )}
        {
          isPending && Object.keys(isPending).length > 0 && (
            <div>
              {isPending.pending && (
                <Box className="fx-row ai-ct">
                  <Image src={pendingIcon} width={12} height={12} className="click loading_status" />
                  <Text className="fz12 fw400 white87" ml="4px">
                    {_.uniqWith(isPending.hashList).length}
                  </Text>
                </Box>
              )}
            </div>
          )
          // <Popover placement="bottom" content={<PendingPopover />}>
          //   <Box className="fx-row ai-ct">
          //     <Image src={pendingIcon} width={12} height={12} className="click loading_status" />
          //     <Text color="rgba(255, 255, 255, 0.87)" fontSize="12px" ml="4px">
          //       110
          //     </Text>
          //   </Box>
          // </Popover>
        }
      </Box>
    </Box>
  )
}
