import React from 'react'
import { notification } from 'antd'
import { infoMsg, successMsg, warningMsg, errorMsg, close } from '@/assets/img'

export const OpenNotification = (title: string, type?: string, duration?: string, key?: string) => {
  let typeMsg = infoMsg
  switch (type) {
    case 'info':
      typeMsg = infoMsg
      break
    case 'success':
      typeMsg = successMsg
      break
    case 'warning':
      typeMsg = warningMsg
      break
    case 'error':
      typeMsg = errorMsg
      break
    default:
      typeMsg = infoMsg
      break
  }

  notification.open({
    maxCount: 5,
    message: title,
    description: '',
    className: 'antdNotificationClassName',
    icon: <img src={typeMsg} height={20} width={20} alt="" />,
    closeIcon: <img src={close} height={10} width={10} alt="" />,
    duration: duration === 'never' ? null : 5,
    key
  })
}

export const CloseNotification = (key: string) => {
  notification.close(key)
}

export const NotMainNetNotice = () => {
  CloseNotification('switchChainMsg')
  OpenNotification('Please switch the network to the Ethereum Mainnet.', 'warning', 'never', 'switchChainMsg')
}
