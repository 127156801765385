import React, { useState } from 'react'
import { Table as AntTable } from 'antd'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { Image, Box, Text, Flex } from '@/packages/'
import { splitAddress, beyondStr } from '@/utils/tools'
import { sortDefault, sortActive } from '@/assets/svg'
import { Chart } from '@/components/Charts/Chart'
import { TrendingVolumeOption, TrendingFloorOption } from '@/components/Charts/options/index'
import { ChartWrapper } from '@/components/Charts/ChartWrapper'

interface iColumns {
  title: string
  dataIndex: string
  key: string
}
interface iScroll {
  offsetHeader: number
}
export interface iTable {
  dataSource: Array<any>
  columns: Array<iColumns>
  sticky: iScroll
}

export interface iWalletColTrending {
  imageUrl: string
  name: string
  like: boolean
  address: string
  nftId: number
}
export const WalletColTrending: React.FC<iWalletColTrending> = ({ imageUrl, name, like, address, nftId }) => {
  const navigate = useNavigate()
  const handleDetail = (nId: number) => {
    nId && navigate(`/trending/liveview/${nId}`)
  }
  return (
    <Box className="fx-row ai-ct click" onClick={() => handleDetail(nftId)}>
      <Image height={46} width={46} style={{ borderRadius: '6px' }} src={imageUrl} className="circle" />
      <Box className="fx-col ml10">
        <Text color="#E0E3E3" fontSize="14px" title={name}>
          {beyondStr(name, 20)}
        </Text>
      </Box>
    </Box>
  )
}

export interface iWalletCol {
  img: string
  name: string
  like: boolean
  address: string
}
export const WalletCol: React.FC<iWalletCol> = ({ img, name, like, address }) => {
  return (
    <Box className="fx-row ai-ct">
      <Image height={46} width={46} style={{ borderRadius: '6px' }} src={img} className="circle" />
      <Box className="fx-col ml10">
        <Text color="#E0E3E3" fontSize="14px">
          {name}
        </Text>
        {/* <Box className="fx-row" mt="2px">
          {like && <Image height={16} width={16} src={img} />}
          <Text color="#BEC8C9" fontSize="12px" ml="4px">
            {splitAddress(address)}
          </Text>
        </Box> */}
      </Box>
    </Box>
  )
}

export interface iTrendingFloor {
  values: Array<any>
  dates: Array<any>
}
export const TrendingVolume: React.FC<iTrendingFloor> = ({ values, dates }) => {
  return (
    <Box className="" width="80px" height="40px">
      <Chart option={TrendingVolumeOption({ values, dates })} />
    </Box>
  )
}

export const TrendingFloor: React.FC<iTrendingFloor> = ({ values, dates }) => {
  return (
    <Box className="" width="145px" height="48px">
      <Chart option={TrendingFloorOption({ values, dates })} />
    </Box>
  )
}

export interface iBalance {
  value: string
  progress: number
}

export const ProgressContainer = styled(Box)`
  position: relative;
  width: 111px;
  height: 5px;
  background: #47464f;
  border-radius: 22px;
`
export const ProgressBar = styled.div<{ progress: number }>`
  height: 100%;
  background: #c4c0ff;
  border-radius: 22px;
  top: 0;
  left: 0;
  position: absolute;
  width: ${({ progress }) => `${progress * 100}%`};
`
export const PrimaryText = styled.span<{ color?: string }>`
  font-size: 14x;
  color: ${({ color }) => color || '#E0E3E3'};
`
export const BalanceCol: React.FC<iBalance> = ({ value, progress }) => {
  return (
    <Box className="fx-col">
      <Flex className="fx-row">
        <Text fontSize="14px" color="#E0E3E3">
          {value}
        </Text>
        <Text fontSize="14px" color="#C4C0FF" ml="10px">{`(${progress * 100})%`}</Text>
      </Flex>
      <ProgressContainer mt="8px">
        <ProgressBar progress={progress} />
      </ProgressContainer>
    </Box>
  )
}

interface iColors {
  unit: string
  value: number
}
// 红跌 绿涨
export const ColorCol: React.FC<iColors> = ({ unit, value }) => {
  return <Text fontSize="16px" color={unit === '+' ? '#85DB68' : '#BA1B1B'}>{`${unit}${value}`}</Text>
}
export const Table: React.FC<iTable> = ({ dataSource, columns, sticky }) => {
  return <AntTable dataSource={dataSource} columns={columns} pagination={false} sticky={sticky} />
}
// 表格表头 排序
interface iHeaderSort {
  name: string
  changeType: (type: string) => void
}

const Name = styled(Box)`
  color: #918f99;
  font-size: 14px;
  height: 24px;
  width: 24px;
`
export const HeaderSort: React.FC<iHeaderSort> = ({ name, changeType }) => {
  const [type, setType] = useState('null') // 默认排序
  const clickSort = (t: string) => {
    // desc 降序 asc 升序
    setType(t)
    changeType(t)
  }
  return (
    <Box className="fx-row ai-ct" width="120px" height="44px">
      <Name className="center">{name}</Name>
      <Box className="fx-col" pt="4px">
        <Box height="12px" width="24px" className="click center" onClick={() => clickSort('desc')}>
          <Image height={4} width={8} src={type === 'desc' ? sortActive : sortDefault} />
        </Box>
        <Box height="12px" width="24px" className="click fx jc-ct" onClick={() => clickSort('asc')}>
          <Image height={4} width={8} src={type === 'asc' ? sortActive : sortDefault} className="rotate180" />
        </Box>
      </Box>
    </Box>
  )
}
