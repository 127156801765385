import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Box, Text, Grid } from '@/packages/'
import { Col } from '@/components/Layout/Layout'
import { useMatchBreakpoints } from '@/hooks/useMatchBreakpoints'

const AgileCodeBox = styled(Box)`
  width: 100%;
  height: 100%;
`
const DotLast = styled(Box)`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #fff;
`
const AnalysisImg = styled(Box)`
  width: 100%;
  height: 100%;
`
export const InDepth = (scrollNum: any) => {
  const { isMobile, isTablet } = useMatchBreakpoints()
  const [visible3, setVisible3] = useState(false)
  return (
    <Box className="c-sb w100" height={['505px', '730px', '940px', '990px']}>
      <Col className="mxw relative">
        {isMobile || isTablet ? (
          <Box marginTop={['65px', '103px', '141px', '180px']}>
            <Box className="fx jc-sb ai-ct">
              <Box
                className={`card ${visible3 ? 'rotated click' : 'click'}`}
                onClick={() => setVisible3(!visible3)}
                height={['115px', '183px', '251px', '320px']}
                width={['160px', '256px', '352px', '450px']}
                marginBottom={['0', '0', '0', '132px']}
              >
                <Box
                  className="front bg24 codeBox"
                  height={['135px', '183px', '251px', '320px']}
                  width={['160px', '256px', '352px', '450px']}
                  paddingTop={['50px', '80px', '100px', '72px']}
                  marginBottom={[0, 0, 0, '56px']}
                  paddingLeft={['10px', '16px', '24px', '30px']}
                >
                  <AgileCodeBox>
                    <Text fontSize={['16px', '24px', '32px', '40px']} fontWeight={700} color="#fff">
                      In-Depth Analysis
                    </Text>
                    <Box className="pa bm5 rg4 bg34" width="40px" height="10px" />
                  </AgileCodeBox>
                </Box>
                <Box
                  className="back bg31 codeBox"
                  height={['135px', '183px', '251px', '320px']}
                  width={['160px', '256px', '352px', '450px']}
                  paddingTop={['11px', '20px', '30px', '30px']}
                >
                  <AgileCodeBox>
                    <Box className="fx">
                      <Box
                        width={['5px', '5px', '6px', '8px']}
                        height={['5px', '5px', '6px', '8px']}
                        marginTop={['5px', '5px', '10px', '10px']}
                        marginLeft={['6px', '6px', '14px', '14px']}
                        marginRight={['6px', '6px', '14px', '14px']}
                      >
                        <DotLast />
                      </Box>
                      <Text
                        fontSize={['12px', '15px', '19px', '22px']}
                        fontWeight={700}
                        color="#fff"
                        lineHeight={['14px', '19px', '24px', '29px']}
                        marginBottom={['5px', '14px', '22px', '30px']}
                      >
                        Flash Buy
                      </Text>
                    </Box>
                    <Box className="fx">
                      <Box
                        width={['5px', '5px', '6px', '8px']}
                        height={['5px', '5px', '6px', '8px']}
                        marginTop={['5px', '5px', '10px', '10px']}
                        marginLeft={['6px', '6px', '14px', '14px']}
                        marginRight={['6px', '6px', '14px', '14px']}
                      >
                        <DotLast />
                      </Box>
                      <Text
                        fontSize={['12px', '15px', '19px', '22px']}
                        fontWeight={700}
                        color="#fff"
                        lineHeight={['14px', '19px', '24px', '29px']}
                        marginBottom={['5px', '14px', '22px', '30px']}
                      >
                        Mobile Version
                      </Text>
                    </Box>
                    <Box className="fx">
                      <Box
                        width={['5px', '5px', '6px', '8px']}
                        height={['5px', '5px', '6px', '8px']}
                        marginTop={['5px', '5px', '10px', '10px']}
                        marginLeft={['6px', '6px', '14px', '14px']}
                        marginRight={['6px', '6px', '14px', '14px']}
                      >
                        <DotLast />
                      </Box>
                      <Text
                        fontSize={['12px', '15px', '19px', '22px']}
                        fontWeight={700}
                        color="#fff"
                        lineHeight={['14px', '19px', '24px', '29px']}
                        marginBottom={['5px', '14px', '22px', '30px']}
                      >
                        Autobuy&Mass Bid (upcoming)
                      </Text>
                    </Box>
                    <Box className="fx">
                      <Box
                        width={['5px', '5px', '6px', '8px']}
                        height={['5px', '5px', '6px', '8px']}
                        marginTop={['5px', '5px', '10px', '10px']}
                        marginLeft={['6px', '6px', '14px', '14px']}
                        marginRight={['6px', '6px', '14px', '14px']}
                      >
                        <DotLast />
                      </Box>
                      <Text
                        fontSize={['12px', '15px', '19px', '22px']}
                        fontWeight={700}
                        color="#fff"
                        lineHeight={['14px', '19px', '24px', '29px']}
                      >
                        Multi-wallet Address Management (upcoming)
                      </Text>
                    </Box>
                  </AgileCodeBox>
                </Box>
              </Box>
              <Box
                paddingLeft="26px"
                width={['180px', '350px', '410px']}
                className={
                  scrollNum.scrollNum > 1500 && scrollNum.scrollNum < 2600
                    ? ' isMobileInDepthBox'
                    : ' isMobileInDepthBoxNo'
                }
              >
                <Box className="fx">
                  <Box
                    width={['5px', '5px', '6px', '8px']}
                    height={['5px', '5px', '6px', '8px']}
                    marginTop={['8px', '11px', '16px', '16px']}
                    marginLeft={['6px', '6px', '14px', '14px']}
                    marginRight={['6px', '6px', '14px', '14px']}
                  >
                    <DotLast />
                  </Box>
                  <Text
                    marginBottom={['5px', '14px', '22px', '30px']}
                    fontSize={['15px', '20px', '25px']}
                    fontWeight={400}
                    color="#fff"
                  >
                    Sniper Pro
                  </Text>
                </Box>
                <Text fontSize={['12px', '20px', '24px']} fontWeight={500} color="rgba(255, 255, 255, 0.5)">
                  Discover the most effective indicators and opportunities in the market.
                </Text>
              </Box>
            </Box>
            <Box
              className="relative m"
              left={[0, 0, 0, '-60px']}
              width={['340px', '526px', '712px', '900px']}
              height={['248px', '385px', '512px', '660px']}
            >
              <AnalysisImg
                className={
                  scrollNum.scrollNum > 1700 && scrollNum.scrollNum < 2800
                    ? 'bg25 isMobileInDepthImg'
                    : 'bg25 isMobileInDepthImgNo'
                }
              />
            </Box>
          </Box>
        ) : (
          <Box className="fx jc-ct ai-ct" marginTop={['65px', '103px', '141px', '180px']}>
            <Box
              className="relative"
              left={[0, 0, '-30px', '-60px']}
              width={['340px', '526px', '712px', '900px']}
              height={['248px', '385px', '512px', '660px']}
            >
              <AnalysisImg
                className={
                  scrollNum.scrollNum > 4300 && scrollNum.scrollNum < 5500 ? 'bg25 inDepthImg' : 'bg25 inDepthImgNo'
                }
              />
            </Box>
            <Box>
              <Box
                className={`card ${visible3 ? 'rotated click' : 'click'}`}
                onMouseOver={() => setVisible3(true)}
                onMouseLeave={() => setVisible3(false)}
                height={['115px', '183px', '251px', '320px']}
                width={['160px', '256px', '352px', '450px']}
                marginBottom={['0', '0', '0', '132px']}
              >
                <Box
                  className="front bg24 codeBox"
                  height={['115px', '183px', '251px', '320px']}
                  width={['160px', '256px', '352px', '450px']}
                  paddingTop={['25px', '40px', '90px', '128px']}
                  marginBottom={[0, 0, '90px', '56px']}
                  paddingLeft={['10px', '16px', '24px', '30px']}
                >
                  <AgileCodeBox>
                    <Text fontSize={['16px', '24px', '32px', '40px']} fontWeight={700} color="#fff">
                      In-Depth Analysis
                    </Text>
                  </AgileCodeBox>
                </Box>
                <Box
                  className="back bg31 codeBox"
                  height={[0, 0, 0, '320px']}
                  width={[0, 0, 0, '450px']}
                  paddingTop={['11px', '20px', '30px', '30px']}
                >
                  <AgileCodeBox>
                    <Box className="fx">
                      <Box
                        width={['5px', '5px', '6px', '8px']}
                        height={['5px', '5px', '6px', '8px']}
                        marginTop={['5px', '5px', '10px', '10px']}
                        marginLeft={['6px', '6px', '14px', '14px']}
                        marginRight={['6px', '6px', '14px', '14px']}
                      >
                        <DotLast />
                      </Box>
                      <Text
                        fontSize={['12px', '15px', '19px', '22px']}
                        fontWeight={700}
                        color="#fff"
                        lineHeight={['14px', '19px', '24px', '29px']}
                        marginBottom={['5px', '14px', '22px', '30px']}
                      >
                        Flash Buy
                      </Text>
                    </Box>
                    <Box className="fx">
                      <Box
                        width={['5px', '5px', '6px', '8px']}
                        height={['5px', '5px', '6px', '8px']}
                        marginTop={['5px', '5px', '10px', '10px']}
                        marginLeft={['6px', '6px', '14px', '14px']}
                        marginRight={['6px', '6px', '14px', '14px']}
                      >
                        <DotLast />
                      </Box>
                      <Text
                        fontSize={['12px', '15px', '19px', '22px']}
                        fontWeight={700}
                        color="#fff"
                        lineHeight={['14px', '19px', '24px', '29px']}
                        marginBottom={['5px', '14px', '22px', '30px']}
                      >
                        Mobile Version
                      </Text>
                    </Box>
                    <Box className="fx">
                      <Box
                        width={['5px', '5px', '6px', '8px']}
                        height={['5px', '5px', '6px', '8px']}
                        marginTop={['5px', '5px', '10px', '10px']}
                        marginLeft={['6px', '6px', '14px', '14px']}
                        marginRight={['6px', '6px', '14px', '14px']}
                      >
                        <DotLast />
                      </Box>
                      <Text
                        fontSize={['12px', '15px', '19px', '22px']}
                        fontWeight={700}
                        color="#fff"
                        lineHeight={['14px', '19px', '24px', '29px']}
                        marginBottom={['5px', '14px', '22px', '30px']}
                      >
                        Autobuy&Mass Bid (upcoming)
                      </Text>
                    </Box>
                    <Box className="fx">
                      <Box
                        width={['5px', '5px', '6px', '8px']}
                        height={['5px', '5px', '6px', '8px']}
                        marginTop={['5px', '5px', '10px', '10px']}
                        marginLeft={['6px', '6px', '14px', '14px']}
                        marginRight={['6px', '6px', '14px', '14px']}
                      >
                        <DotLast />
                      </Box>
                      <Text
                        fontSize={['12px', '15px', '19px', '22px']}
                        fontWeight={700}
                        color="#fff"
                        lineHeight={['14px', '19px', '24px', '29px']}
                      >
                        Multi-wallet Address Management (upcoming)
                      </Text>
                    </Box>
                  </AgileCodeBox>
                </Box>
              </Box>
              <Box
                className={
                  scrollNum.scrollNum > 4600 && scrollNum.scrollNum < 5500 ? 'w450 inDepthBox' : 'w450 inDepthBoxNo'
                }
              >
                <Box className="fx">
                  <Box
                    width={['5px', '5px', '6px', '8px']}
                    height={['5px', '5px', '6px', '8px']}
                    marginTop={['5px', '5px', '16px', '16px']}
                    marginLeft={['6px', '6px', '14px', '14px']}
                    marginRight={['6px', '6px', '14px', '14px']}
                  >
                    <DotLast />
                  </Box>
                  <Text fontSize="30px" fontWeight={400} color="#fff" lineHeight="40px">
                    Sniper Pro
                  </Text>
                </Box>
                <Text fontSize="28px" fontWeight={500} color="rgba(255, 255, 255, 0.5)">
                  Discover the most effective indicators and opportunities in the market.
                </Text>
              </Box>
            </Box>
          </Box>
        )}
      </Col>
    </Box>
  )
}
