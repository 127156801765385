import SockJS from 'sockjs-client'
import * as StompJs from '@stomp/stompjs'
import { locaStoreageGetItem, retryTimes } from '@/utils/tools'

let wsUrl: string
if (process.env.REACT_APP_PEWEE_ENV === 'dev' || process.env.REACT_APP_PEWEE_ENV === 'pre') {
  wsUrl = 'https://www.pre.xingjigangwan.com/bls/stomp'
} else {
  wsUrl = 'https://www.pewee.tools/bls/stomp'
}
export class PeweeWS {
  private client: any

  public isConnected: boolean

  private times: number

  constructor() {
    this.init()
    this.isConnected = false
    this.times = 3
  }

  init() {
    const socket = new SockJS(wsUrl, null, { transports: 'websocket' })
    this.client = StompJs.Stomp.over(socket)
    this.client.reconnect_delay = 3000
    this.client.heartbeatIncoming = 10000
    this.client.heartbeatOutgoing = 10000

    this.onConnect()
  }

  onConnect() {
    const _client = this.client
    const _this = this
    this.client.connect(
      {
        headers: {
          Authorization: `Bearer ${locaStoreageGetItem('tokens')}`
        }
      },
      function connectCallback() {
        _this.isConnected = true
        _client.activate()
      },
      function errorCallback() {
        console.log('errorCallback')
        while (_this.times-- > 0) {
          _client.reconnect_delay = 3000
          const __this = _this
          __this.init()
        }
      },
      function closeEventCallback() {
        console.log('closeEventCallback', _this.times)
        // while (_this.times-- > 0) {
        //   _client.reconnect_delay = 3000
        //   const __this = _this
        //   __this.init()
        // }
      }
    )
  }

  disConnect() {
    console.log('dis connect')
    this.client.reconnect_delay = 0
    try {
      this.client.disconnect()
    } catch (error) {
      this.client.disconnect()
      console.log('dis connect error')
    }
  }
}
