import { formatDistance } from 'date-fns'
import { notification } from 'antd'
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface'

export function delStr(str: string) {
  // https://date-fns.org/v2.29.3/docs/formatDistance
  const arr = ['about', 'less', 'than', 'over', 'almost']
  let res = ''
  if (str.includes('a minute')) {
    str = '1 minute'
  }
  str.split(' ').forEach((item: string) => {
    item === 'second' && (item = item.replace('second', 's'))
    item === 'seconds' && (item = item.replace('seconds', 's'))
    item === 'minute' && (item = item.replace('minute', 'm'))
    item === 'minutes' && (item = item.replace('minutes', 'm'))
    item === 'hour' && (item = item.replace('hour', 'h'))
    item === 'hours' && (item = item.replace('hours', 'h'))
    item === 'day' && (item = item.replace('day', 'd'))
    item === 'days' && (item = item.replace('days', 'd'))
    item === 'month' && (item = item.replace('month', 'mon'))
    item === 'months' && (item = item.replace('months', 'mon'))
    item === 'year' && (item = item.replace('year', 'y'))
    item === 'years' && (item = item.replace('years', 'y'))

    if (!arr.includes(item)) {
      res += `${item}`
    }
  })

  return res
}

export function someTimesAgo(stamp: number | Date | string) {
  if (stamp) {
    const d = Number(stamp) / 1000
    return `${delStr(`${formatDistance(new Date().getTime(), Number(stamp))}`)} ago`
  }
  return '--'
}
export function toNonExponential(num: number | string, digits = 4): number {
  let n = Number(num)
  if (n) {
    if (n > 1) {
      digits = 3
    }
    n = Number(Number(n).toFixed(digits))
    const m = n.toExponential().match(/\d(?:\.(\d*))?e([+-]\d+)/) || []
    return Number(n.toFixed(Math.max(0, (m[1] || '').length - Number(m[2]))))
  }
  return 0
}
export function toNonExponential2(num: number | string): number {
  let n = Number(num)
  if (n) {
    n = Number(Number(n).toFixed(2))
    const m = n.toExponential().match(/\d(?:\.(\d*))?e([+-]\d+)/) || []
    return Number(n.toFixed(Math.max(0, (m[1] || '').length - Number(m[2]))))
  }
  return 0
}
export function toNonExponential1(num: number | string, digits = 4): number {
  let n = Number(num)
  if (n) {
    n = Number(Number(n).toFixed(digits))
    const m = n.toExponential().match(/\d(?:\.(\d*))?e([+-]\d+)/) || []
    return Number(n.toFixed(Math.max(0, (m[1] || '').length - Number(m[2]))))
  }
  return 0
}
// 大于10w
export const greater100k = (value: number): string => {
  // （2）若Ξ100000≦价格/数量<Ξ1000000 ，则显示为“XX.XXK”；
  // （3）若Ξ1000000≦价格/数量<Ξ1000000000，则显示为“XX.XXM”；
  // （4）若Ξ1000000000≦价格/数量<Ξ1000000000000，则显示为“XX.XXB”；
  // （5）若价格/数量≥Ξ1000000000000，则显示为“XX.XXT”；
  const newVal = Number(value.toFixed(0))
  if (newVal > 1000000000000) return `${toNonExponential2(newVal / 1000000000000)}T`
  if (newVal > 1000000000) return `${toNonExponential2(newVal / 1000000000)}B`
  if (newVal > 1000000) return `${toNonExponential2(newVal / 1000000)}M`
  if (newVal > 100000) return `${toNonExponential2(newVal / 1000)}K`
  return `${value}`
}

export function valumeUnit(value: number | string): string {
  // 2. 平台内默认以太坊金额/百分比例显示数字方式为有效4位数，当数据量大于等于100000时，
  //      （1）小于1的，保留小数点后4位（从下一位四舍五入），遇到0的舍去不显示；
  //      （2）大于1的，保留小数点后3位（从下一位四舍五入），遇到0的舍去不显示；
  //      例如：0.1231（0.123052），0.21（0.20999），1.231（1.23139），1.11（1.109950）

  // （1）若0≦价格/数量<100000，则显示为全部数据；
  if (value === '--') return `--`
  const _value = Number(value)
  if (_value === 0) return `0`
  if (_value) {
    if (_value > 100000 || _value === 100000) return greater100k(_value)
    if (_value > 1) return String(toNonExponential(_value, 4))
    return String(toNonExponential(_value, 3))
  }
  return `--`
}

export function valumeUnit4Chart(value: number): string {
  const str = value.toFixed(0)
  if (str.length > 6) {
    const m = Number(str) / 1000000
    return `${m}M`
  } // 百万
  if (str.length > 2) {
    const k = Number(str) / 1000
    return `${k}K`
  } // 千
  return `${Number(str)}`
}
export const less0 = (n: number) => {
  return n < 10 ? `0${n}` : n
}
export function unitTime(stamp: number | string) {
  const monthList = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEPT', 'OCT', 'NOV', 'DEC']
  const weekList = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'] // 一周从周日开始
  const d = new Date(Number(stamp))
  const m = d.getMonth() + 1
  const day = d.getDate()
  const h = d.getHours()
  const min = d.getMinutes()
  const s = d.getSeconds()
  const w = d.getDay() // getDay() 方法根据本地时间，返回一个具体日期中一周的第几天，0 表示星期天。对于某个月中的第几天
  // Web Apr 13, 10:00
  return `${weekList[w]}. ${monthList[d.getMonth()]} ${day}, ${less0(h)}:${less0(min)}:${less0(s)}`
}

// 时间戳转年月日  Apr.13 2022
export function timeFormate(stamp: number | Date, md?: boolean) {
  const monthList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
  const d = new Date(stamp)
  if (md) return `${monthList[d.getMonth()]} ${d.getDate()}`
  return `${monthList[d.getMonth()]} ${d.getDate()} ${d.getFullYear()}`
}
// 时间戳转年月日时分秒  Apr.13 2022 12:12:12
export function ymdhmsFormate(stamp: number | Date, md?: boolean) {
  if (stamp) {
    const ymd = timeFormate(stamp)
    const d = new Date(stamp)
    return `${ymd} ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`
  }
  return '--'
}

// stamp => 2022/08/15 23:00
export function ymdhmsFormate2(stamp: number) {
  if (stamp) {
    const d = new Date(Number(stamp))
    const y = d.getFullYear()
    const m = d.getMonth() + 1
    const day = d.getDate()
    const h = d.getHours()
    const min = d.getMinutes()

    return `${y}/${less0(m)}/${less0(day)} ${less0(h)}:${less0(min)}`
  }
  return '--'
}
export function dateList(list: Array<number>): Array<string> {
  const newList: Array<string> = []
  list.forEach((item: number) => newList.push(timeFormate(item, true)))
  return newList
}

// 返回hh:mm
export function timeFormate2(stamp: number | Date) {
  const d = new Date(stamp)
  return `${less0(d.getHours())}:${less0(d.getMinutes())}`
}
// 返回 MM:DD
// export function timeFormate3(stamp: number | Date) {
//   const d = new Date(stamp)
//   return `${less0(d.getHours())}:${less0(d.getMinutes())}`
// }
export function dateList2(list: Array<number>): Array<string> {
  const newList: Array<string> = []
  list.forEach((item: number) => newList.push(timeFormate2(item)))
  return newList
}

export function splitAddress(addrsss: string, start: number = 4, end: number = 4) {
  if (addrsss) {
    return `${addrsss.substring(0, start)}...${addrsss.substring(addrsss.length - end)}`
  }
  return '--'
}

export function beyondStr(str: string, step: number = 15) {
  let res = '--'
  if (str) {
    res = str.length > step ? `${str.substring(0, step)}...` : str
  }
  return res
}

export function locaStoreageSetItem(key: string, value: any) {
  localStorage.setItem(key, JSON.stringify(value))
}
export function locaStoreageGetItem(key: string) {
  const value: string | null = localStorage.getItem(key)
  let str: any
  if (value) {
    str = JSON.parse(value)
  }
  return str
}
export function locaStoreageRemoveItem(key: string) {
  localStorage.removeItem(key)
}

// 判断是否是空对象
export function isEmptyObj(obj: Object) {
  if (obj && !!Object.keys(obj).length) {
    return true
  }
  return false
}
export function debounce(fn: any, delay: number = 500, ...args: any) {
  let timer: any = null
  return function () {
    if (timer) {
      clearTimeout(timer)
    }
    let self: any
    timer = setTimeout(() => {
      fn.call(self, args)
    }, delay)
  }
}

export const openWindow = (url: string) => {
  window.open(url)
}

// 删除参数对象中的0 null undefind等
export const delQueryFalse = (obj: any) => {
  Object.keys(obj).forEach((key) => {
    const value = obj[key]
    // eslint-disable-next-line no-unused-expressions
    value && typeof value === 'object' && delQueryFalse(value)
    if (
      value === 0 ||
      value === '' ||
      value === null ||
      value === undefined ||
      value.length === 0
      // Object.keys(value).length === 0
    ) {
      delete obj[key]
    }
  })
  return obj
}

export const delQueryFalseNot0 = (obj: any) => {
  Object.keys(obj).forEach((key) => {
    const value = obj[key]
    // eslint-disable-next-line no-unused-expressions
    value && typeof value === 'object' && delQueryFalse(value)
    if (
      value === '' ||
      value === null ||
      value === undefined ||
      value.length === 0
      // Object.keys(value).length === 0
    ) {
      delete obj[key]
    }
  })
  return obj
}

// 删除空对象中的值
export const delEmptyObj = (obj: any) => {
  Object.keys(obj).forEach((item) => {
    if (typeof obj[item] === 'object' && Object.keys(obj[item]).length === 0) {
      delete obj[item]
    }
  })
  return obj
}

// 两个浮点数求和
export function accAdd(num1: number, num2: number) {
  let r1
  let r2
  try {
    r1 = num1.toString().split('.')[1].length
  } catch (e) {
    r1 = 0
  }
  try {
    r2 = num2.toString().split('.')[1].length
  } catch (e) {
    r2 = 0
  }
  const m = 10 ** Math.max(r1, r2)
  // return (num1*m+num2*m)/m;
  return Math.round(num1 * m + num2 * m) / m
}

// 两个浮点数相减
export function accSub(num1: number, num2: number) {
  let r1
  let r2
  try {
    r1 = num1.toString().split('.')[1].length
  } catch (e) {
    r1 = 0
  }
  try {
    r2 = num2.toString().split('.')[1].length
  } catch (e) {
    r2 = 0
  }
  const m = 10 ** Math.max(r1, r2)
  const n = r1 >= r2 ? r1 : r2
  return (Math.round(num1 * m - num2 * m) / m).toFixed(n)
}

// 两数相乘
export function accMul(num1: number | string, num2: number | string) {
  let m = 0
  const s1 = num1.toString()
  const s2 = num2.toString()
  try {
    m += s1.split('.')[1].length
  } catch (e: any) {
    // console.log('11')
  }
  try {
    m += s2.split('.')[1].length
  } catch (e: any) {
    // console.log('22')
  }
  return (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) / 10 ** m
}

// 两数相除
export function accDiv(num1: number, num2: number) {
  let t1
  let t2
  try {
    t1 = num1.toString().split('.')[1].length
  } catch (e) {
    t1 = 0
  }
  try {
    t2 = num2.toString().split('.')[1].length
  } catch (e) {
    t2 = 0
  }
  const r1 = Number(num1.toString().replace('.', ''))
  const r2 = Number(num2.toString().replace('.', ''))
  return (r1 / r2) * 10 ** (t2 - t1)
}

export const openNotification = (title: string, desc: string, type?: string) => {
  // type: success error warning info
  // infoMsg, successMsg, warningMsg, errorMsg

  notification.open({
    message: title,
    description: desc
  })
}

export const retryTimes = (callback: () => void, times?: number) => {
  let t = times || 3
  while (t-- > 0) callback()
}

// 6981799.2324324 => 7000000
// (6981799.2324324, 1) => 10000000
export const getYAxisMax = (yList: Array<number>, oneMore?: number) => {
  const yListMax = Number(Math.max(...yList).toFixed())
  const len = oneMore ? `${yListMax}`.length + oneMore : `${yListMax}`.length
  const maxUnit = 10 ** (len - 1)
  return Math.ceil(yListMax / maxUnit) * maxUnit
}

// 给数组添加id
export const addId4Array = (list: Array<any>) => {
  const _list = JSON.parse(JSON.stringify(list))
  const arr: Array<any> = []
  _list.forEach((_l: any, _i: number) => {
    arr.push({ id: _i, ..._l })
  })
  return arr
}

// 给watchlist数组添加visible属性
export const addVisible4WatchList = (list: Array<any>) => {
  const _list = JSON.parse(JSON.stringify(list))
  const arr: Array<any> = []
  _list.forEach((_l: any, _i: number) => {
    arr.push({ visible: !!(_l.alert && !!Object.keys(_l.alert).length), ..._l })
  })
  return arr
}

// 数组里的string转成number
export const str2NumArray = (list: Array<number | string>) => {
  const _list = JSON.parse(JSON.stringify(list))
  const arr: Array<number> = []
  _list.forEach((_l: any, _i: number) => {
    arr.push(Number(_l))
  })
  return arr
}

// 获取 当前listing的tokenid
export const getTokenIdFromListing = (listing: Array<any>): Array<number> => {
  const tokenIdArr: Array<number> = []
  listing.forEach((item: any, idx: number) => {
    if (item.nftTokenVO) {
      tokenIdArr.push(Number(item.nftTokenVO.tokenId))
    }
  })
  return tokenIdArr
}

//
export const pendingTokenIdIndex = (tokenIds: Array<number>, pendingTokenId: Array<number>) => {
  const idxs: Array<number> = []
  tokenIds.forEach((t: number, tIdx: number) => {
    pendingTokenId.forEach((p: number) => {
      if (t === p) {
        idxs.push(tIdx)
      }
    })
  })
  return idxs
}
export const isURL = (URL: string): boolean => {
  const exp = /(http|https):\/\/\S*/
  return exp.test(URL)
}

export const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result as string))
  reader.readAsDataURL(img)
}

export const getRankColor = (rank: number, supply: number) => {
  let rankColor = '#727272'
  if (Number(rank) < supply * 0.001) {
    rankColor = '#D4B02B'
  } else if (Number(rank) < supply * 0.01) {
    rankColor = '#955CF1'
  } else if (Number(rank) < supply * 0.1) {
    rankColor = '#2D6FF1'
  }
  return rankColor
}

// 小数点后x位
export const decimalNum = (str: number | string, precision: number) => {
  const taregt = String(str)
  const idx = taregt.indexOf('.')
  // const deciamlPrev = taregt.substring(0, idx + 1)
  const deciamlRest = taregt.substring(idx + 1) // 小数点后的位数
  if (idx !== -1) {
    if (deciamlRest.length > precision) {
      return false
    }
  }
  return true
}

export const share2Twitter = (link: string) => {
  const text = `Pewee tools is amazing! It's fast and friendly to  use making purchase  easier.  Have a try here `
  openWindow(`https://twitter.com/share?text=${text}&url=${link}`)
}
