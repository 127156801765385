import { WALLET_LOGIN, CLICK_ROOT, ACCESS_AUTH } from '../common'
import { iNavState, AppActionType } from '@/types/store'
import { locaStoreageSetItem } from '@/utils/tools'
import { iWalletLogin } from '@/types/store/index'

const saveToken = (token: iWalletLogin) => {
  locaStoreageSetItem('tokens', token)
}

export const navInitState = {
  walletLogin: null,
  clickRootStatus: false,
  accessAuth: null
}

export function NavReducer(state: iNavState, action: AppActionType): iNavState {
  const { walletLogin, clickRootStatus, accessAuth } = action.payload
  switch (action.type) {
    case WALLET_LOGIN:
      saveToken(walletLogin)
      return {
        ...state,
        walletLogin
      }

    case CLICK_ROOT:
      return {
        ...state,
        clickRootStatus
      }
    case ACCESS_AUTH:
      return {
        ...state,
        accessAuth
      }
    default:
      return state
  }
}
