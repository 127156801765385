import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { Flex, Box, Grid, Text } from '@/packages/'
import { useAppContext } from '@/hooks/useAppContext'
import { ListCardItem, TraderIn1Min } from './component'
import {
  ListingCardTitle,
  Col,
  Row,
  ListingLoadWrapper,
  LoadingItemWrapper,
  ETHValue
} from '@/components/Layout/Layout'
import { SalesApi, SalesCountApi } from '@/request/Api'
import { iListingRes, iListingParams, iListingRes2 } from '@/request/types'
import { someTimesAgo, toNonExponential, beyondStr, accMul } from '@/utils/tools'
import { iVolume } from '@/types/store'
import { HoverWrapper } from '@/components/Layout/HoverWrapper'
import { ColorRank } from '@/components/ColorRank'
import { NoData } from '@/components/NoData'
import { LiveFeedDot, LiveFeedDotMobile } from '@/components/LiveFeedDot'
import { useMatchBreakpoints } from '@/hooks/useMatchBreakpoints'
import { SoldOutNFTAction } from '@/store/actions/LiveviewAction'

interface iLeftComponent {
  prevSalePrice: iVolume
  rank: number
}

interface iRightCompnent {
  value: number
  increase: boolean
  percent: number
  price: number
  royalty: number
  createTime: number
}
const Container = styled(Box)``

const RightCompnent: React.FC<iRightCompnent> = ({ increase, percent, value, price, royalty, createTime }) => {
  const val: number = value !== 0 ? (price * (1 - royalty / 10000) - value) / value : 0
  const res: number = accMul(toNonExponential(val), 100)
  return (
    <Col className="fx ai-end" pr="10px">
      <ETHValue value={toNonExponential(price)} mt={-1} />
      <Row>
        <Text className="fz12 fw400 white60" mr="4px">
          PNL:
        </Text>
        <Text
          fontSize={['12px', '12px', '14px', '14px']}
          fontWeight={400}
          color={Number(val) === 0 ? '#A0A1A3' : res > 0 ? '#38C742' : '#F32626'}
        >
          {Number(val) === 0 ? '0' : <div>{res > 0 ? `+${Math.abs(res)}%` : `-${Math.abs(res)}%`}</div>}
        </Text>
      </Row>
      <Text mt="12px" fontSize="12px" color="rgba(255, 255, 255, 0.38)">
        {`${someTimesAgo(createTime)}`}
      </Text>
    </Col>
  )
}

const LeftComponent: React.FC<iLeftComponent> = ({ prevSalePrice, rank }) => {
  const { increase, percent, value } = prevSalePrice
  return (
    <Col className="">
      <ColorRank rankValue={rank} />
      <Row mr="4px" mt="32px">
        <Text color="rgba(255, 255, 255, 0.38)" fontSize="12px">
          Previous sale
        </Text>
        <Text color="rgba(255, 255, 255, 0.6)" fontSize="12px" mx="4px">
          Ξ {toNonExponential(value)}
        </Text>
      </Row>
    </Col>
  )
}

export const Sales = (wsSocket: any) => {
  const [statusType, setStatusType] = useState<boolean>(true)
  const { state, dispatch } = useAppContext()
  const [sales, setSales] = useState<Array<any>>([])
  const [total, setTotal] = useState(0)
  const [tradesNumber, setTradesNumber] = useState(0)
  const [pageIndex, setPageIndex] = useState(1)
  const [pageSize, setPageSize] = useState(12)
  const [isLoading, setLoading] = useState(true)
  const [isInit, setInit] = useState(true)
  const params = useParams()
  const NFTID = Number(params.nftId)
  const [query, setQuery] = useState<iListingParams>({
    nftId: NFTID,
    pageIndex: 1,
    pageSize
  })
  const salesRef: any = useRef()
  const { isMobile } = useMatchBreakpoints()

  const enptyValume = {
    increase: false,
    percent: 0,
    value: 0
  }

  useEffect(() => {
    salesRef.current = sales
    if (wsSocket && wsSocket.socket) {
      try {
        setTimeout(() => {
          wsSocket.socket.client.subscribe(`/topic/trade-new-${NFTID}`, (res: any) => {
            const d = JSON.parse(res.body)
            salesRef.current.unshift(d)
            setSales([...salesRef.current])
            if (d && d.nftTokenVO) {
              console.log('ws data==sales>>>>>>>>', d)
              // const ddd: any = {
              //   dataTime: 1665473843000,
              //   lastPrice: {
              //     increase: null,
              //     percent: null,
              //     value: 0
              //   },
              //   nftTokenVO: {
              //     imageUrl:
              //       'https://lh3.googleusercontent.com/9GgkzN-7si-yuuOHyZ6OmOj28LBRutwfFA-rgJnT10vXDuv8-nJdVd5r6UjQEnvlquDEYHVlXIt82Y5rzKNXljSV9rp7hI6BQtCleQ',
              //     name: '0xa720',
              //     nftAddress: '0x31d45de84fde2fb36575085e05754a4932dd5170',
              //     nftId: 2601,
              //     rank: null,
              //     tokenId: '1'
              //   },
              //   price: 0.388,
              //   rank: null,
              //   royalty: 1000
              // }
              // 判断listing和sale中的from id price相同时  那么这条数据从listing中删除
              SoldOutNFTAction(dispatch, d)
            }
          })
        }, 10100)
      } catch (error) {
        console.log('connect error', error)
      }
    }
  }, [sales])

  const fetch = async () => {
    setLoading(true)
    setInit(false)
    const d: any = await SalesApi(query)
    setLoading(false)
    if (d) {
      const combime = [...sales, ...d.records]
      setSales(combime)
      setTotal(d.total)
      const traders: any = await SalesCountApi(Number(params.nftId), {
        timeunit: 'minute',
        value: 1
      })
      setTradesNumber(traders)
    }
  }
  useEffect(() => {
    fetch()
  }, [query])
  useEffect(() => {
    if (!isInit) {
      const p = JSON.parse(JSON.stringify(query))
      if (state?.LiveViewReducer?.listingFilterOptions) {
        const { tokenId, priceRange, rankRange, traitQueries } = state.LiveViewReducer.listingFilterOptions
        p.pageIndex = 1
        p.tokenId = tokenId
        p.priceRange = priceRange
        p.rankRange = rankRange
        p.traitQueries = traitQueries
      }

      setSales([])
      setQuery(p)
    }
  }, [state?.LiveViewReducer?.listingFilterOptions])

  const onSalesBottom = () => {
    if (!isLoading) {
      const p = JSON.parse(JSON.stringify(query))
      const newIndex = pageIndex + 1
      setPageIndex(newIndex)
      p.pageIndex = newIndex
      setQuery(p)
    }
  }

  return (
    <Container className="">
      <TraderIn1Min content={`${tradesNumber} Sales in 1 min`} count={tradesNumber} />
      <Box className="" height="34px" mb={isMobile ? '16px' : '0px'}>
        <Row>
          {isMobile && <LiveFeedDotMobile isLive={statusType} />}
          <ListingCardTitle title="Sales" count={total} />
        </Row>
      </Box>
      {!isMobile && <LiveFeedDot isLive={statusType} />}

      <HoverWrapper
        mouseOver={() => setStatusType(false)}
        mouseLeave={() => setStatusType(true)}
        isEmpty={!isLoading && sales.length === 0}
      >
        <ListingLoadWrapper onContainerBottom={onSalesBottom}>
          {!!sales.length &&
            sales.map((item: iListingRes2, idx: number) => (
              <ListCardItem
                isFirst={idx === 0}
                key={Math.random().toString()}
                imageUrl={item.nftTokenVO ? item.nftTokenVO.imageUrl : ''}
                tokenId={`#${item.nftTokenVO ? beyondStr(item.nftTokenVO.tokenId, 4) : '--'}`}
                leftCompnent={
                  <LeftComponent
                    rank={item.nftTokenVO ? item.nftTokenVO.rank : 0}
                    prevSalePrice={item.lastPrice ? item.lastPrice : enptyValume}
                  />
                }
                rightCompnent={
                  <RightCompnent
                    createTime={item.dataTime}
                    increase={item.lastPrice ? item.lastPrice.increase : false}
                    percent={item.lastPrice ? item.lastPrice.percent : 0}
                    value={item.lastPrice ? item.lastPrice.value : 0}
                    price={item.price ? item.price : 0}
                    royalty={item.royalty ? item.royalty : 0}
                  />
                }
              />
            ))}
          {!isLoading && !sales.length && <NoData isLoading={false} msg="No sales in the project" />}
          {isLoading && <LoadingItemWrapper height={126} count={12} />}
        </ListingLoadWrapper>
      </HoverWrapper>
    </Container>
  )
}
