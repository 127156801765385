import * as echarts from 'echarts'
import { ChartBgColor, tooltipBg } from './types'
import { timeFormate, unitTime, toNonExponential, valumeUnit, getYAxisMax } from '@/utils/tools'
import { eth } from '@/assets/img'

export const ListingUnderFloorOption = (floors: Array<string | number>, dates: Array<string | number>): any => {
  const fArr: Array<number> = []
  const xAxisData: Array<number> = []
  floors.forEach((f: string | number, i: number) => {
    fArr.push(Number(f))
    xAxisData.push(i)
  })
  const dArr: Array<number> = []
  const yAxisData: Array<number> = []
  dates.forEach((f: string | number, i: number) => {
    yAxisData.push(i)
  })
  const isUp: boolean = fArr[0] > fArr[fArr.length - 1]

  return {
    backgroundColor: ChartBgColor, // 图表背景色
    tooltip: {
      backgroundColor: tooltipBg,
      borderRadius: 12,
      borderWidth: 0,
      padding: 0,
      borderColor: 'transparent',

      trigger: 'axis',
      position: [-260, -20],
      axisPointer: {
        type: 'shadow'
      },
      formatter(value: any) {
        let temp = ''
        if (value && value.length > 0) {
          const time = unitTime(value[0].name)
          temp = `
            <div id="custom_tooltip_container">
              <div id="tooltip" >${time}</div>
              <div id="tooltip" >Floor: Ξ ${valumeUnit(value[0].value)}</div>
            </div>
          `
        }
        return temp
      }
    },
    xAxis: {
      show: false,
      data: dates
    },
    yAxis: {
      show: false,
      type: 'value'
    },
    grid: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0
    },
    series: [
      {
        name: '',
        type: 'line',
        smooth: true,
        lineStyle: {
          width: 2,
          color: !isUp ? '#34C77B' : '#EB484C'
        },
        showSymbol: false,
        center: ['50%', '50%'],
        areaStyle: {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 0.4, [
            {
              offset: 0,
              color: !isUp ? 'rgba(52, 199, 123, 0.6)' : 'rgba(235, 72, 76, 0.6)'
            },
            {
              offset: 1,
              color: !isUp ? 'rgba(52, 199, 123, 0)' : 'rgba(235, 72, 76, 0)'
            }
          ])
        },
        data: fArr
      }
    ]
  }
}
