import { useEffect, useState } from 'react'
import ReactGA from 'react-ga'

export const useGoogleAnalytics = () => {
  const [init, setInit] = useState(false)
  useEffect(() => {
    if (process.env.REACT_APP_PEWEE_ENV === 'dev' || process.env.REACT_APP_PEWEE_ENV === 'pre') {
      ReactGA.initialize('UA-236887470-2')
    } else {
      ReactGA.initialize('UA-236887470-3')
    }
    setInit(true)
  }, [])
  return {
    init
  }
}
