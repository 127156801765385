import React from 'react'
import { Col, ContentWrapper } from '@/components/Layout/Layout'
import { MKTCAP } from './MKTCAP'
import { Traders } from './Traders'
import { GasBurn } from './GasBurn'
import { Top20Collection } from './Top20Collection'
import { Box, Grid } from '@/packages'
import { InvitationEntrance } from '@/views/Invite/components'

export const MKT = () => {
  return (
    <ContentWrapper>
      <Col className="w100 ">
        {process.env.REACT_APP_PEWEE_ENV !== 'prod' && (
          <Box mb={['24px', '32px', '42px', '42px']}>
            <InvitationEntrance />
          </Box>
        )}

        <Grid className="w100" gridTemplateColumns={['1fr', '1fr', '1fr', '1fr 1fr']} gridColumnGap={[0, 0, 0, 24]}>
          <MKTCAP />
          <GasBurn />
        </Grid>
        <Traders />
        <Top20Collection />
      </Col>
    </ContentWrapper>
  )
}
