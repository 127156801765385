import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { ChartTitle, Col, ChartContainer, Row } from '@/components/Layout/Layout'
import { Box, Grid, Text, Image } from '@/packages/'
import { Chart } from '@/components/Charts/Chart'
import { Top20CollectionApi } from '@/request/Api'
import { Top20CollectionOptions } from '@/components/Charts/options/index'
import { beyondStr, toNonExponential } from '@/utils/tools'
import { eth, isOS } from '@/assets/img'
import { colorBy } from '@/components/Charts/options/Top20CollectionOptions'
import { NoData } from '@/components/NoData'

interface iNFT {
  bannerImageUrl: string
  createDate: number
  discordUrl: string
  floorPrice: number
  imageUrl?: string
  largeImageUrl: string
  name?: string
  nftAddresses: string
  nftId: number
  officialUrl: string
  openSeaRoyalties: number
  revealed: number
  royalties: number
  slug: string
  totalSales: number
  totalVolume: number
  twitterUsername: string
  verifyStatus: number
}

const BarContainer = styled(Box)<{ w: number; bg: string }>`
  background: ${({ bg }) => bg};
  width: ${({ w }) => `${w * 100}%`};
  height: 26px;
  position: relative;
  border-radius: 6px;
`

const BarWrapper = styled(Box)`
  height: 26px;
`
const ValumeBar: React.FC<{
  imageUrl: string
  name: string
  percent: number
  volume: number
  No: number
  verifyStatus?: number
  onClick: () => void
}> = ({ imageUrl, name, percent, volume, No, verifyStatus, onClick }) => {
  return (
    <Grid
      gridTemplateColumns={['1fr 3.5fr 5fr']}
      px="24px"
      height="48px"
      background="rgb(36, 36, 36)"
      className="w100 fx-row ai-ct"
      style={{ border: '1px solid rgba(255, 255, 255, 0.04)' }}
    >
      <Text color="rgba(255, 255, 255, 0.87)" fontSize="14px">{`${No + 1}.`}</Text>
      <Box className="w100 fx-row ai-ct click" onClick={onClick}>
        <Image src={imageUrl} width={38} height={38} className="circle" />
        <Text color="rgba(255, 255, 255, 0.87)" fontSize="14px" ml="10px" title={name} className="click beyondHide">
          {beyondStr(name, 16)}
        </Text>
        {verifyStatus === 1 && <Image src={isOS} width={16} height={16} className="ml4" />}
      </Box>
      <BarWrapper className="w100">
        <BarContainer className="center" w={percent} bg={No > 4 ? colorBy[5] : colorBy[No]}>
          <Row style={{ position: 'absolute', left: 32 }}>
            <Image src={eth} height={10} width={6} />
            <Text color="rgba(255, 255, 255, 0.87)" fontSize="14px" ml="4px">
              {toNonExponential(volume)}
            </Text>
          </Row>
        </BarContainer>
      </BarWrapper>
    </Grid>
  )
}

export const Top20Collection = () => {
  const [chartList, setChartList] = useState<any>()
  const [chartVolList, setCharVoltList] = useState<any>()
  const [data, setData] = useState<any>({
    nfts: [],
    volumes: [],
    percents: []
  })
  const [isLoading, setLoading] = useState<boolean>(true)

  const navigate = useNavigate()

  useEffect(() => {
    const fetch = async () => {
      setLoading(true)
      const d: any = await Top20CollectionApi()
      setLoading(false)
      if (d) {
        const { nfts, percents, volumes } = d
        const copynfts = JSON.parse(JSON.stringify(nfts))
        const copynfts2 = JSON.parse(JSON.stringify(nfts))
        const copypercents = JSON.parse(JSON.stringify(percents))
        const copypercents2 = JSON.parse(JSON.stringify(percents))
        const copyvolumes = JSON.parse(JSON.stringify(volumes))
        const copyvolumes2 = JSON.parse(JSON.stringify(volumes))

        const _nfts = copynfts.splice(0, 5)
        const _percents = copypercents.splice(0, 5)
        const _volumes = copyvolumes.splice(0, 5)
        const _d = {
          nfts: _nfts,
          percents: _percents,
          volumes: _volumes
        }
        // const _nfts1 = copynfts2.splice(0, 10)
        // const _percents1 = copypercents2.splice(0, 10)
        // const _volumes1 = copyvolumes2.splice(0, 10)
        const _d1 = {
          nfts: copynfts2,
          percents: copypercents2,
          volumes: copyvolumes2
        }
        // setData(d)
        setData(_d1)
        setChartList(Top20CollectionOptions(_d1))
        // setCharVoltList(Top20VolumesOptions(_d))
      }
    }
    fetch()
  }, [])
  const onClickPie = (pie: any) => {
    const targetPie = data.nfts.find((nft: any) => nft.name === pie.name)
    const { nftId } = targetPie
    nftId && navigate(`/trending/liveview/${nftId}`)
  }

  return (
    <Box className="fx-col body_wrapper " mt="24px" height="500px" mb="24px">
      <Grid gridTemplateColumns={['1fr', '1fr', '1fr', '1fr 1fr']}>
        <Box className="w100 center ">
          <Box className="" height="300px" width="300px">
            {chartList ? (
              <Chart option={chartList} jumpable onClickChart={(val: any) => onClickPie(val)} />
            ) : (
              <NoData isLoading={isLoading} />
            )}
          </Box>
        </Box>
        <Col className="fx ai-ct " mt="24px">
          <Text color="rgba(255, 255, 255, 0.87)" fontSize="24px" fontWeight={600}>
            24H Volume of Top 20 Collection
          </Text>
          <Grid
            gridTemplateColumns={['1fr 3.5fr 5fr']}
            px="24px"
            mt="24px"
            height="48px"
            background="rgba(255, 255, 255, 0.08)"
            style={{ borderRadius: '12px 12px 0px 0px' }}
            className="w100 fx-row ai-ct"
          >
            <Text color="rgba(255, 255, 255, 0.87)" fontSize="12px" fontWeight={600}>
              NO.
            </Text>
            <Text color="rgba(255, 255, 255, 0.87)" fontSize="12px" fontWeight={600}>
              COLLECTION
            </Text>
            <Text color="rgba(255, 255, 255, 0.87)" fontSize="12px" fontWeight={600}>
              VOLUME
            </Text>
          </Grid>
          <Box height="368px" className="hide_scrollbar w100" style={{ overflow: 'scroll' }}>
            {data.nfts &&
              !!data.nfts.length &&
              data.nfts.map((nft: iNFT, idx: number) => (
                <ValumeBar
                  onClick={() => onClickPie(nft)}
                  verifyStatus={nft?.verifyStatus}
                  No={idx}
                  imageUrl={nft?.imageUrl || ''}
                  name={nft?.name || ''}
                  volume={data.volumes[idx]}
                  percent={data.percents[idx]}
                  key={Math.random().toString()}
                />
              ))}
            {!data.nfts.length && <NoData isLoading={isLoading} />}
          </Box>
        </Col>
      </Grid>
    </Box>
  )
}
