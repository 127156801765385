import React, { useEffect, useState, useCallback, useRef } from 'react'
import { Table as AntTable, Spin } from 'antd'
import { Box, Text, Image } from '@/packages'
import { useAppContext } from '@/hooks/useAppContext'

import { toNonExponential } from '@/utils/tools'
import { Row, PeweeTooltip, ETHValue, ContentWrapper, CardTitle } from '@/components/Layout/Layout'
import { TrendingCollectionsApi } from '@/request/Api'
import { TabListSelected, iTabItem } from '@/components/TabListSelected'
import { CollectionItem } from '../Dashboard/Collection/components'
import { TrendingVolume, iTrendingFloor, TrendingFloor } from '@/components/Table/Table'
import { tooltip, back2Top, scrollLoading } from '@/assets/img'
import { TableLoadMore } from '@/components/TableLoadMore'
import { NoData } from '@/components/NoData'
import { TitleDesc } from '@/views/Dashboard/component'
import { useMatchBreakpoints } from '@/hooks/useMatchBreakpoints'
import { InvitationEntrance } from '@/views/Invite/components'

interface iLegend {
  name: string
  title: string
}
export const Legend: React.FC<iLegend> = ({ name, title }) => {
  return (
    <Box className="fx ai-ct jc-end">
      <Text className="click fz12 fw700" color="#fff">
        {name}
      </Text>
      <PeweeTooltip title={title}>
        <Image src={tooltip} height={16} width={16} className="ml6 click" />
      </PeweeTooltip>
    </Box>
  )
}

const ChartTitle: React.FC<{ name: string }> = ({ name }) => {
  return (
    <Box className="bd1 w100 center">
      <Text className="fz12 fw700 white87">{name}</Text>
    </Box>
  )
}

export const Trending = () => {
  const { state, dispatch } = useAppContext()
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [granularity, setGranularity] = useState<string>('1d')
  const [collectionList, setCollectionList] = useState<Array<any>>([])
  const [isLoading, setLoading] = useState(true)
  const { isMobile, isTablet } = useMatchBreakpoints()

  const [period, setPeriod] = useState<Array<iTabItem>>([
    { id: 0, name: '1m', active: false },
    { id: 1, name: '5m', active: false },
    { id: 2, name: '15m', active: false },
    { id: 3, name: '30m', active: false },
    { id: 4, name: '1h', active: false },
    { id: 5, name: '6h', active: false },
    { id: 6, name: '1d', active: true },
    { id: 7, name: '7d', active: false },
    { id: 8, name: '30d', active: false }
  ])
  const tRef: any = useRef()

  let columns: any = []
  if (isMobile || isTablet) {
    columns = [
      {
        title: 'COLLECTION',
        width: '45%',
        render: (item: any) => (
          <CollectionItem
            customComp={
              <Text className="fx-row ai-ct beyondHide fz12 fw400" color="#2FA8CC">
                NBCP: {item.nbcp ? `${toNonExponential(item.nbcp * 100, 2)}%` : '--'}
              </Text>
            }
            {...item.collection}
          />
        )
      },
      {
        title: 'FLOOR',
        width: '30%',
        dataIndex: 'floor',
        render: (item: number) => (
          <Box className="w100 fx jc-end">
            <ETHValue value={toNonExponential(item, 3)} />
          </Box>
        )
      },
      {
        title: 'TRADES',
        width: '25%',
        dataIndex: 'trades',
        render: (item: number) => (
          <Box className="w100 fx jc-end">
            <Text className="fz14 fw400 white87">{item}</Text>
          </Box>
        )
      }
    ]
  } else {
    columns = [
      {
        title: 'COLLECTION',
        dataIndex: 'collection',
        width: '20%',
        render: (item: any) => <CollectionItem {...item} />
      },
      {
        title: 'FLOOR PRICE',
        dataIndex: 'floor',
        render: (item: number) => (
          <Box className="w100 fx jc-end">
            <ETHValue value={toNonExponential(item)} />
          </Box>
        )
      },
      {
        title: 'FLOOR',
        dataIndex: 'dateFloors',
        render: (item: iTrendingFloor) => (
          <Box className="w100 " ml="80px">
            {item ? <TrendingFloor {...item} /> : <div>--</div>}
          </Box>
        )
      },
      {
        title: 'AVG PRICE',
        dataIndex: 'avgPrice',
        render: (item: number) => (
          <Box className="w100 fx jc-end">
            <ETHValue value={toNonExponential(item)} />
          </Box>
        )
      },
      {
        title: 'TRADES',
        dataIndex: 'trades',
        render: (item: number) => (
          <Box className="w100 fx jc-end">
            <Text className="white87 fz16 fw500">{item}</Text>
          </Box>
        )
      },
      {
        title: 'VOLUME',
        dataIndex: 'dateVolumes',
        render: (item: iTrendingFloor) => (
          <Box className="w100 fx jc-end" ml="20px">
            {item ? <TrendingVolume {...item} /> : <div>--</div>}
          </Box>
        )
      },
      {
        title: (
          <Legend
            name="NBCP"
            title="Next Blue Chip Probability：Percentage of owners of this collection who have at least one Blue Chip NFT. The NBCP displayed here is refreshed daily."
          />
        ),
        dataIndex: 'nbcp',
        render: (item: number) => (
          <Box className="w100 fx jc-end">
            <Text className="white87 fz16 fw500">{item ? `${toNonExponential(item * 100, 2)}%` : '--'}</Text>
          </Box>
        )
      }
    ]
  }

  const fetchList = async () => {
    setLoading(true)
    const p = {
      pageIndex,
      pageSize: 20,
      granularity
    }
    const d: any = await TrendingCollectionsApi(p)
    setLoading(false)
    if (d && d.records && d.records.length > 0) {
      const recordList = d.records
      const combime = [...collectionList, ...recordList]
      const temp: any = {}
      const newCombime = combime.reduce((prev: any, cur: any) => {
        temp[cur.collection.name] ? '' : (temp[cur.collection.name] = true && prev.push(cur))
        return prev
      }, [])
      setCollectionList(newCombime)
    }
  }

  useEffect(() => {
    fetchList()
  }, [granularity, pageIndex])

  const onTableBottom = () => {
    if (!isLoading) {
      setPageIndex(pageIndex + 1)
    }
  }
  const onChangePeriod = (val: iTabItem) => {
    const _period = JSON.parse(JSON.stringify(period))
    _period.forEach((_p: iTabItem) => {
      _p.active = _p.name === val.name
    })
    setPeriod(_period)
    setGranularity(val.name)
    setPageIndex(1)
    setCollectionList([])
    scrollToTop()
  }

  const scrollToTop = () => {
    tRef.current && tRef.current.scrollToTop()
  }
  const handleTableChange = (pagination: any, filters: any, sorter: any, extra: any) => {
    // const p = JSON.parse(JSON.stringify(query))
    // const { field, order } = sorter // order:"ascend"=> up "descend" => down
    // p.order.column = field
    // p.order.asc = order === 'ascend'
    // setQuery(p)
  }

  return (
    <ContentWrapper>
      <Box className="w100">
        {process.env.REACT_APP_PEWEE_ENV !== 'prod' && (
          <Box mb={['24px', '32px', '42px', '42px']}>
            <InvitationEntrance />
          </Box>
        )}
        <TitleDesc title="Trending Collections" subTitle="Search at the top of the page to find your collection." />
        {isMobile || isTablet ? (
          <TabListSelected ml={2} ftsz={12} list={period} onChange={onChangePeriod} selectedList={false} />
        ) : (
          <Box className="fx-row jc-end ai-ct w100" height="40px">
            <Text color="#fff" fontSize="12px" mr="10px">
              Period:
            </Text>
            <TabListSelected ml={10} ftsz={12} list={period} onChange={onChangePeriod} selectedList={false} />
          </Box>
        )}
        <Box mb={isMobile || isTablet ? '60px' : '60px'}>
          <TableLoadMore onTableBottom={onTableBottom} tRef={tRef} height="650px">
            <>
              <AntTable
                locale={{ emptyText: ' ' }}
                rowKey={(record) => record?.collection?.nftId}
                pagination={false}
                columns={columns}
                dataSource={collectionList}
                onChange={handleTableChange}
                scroll={{ y: 650, scrollToFirstRowOnChange: true }}
              />
              {!collectionList.length && <NoData isLoading={isLoading} />}
            </>
          </TableLoadMore>
          {(isMobile || isTablet) && pageIndex > 1 && isLoading && (
            <Box
              className="center w100 "
              mt="50px"
              height="72px"
              borderBottomLeftRadius="12px"
              borderBottomRightRadius="12px"
              background="rgb(24,24,27)"
            >
              <Image src={scrollLoading} width={15} height={15} className="loading_status" />
            </Box>
          )}

          {(isMobile || isTablet) && pageIndex > 1 && (
            <Box className="click" style={{ position: 'absolute', bottom: 50, right: 10 }} onClick={scrollToTop}>
              <Image src={back2Top} height={40} width={40} />
            </Box>
          )}
        </Box>
      </Box>
    </ContentWrapper>
  )
}
