import React, { useState, useEffect, useRef, useCallback } from 'react'
import styled from 'styled-components'
import { Table as AntTable, Spin } from 'antd'
import useId from '@mui/material/utils/useId'
import { Box, Text, Image } from '@/packages/'
import { CollectionItem, AlignRightCell, AlertPriceCell, WatchListCollectionMobile } from './components'
import { WatchListV2Api, WatchListAlertApi, CancelWatchListAlertApi, NftInfoAttentionApi } from '@/request/Api'
import { iWatchListV2Prams } from '@/request/types'
import { IncreaseText, iText } from '@/components/IncreaseText'
import { Chart } from '@/components/Charts/Chart'
import { LiveView7DayVolumeOption } from '@/components/Charts/options/index'
import { Row, Col } from '@/components/Layout/Layout'
import { toNonExponential, addId4Array, addVisible4WatchList, str2NumArray } from '@/utils/tools'
import { useAppContext } from '@/hooks/useAppContext'
import { alert, alertActive, addAlertPrice, starActiveIcon } from '@/assets/img'
import { SwitchButton } from '@/components/SwitchButton'
import { NoData } from '@/components/NoData'
import { useMatchBreakpoints } from '@/hooks/useMatchBreakpoints'
import { OpenNotification } from '@/components/OpenNotification'

export const WatchList = () => {
  const [list, setList] = useState<Array<any>>([])
  const [query, setQuery] = useState<iWatchListV2Prams>({
    pageIndex: 1,
    pageSize: 20,
    keyword: '',
    granularity: '',
    order: {
      asc: false,
      column: 'sales' // floor, sales,
    }
  })
  const [isLoading, setLoading] = useState<boolean>(true)
  const listRef: any = useRef()

  const { state, dispatch } = useAppContext()
  const { isMobile, isTablet } = useMatchBreakpoints()
  let columns: any = []

  if (isMobile || isTablet) {
    columns = [
      {
        title: 'COLLECTION',
        render: (item: any) => {
          return (
            <WatchListCollectionMobile
              {...item}
              subTitle="Deployed"
              onMobileOpenAlert={onOpenAlert}
              fetchWatchList={fetch}
            >
              <Image
                src={starActiveIcon}
                height={16}
                width={16}
                className="click mr6"
                onClick={() => onCancelWatch(item)}
              />
            </WatchListCollectionMobile>
          )
        }
      }
    ]
  } else {
    columns = [
      {
        title: 'COLLECTION',
        dataIndex: 'collection',
        width: '22%',
        render: (item: any) => (
          <CollectionItem {...item} subTitle="Deployed" hideOfficialUrl={false}>
            <Image
              src={starActiveIcon}
              height={16}
              width={16}
              className="click mr24"
              onClick={() => onCancelWatch(item)}
            />
          </CollectionItem>
        )
      },
      {
        title: 'FLOOR',
        dataIndex: 'floor',
        render: (item: number) => <AlignRightCell value={`Ξ ${toNonExponential(item)}`} />,
        sorter: true,
        sortDirections: ['descend' as 'descend', 'ascend' as 'ascend', 'descend' as 'descend'],
        showSorterTooltip: false
      },
      {
        title: 'SALES',
        dataIndex: 'sales',
        render: (item: number) => <AlignRightCell value={item} />,
        sorter: true,
        defaultSortOrder: 'descend' as 'descend',
        sortDirections: ['descend' as 'descend', 'ascend' as 'ascend', 'descend' as 'descend'],
        showSorterTooltip: false
      },
      {
        title: '7D VOLUME',
        dataIndex: 'volumes',
        render: (item: Array<number | string>) =>
          item && item.every((i: number | string) => Number(i) === 0) ? (
            <Box className="w100 fx jc-end">
              <Text color="#FFFFFF">--</Text>
            </Box>
          ) : (
            <Box className="w100 fx jc-end">
              <Box width="80px" height="40px" className="">
                <Chart option={LiveView7DayVolumeOption(str2NumArray(item))} />
              </Box>
            </Box>
          )
      },
      {
        title: 'ALERT OFF/ON',
        render: (_: any, record: any) => {
          return (
            <Box className="w100 fx jc-end ">
              <SwitchButton title="" onChange={(v: boolean) => onOpenAlert(v, record)} defaultStatus={!!_.alert} />
            </Box>
          )
        }
      },
      {
        title: 'ALERT PRICE',
        render: (item: any) => {
          const { collection, alert: _alert, visible: _visible } = item
          return <AlertPriceCell visible={_visible} alert={_alert} nftId={collection.nftId} />
        }
      }
    ]
  }

  const cancelAlert = async (nftId: number) => {
    // 取消Alert 需要告诉后端  该项目不再推送
    const d: any = await CancelWatchListAlertApi(nftId)
    if (d) {
      OpenNotification('Alert off', 'success')
    }
  }

  const onOpenAlert = useCallback(
    (isOpen: boolean, record: any) => {
      listRef.current = list
      if (listRef.current && !!listRef.current.length) {
        listRef.current.forEach((_l: any, _i: number) => {
          if (record.id === _i) {
            if (isOpen) {
              _l.visible = true
            } else {
              _l.visible = false
              cancelAlert(record.collection.nftId)
            }
          }
        })
        setList([...listRef.current])
      }
    },
    [list]
  )

  const onCancelWatch = async (r: any) => {
    listRef.current = list
    const idx = listRef.current.findIndex((f: any) => f.collection.name === r.name)
    listRef.current.splice(idx, 1)
    // 取消关注
    const d: any = await NftInfoAttentionApi(r.nftId, 0)
    if (d) {
      setList([...listRef.current])
    }
  }

  useEffect(() => {
    if (state.DashbordReducer.period) {
      const { tabName, watchListPeriod } = state.DashbordReducer.period
      if (tabName === 'WatchList') {
        setQuery((q: iWatchListV2Prams) => ({ ...q, granularity: watchListPeriod }))
      }
    }
  }, [state?.DashbordReducer?.period])

  useEffect(() => {
    if (state.DashbordReducer.refreshTable) {
      const { activeTable } = state.DashbordReducer.refreshTable
      activeTable === 'WatchList' && fetch()
    }
  }, [state?.DashbordReducer?.refreshTable])

  const fetch = async () => {
    if (query.granularity) {
      setLoading(true)
      setList([])
      const d: any = await WatchListV2Api(query)
      setLoading(false)
      d && d.records && !!d.records.length && setList(addVisible4WatchList(addId4Array(d.records)))
    }
  }

  useEffect(() => {
    fetch()
  }, [query])

  const handleTableChange = (pagination: any, filters: any, sorter: any, extra: any) => {
    // console.log('extra', extra)
    const p = JSON.parse(JSON.stringify(query))
    const { field, order } = sorter // order:"ascend"=> up "descend" => down
    p.order.column = field
    p.order.asc = order === 'ascend'
    setQuery(p)
  }

  return (
    <Box className="w100" height="420px" background="rgba(255, 255, 255, 0.04)">
      <AntTable
        locale={{ emptyText: ' ' }}
        rowKey={(record) => record.id}
        pagination={false}
        columns={columns}
        dataSource={list}
        onChange={handleTableChange}
        style={{ marginTop: 20 }}
        scroll={{ y: 370 }}
      />
      {!list.length && <NoData isLoading={isLoading} />}
    </Box>
  )
}
