/* eslint-disable no-undef */
import React, { useEffect, useState, useImperativeHandle, useRef } from 'react'
import styled from 'styled-components'
import { Table as AntTable, Spin } from 'antd'
import { Box, Text, Image } from '@/packages/'
import { MintDateCell, MintDateCellMobile } from './components/MintDateCell'
import { UpcomingNoData } from './components/UpcomingNoData'

import { toNonExponential, addId4Array, valumeUnit } from '@/utils/tools'
import { Row, Col, PeweeTooltip, ETHValue } from '@/components/Layout/Layout'
import { iUpcomingListParams } from '@/request/types'
import { useAppContext } from '@/hooks/useAppContext'
import { UpcomingListApi } from '@/request/Api'
import { TableLoadMore } from '@/components/TableLoadMore'
import { useMatchBreakpoints } from '@/hooks/useMatchBreakpoints'

import {
  tooltip,
  editCell,
  discordActive,
  discord,
  twitterActive,
  twitterUrl,
  website,
  websiteActive
} from '@/assets/img'
import { CollectionItem, ChangeCell, AlignRightCell } from '../Dashboard/Collection/components'
import { ActiveIcon } from '@/components/ActiveIcon'
import { NoData } from '@/components/NoData'

const Wrappers = styled.div``
interface iLegend {
  name: string
  title: string
}

export const Legend: React.FC<iLegend> = ({ name, title }) => {
  return (
    <Wrappers className="fx ai-ct jc-end">
      <Text className=" click fw700" color="#fff" fontSize="12px" textAlign="right">
        {name}
      </Text>
      <PeweeTooltip title={title}>
        <Image src={tooltip} height={16} width={16} className="ml6 click" />
      </PeweeTooltip>
    </Wrappers>
  )
}

interface iSocialCell {
  valueNum: number
  increase: boolean
  percent: number
}
const SocialCell: React.FC<iSocialCell> = ({ valueNum, increase, percent }) => {
  return (
    <Col>
      <Text className="white87 fz16 fw500" textAlign="right">
        {valueNum || valueNum === 0 ? valueNum : '--'}
      </Text>
      {increase !== null && increase !== undefined && <ChangeCell increase={increase} percent={percent} />}
    </Col>
  )
}
interface iUpcomingTable {
  onVisibleModal: (type: string, r?: any) => void
  uRef?: any
}
export const UpcomingTable: React.FC<iUpcomingTable> = ({ onVisibleModal, uRef }) => {
  const [list, setList] = useState<Array<any>>([])
  const [query, setQuery] = useState<iUpcomingListParams>({
    pageIndex: 1,
    pageSize: 20,
    order: {
      asc: false,
      column: 'mintTime' //  twitterFollowers twitterWhales discordNum supply mintPrice mintTime
    }
  })
  const { isMobile, isTablet } = useMatchBreakpoints()
  const [isLoading, setLoading] = useState<boolean>(true)
  const tRef: any = useRef()

  let columns: any = []
  if (isMobile || isTablet) {
    columns = [
      {
        title: 'PROJECT',
        width: isMobile || isTablet ? '40%' : '20%',
        render: (item: any) => <CollectionItem {...item} />
      },
      {
        title: 'DROP DATE',
        render: (item: any) =>
          isMobile || isTablet ? <MintDateCellMobile record={item} /> : <MintDateCell value={item.mintTime} />,
        sorter: true,
        sortDirections: ['descend' as 'descend', 'ascend' as 'ascend', 'descend' as 'descend'],
        defaultSortOrder: 'descend' as 'descend',
        showSorterTooltip: false
      }
    ]
  } else {
    columns = [
      {
        title: 'PROJECT',
        width: '20%',
        render: (item: any) => (
          <CollectionItem
            {...item}
            customComp={
              <Row mt="4px">
                <ActiveIcon
                  w={16.6}
                  h={16.6}
                  src={website}
                  activeSrc={websiteActive}
                  targetUrl={item.website}
                  tooltip="Website"
                />
                <ActiveIcon
                  w={18}
                  h={15.4}
                  src={twitterUrl}
                  activeSrc={twitterActive}
                  targetUrl={item.twitter}
                  tooltip="Twitter"
                />
                <ActiveIcon
                  w={18.4}
                  h={16}
                  src={discord}
                  activeSrc={discordActive}
                  targetUrl={item.discord}
                  tooltip="Discord"
                />
              </Row>
            }
          />
        )
      },
      {
        title: (
          <Legend
            name="TWITTER FOLLOWERS"
            title="The number of followers of the project twitter, the percentage increase compared to yesterday."
          />
        ),
        dataIndex: 'twitterFollowers',
        render: (item: any) => {
          const { beforeValue, increase, percent, value } = item
          return <SocialCell valueNum={value} increase={increase} percent={percent || 0} />
        },
        sorter: true,
        sortDirections: ['descend' as 'descend', 'ascend' as 'ascend', 'descend' as 'descend'],
        showSorterTooltip: false
      },
      {
        title: <Legend name="WHALES" title="The number of whale followers on  Twitter." />,
        dataIndex: 'twitterWhales',
        render: (item: any) => {
          const { beforeValue, increase, percent, value } = item
          return <SocialCell valueNum={value} increase={increase} percent={percent || 0} />
        },
        sorter: true,

        sortDirections: ['descend' as 'descend', 'ascend' as 'ascend', 'descend' as 'descend'],
        showSorterTooltip: false
      },
      {
        title: 'DISCORD',
        dataIndex: 'discordNum',
        render: (item: any) => {
          const { beforeValue, increase, percent, value } = item
          return <SocialCell valueNum={value} increase={increase} percent={percent || 0} />
        },
        sorter: true,
        sortDirections: ['descend' as 'descend', 'ascend' as 'ascend', 'descend' as 'descend'],
        showSorterTooltip: false
      },
      {
        title: 'SUPPLY',
        dataIndex: 'supply',
        render: (item: number) => <AlignRightCell value={item !== null ? valumeUnit(item) : 'TBA'} />,
        sorter: true,
        sortDirections: ['descend' as 'descend', 'ascend' as 'ascend', 'descend' as 'descend'],
        showSorterTooltip: false
      },
      {
        title: <Legend name="MINT PRICE" title="Public Sale Price." />,
        dataIndex: 'mintPrice',
        sorter: true,
        sortDirections: ['descend' as 'descend', 'ascend' as 'ascend', 'descend' as 'descend'],
        showSorterTooltip: false,
        render: (item: number) => <AlignRightCell value={item !== null ? `Ξ ${toNonExponential(item)}` : 'TBA'} />
      },
      {
        title: 'DROP DATE',
        dataIndex: 'mintTime',
        render: (item: number) => <MintDateCell value={item} />,
        sorter: true,
        defaultSortOrder: 'descend' as 'descend',
        sortDirections: ['descend' as 'descend', 'ascend' as 'ascend', 'descend' as 'descend'],
        showSorterTooltip: false
      },
      {
        title: '',
        render: (_: any, record: any) => (
          <PeweeTooltip
            placement="bottomRight"
            title="If you think the information is incorrect, you can simply edit and submit it, and we will update the correct information after confirmation. Note: We will record the number of correct and incorrect submissions."
          >
            <Image src={editCell} width={40} height={40} className="click" onClick={() => onEdit(record)} />
          </PeweeTooltip>
        )
      }
    ]
  }

  useEffect(() => {
    fetch()
  }, [query])

  const fetch = async () => {
    setLoading(true)
    const d: any = await UpcomingListApi(query)
    setLoading(false)
    if (d && d.records && d.records.length > 0) {
      const recordList = d.records
      const combime = [...list, ...recordList]
      setList(combime)
    }
  }

  const onEdit = (record: any) => {
    onVisibleModal('edit', record)
  }
  const handleTableChange = (pagination: any, filters: any, sorter: any, extra: any) => {
    const { field, order } = sorter // order:"ascend"=> up "descend" => down
    setList([])
    setQuery((q: iUpcomingListParams) => ({
      ...q,
      pageIndex: 1,
      order: {
        column: isMobile || isTablet ? 'mintTime' : field,
        asc: order === 'ascend'
      }
    }))
  }

  useImperativeHandle(uRef, () => ({
    refreshList: () => {
      setList([])
      setQuery((q: iUpcomingListParams) => ({ ...q, pageIndex: 1 }))
    }
  }))

  const onTableBottom = () => {
    if (!isLoading) {
      setQuery((q: iUpcomingListParams) => ({ ...q, pageIndex: q.pageIndex + 1 }))
    }
  }

  return (
    <Box className="w100" background="rgba(255, 255, 255, 0.04)" mt="20px">
      <TableLoadMore onTableBottom={onTableBottom} tRef={tRef} height="650px">
        <>
          <AntTable
            locale={{ emptyText: ' ' }}
            rowKey={(record) => record.id}
            pagination={false}
            columns={columns}
            dataSource={list}
            className="upcomint_table"
            onChange={handleTableChange}
            scroll={{ y: 580, scrollToFirstRowOnChange: true }} // 540-470=70  650-70=580
          />
          {!list.length && <NoData isLoading={isLoading} />}
        </>
      </TableLoadMore>
    </Box>
  )
}
