import * as echarts from 'echarts'
import { line1Legend } from '@/assets/img'
import { ChartBgColor, LegendStyle, axisLabel, grid, axisLine, axisTick, splitLine, lineColor2 } from './types'

export const UniqueWalletsOption: any = {
  backgroundColor: ChartBgColor, // 图表背景色
  toolbox: {
    show: false
  },
  tooltip: {
    trigger: 'item',
    borderColor: '#464559',
    padding: 0,
    formatter(params: any) {
      const str = `
      <div style="padding: 10px;color: #E0E3E3;font-size: 14px;display: flex; flex-direction: column;
      background-color: #464559;border-radius: 2px;">
        <span>${params.name}</span>
        <span>Unique Wallets ${params.data}</span>
    </div>`
      return str
    }
  },
  xAxis: {
    name: '',
    data: ['3.20', '3.21', '3.22', '3.23', '3.24'],
    axisLabel,
    axisLine,
    axisTick
  },
  yAxis: {
    splitLine,
    axisLabel
  },
  grid,
  series: [
    {
      data: [150, 230, 224, 218, 135, 147, 260],
      type: 'line',
      color: lineColor2,
      areaStyle: {
        normal: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: '#C4C0FF' },
            { offset: 0.3, color: '#C4C0FF' },
            { offset: 1, color: 'black' }
          ])
        }
      }, // 填充区域样式
      lineStyle: {
        color: lineColor2,
        width: 2
      }, // 线条的样式
      itemStyle: {
        color: 'rgb(255, 70, 131)',
        opacity: 0 // 为0不会绘制图形拐点消失
      }
    }
  ]
}
