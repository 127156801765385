import React from 'react'
import { Row, CardTitle } from '@/components/Layout/Layout'
import { Text } from '@/packages'
import { TabListSelected, iTabItem } from '@/components/TabListSelected'

interface iTitleAndPeriod {
  children?: React.ReactNode
  title: string
  periodList: Array<iTabItem>
  onChangePeriod: (val: iTabItem) => void
}

export const TitleAndPeriod: React.FC<iTitleAndPeriod> = ({ children, title, periodList, onChangePeriod }) => {
  return (
    <Row className="jc-sb" mt="24px">
      <CardTitle>{title}</CardTitle>
      <Row>
        <Row mr="24px">
          <Text fontSize="12px" color="rgba(255, 255, 255, 0.87)" mr="10px">
            Period
          </Text>
          <TabListSelected ml={10} ftsz={12} list={periodList} onChange={onChangePeriod} selectedList={false} />
        </Row>
        {children}
      </Row>
    </Row>
  )
}
