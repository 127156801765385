import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { PeweeTooltip } from '@/components/Layout/Layout'
import { Flex, Box, Grid, Text, Image } from '@/packages/'
import useDebounce from '@/hooks/useDebounce'

interface iHover {
  children: React.ReactNode
  title?: string
  height?: number
  mouseOver?: () => void
  mouseLeave?: () => void
  isEmpty?: boolean
}

const PausedRefresh = styled(Box)`
  width: 100%;
  height: 32px;
  background: #17191f;
  padding-left: 12px;
`

export const HoverWrapper: React.FC<iHover> = ({ isEmpty, children, title, height, mouseOver, mouseLeave }) => {
  const [hover, setHover] = useState<boolean>(false)
  const debouncedValue = useDebounce<boolean>(hover, 500)

  const isOver = () => {
    setHover(true)
    // mouseOver && mouseOver()
  }
  const isLeave = () => {
    setHover(false)
    mouseLeave && mouseLeave()
  }
  useEffect(() => {
    if (debouncedValue) {
      mouseOver && mouseOver()
    }
  }, [debouncedValue])

  return (
    <div
      onMouseOver={isOver}
      onMouseLeave={isLeave}
      className={isEmpty ? 'click w100 body_wrapper' : 'click w100'}
      style={{ height: `${height}px` }}
      onFocus={() => 0}
    >
      {hover && title ? (
        <PausedRefresh className="fx ai-ct" mb="12px">
          <Text color="#FFE800" fontSize="12px">
            {title}
          </Text>
        </PausedRefresh>
      ) : (
        // <Box height="32px" mb="12px" />
        ''
      )}
      {children}
    </div>
  )
}
