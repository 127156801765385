import React, { useEffect, useState } from 'react'
import { Popover } from 'antd'
import { Box, Image, Text } from '@/packages/'
import { Row, Col, NFTImage, PeweeTooltip } from '@/components/Layout/Layout'
import { iNFTVO } from '@/types/store/index'
import { someTimesAgo, beyondStr } from '@/utils/tools'
import { RevealedBtn, WatchProject, AddressItem } from './NFTInfo'
import { ActiveIconMobile } from '@/components/ActiveIcon'
import {
  copyIcon,
  copyed,
  isOS,
  suspicious,
  starIcon,
  starActiveIcon,
  arrowDown,
  arrowUp,
  discordActive,
  discord,
  etherscanActive,
  etherscan,
  osActive,
  os,
  twitterActive,
  twitterUrl,
  website,
  websiteActive,
  more
} from '@/assets/img'

interface iMoreRender {
  slug: string
  officialUrl: string
  nftAddresses: string
  twitterUsername: string
  discordUrl: string
}
const MoreRender: React.FC<iMoreRender> = ({ slug, officialUrl, nftAddresses, twitterUsername, discordUrl }) => {
  return (
    <Col width="215px" borderRadius="12px" background="#2E2E2E">
      <ActiveIconMobile src={os} targetUrl={`https://opensea.io/collection/${slug}`} title="View on Opensea" />
      <ActiveIconMobile src={website} targetUrl={officialUrl} title="Website" />
      <ActiveIconMobile
        src={etherscan}
        targetUrl={`https://etherscan.io/address/${nftAddresses}`}
        title="View on Etherscan"
      />
      <ActiveIconMobile src={twitterUrl} targetUrl={`https://twitter.com/${twitterUsername}`} title="Twitter" />
      <ActiveIconMobile src={discord} targetUrl={discordUrl} title="Discord" isLast />
    </Col>
  )
}

export const NFTInfoMobile: React.FC<iNFTVO> = ({
  nftId,
  name,
  nftAddresses,
  imageUrl,
  largeImageUrl,
  bannerImageUrl,
  officialUrl,
  discordUrl,
  twitterUsername,
  createDate,
  slug,
  revealed,
  attention,
  verifyStatus,
  description,
  type,
  rankPercent,
  report
}) => {
  const handleMore = () => {}

  return (
    <Col height="234px" className="h100 fx-row ai-ct relative">
      <Row height="90px" px="16px" className=" w100 jc-sb absolute" top="0px" zIndex="9">
        <NFTImage src={imageUrl} height={90} width={90} iconHW={58} classN="circle" />
        <Col className="ai-end">
          <Row>
            <RevealedBtn
              rankPercent={Number(String(rankPercent).substring(0, 5))}
              report={report}
              revealed={revealed}
              nftId={nftId}
            />
            <Box ml="10px">
              <WatchProject watched={attention} nftId={nftId} />
            </Box>
            <Popover
              content={
                <MoreRender
                  officialUrl={officialUrl}
                  nftAddresses={nftAddresses[0]}
                  twitterUsername={twitterUsername}
                  discordUrl={discordUrl}
                  slug={slug}
                />
              }
              trigger="hover"
              placement="bottomRight"
            >
              <Box
                ml="10px"
                height="30px"
                width="30px"
                background="#2E2E2E"
                className="circle center click"
                onClick={handleMore}
              >
                <Image src={more} height={3} width={20} />
              </Box>
            </Popover>
          </Row>
          <Text mt="30px" className="fz10 fw400  white38">{`Deployed ${someTimesAgo(createDate)}`}</Text>
        </Col>
      </Row>
      <Col className="w100 body_wrapper absolute" top="45px" pt="60px" px="16px">
        <Row>
          <Text fontSize={[20, 24, 28, 32]} className="white87 beyondHide">
            {beyondStr(name, 20)}
          </Text>
          {verifyStatus && verifyStatus === 1 ? <Image src={isOS} height={16} width={16} className="ml10" /> : null}
          {type && type === 1 ? (
            <PeweeTooltip title="This is a suspicious project.">
              <Image src={suspicious} height={16} width={16} className="ml4" />
            </PeweeTooltip>
          ) : null}
        </Row>
        <AddressItem address={nftAddresses ? nftAddresses[0] : '-'} />
        <Text className="white87 fz14 beyondHide" my="20px" title={description} minWidth="42px">
          {beyondStr(description, 44)}
        </Text>
      </Col>
    </Col>
  )
}
