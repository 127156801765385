import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Checkbox, Modal } from 'antd'
import { useParams } from 'react-router-dom'
import { CloseOutlined, RedoOutlined } from '@ant-design/icons'
import { borderRadius } from 'styled-system'
import { Flex, Box, Grid, Text, Image } from '@/packages/'
import { useAppContext } from '@/hooks/useAppContext'
import { Col, Row } from '@/components/Layout/Layout'
import { iListingTrait, iTrait } from '@/request/types'
import { TraitCountApi } from '@/request/Api'
import { valumeUnit } from '@/utils/tools'
import { TraitModalAction } from '@/store/actions/LiveviewAction'
import { close } from '@/assets/img'
import { useMatchBreakpoints } from '@/hooks/useMatchBreakpoints'

const SamllBox = styled(Box)<{ active: boolean }>`
  height: 55px;
  margin-left: 8px;
  margin-top: 8px;
  border: ${({ active }) => (active ? '1px solid #00B2B5' : '')};
  padding: 0 12px;
  cursor: pointer;
  border-radius: 12px;
`

const Vertical = styled(Box)`
  width: 2px;
  height: auto;
  background: #323946;
  margin: 30px 16px 0;
`

interface iTraits {
  number: number
  percent: number
  trait: string
}
interface iListIten {
  size: number
  traitType: string
  traits: Array<iTraits>
}

interface iTraitTitle {
  title: string
  clearTitle: string
  fontSize: string
  onClear: () => void
}

const TraitTitle: React.FC<iTraitTitle> = ({ title, clearTitle, fontSize, onClear }) => {
  return (
    <Row height="38px" className="jc-sb" pl="8px">
      <Text fontSize={fontSize} color="rgba(255, 255, 255, 0.87)">
        {title}
      </Text>
      <Text fontSize="14px" fontWeight={400} color="rgba(255, 255, 255, 0.38)" onClick={onClear} className="click">
        {clearTitle}
      </Text>
    </Row>
  )
}

const SaveBtn = styled(Box)`
  border: 1px solid rgba(255, 255, 255, 0.38);
  border-radius: 20px;
  width: 128px;
  height: 30px;
`
export const Trait = () => {
  const { state, dispatch } = useAppContext()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [traitList, setTraitList] = useState<Array<iListingTrait>>([])
  const [copyList, setCopyList] = useState<Array<iListingTrait>>([])
  const [activeItem, setActiveItem] = useState(0)
  const { isMobile, isTablet } = useMatchBreakpoints()

  const params = useParams()

  const initTraitList = (list: Array<any>, activeIdx: number) => {
    list.forEach((item: any, idx: number) => {
      if (idx === activeIdx) {
        item.active = true
      } else {
        item.active = false
      }
    })
    setTraitList(list)
  }

  const fetch = async () => {
    const dataList: any = await TraitCountApi(Number(params.nftId))
    if (dataList && !!dataList.length) {
      initTraitList(dataList, 0)
      setCopyList(dataList)
    }
  }
  useEffect(() => {
    fetch()
  }, [])

  const onClearAll = () => {
    const _traitList = JSON.parse(JSON.stringify(traitList))
    _traitList.forEach((item: any) => {
      if (item.selected) item.selected = 0
      if (item.query) item.query = []
      item.traits.forEach((_c: any) => {
        if (_c.checked || _c.checked === true) _c.checked = false
      })
    })
    setTraitList(_traitList)
    TraitModalAction({ visible: true, traitQuery: [] }, dispatch)
  }

  const onClearValues = () => {
    const _traitList = JSON.parse(JSON.stringify(traitList))
    _traitList[activeItem].traits.forEach((_v: any) => {
      _v.checked = false
    })
    _traitList[activeItem].selected = 0
    _traitList[activeItem].query = []
    setTraitList(_traitList)
    // TraitModalAction({ visible: true, traitQuery: _traitList }, dispatch)
  }

  const getSelected = (traits: Array<any>, traitType: string) => {
    let counts = 0
    const queryList: Array<any> = []
    traits.forEach((t: any) => {
      if (t.checked && t.checked === true) {
        counts++
        queryList.push({ trait: t.trait, traitType })
      }
    })
    return { counts, queryList }
  }

  const onChangeChecked = (e: any, ck: boolean) => {
    const { value, checked } = e.target
    const _activeType = traitList[activeItem].traitType
    const _traitList = JSON.parse(JSON.stringify(traitList))
    _traitList[activeItem].traits.forEach((_v: any) => {
      if (_v.trait === value) {
        _v.checked = checked
      }
    })

    const { traits, traitType } = _traitList[activeItem]
    const { counts, queryList } = getSelected(traits, _activeType)
    if (traitType === _activeType) {
      _traitList[activeItem].selected = counts
      _traitList[activeItem].query = queryList
    }
    setTraitList(_traitList)
  }

  useEffect(() => {
    if (state?.LiveViewReducer?.trait) {
      setIsModalVisible(state.LiveViewReducer.trait.visible)
    }
  }, [state?.LiveViewReducer?.trait])

  const onSaveTrait = () => {
    setIsModalVisible(false)
    const q: any = []
    traitList.forEach((item: any) => {
      if (item.query && !!item.query.length) {
        q.push(...item.query)
      }
    })
    TraitModalAction({ visible: false, traitQuery: q }, dispatch)
  }
  const handleCancel = () => {
    setIsModalVisible(false)
    TraitModalAction({ visible: false, traitQuery: [] }, dispatch)
  }

  const selectTrait = (idx: number) => {
    setActiveItem(idx)
    initTraitList(traitList, idx)
  }

  const onRefresh = () => {
    setTraitList([])
    setActiveItem(0)
    fetch()
  }
  // console.log('valuesList', valuesList)
  // console.log('_traitList', traitList)

  useEffect(() => {
    return () => {
      TraitModalAction({ visible: false, traitQuery: [] }, dispatch)
    }
  }, [])

  return (
    <Modal
      closable={false}
      visible={isModalVisible}
      footer={null}
      bodyStyle={{
        maxWidth: 1212,
        width: '100%',
        height: 'auto',
        background: '#252525',
        borderRadius: 12
      }}
      className="trait_Modal"
      onCancel={onSaveTrait}
      maskClosable={false}
      width={1212}
    >
      <Box className="fx-col w100">
        <Grid gridTemplateColumns={['1fr', '1fr', '1fr', '1fr 1fr']} gridColumnGap={[0, 0, 2, 16]}>
          <Col pt="26px" pr="24px" pl="24px">
            <TraitTitle title="Select Traits" fontSize="24px" clearTitle="" onClear={onClearAll} />
            <Grid
              gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr 1fr', '1fr 1fr 1fr']}
              className={isMobile ? '' : 'max-h'}
            >
              {!!traitList.length &&
                traitList.map((item: iListingTrait, index: number) => (
                  <SamllBox
                    key={Math.random().toString()}
                    active={item.active}
                    className="fx ai-ct jc-sb bgCol"
                    onClick={() => selectTrait(index)}
                  >
                    <Text fontSize="12px" color="#fff">
                      {item.traitType}
                    </Text>
                    <Row>
                      <Text fontSize="12px" color="#fff">
                        {item.selected || 0}
                      </Text>
                      <Text fontSize="12px" color="#5F6165">
                        /{item.size}
                      </Text>
                    </Row>
                  </SamllBox>
                ))}
            </Grid>
          </Col>
          {/* <Vertical /> */}
          <Col pt="16px" className="bgCol brr12" pl="24px" pr="24px">
            <Row className="jc-end w100" px="10px">
              {/* <Box className="w100" mt="-10px">
            <RedoOutlined style={{ color: '#fff' }} className="click" onClick={onRefresh} />
          </Box> */}
              <Image src={close} height={10} width={10} className="click" onClick={() => setIsModalVisible(false)} />
            </Row>
            <TraitTitle title="TRAITS VALUE" fontSize="12px" clearTitle="Clear Values" onClear={onClearValues} />
            <Grid
              gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr 1fr', '1fr 1fr 1fr']}
              className={isMobile ? '' : 'max-h'}
            >
              {!!traitList.length &&
                traitList[activeItem].traits.map((item: iTrait) => {
                  const { trait, number, percent, checked } = item
                  return (
                    <Col key={Math.random().toString()}>
                      <SamllBox active={false} className="fx jc-sb ai-ct bgCol1">
                        <Box className="fx jc-ct ai-ct traitCheck">
                          <Checkbox value={trait} onChange={(e: any) => onChangeChecked(e, checked)} checked={checked}>
                            <Box className="fx jc-sb ai-ct">
                              <Text
                                fontSize={12}
                                fontWeight={400}
                                color="#fff"
                                className={isMobile ? '' : 'max-w105 sl'}
                              >
                                {trait}
                              </Text>
                              <Box className="tr">
                                <Text fontSize={12} color="#fff" className="beyondHide">
                                  {valumeUnit(number)}
                                </Text>
                                <Text fontSize={12} color="#5F6165" className="beyondHide">
                                  {`(${percent}%)`}
                                </Text>
                              </Box>
                            </Box>
                          </Checkbox>
                        </Box>
                      </SamllBox>
                    </Col>
                  )
                })}
            </Grid>
          </Col>
        </Grid>
        <Box className="fx-row ai-ct jc-sb w100 h60 bgCol2 brb12" paddingRight="16px">
          <Box />
          <Box className="fx ai-ct jc-ct">
            <SaveBtn className="center click mr10" onClick={onClearAll}>
              <Text color="rgba(255, 255, 255, 0.38)" fontWeight={600} fontSize="12px">
                CLEAR ALL
              </Text>
            </SaveBtn>
            <SaveBtn className="center click col5" onClick={onSaveTrait}>
              <Text color={traitList.length > 0 ? '#fff' : 'rgba(255, 255, 255, 0.38)'} fontSize="12px">
                SAVE
              </Text>
            </SaveBtn>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}
