import React, { useState } from 'react'
import styled from 'styled-components'
import { Popover } from 'antd'
import { Box, Image, Text } from '@/packages/'
import { copyIcon, copyed } from '@/assets/img'

import useCopyToClipboard from '@/hooks/useCopyToClipboard'

const AddressWrapper = styled(Box)`
  background: #252525;
  border-radius: 20px;
`

const CopyedPopover = () => {
  return (
    <Box className="fx-row ai-ct jc-ct copy_popover" height="33px" width="88px" background="#242931">
      <Image src={copyed} width={13} height={13} />
      <Text color="#929395" fontSize="13px" ml="4px">
        Copied!
      </Text>
    </Box>
  )
}

export const CopyContent: React.FC<{ content: string }> = ({ content }) => {
  const [value, copy] = useCopyToClipboard()
  const [copyVisible, setCopyVisible] = useState<boolean>(false)
  const onCopy = () => {
    copy(content)
    setCopyVisible(true)
    setTimeout(() => {
      setCopyVisible(false)
    }, 3000)
  }

  return (
    <AddressWrapper className="fx-row center w100 " py="10px" px={['8px', '10px', '20px', '20px']}>
      <Text fontSize="12px" color="#A0A1A3" className="beyondHide">
        {content}
      </Text>
      <Popover placement="bottom" visible={copyVisible} content={<CopyedPopover />}>
        <Image
          src={copyIcon}
          onClick={content ? () => onCopy() : () => {}}
          height={16}
          width={16}
          className="ml8 click"
        />
      </Popover>
    </AddressWrapper>
  )
}
export const CopyContentMobile: React.FC<{ content: string }> = ({ content }) => {
  const [value, copy] = useCopyToClipboard()
  const [copyVisible, setCopyVisible] = useState<boolean>(false)
  const onCopy = () => {
    copy(content)
    setCopyVisible(true)
    setTimeout(() => {
      setCopyVisible(false)
    }, 3000)
  }

  return (
    <Box className="fx-row ai-ct jc-sb w100 " py="12px" px="12px">
      <Text fontSize="12px" color="#A0A1A3" className="beyondHide">
        {content}
      </Text>
      <Popover placement="bottom" visible={copyVisible} content={<CopyedPopover />}>
        <Image
          src={copyIcon}
          onClick={content ? () => onCopy() : () => {}}
          height={14}
          width={14}
          className="ml8 click"
        />
      </Popover>
    </Box>
  )
}
