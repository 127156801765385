import React from 'react'
import styled from 'styled-components'
import { Box, Text } from '@/packages/'
import { Row } from '@/components/Layout/Layout'
import { useMatchBreakpoints } from '@/hooks/useMatchBreakpoints'

interface iLiveFeedDot {
  isLive: boolean
}

const Dot = styled(Box)`
  width: 8px;
  height: 8px;
  border-radius: 50%;
`

export const LiveFeedDot: React.FC<iLiveFeedDot> = ({ isLive }) => {
  const { isMobile } = useMatchBreakpoints()
  return (
    <Row className="w100 fx jc-end" height="38px">
      <Text fontSize="12px" color={isLive ? '#00B2B5' : '#FFE800'} ml="10px" mr="6px" className="beyondHide">
        {isLive ? (isMobile ? 'LIVE' : 'LIVE FEED') : 'LIVE FEED PAUSED'}
      </Text>
      <Dot className={isLive ? ' show_status col5' : 'col6'} />
    </Row>
  )
}

export const LiveFeedDotMobile: React.FC<{ isLive: boolean }> = ({ isLive }) => {
  return (
    <Box height="28px" width="28px" className="center">
      <Dot className={isLive ? 'col5 show_status' : 'col6'} />
    </Box>
  )
}
