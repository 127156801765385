import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Flex, Box, Grid } from '@/packages/Box'
import { useAppContext } from '@/hooks/useAppContext'
import { HoverWrapper } from '@/components/Layout/HoverWrapper'
import { ContentWrapper, Col } from '@/components/Layout/Layout'
import { MarketCap } from './MarketCap'
import { HotCollection } from './HotCollection'
import { HotMints } from './HotMints'
import { Feed } from './Feed'
import { Collection } from './Collection'
import { ListingUnderFloor } from './ListingUnderFloor'
import { WhalesActivity } from './WhalesActivity'
import { PeweeWS } from '@/utils/ws'
import { QuickBuy } from '@/views/Trending/LiveView/Sniper/QuickBuy'
import { AlertList } from '@/components/Alert'
import { AddAlertAction, MemberInfoAction } from '@/store/actions/DashbordAction'
import { memberInfoApi } from '@/request/Api'
import { InvitationEntrance } from '@/views/Invite/components'

export const Dashboard = () => {
  const [socket, setSocket] = useState<any>(null)
  const [userId, setUserId] = useState<number>(0)
  const { state, dispatch } = useAppContext()
  const createWs = () => {
    if (socket === null) {
      const ws: any = new PeweeWS()
      setSocket(ws)
    }
  }
  const disWsConnect = () => {
    if (socket) {
      try {
        socket?.disConnect()
        setSocket(null)
      } catch (error) {
        console.log('disconnect ws error', error)
      }
    }
  }

  const getMemberInfo = async () => {
    const m: any = await memberInfoApi()
    if (m) {
      setUserId(m.userId)
      MemberInfoAction(dispatch, m)
    }
  }

  useEffect(() => {
    getMemberInfo()
    createWs()
  }, [])
  useEffect(() => {
    // setInterval(() => {
    //   const A: any = {
    //     alertPrice: 0.01,
    //     nft: {
    //       assets: 2,
    //       attention: 0,
    //       bannerImageUrl:
    //         'https://lh3.googleusercontent.com/xBHOC1MbundsmD5UsyibBbk_Gdo_oadeSVbEcukuJE92nDBcc32F5zm2VQdeVWdrey1-wV_ljlD7G9EgxQj7SS2GKBUANue87IwCutQ=s2500',
    //       createDate: 1646044529570,
    //       description:
    //         'ALTAVA Second Skin : Metamorphosis is your transformed identity in the metaverse inspired by nature ─ transcend beyond luxury fashion and express alternative versions of yourself.\n\nhttps://secondskin.app',
    //       discordUrl: 'https://discord.gg/altava',
    //       floorPrice: null,
    //       imageUrl:
    //         'https://lh3.googleusercontent.com/O1LBjsWyWywfjSZw081zFC5OT8c8mxcvFYwrScRNLeUqeMU9VrjbFQlBjz56eprN64BcnLO6wy159VODaszNU7AlR_1eW0_oeFZokw=s120',
    //       largeImageUrl:
    //         'https://lh3.googleusercontent.com/wlFus14AxKed7y5jipeeT6VV0wITNy3-YwT2dnZVOb5SqfAmeSGe1m-tTqzcC4p1jnD-n3nfkZJ8CrjJJJiZPgOlcRoONVXCOHd5VlA=s300',
    //       name: 'ALTAVA Second Skin Metamorphosis',
    //       nftAddresses: `${Math.random()}`,
    //       nftId: 510,
    //       officialUrl: 'https://secondskin.app',
    //       openSeaRoyalties: null,
    //       rank: 0,
    //       revealed: 1,
    //       royalties: null,
    //       slug: 'altava-second-skin-metamorphosis',
    //       totalSales: null,
    //       totalVolume: null,
    //       twitterUsername: 'altavagroup',
    //       verifyStatus: 0
    //     },
    //     triggerPrice: 0.9589,
    //     triggerTime: 1662022767912,
    //     triggered: true
    //   }
    //   AddAlertAction({ add: A }, dispatch)
    // }, 3000)

    if (socket && userId !== 0) {
      try {
        setTimeout(() => {
          socket.client.subscribe(`/topic/watchlist-alert-${userId}`, (res: any) => {
            const d = JSON.parse(res.body)
            console.log('ws data==watchlist-alert>>>>>>>>', d)
            AddAlertAction({ add: d }, dispatch)
          })
        }, 10100)
      } catch (error) {
        console.log('connect error', error)
      }
    }
  }, [userId])

  useEffect(() => {
    return () => disWsConnect()
  }, [socket])

  return (
    <ContentWrapper>
      <AlertList />
      <Col className="w100">
        {process.env.REACT_APP_PEWEE_ENV !== 'prod' && (
          <Box mb={['24px', '32px', '42px', '42px']}>
            <InvitationEntrance />
          </Box>
        )}
        <MarketCap />
        <Collection />
        <Grid
          mt="40px"
          mb="40px"
          gridTemplateColumns={['1fr', '1fr', '1fr 1fr', '1fr 1fr']}
          className="w100"
          gridColumnGap={[0, 2, 2, 24]}
        >
          {socket && <WhalesActivity socket={socket} />}
          {socket && <ListingUnderFloor socket={socket} />}
        </Grid>
      </Col>
      <QuickBuy />
    </ContentWrapper>
  )
}
