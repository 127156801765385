import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { useAppContext } from '@/hooks/useAppContext'
import { Box, Text, Input, Image } from '@/packages'
import { Chart } from '@/components/Charts/Chart'
import { RankTradesOption, TradesVolumeOption } from '@/components/Charts/options/index'
import { Col, Row, CardTitle, PeweeTooltip } from '@/components/Layout/Layout'
import { TradesApi } from '@/request/Api'
import { TabListSelected, iTabItem } from '@/components/TabListSelected'
import { NoData } from '@/components/NoData'
import { SwitchButton } from '@/components/SwitchButton'
import { ChartLegend } from '@/components/ChartLegend'
import { inappropriateRank, notice, bulb, bulbActive, trendlineGuidesContent, close } from '@/assets/img'

const Container = styled(Box)`
  height: 400px;
`
const initOption = {
  volumes: [],
  trades: [],
  dates: [],
  coin: {
    symbol: '',
    chain: '',
    address: '',
    precision: 18,
    name: ''
  }
}
export const RankTrades = () => {
  const [isOpen, setOpen] = useState<boolean>(false)
  const [granularity, setGranularity] = useState('1d')
  const [options, setOptions] = useState<any>(null)
  const [volumesOptions, setVolumeOptions] = useState(null)
  const [initList, setInitList] = useState<any>(null)
  const [latestPrice, setLatestPrice] = useState<number>(0)

  const tradesWithHeightRankRef: any = useRef()
  const tradesWithMiddleRankRef: any = useRef()
  const tradesWithLowRankRef: any = useRef()
  const tradesWithPoorRankRef: any = useRef()
  const [temp, setTemp] = useState(initOption)

  const [period, setPeriod] = useState<Array<iTabItem>>([
    { id: 0, name: '1h', active: false },
    { id: 1, name: '4h', active: false },
    { id: 2, name: '12h', active: false },
    { id: 3, name: '1d', active: true },
    { id: 4, name: '3d', active: false }
  ])
  const [isLoading, setLoading] = useState<boolean>(true)
  const { state, dispatch } = useAppContext()

  const params = useParams()
  const fetch = async () => {
    setLoading(true)
    setOptions(null)
    setVolumeOptions(null)
    const p = {
      nftId: Number(params.nftId),
      granularity,
      outliersOn: !isOpen
    }
    const d: any = await TradesApi(p)
    setLoading(false)
    if (d && !!Object.keys(d).length) {
      setInitList(d)
      setTemp(d)
      setOptions(RankTradesOption(d, latestPrice))
      setVolumeOptions(TradesVolumeOption(d, granularity))
      resetBtnStatus()
    }
  }
  useEffect(() => {
    fetch()
  }, [granularity, latestPrice, isOpen])

  const onChangePeriod = (val: iTabItem) => {
    const _period = JSON.parse(JSON.stringify(period))
    _period.forEach((_p: iTabItem) => {
      _p.active = _p.name === val.name
    })
    setPeriod(_period)
    setGranularity(val.name)
  }

  useEffect(() => {
    if (state?.LiveViewReducer?.wsFloorPrice) {
      const price = state.LiveViewReducer.wsFloorPrice.close
      setLatestPrice(price)
    }
  }, [state?.LiveViewReducer?.wsFloorPrice])

  const resetBtnStatus = () => {
    tradesWithHeightRankRef.current && tradesWithHeightRankRef.current.resetStatus()
    tradesWithMiddleRankRef.current && tradesWithMiddleRankRef.current.resetStatus()
    tradesWithLowRankRef.current && tradesWithLowRankRef.current.resetStatus()
    tradesWithPoorRankRef.current && tradesWithPoorRankRef.current.resetStatus()
  }

  const visibleChart = (open: boolean, type: string) => {
    if (initList) {
      const _initList = JSON.parse(JSON.stringify(temp))
      if (type === 'tradesWithHeightRank') {
        _initList.tradesWithHeightRank = open ? initList.tradesWithHeightRank : []
      }
      if (type === 'tradesWithLowRank') {
        _initList.tradesWithLowRank = open ? initList.tradesWithLowRank : []
      }
      if (type === 'tradesWithMiddleRank') {
        _initList.tradesWithMiddleRank = open ? initList.tradesWithMiddleRank : []
      }
      if (type === 'tradesWithPoorRank') {
        _initList.tradesWithPoorRank = open ? initList.tradesWithPoorRank : []
      }
      setTemp(_initList)
      setOptions(RankTradesOption(_initList, latestPrice))
    }
  }
  return (
    <Box className="fx-col body_wrapper " mt="24px" height="550px" mb="24px">
      <Row className="jc-sb" mt="24px">
        <CardTitle>Trades</CardTitle>
        <Row>
          <Row mr="24px">
            <Text fontSize="12px" color="rgba(255, 255, 255, 0.87)" mr="10px">
              Period
            </Text>
            <TabListSelected ml={10} ftsz={12} list={period} onChange={onChangePeriod} selectedList={false} />
          </Row>
          <SwitchButton title="Outliers" onChange={() => setOpen(!isOpen)} defaultStatus={false} />
        </Row>
      </Row>
      <Row className="jc-sb w100">
        <Row>
          <ChartLegend
            type="dot"
            bgColor="#FFE800"
            title="Rank 0.1%"
            cRef={tradesWithHeightRankRef}
            onChange={(v: boolean) => visibleChart(v, 'tradesWithHeightRank')}
          />
          <ChartLegend
            type="dot"
            bgColor="#9452FF"
            title="Rank 1%"
            cRef={tradesWithMiddleRankRef}
            onChange={(v: boolean) => visibleChart(v, 'tradesWithMiddleRank')}
          />
          <ChartLegend
            type="dot"
            bgColor="#2D6FF1"
            title="Rank 10%"
            cRef={tradesWithLowRankRef}
            onChange={(v: boolean) => visibleChart(v, 'tradesWithLowRank')}
          />
          <ChartLegend
            type="dot"
            bgColor="#727272"
            title="> Rank 10%"
            cRef={tradesWithPoorRankRef}
            onChange={(v: boolean) => visibleChart(v, 'tradesWithPoorRank')}
          />
        </Row>
      </Row>
      <Container className="">
        <Box height="310px" className="">
          {options ? (
            <Chart option={options} />
          ) : (
            <NoData isLoading={isLoading} msg="No trades in the selected time range." />
          )}
        </Box>
        {volumesOptions && (
          <Box height="90px" className="">
            <Chart option={volumesOptions} />
          </Box>
        )}
      </Container>
    </Box>
  )
}
