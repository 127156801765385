import React from 'react'
import styled from 'styled-components'
import { Box, Image, Text } from '@/packages/'
import { createAdd } from '@/assets/img'

const CreateButton = styled(Box)`
  height: 40px;
  background: rgba(255, 255, 255, 0.12);
  border-radius: 20px;
`
interface iCreateContainer {
  onCreate: () => void
}
export const CreateContainer: React.FC<iCreateContainer> = ({ onCreate }) => {
  return (
    <CreateButton className="fx-row center click" px="16px" onClick={onCreate}>
      <Image src={createAdd} height={14} width={14} />
      <Text color="rgba(255, 255, 255, 0.87);" fontSize="16px" fontWeight={700} ml="8px">
        Create
      </Text>
    </CreateButton>
  )
}
