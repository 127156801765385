import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Flex, Box, Grid, Text } from '@/packages/'
import { Info } from './Info'
import { Sniper } from './Sniper'
import { Insight } from './Insight'
import { Holders } from './Holders'
import { Tokens } from './Tokens'
import { Socials } from './Socials'
import { ContentWrapper, Col } from '@/components/Layout/Layout'
import { useAppContext } from '@/hooks/useAppContext'
import { NFTIdAction } from '@/store/actions/LiveviewAction'
import { TabListSelected, iTabItem } from '@/components/TabListSelected'
import { InvitationEntrance } from '@/views/Invite/components'

interface iTabList {
  id: number
  name: string
  active: boolean
}

const TabsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  height: 46px;
  border-bottom: 2px solid #37383b;
  margin-top: 40px;
`
const TabItem = styled(Box)`
  cursor: pointer;
  height: 46px;
`
const GradientBorder = styled(Box)`
  height: 2px;
  background: linear-gradient(90deg, #ffe800 0%, #00b2b5 100%);
  margin-top: 16px;
`

export const LiveView = () => {
  const [tabList, setTabList] = useState<Array<iTabList>>([
    { id: 0, name: 'Sniper', active: true }
    // { id: 1, name: 'Insight', active: false },
    // { id: 2, name: 'Holders', active: false },
    // { id: 3, name: 'Tokens', active: false },
    // { id: 4, name: 'InsSocialsight', active: false }
  ])

  const { state, dispatch } = useAppContext()
  useEffect(() => {
    // const path = document.location.pathname
    // let nftId = 0
    // if (path.startsWith('/liveview/')) {
    //   nftId = Number(path.substring(path.length - 1))
    // }
    // console.log('nftId', nftId)
    // NFTIdAction(nftId, dispatch)
  }, [])

  const changeIdx = (index: number) => {
    const _tabList = JSON.parse(JSON.stringify(tabList))
    _tabList.forEach((tab: iTabList) => {
      if (tab.id === index) {
        tab.active = true
      } else {
        tab.active = false
      }
    })
    setTabList(_tabList)
  }
  return (
    <Box className="w100">
      <ContentWrapper>
        {process.env.REACT_APP_PEWEE_ENV !== 'prod' && (
          <Box mb={['24px', '32px', '42px', '42px']} className="w100">
            <InvitationEntrance />
          </Box>
        )}
      </ContentWrapper>
      <Info />

      {/* <ContentWrapper>
        <Box mt="32px" className="w100 fx jc-start">
          <TabListSelected ml={24} list={tabList} onChange={(val: any) => setTabList(val)} selectedList />
        </Box>
      </ContentWrapper> */}
      <>
        {tabList.map((item: iTabList) => (
          <Box key={item.id}>
            {item.id === 0 && <Sniper />}
            {/* {item.id === 1 && <Insight />}
            {item.id === 2 && <Holders />}
            {item.id === 3 && <Tokens />}
            {item.id === 4 && <Socials />} */}
          </Box>
        ))}
      </>
    </Box>
  )
}
