import React, { useState, useEffect, useCallback, useRef } from 'react'
import styled from 'styled-components'
import { ChartTitle, Col, ChartContainer, Row, SwitchGranularity } from '@/components/Layout/Layout'
import { Flex, Box, Grid, Text, Image } from '@/packages/'
import { ETHValue, IncreaseText } from '@/components/'
import { Chart } from '@/components/Charts/Chart'
import { TradersChartApi } from '@/request/Api'
import { TradersOptions } from '@/components/Charts/options/index'

import { TitleAndPeriod } from '@/components/TitleAndPeriod'
import { iTabItem } from '@/components/TabListSelected'
import { ChartLegend } from '@/components/ChartLegend'
import { NoData } from '@/components/NoData'

export const Traders = () => {
  const [granularity, setGranularity] = useState<string>('7d')
  const [chartList, setChartList] = useState<any>()

  const [data, setData] = useState<any>(null)
  const [initData, setInitData] = useState<any>(null)
  const [period, setPeriod] = useState<Array<iTabItem>>([
    { id: 0, name: '7d', active: true },
    { id: 1, name: '30d', active: false }
  ])
  const [isLoading, setLoading] = useState<boolean>()

  const sellersRef: any = useRef()
  const buyersRef: any = useRef()
  const tradersRef: any = useRef()

  const fetch = async () => {
    setLoading(true)
    const d: any = await TradersChartApi(granularity)
    setLoading(false)
    if (d) {
      const { dates, buyers, sellers, traders } = d
      const c: any = {
        dates,
        buyers,
        sellers,
        traders
      }
      setData(c)
      setInitData(c)
      setChartList(TradersOptions(c))
      resetBtnStatus()
    }
  }
  useEffect(() => {
    fetch()
  }, [granularity])

  const visibleChart = (open: boolean, type: string) => {
    const _data = JSON.parse(JSON.stringify(data))
    _data[type] = open ? initData[type] : []
    setData(_data)
    setChartList(TradersOptions(_data))
  }

  const onChangePeriod = (val: iTabItem) => {
    const _period = JSON.parse(JSON.stringify(period))
    _period.forEach((_p: iTabItem) => {
      _p.active = _p.name === val.name
    })
    setPeriod(_period)
    setGranularity(val.name)
  }
  const resetBtnStatus = () => {
    sellersRef.current && sellersRef.current.resetStatus()
    buyersRef.current && buyersRef.current.resetStatus()
    tradersRef.current && tradersRef.current.resetStatus()
  }
  return (
    <Box className="fx-col body_wrapper " mt="24px" height="486px">
      <TitleAndPeriod title="Trades" periodList={period} onChangePeriod={onChangePeriod} />
      <Row>
        <ChartLegend
          type="dot"
          bgColor="#34C77B"
          title="Sellers"
          cRef={sellersRef}
          onChange={(v: boolean) => visibleChart(v, 'sellers')}
        />
        <ChartLegend
          type="dot"
          cRef={buyersRef}
          bgColor="#EB484C"
          title="Buyers"
          onChange={(v: boolean) => visibleChart(v, 'buyers')}
        />
        <ChartLegend
          type="line"
          bgColor="#FFE800"
          title="Traders"
          cRef={tradersRef}
          onChange={(v: boolean) => visibleChart(v, 'traders')}
        />
      </Row>
      <Box className="w100" height="300px" mt="40px">
        {chartList ? <Chart option={chartList} /> : <NoData isLoading={isLoading} />}
      </Box>
    </Box>
  )
}
