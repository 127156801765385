import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Box, Text, Image, Grid } from '@/packages/'
import { ContentWrapper, Col, Row } from '@/components/Layout/Layout'
import { Tools } from './Tools'
import { PassUtilities } from './PassUtilities'
import { Major } from './Major'
import { Agile } from './Agile'
import { InDepth } from './InDepth'
import { Roadmap } from './Roadmap'
import { Teams } from './Teams'
import { Faq } from './Faq'
import { useMatchBreakpoints } from '@/hooks/useMatchBreakpoints'

const BigContBox = styled(Box)``

export const Aboutus = () => {
  const { isMobile, isTablet } = useMatchBreakpoints()
  const [scrollNum, setScrollNum] = useState(0)
  const [toolsShow, setToolsShow] = useState(false)
  const [passShow, setPassShow] = useState(false)
  const [majorShow, setMajorShow] = useState(false)
  const fetch = () => {
    setToolsShow(true)
    if (isMobile) {
      setPassShow(true)
    }
    window.addEventListener('scroll', bindHandleScroll)
  }
  const bindHandleScroll = (event: any) => {
    const scrollTop =
      (event.srcElement ? event.srcElement.documentElement.scrollTop : false) ||
      window.pageYOffset ||
      (event.srcElement ? event.srcElement.body.scrollTop : 0)
    setScrollNum(scrollTop)
    if (isMobile) {
      console.log('h5')
      console.log(scrollTop)
      if (scrollTop < 600) {
        setToolsShow(true)
      } else {
        setToolsShow(false)
      }
      if (scrollTop < 800) {
        setPassShow(true)
      } else {
        setPassShow(false)
      }
      if (scrollTop > 600 && scrollTop < 1500) {
        setMajorShow(true)
      } else {
        setMajorShow(false)
      }
    } else {
      console.log('PC')
      if (scrollTop < 1300) {
        setToolsShow(true)
      } else {
        setToolsShow(false)
      }
      if (scrollTop > 500 && scrollTop < 1400) {
        setPassShow(true)
      } else {
        setPassShow(false)
      }
      if (scrollTop > 1500 && scrollTop < 2400) {
        setMajorShow(true)
      } else {
        setMajorShow(false)
      }
    }
  }
  useEffect(() => {
    fetch()
  }, [])
  return (
    <BigContBox className="w100 hide">
      <Box>
        <Grid
          className="w100 relative"
          gridColumnGap={['0', '0', '0', '24px']}
          gridTemplateColumns={['1fr', '1fr', '1fr', '1fr']}
        >
          <Box
            className="pa tp26 rg0 scaleImg"
            width={['320px', '533px', '746px', '966px']}
            height={['344px', '540px', '735px', '930px']}
            right={['-100px', '-67px', '-34px', '0px']}
          >
            <Image src="https://peweetools.s3.us-west-2.amazonaws.com/png/tyFarst.png" className="rw100 rh100" />
          </Box>
        </Grid>

        <Box className={toolsShow ? 'toolsEffect' : 'toolsTest'}>
          <Tools />
        </Box>
        <Box className={passShow ? 'passEffect' : 'passTest'}>
          <PassUtilities scrollNum={scrollNum} />
        </Box>
        <Box className={majorShow ? 'majorEffect' : 'majorTest'}>
          <Major scrollNum={scrollNum} />
        </Box>

        <Agile scrollNum={scrollNum} />
        <InDepth scrollNum={scrollNum} />
        <Roadmap scrollNum={scrollNum} />
        <Teams scrollNum={scrollNum} />
        <Faq scrollNum={scrollNum} />
      </Box>
    </BigContBox>
  )
}
