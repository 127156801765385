import React, { useState, useEffect, useImperativeHandle, useRef } from 'react'
import styled from 'styled-components'
import { Modal, Tooltip } from 'antd'
import Web3 from 'web3'
import _ from 'lodash'
import { OpenSeaPort, Network } from 'opensea-js'
import { useParams } from 'react-router-dom'
import { CloseOutlined } from '@ant-design/icons'
import ReactGa from 'react-ga'
import axios from 'axios'
import { Flex, Box, Grid, Text, Image } from '@/packages/'
import { Row, Col, NFTImage, PeweeTooltip, ETHValue } from '@/components/Layout/Layout'
import { buyLoading, revealed1, revealed2, openseaIcon, close } from '@/assets/img/'
import { useAppContext } from '@/hooks/useAppContext'
import { QuickBuyVisibleAction } from '@/store/actions/LiveviewAction'
import { splitAddress, toNonExponential, openWindow, beyondStr } from '@/utils/tools'
import { useEthereum } from '@/hooks/useEthereum'
import { FloorCountApi, TradeRecordApi } from '@/request/Api'
import { useMatchBreakpoints } from '@/hooks/useMatchBreakpoints'
import { OpenNotification, NotMainNetNotice } from '@/components/OpenNotification'
import { ColorRank } from '@/components/ColorRank'
import { isMainNet } from '@/utils/chain'

const MAIN_NET = 'https://mainnet.infura.io/v3/59cc19bcf4a740bba9f19e162c9441ba'
const TEST_NET = 'https://rinkeby.infura.io/v3/a25e4f183c94424fb9de0182392af4ed'

const InfoItem: React.FC<{
  title: string
  value?: string | number
  children?: React.ReactNode
  right?: number
  bottom?: number
  tipTitle?: string
}> = ({ tipTitle, title, children, right = 0, bottom = 0, value }) => {
  return (
    <Box
      className="center fx-col"
      width="174px"
      height="65px"
      style={{
        borderColor: '#323946',
        borderStyle: 'solid',
        borderTopWidth: 1,
        borderLeftWidth: 1,
        borderRightWidth: right,
        borderBottomWidth: bottom
      }}
    >
      {tipTitle ? (
        <Tooltip title={tipTitle} getPopupContainer={(trigger: any) => trigger.parentNode}>
          <Text color="#5F6165" fontSize="12px">
            {title}
          </Text>
        </Tooltip>
      ) : (
        <Text color="#5F6165" fontSize="12px">
          {title}
        </Text>
      )}
      {children || (
        <Text fontSize="14px" color="#FFFFFF">
          Ξ {value ? toNonExponential(value) : 0}
        </Text>
      )}
    </Box>
  )
}

const BuyingWrapper = styled(Box)`
  height: 24px;
  background: #000000;
  padding: 5px 10px;
`
const MobileBuyingWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 315px;
  height: 40px;
  background: rgba(0, 178, 181, 0.08);
  border: 1px solid rgba(0, 178, 181, 0.16);
  border-radius: 34px;
  position: relative;
`

const Buying: React.FC<{ price: number; nftAddress: string; tokenId: string; nftId: number; bRef: any }> = ({
  price,
  nftAddress,
  tokenId,
  nftId,
  bRef
}) => {
  const [buyText, setBuyText] = useState<string>(``)
  const [isLoading, setLoading] = useState(false)
  const [seaport, setSeaport] = useState<any>(null)
  const [crtWeb3, setWeb3] = useState<any>(null)
  const [txFinish, setFinish] = useState<boolean>(false)
  const [targetOrder, setTargetOrder] = useState<any>(null) // 当前用于交易的订单
  const { ethereum, provider } = useEthereum()
  const { isMobile } = useMatchBreakpoints()

  useEffect(() => {
    setBuyText(`BUYING FOR Ξ ${price}`)
  })
  useImperativeHandle(bRef, () => ({
    initStatus: () => {
      setBuyText(``)
      setLoading(false)
      setSeaport(null)
      setWeb3(null)
      setFinish(false)
      setTargetOrder(null)
    },
    initListingSeaPort: () => {
      if (!txFinish) {
        setLoading(true)
        const web3Provider: any = new Web3.providers.HttpProvider(MAIN_NET)
        const web3 = new Web3(Web3.givenProvider || MAIN_NET)
        const openseaSDK: any = new OpenSeaPort(web3Provider, {
          // networkName: Network.Rinkeby
          networkName: Network.Main
        })
        setWeb3(web3)
        setSeaport(openseaSDK)
      }
    }
  }))

  const seaportGetOrders = async () => {
    let orderList: Array<any> = []
    try {
      const url = `https://api.opensea.io/api/v1/asset/${nftAddress}/${tokenId}/?include_orders=true`
      const response = await axios.get(url)
      if (response.status === 200) {
        const list = response.data.seaport_sell_orders
        list.forEach((order: any) => {
          if (order.side === 'ask') {
            orderList.push(order)
          }
        })
      }
      // const res1: any = await seaport.api.getOrders({
      //   protocol: 'seaport',
      //   side: 'ask', // "bid" for buy orders, "ask" for sell orders
      //   tokenIds: [tokenId],
      //   assetContractAddress: nftAddress
      // })
      // console.log('res1', res1)
    } catch (error) {
      orderList = []
      OpenNotification('Incorrect order, please check and try again.', 'error')
    }
    return orderList
  }

  const getWalletAccount = async () => {
    let act = ''
    try {
      const accountsList = await ethereum.request({ method: 'eth_requestAccounts' })
      if (accountsList && accountsList.length > 0) {
        act = accountsList[0]
      }
    } catch (error) {
      act = ''
    }
    return act
  }

  const AddTradeRecord = async (txHash: string) => {
    const d = await TradeRecordApi({
      nftId,
      nftAddress,
      tokenId,
      txHash
    })
  }

  // 开始交易
  const metamaskTrading = async (order: any) => {
    // AddTradeRecord('0x7e6eb36e483fc95189bb5afffd78772bc4d3edd47ff0267280dbcb1a7d98907f')

    const accounts = await getWalletAccount()
    if (order) {
      const {
        cancelled,
        client_signature: clientSignature,
        closing_date: closingDate,
        created_date: createdDate,
        criteria_proof: criteriaProof,
        current_price: currentPrice,
        expiration_time: expirationTime,
        finalized,
        listing_time: listingTime,
        maker,
        maker_fees: makerFees,
        marked_invalid: markedInvalid,
        order_hash: orderHash,
        order_type: orderType,
        protocol_address: protocolAddress,
        protocol_data: protocolData,
        relay_id: relayId,
        side,
        taker,
        taker_fees: takerFees
      } = order
      const makerFeesList: Array<any> = []
      makerFees.forEach((makerItem: any) => {
        const { address, config, profile_img_url: profileImgUrl, user } = makerItem.account
        makerFeesList.push({
          basisPoints: makerItem.basis_points,
          account: {
            address,
            config,
            profileImgUrl,
            user
          }
        })
      })

      const v1Order = {
        cancelled,
        clientSignature,
        closingDate,
        createdDate,
        criteriaProof,
        currentPrice,
        expirationTime,
        finalized,
        listingTime,
        maker: {
          address: maker.address,
          config: maker.config,
          profileImgUrl: maker.profile_img_url,
          user: maker.user
        },
        makerFees: makerFeesList,
        markedInvalid,
        orderHash,
        orderType,
        protocolAddress,
        protocolData,
        relayId,
        side,
        taker,
        takerFees
      }
      const data = await seaport.fulfillOrder({ order: v1Order, accountAddress: accounts })
      let gas: any = 0
      try {
        gas = await crtWeb3.eth.estimateGas({
          from: accounts,
          to: protocolAddress,
          value: Number(currentPrice),
          data
        })
      } catch (error: any) {
        // console.log('gas error', error)
        if (error) {
          setLoading(false)
          return OpenNotification(`Insufficient funds`, 'error')
        }
      }

      // console.log('gas', gas)
      let gasPrice: any = 0
      try {
        gasPrice = await crtWeb3.eth.getGasPrice()
      } catch (error: any) {
        // console.log('gasprice error', error)
        if (error) {
          setLoading(false)
          return OpenNotification(`Insufficient funds`, 'error')
        }
      }
      // console.log('gasPrice', gasPrice)
      try {
        const txHash = await ethereum.request({
          method: 'eth_sendTransaction',
          params: [
            {
              from: accounts,
              to: protocolAddress, // opensea地址
              value: Number(currentPrice.toString()).toString(16),
              gasPrice: Number(gasPrice).toString(16),
              gas: gas.toString(16),
              data
            }
          ]
        })
        // console.log('txHash', txHash)
        setBuyText('-')
        setTargetOrder(null)
        setFinish(true)
        setLoading(false)
        OpenNotification('Submitted', 'success')
        AddTradeRecord(txHash)
      } catch (error: any) {
        // console.log('tx error', error)
        if (error) {
          setLoading(false)
          if (error.code === 4001) {
            setBuyText('USER REJECTED')
            OpenNotification('MetaMask Tx Signature: User denied transaction signature', 'warning')
          } else {
            OpenNotification(error.message, 'error')
          }
        }
      }
    }
    return true
  }

  const startTrading = async (crtOrder: any) => {
    if (seaport) {
      if (crtOrder) {
        metamaskTrading(crtOrder)
      }
    }
  }
  // 2 不支持私有挂单
  const isPrivteOrder = (order: any) => {
    if (order.taker === null) {
      return false
    }
    // 该订单为私有挂单
    return true
  }
  // 只支持ETH代币  其他代币不能购买
  const isETH = (order: any) => {
    if (order?.protocol_data?.parameters?.consideration) {
      const { consideration } = order.protocol_data.parameters
      const c = consideration.every((item: any) => item.token === '0x0000000000000000000000000000000000000000')
      return c
    }
    return false
  }
  const getPrice = (p: string) => parseInt(p) / 10 ** 18

  // 该订单是否还处于挂单状态
  const isPendingOrder = (orders: Array<any>) => {
    if (orders.length === 0) {
      OpenNotification('The NFT has been purchased', 'warning')
      return false
    }
    return true
  }
  // 挂单价格是否变更
  const isOrderPriceChange = (orders: Array<any>) => {
    const minOrder: any = _.minBy(orders, function (o) {
      return Number(o.current_price)
      // return Number(o.currentPrice)
    })
    if (isPrivteOrder(minOrder)) {
      return OpenNotification('This order is a private pending order', 'warning')
    }
    if (!isETH(minOrder)) {
      return OpenNotification('Only supports ETH tokens, other tokens cannot be purchased', 'warning')
    }
    const _price = getPrice(minOrder.current_price)
    // const _price = getPrice(minOrder.currentPrice)
    // console.log('price', price)
    // console.log('_price', _price)
    if (_price === price) {
      startTrading(minOrder)
      return false
    }
    if (_price < price) {
      setBuyText(`BUYING FOR Ξ ${_price}`)
      startTrading(minOrder)
      OpenNotification('The listing price has changed', 'warning')
      return false
    }
    if (_price > price) {
      setLoading(false)
      setBuyText(`BUYING FOR Ξ ${_price}`)
      setTargetOrder(minOrder)
      OpenNotification('The listing price has changed', 'warning')
      return true
    }
    return true
  }

  const initSeaPort = async () => {
    const net = await isMainNet()
    if (net) {
      if (!txFinish) {
        setLoading(true)
        const web3Provider: any = new Web3.providers.HttpProvider(MAIN_NET)
        const web3 = new Web3(Web3.givenProvider || MAIN_NET)
        const openseaSDK: any = new OpenSeaPort(web3Provider, {
          // networkName: Network.Rinkeby
          networkName: Network.Main
        })
        setWeb3(web3)
        setSeaport(openseaSDK)
      }
    } else {
      NotMainNetNotice()
    }
  }

  const beforeBuy = async () => {
    // 切换至主网 Ethereum Main Network (Mainnet) 0x1
    const orList = await seaportGetOrders()
    if (!!orList.length && isPendingOrder(orList)) {
      isOrderPriceChange(orList)
    } else {
      OpenNotification('Incorrect order, please check and try again.', 'error')
      setLoading(false)
    }
  }

  useEffect(() => {
    targetOrder && startTrading(targetOrder)
  }, [targetOrder])
  useEffect(() => {
    seaport && beforeBuy()
  }, [seaport])
  return (
    <div>
      {isMobile ? (
        <MobileBuyingWrapper className="click" onClick={initSeaPort}>
          {txFinish ? (
            <Text className="fz16 fw600" color="#00B2B5">
              Submitted
            </Text>
          ) : (
            <>
              {isLoading && <Image src={buyLoading} width={15} height={15} className="loading_status" />}
              <Text className="fz16 fw600" color="#00B2B5" ml="12px">
                {buyText}
              </Text>
            </>
          )}
        </MobileBuyingWrapper>
      ) : (
        <BuyingWrapper className="fx-row ai-ct click" ml="4px" onClick={initSeaPort}>
          {txFinish ? (
            <Text fontSize="12px" color="#FFFFFF">
              Submitted
            </Text>
          ) : (
            <>
              {isLoading && <Image src={buyLoading} width={15} height={15} className="loading_status" />}
              <Text fontSize="12px" color="#FFFFFF" ml="6px">
                {buyText}
              </Text>
            </>
          )}
        </BuyingWrapper>
      )}
    </div>
  )
}

const Mask = styled(Box)`
  width: 100%;
  border: 1px solid #0f0f12;
  backdrop-filter: blur(8px);
  padding: 16px;
`
const Left = styled(Box)``
const Right = styled(Box)``
const Owner = styled(Box)`
  width: 350px;
  height: 77px;
  border: 1px solid #323946;
  padding: 12px;
`
const InfoWrapper = styled(Box)`
  width: 350px;
  height: 130px;
`
interface iMobileInfoWrapper {
  name: string
  children: React.ReactNode
  mt?: string
}
const MobileInfoWrapper: React.FC<iMobileInfoWrapper> = ({ name, children, mt }) => {
  return (
    <Col mt={mt || '10px'}>
      <Text className="fz12 white38 fw600">{name}</Text>
      {children}
    </Col>
  )
}
const RankItem: React.FC<{ r: number; t: number }> = ({ r, t }) => {
  return (
    <Row>
      <ColorRank rankValue={r} onlyValue />
      <Text fontSize={['16px', '16px', '14px', '14px']} className="fw600 fz16 white87" fontWeight={400}>
        /{t}
      </Text>
    </Row>
  )
}
export const QuickBuy = () => {
  const [visible, setVisible] = useState(false)
  const { state, dispatch } = useAppContext()
  const [modalInfo, setModalInfo] = useState<any>({
    nftTokenVO: {
      name: '',
      nftAddress: '',
      nftId: 0,
      rank: 0,
      tokenId: '',
      imageUrl: ''
    },
    lastPrice: {
      increase: false,
      percent: 0,
      value: 0
    },
    price: 0,
    rank: 0,
    royalty: 0
  })
  const [floor, setFloor] = useState<number>(0)
  const [revealed, setRevealed] = useState<number>(0)
  const [totalRank, setTotalRank] = useState<number>(0)
  const [eventPrice, setEventPrice] = useState<number>(0)
  const params = useParams()
  const NFTID = Number(params.nftId)
  const buyingRef: any = useRef()
  const { isMobile } = useMatchBreakpoints()

  useEffect(() => {
    if (state?.LiveViewReducer?.buyInfo) {
      const { buyInfo, quickBuyFrom } = state.LiveViewReducer
      const { royalty, price } = buyInfo
      setEventPrice(toNonExponential(((1 + 0) * price) / (1 - royalty / 10000), 3))
      setModalInfo(buyInfo)
      setVisible(true)
      ReactGa.event({
        category: 'Show Quick Buy Popup',
        action: `Source: ${quickBuyFrom}`,
        label: `NFTId: ${buyInfo.nftTokenVO.nftId}  TokenId: ${buyInfo.nftTokenVO.tokenId}`
      })
      isImmediatelyBuy(quickBuyFrom)
    }
    if (state?.LiveViewReducer?.nftInfo) {
      const { nftInfo } = state.LiveViewReducer
      if (nftInfo.revealed) {
        setRevealed(nftInfo.revealed)
      }
    }
    if (state?.LiveViewReducer?.crtSupply) {
      const { crtSupply } = state.LiveViewReducer
      setTotalRank(crtSupply)
    }
  }, [
    state?.LiveViewReducer?.buyInfo,
    state?.LiveViewReducer?.quickBuyFrom,
    state?.LiveViewReducer?.nftInfo,
    state?.LiveViewReducer?.crtSupply
  ])
  const handleCancel = () => {
    setVisible(false)
    QuickBuyVisibleAction(null, '', dispatch)
    // 清除buying状态
    buyingRef.current && buyingRef.current.initStatus()
  }
  const isImmediatelyBuy = (from: string) => {
    // if(from === 'priceEstimate') {}
    if (from === 'listing' || from === 'listingUnderFloor') {
      setTimeout(() => {
        buyingRef.current && buyingRef.current.initListingSeaPort()
      }, 200)
    }
  }
  const getFloorPrice = async () => {
    if (modalInfo && modalInfo.nftTokenVO.nftId !== 0) {
      const f: any = await FloorCountApi(modalInfo.nftTokenVO.nftId)
      setFloor(f)
    }
  }

  useEffect(() => {
    getFloorPrice()
  }, [modalInfo])
  return (
    <div>
      {visible && (
        <Modal
          closeIcon={
            <Box mt="16px" mr="16px">
              <Image src={close} height={16} width={16} className="click" />
            </Box>
          }
          visible={visible}
          onCancel={handleCancel}
          footer={null}
          bodyStyle={{
            borderRadius: 12,
            maxWidth: 600,
            width: '100%',
            background: '#1e2229'
          }}
          className="modalClassName"
          maskClosable={false}
          width={600}
        >
          <Box>
            {isMobile ? (
              <Box p="20px">
                <Text className="fz16 fw600 white87" height="16px">
                  {modalInfo.nftTokenVO?.name || ''}
                </Text>
                <Row mt="20px">
                  <NFTImage src={modalInfo.nftTokenVO?.imageUrl} width={172} height={172} classN="mt8" />
                  <Right ml="20px">
                    <MobileInfoWrapper name="RANK" mt="0px">
                      <Row>
                        <ColorRank rankValue={modalInfo.rank} fz="16px" onlyValue />
                        <Text className="white87">/{totalRank}</Text>
                      </Row>
                    </MobileInfoWrapper>
                    <MobileInfoWrapper name="FLOOR PRICE">
                      <ETHValue value={floor} fw={600} mt={-0.5} fz={16} />
                    </MobileInfoWrapper>
                    <MobileInfoWrapper name="LAST TRADE PRICE">
                      <ETHValue value={modalInfo.lastPrice?.value} fw={600} mt={-0.5} fz={16} />
                    </MobileInfoWrapper>
                    <MobileInfoWrapper name="BREAK-EVEN PRICE">
                      <ETHValue value={eventPrice} fw={600} mt={-0.5} fz={16} />
                    </MobileInfoWrapper>
                  </Right>
                </Row>
                <Row mt="4px">
                  <Text className="fz12 fw400 white60">Owner:</Text>
                  <Text className="fz12 fw400 white38" ml="4px">
                    {splitAddress(`${modalInfo.nftTokenVO?.nftAddress}`, 6, 4)}
                  </Text>
                </Row>
                <Row mt="20px">
                  <Box className="absolute" style={{ left: '30px' }} mt="6px">
                    <Image
                      src={openseaIcon}
                      width={20}
                      height={20}
                      className="click"
                      onClick={() =>
                        openWindow(
                          `https://opensea.io/assets/${modalInfo.chain}/${modalInfo.nftTokenVO?.nftAddress}/${modalInfo.nftTokenVO?.tokenId}`
                        )
                      }
                    />
                  </Box>
                  <Buying
                    bRef={buyingRef}
                    price={modalInfo.price}
                    nftAddress={modalInfo.nftTokenVO?.nftAddress || '--'}
                    tokenId={modalInfo.nftTokenVO?.tokenId || '--'}
                    nftId={modalInfo.nftTokenVO?.nftId || 0}
                  />
                </Row>
              </Box>
            ) : (
              <Mask>
                <Row className="jc-sb">
                  <Text color="#FFFFFF" fontSize="16px" height="16px">
                    {modalInfo.nftTokenVO?.name || ''}
                  </Text>
                </Row>
                <Row className="jc-sb" mt="8px">
                  <Left className="fx-col jc-start">
                    <Text color="#A3A4A7" fontSize="14px">
                      {`#${beyondStr(modalInfo.nftTokenVO?.tokenId, 4)}`}
                    </Text>
                    <NFTImage src={modalInfo.nftTokenVO?.imageUrl} width={185} height={185} classN="mt8" />
                  </Left>
                  <Right>
                    <Owner>
                      <Box className="fx-row jc-sb">
                        <Row>
                          <Text fontSize="12px" color="#FFF">
                            Owner
                          </Text>
                          <Text fontSize="12px" color="#5F6165" ml="12px">
                            {splitAddress(`${modalInfo.nftTokenVO?.nftAddress}`, 6, 4)}
                          </Text>
                        </Row>
                        {/* <Image src={revealed === 0 ? revealed1 : revealed2} width={22} height={22} /> */}
                      </Box>
                      <Row>
                        <Image
                          src={openseaIcon}
                          width={16}
                          height={16}
                          className="click"
                          onClick={() =>
                            openWindow(
                              `https://opensea.io/assets/${modalInfo.chain}/${modalInfo.nftTokenVO?.nftAddress}/${modalInfo.nftTokenVO?.tokenId}`
                            )
                          }
                        />
                        <Buying
                          bRef={buyingRef}
                          price={modalInfo.price}
                          nftAddress={modalInfo.nftTokenVO?.nftAddress || '--'}
                          tokenId={modalInfo.nftTokenVO?.tokenId || '--'}
                          nftId={modalInfo.nftTokenVO?.nftId || 0}
                        />
                      </Row>
                    </Owner>
                    <InfoWrapper mt="12px">
                      <Row>
                        <InfoItem title="Rank">
                          <RankItem r={modalInfo.rank} t={totalRank} />
                        </InfoItem>
                        <InfoItem title="Last Trade Price" right={1} value={modalInfo.lastPrice?.value || '--'} />
                      </Row>
                      <Row>
                        <InfoItem title="Floor Price" bottom={1} value={floor} />

                        <InfoItem
                          tipTitle="B/E: The Break Even price is a selling price where you make neither a profit or loss.Sell at least for this price to recoup for the original price and all fees associated with the purchase and sale."
                          title="Break-Even Price"
                          bottom={1}
                          right={1}
                          value={eventPrice}
                        />
                      </Row>
                    </InfoWrapper>
                  </Right>
                </Row>
              </Mask>
            )}
          </Box>
        </Modal>
      )}
    </div>
  )
}
