import React, { useState } from 'react'
import styled from 'styled-components'
import { Box, Image, Text } from '@/packages'
import { nft, nftSlected, openseaDark } from '@/assets/svg'

const Container = styled(Box)`
  width: 302px;
  height: 356px;
  background: #28272f;
  border-radius: 4px;
`
const ImageWrapper = styled(Box)`
  position: relative;
  height: 302px;
  width: 302px;
`
const MaskTop = styled(Box)`
  position: absolute;
  top: 0;
  width: 100%;
  height: 47px;
  background: rgba(47, 46, 66, 1);
  border-radius: 4px 4px 0px 0px;
`
const MaskBottom = styled(Box)`
  position: absolute;
  width: 100%;
  height: 28px;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(3px);
`
const No = styled(Box)`
  position: absolute;
  width: 50px;
  height: 24px;
  left: 10px;
  bottom: 38px;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(3px);
  border-radius: 18px;
  color: #e5e1e6;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const InfoContainer = styled(Box)`
  width: 100%;
  height: 54px;
  background: #28272f;
`
export const BuyNowBtn = styled(Box)<{ width: number }>`
  width: ${({ width }) => `${width}px`};
  height: 32px;
  background: #c4c0ff;
  border-radius: 4px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #292377;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const NFTCard = () => {
  const [isMakeOffer, setIsMakeOffer] = useState<Boolean>(true)
  const [isSlected, setIsSlected] = useState<Boolean>(false)
  const selectNFT = () => {
    setIsSlected(!isSlected)
  }
  return (
    <Container className="fx-col">
      <ImageWrapper className="click" onClick={selectNFT}>
        {isSlected && (
          <MaskTop className="center">
            <Image src={nftSlected} />
          </MaskTop>
        )}
        <MaskBottom className="fx-row ai-ct" px="10px">
          <Text fontSize="14px" color="#E5E1E6">
            Rarity Rank
          </Text>
          <Text fontSize="14px" color="#E5E1E6" bold ml="10px">
            12/10000,00
          </Text>
        </MaskBottom>
        <No>#110</No>
        <Image width={302} height={302} src={nft} />
      </ImageWrapper>
      <InfoContainer className="fx-row ai-ct jc-sb" px="10px">
        <Box className="fx-row">
          <Image src={openseaDark} />
          {!isMakeOffer && (
            <Text fontSize="18px" color="#E5E1E6" ml="10px" bold>
              5.123
            </Text>
          )}
        </Box>
        {isMakeOffer ? <BuyNowBtn width={248}>Make Offer</BuyNowBtn> : <BuyNowBtn width={92}>Buy Now</BuyNowBtn>}
      </InfoContainer>
    </Container>
  )
}
