import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { createPortal } from 'react-dom'
import { useNavigate } from 'react-router-dom'
import { Box, Text, Image } from '@/packages/'
import { close, isOS, arrowRight, arrowDown } from '@/assets/img'
import { Row, Col } from '@/components/Layout/Layout'
import { someTimesAgo, beyondStr } from '@/utils/tools'
import { useAppContext } from '@/hooks/useAppContext'
import { AddAlertAction } from '@/store/actions/DashbordAction'
import { useMatchBreakpoints } from '@/hooks/useMatchBreakpoints'

import './index.css'

const AlertItem = styled(Box)`
  height: 88px;
  padding: 10px;
  cursor: pointer;
`
const GoSnipe = styled(Box)`
  width: 102px;
  height: 34px;
  background: #00b2b5;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`
const AlertBtnWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 96px;
  height: 24px;
  background: #474747;
  border-radius: 12px;
  cursor: pointer;
  margin-left: 6px;
  margin-bottom: 6px;
`
interface iAlertBtn {
  name: string
  img: string
  onHandle: () => void
}
interface iAlert {
  onAlertBody: () => void
  onCloseCurrentAlert: (address: string) => void
  cn?: string
  triggerTime: number
  triggerPrice: number
  verifyStatus: number
  nftId: number | string
  nftAddresses: string
  name: string
  imageUrl: string
}
interface iAlertList {}
export const AlertBtn: React.FC<iAlertBtn> = ({ name, img, onHandle }) => {
  return (
    <AlertBtnWrapper onClick={onHandle}>
      <Text color="#fff" fontSize="12px" mr="6px">
        {name}
      </Text>
      <Image src={img} height={10} width={10} />
    </AlertBtnWrapper>
  )
}
export const Alert: React.FC<iAlert> = ({
  onAlertBody,
  onCloseCurrentAlert,
  cn,
  triggerTime,
  triggerPrice,
  verifyStatus,
  nftId,
  nftAddresses,
  name,
  imageUrl
}) => {
  const navigate = useNavigate()

  const onCloseImg = (event: any) => {
    event.stopPropagation()
    onCloseCurrentAlert(nftAddresses)
  }
  const onGoSnipe = (event: any) => {
    event.stopPropagation()
    nftId && navigate(`/trending/liveview/${nftId}`)
  }
  return (
    <AlertItem className={cn} onClick={onAlertBody} pl="16px" pr="12px">
      <Row className="jc-sb">
        <Row>
          <Text color="#fff" fontSize="12px">
            WATCHLIST ALERT
          </Text>
          <Text color="#fff" fontSize="12px" ml="10px">
            {someTimesAgo(triggerTime)}
          </Text>
        </Row>
        <Image src={close} className="click" width={10} height={10} onClick={onCloseImg} />
      </Row>
      <Row className="jc-sb" mt="10px">
        <Row>
          <Image src={imageUrl} height={40} width={40} className="circle click" />
          <Col ml="16px">
            <Row>
              <Text color="#fff" fontSize="14px" bold mr="4px">
                {beyondStr(name, 15)}
              </Text>
              {verifyStatus === 1 && <Image src={isOS} width={16} height={16} />}
            </Row>
            <Text color="#fff" fontSize="14px">
              {`Floor price is ${triggerPrice}`}
            </Text>
          </Col>
        </Row>
        <GoSnipe onClick={onGoSnipe} ml={['24px', '44px', '68px', '68px']}>
          <Text color="#fff" fontSize="12px" mr="4px">
            GO SNIPE
          </Text>
          <Image src={arrowRight} height={8} width={5} />
        </GoSnipe>
      </Row>
    </AlertItem>
  )
}

const AlertContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [dom, setDom] = useState(document.createElement('div'))
  const { isMobile } = useMatchBreakpoints()
  useEffect(() => {
    dom.setAttribute('id', 'portal')
    document.body.appendChild(dom)
  })
  return createPortal(
    <Col
      className="h100 hide_scrollbar fx"
      style={{
        overflow: 'scroll',
        justifyContent: isMobile ? 'flex-end' : 'flex-start',
        paddingTop: isMobile ? '0px' : '80px'
      }}
    >
      {children}
    </Col>,
    dom
  )
}

export const AlertList: React.FC<iAlertList> = () => {
  const [list, setList] = useState<Array<any>>([])
  const [isShowAll, setShowAll] = useState<boolean>(false)

  const { state, dispatch } = useAppContext()

  useEffect(() => {
    const len = state?.DashbordReducer?.alertList.length
    if (len === 0) {
      setShowAll(false)
      setList([])
    } else if (len === 1) {
      setShowAll(false)
      setList(state.DashbordReducer.alertList)
    } else {
      setList(state.DashbordReducer.alertList)
    }
  }, [state?.DashbordReducer?.alertList])
  const onShowLess = () => {
    setShowAll(false)
  }
  const onCloseAll = () => {
    AddAlertAction({ closeAll: null }, dispatch)
  }
  const onShowAll = () => {
    setShowAll(true)
  }
  const onCloseCurrentAlert = (address: any) => {
    AddAlertAction({ close: address }, dispatch)
  }
  const onAlertBody = () => {
    if (list.length > 1 && !isShowAll) {
      setShowAll(true)
    }
  }
  useEffect(() => {
    return () => onCloseAll()
  }, [])
  //
  return (
    <AlertContainer>
      <Col mt="6px" key={Math.random()}>
        {isShowAll ? (
          <>
            <Box className="fx-row jc-end">
              <AlertBtn name="Show less" img={arrowDown} onHandle={onShowLess} />
              <AlertBtn name="Close all" img={close} onHandle={onCloseAll} />
            </Box>
            {list.length > 1 &&
              list.map((item: any) => (
                <Alert
                  key={Math.random()}
                  onAlertBody={() => null}
                  cn="alert_bg"
                  onCloseCurrentAlert={onCloseCurrentAlert}
                  triggerTime={item.triggerTime}
                  triggerPrice={item.triggerPrice}
                  verifyStatus={item.nft.verifyStatus}
                  nftId={item.nft.nftId}
                  nftAddresses={item.nft.nftAddresses}
                  name={item.nft.name}
                  imageUrl={item.nft.imageUrl}
                />
              ))}
          </>
        ) : (
          <div>
            {list.length > 0 && (
              <Alert
                onAlertBody={onAlertBody}
                cn={list.length === 1 ? 'alert_bg' : 'alert_less'}
                onCloseCurrentAlert={onCloseCurrentAlert}
                triggerTime={list[0].triggerTime}
                triggerPrice={list[0].triggerPrice}
                verifyStatus={list[0].nft.verifyStatus}
                nftId={list[0].nft.nftId}
                nftAddresses={list[0].nft.nftAddresses}
                name={list[0].nft.name}
                imageUrl={list[0].nft.imageUrl}
              />
            )}
          </div>
        )}
      </Col>
    </AlertContainer>
  )
}
