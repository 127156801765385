import { toNonExponential } from '@/utils/tools'
import { ChartBgColor, axisLabel, fullGrid, axisLine, axisTick, splitLine, axisLabel2, tooltipBg } from './types'
import { eth } from '@/assets/img'

function getBarValue(list: Array<number>, idx: number) {
  let res = 0
  for (let i = 0; i < list.length - idx; i++) {
    res += list[i]
  }
  return res
}

export const ListingDepthLadderOption = (options: any[]): any => {
  const Depth0: Array<number> = []
  const Depth1: Array<any> = []
  const xAxis: Array<string> = []

  const lenIdx: number = options.length - 1
  const lastBarItem: any = options[lenIdx]
  const firstBarItem: any = options[0]

  options.forEach((item: any, idx: number) => {
    const { max, min } = item.priceRange
    const x: string = idx === lenIdx ? ` ` : `${max}`
    // const x: string = idx === lenIdx ? `≥${min}` : `${max}`
    xAxis.push(x)
    Depth1.push([x, item.count, item])
  })

  if (Depth1.length > 0) {
    const Depth1Arr: any[] = [0]
    Depth1.forEach((d: any, i: number) => {
      Depth1Arr.push(d[1])
    })
    for (let i = Depth1Arr.length - 1; i > 0; i--) {
      Depth0.push(getBarValue(Depth1Arr, i))
    }
  }

  return {
    backgroundColor: ChartBgColor,
    tooltip: {
      backgroundColor: tooltipBg,
      borderRadius: 12,
      borderWidth: 0,
      padding: 0,
      borderColor: 'transparent',
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      },
      formatter(value: any) {
        const item0 = value[0].value
        const item = value[1].value
        const { priceRange, count } = item[2]
        const { min: _min, max: _max } = priceRange
        const { priceRange: lastPriceRange, count: lastCount } = lastBarItem
        const { min: lastMin, max: lastMax } = lastPriceRange

        const { priceRange: firstPriceRange, count: firstCount } = firstBarItem
        const { min: firstMin, max: firstMax } = firstPriceRange

        let rangeItem: string = ''

        if (count === lastCount && _min === lastMin && _max === lastMax) {
          // 最后一个
          rangeItem = `Price in Ξ ≥ ${toNonExponential(_min)}`
        } else if (count === firstCount && _min === firstMin && _max === firstMax) {
          // 第一个
          rangeItem = `Price in Ξ < ${toNonExponential(_max)}`
        } else {
          rangeItem = `${toNonExponential(_min)} ≤ Price in Ξ < ${toNonExponential(_max)}`
        }
        // Ξ
        const temp = `
          <div class="custom_tooltip_container" style="padding: 10px;">
            <div id="tooltip" style="color: rgb(30,47,50);">
              ${rangeItem}
            </div>
            <div class="fx-row jc-sb mt5">
              <div id="tooltip" style="color: rgb(30,47,50);">Count</div>
              <div id="tooltip" style="color: rgb(30,47,50);">${item[1] || 0}</div>
            </div>
            <div class="fx-row jc-sb mt5">
              <div id="tooltip" style="color: rgb(30,47,50);">Cumulative</div>
              <div id="tooltip" style="color: rgb(30,47,50);">${item0 + item[1] || 0}</div>
            </div>
          </div>
        `
        return temp
      }
    },
    xAxis: {
      data: xAxis,
      axisLabel: Object.assign(axisLabel, { interval: 0, rotate: 0 }),
      axisLine,
      axisTick
    },
    yAxis: {
      type: 'value',
      splitLine,
      axisLabel
    },
    grid: fullGrid,
    series: [
      {
        name: 'Depth',
        type: 'bar',
        barWidth: 10,
        color: '#0F0F12',
        stack: 'Total',
        itemStyle: {
          color: '#363636'
        },
        emphasis: {
          itemStyle: {
            borderColor: 'transparent',
            color: '#A4A4A4'
          }
        },
        data: Depth0
      },
      {
        name: 'Depth',
        type: 'bar',
        barWidth: 10,
        color: '#0F0F12',
        showBackground: false,
        stack: 'Total',
        label: {
          show: false
        },
        itemStyle: {
          normal: {
            borderRadius: [2, 2, 0, 0],
            color: '#0B7677'
          }
        },
        data: Depth1
      }
    ]
  }
}
