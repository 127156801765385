import React from 'react'
import { WALLET_LOGIN, CLICK_ROOT, ACCESS_AUTH } from '@/store/common'
import { walletLoginApi, walletLoginWithoutSignApi } from '@/request/Api'
import { AppActionType, AppActionAddress, iWalletLogin } from '@/types/store'

export const WalletLoginWithoutSignAction = async (data: iWalletLogin, dispatch: React.Dispatch<AppActionType>) => {
  return dispatch({
    type: WALLET_LOGIN,
    payload: {
      walletLogin: data
    }
  })
}
// 访问限制
export const AccessAuthAction = async (accessAuth: boolean, dispatch: React.Dispatch<AppActionType>) => {
  return dispatch({
    type: ACCESS_AUTH,
    payload: {
      accessAuth
    }
  })
}

export const clickRootAction = async (status: boolean, dispatch: React.Dispatch<AppActionType>) => {
  return dispatch({
    type: CLICK_ROOT,
    payload: {
      clickRootStatus: status
    }
  })
}
