import * as echarts from 'echarts'
import { grid, axisTick, axisLabel, tooltipBg } from './types'
import { dateList, unitTime, toNonExponential } from '@/utils/tools'

export const TrendingVolumeOption = (params: any): any => {
  const { values, dates } = params
  const valList: Array<number> = []
  const newVal: Array<number> = []
  values.forEach((item: any) => newVal.push(Number(item)))
  newVal.forEach((val: number) => valList.push(val === 0 ? Math.max(...newVal) * 0.1 : val))
  return {
    // backgroundColor: ChartBgColor, // 图表背景色
    toolbox: {
      show: false
    },
    tooltip: {
      backgroundColor: tooltipBg,
      borderRadius: 12,
      borderWidth: 0,
      padding: 0,
      borderColor: 'transparent',
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      },
      position: [10, 48],
      formatter(value: any) {
        let temp = ''
        const time = unitTime(value[0].axisValue)

        temp = `
          <div class="custom_tooltip_container" style="padding: 10px;">
            <div id="tooltip" style="color: rgb(30,47,50);margin-bottom: 5px">${time}</div>
            <div class="fx-row jc-sb" style="margin-top: 5px;">
              <div id="tooltip" style="color: rgb(30,47,50);">${value[0].seriesName}</div>
              <div id="tooltip" style="color: rgb(30,47,50);">${toNonExponential(value[0].value)}</div>
            </div>
          </div>
        `
        return temp
      }
    },
    xAxis: {
      data: dates,
      axisLabel,
      axisTick
    },
    yAxis: {
      show: false
    },
    grid: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0
    },
    series: [
      {
        data: valList,
        type: 'bar',
        name: 'VOLUME',
        color: '#00B2B5',
        itemStyle: {
          borderRadius: [2, 2, 0, 0],
          normal: {
            color: '#00B2B5'
          }
        }
      }
    ]
  }
}
