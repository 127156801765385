import { combineReducers } from '@/utils/combineReducers'
import { navInitState, NavReducer } from './NavReducer'
// import { NFTInitState, NFTReducer } from './NFTReducer'
// import { WalletReducer } from './WalletReducer'
import { dashInitState, DashbordReducer } from './DashbordReducer'
import { initHotCollection, TrendingDashbordReducer } from './Trendingducer'

import { liveviewInitState, LiveViewReducer } from './LiveViewReducer'
import { initUpcomingState, UpcomingReducer } from './UpcomingReducer'

export const AppInitState = { ...dashInitState, ...liveviewInitState, ...initHotCollection, ...initUpcomingState }

// export const AppInitState = {}
export const AppReducer = combineReducers({
  NavReducer,
  DashbordReducer,
  LiveViewReducer,
  TrendingDashbordReducer,
  UpcomingReducer
})
