import React, { useState, useEffect } from 'react'
import { Navigate, useRoutes, HashRouter } from 'react-router-dom'
import { Nav, NavMobile } from '@/components/Layout/Nav'
import { Footer } from '@/components/Layout/Footer'
import { AccessModal } from '@/components/Layout/Nav/AccessModal'
import { NotFound } from '@/views/NotFound'
import { Dashboard } from '@/views/Dashboard'
import { Trending } from '@/views/Trending'
import { Upcoming } from '@/views/Upcoming'
import { Invite } from '@/views/Invite'
import { InvitationInfo } from '@/views/Invite/InvitationInfo'
import { Aboutus } from '@/views/Aboutus'
import { LiveView } from '@/views/Trending/LiveView'
import { MKT } from '@/views/Dashboard/MKT'
import { GoogleAnalytics } from '@/components/GoogleAnalytics'
import { useGoogleAnalytics } from '@/hooks/useGoogleAnalytics'
import { BodyWrapper } from '@/components/Layout/Layout'
import { useAppContext } from '@/hooks/useAppContext'
import { locaStoreageGetItem, locaStoreageRemoveItem } from '@/utils/tools'
import { AccessAuthAction } from '@/store/actions/NavAction'
import { useMatchBreakpoints } from '@/hooks/useMatchBreakpoints'
import { OpenNotification, CloseNotification } from '@/components/OpenNotification'
import { useChainChanged } from '@/hooks/useChainChanged'
import { useAccountsChange } from '@/hooks/useAccountsChange'
import { isMainNet } from '@/utils/chain'
import { useEthereum } from '@/hooks/useEthereum'

interface RouteObject {
  caseSensitive?: boolean
  children?: RouteObject[]
  element?: React.ReactNode
  index?: boolean
  path?: string
  isLink?: string
}

export const rootRouter: RouteObject[] = [
  {
    path: '/',
    element: <Dashboard />
  },
  {
    path: '/dashboard',
    element: <Dashboard />
  },
  {
    path: '/dashboard/mkt',
    element: <MKT />
  },
  {
    path: '/trending',
    element: <Trending />
  },
  {
    path: '/trending/liveview/:nftId',
    element: <LiveView />
  },
  {
    path: '/upcoming',
    element: <Upcoming />
  },
  {
    path: '/invite',
    element: <Invite />
  },
  {
    path: '/invite/info',
    element: <InvitationInfo />
  },
  {
    path: '/aboutUs',
    element: <Aboutus />
  },
  {
    path: '*',
    element: <NotFound />
  }
]

const Router = () => {
  const routes = useRoutes(rootRouter)
  return routes
}

export function AppRouter() {
  const [isAccess, setAccess] = useState(false)
  const [isLoading, setLoading] = useState<number>(0)
  const { init } = useGoogleAnalytics()
  const { state, dispatch } = useAppContext()
  const { isMobile, isTablet } = useMatchBreakpoints()
  const chainId = useChainChanged()
  const accounts = useAccountsChange()
  const { ethereum } = useEthereum()

  useEffect(() => {
    if (locaStoreageGetItem('tokens')) {
      // console.log('chainId', chainId)
      // console.log('accounts', accounts)
      if (accounts.length === 0) {
        if (chainId === '0x1') {
          CloseNotification('switchChainMsg')
          onConnectWallet()
        } else {
          tryToSwitchChain()
        }
      } else if (chainId === '0x1') {
        CloseNotification('switchChainMsg')
      } else {
        OpenNotification('Please switch the network to the Ethereum Mainnet.', 'warning', 'never', 'switchChainMsg')
      }
    }
  }, [chainId, accounts])

  useEffect(() => {
    if (state?.NavReducer?.walletLogin) {
      if (state.NavReducer.walletLogin) {
        const { walletLogin } = state.NavReducer
        if (walletLogin.code === '990004' || walletLogin.code === '000004') {
          OpenNotification(walletLogin.msg, 'error')
          locaStoreageRemoveItem('tokens')
          AccessAuthAction(false, dispatch)
          setLoading(1)
        } else if (walletLogin.accessToken) {
          setLoading(2)
          OpenNotification('Login successful', 'success')
          setAccess(true)
          AccessAuthAction(true, dispatch)
        }
      }
    }
  }, [state?.NavReducer?.walletLogin])

  useEffect(() => {
    const t: any = locaStoreageGetItem('tokens')
    // if(window.location.hash.includes('aboutUs')) {
    //   return setLoading(2)
    // }
    if (t && t.accessToken) {
      setLoading(2)
      setAccess(true)
      AccessAuthAction(true, dispatch)
    } else {
      setLoading(1)
    }
  }, [])
  const onConnectWallet = async () => {
    await ethereum.request({ method: 'eth_requestAccounts' })
  }
  const tryToSwitchChain = async () => {
    if (ethereum) {
      try {
        await ethereum.request({ method: 'wallet_switchEthereumChain', params: [{ chainId: '0x1' }] })
      } catch (error) {
        OpenNotification('Please switch the network to the Ethereum Mainnet.', 'warning', 'never', 'switchChainMsg')
      }
    }
  }
  return (
    <HashRouter>
      <GoogleAnalytics init={init}>
        {isMobile || isTablet ? <NavMobile /> : <Nav />}
        <BodyWrapper mt={`${90 + 1}px`}>
          {isLoading === 0 && null}
          {isLoading === 1 && <AccessModal />}
          {isLoading === 2 && <Router />}
        </BodyWrapper>
        {isLoading === 2 && <Footer />}
      </GoogleAnalytics>
    </HashRouter>
  )
}
