import React, { useEffect, useState, ChangeEvent } from 'react'
import styled from 'styled-components'
import { useNavigate, useLocation } from 'react-router-dom'
import { Table as AntTable, Input as AntInput, notification } from 'antd'
import { SmileOutlined } from '@ant-design/icons'
import { InvitationBar, InvitationEntrance } from './components'
import { ContentWrapper, Col, Row, Divider } from '@/components/Layout/Layout'
import { Flex, Box, Grid, Text, Image } from '@/packages/'
import { avator, twitterWhite, save, saveActive, whale, pencil } from '@/assets/img'
import { NoData } from '@/components/NoData'
import {
  memberInfoApi,
  inviteRecordApi,
  getMenmberInfoApi,
  updateMenmberInfoApi,
  invitationMemberApi
} from '@/request/Api'
import { AlignRightCell } from '../Dashboard/Collection/components'
import { useMatchBreakpoints } from '@/hooks/useMatchBreakpoints'
import { CopyContent, CopyContentMobile } from '@/components/CopyContent'
import { InviteBtn } from './index'
import { addId4Array, someTimesAgo, share2Twitter, splitAddress } from '@/utils/tools'
import { useEthereum } from '@/hooks/useEthereum'
import { OpenNotification } from '@/components/OpenNotification'

const InvitaInfoWrapper = styled(Box)``
const InvitaFormWrapper = styled(Box)``
const RecordTable = styled(Box)``
const LifttimePass = styled(Box)`
  width: 104px;
  height: 29px;
  background: rgba(52, 199, 123, 0.08);
  border: 1px solid rgba(52, 199, 123, 0.38);
  border-radius: 38px;
  font-weight: 400;
  font-size: 12px;
  color: #34c77b;
  margin-top: 12px;
`
interface iFormRow {
  name: string
  children: React.ReactNode
  mt?: number | string
}
interface iInviteCounts {
  inviterId: number // 受邀者数量
  invitation: number // 邀请者数量
  rank: number
}
const FormRow: React.FC<iFormRow> = ({ name, children, mt }) => {
  return (
    <Row className="jc-sb" mt={mt || '10px'}>
      <Box width="30%">
        <Text className="fz14 white60 fw400">{name}</Text>
      </Box>
      <Box width="70%">{children}</Box>
    </Row>
  )
}
const FormRowMobile: React.FC<iFormRow> = ({ name, children, mt }) => {
  return (
    <Row className="jc-sb" mt={mt || '10px'}>
      <Box width="40%">
        <Text className="fz14 white60 fw400">{name}</Text>
      </Box>
      <Box width="60%">{children}</Box>
    </Row>
  )
}

export const Container = styled(Box)`
  max-width: 900px;
  width: 100%;
`

interface iRecord {
  id: number
  firstLogin: number
  invitee: string
  inviteeId: number
  inviterId: number
  isWhale: boolean
}
export const InvitationInfo = () => {
  const [dcValue, setDCValue] = useState<string>('')
  const [isLoading, setLoading] = useState(true)
  const [isSaveActive, setSaveActive] = useState(false)
  const [recordList, setRecordList] = useState<Array<any>>([])
  const [address, setAddress] = useState<string>('')
  const [invitationLink, setInvitationLink] = useState<string>('')
  const [inviteCounts, setInviteCounts] = useState<iInviteCounts>({
    inviterId: 0,
    invitation: 0,
    rank: 0
  })
  const [isEditDC, setEditDC] = useState<boolean>(true)

  const { isMobile, isTablet, isDesktop } = useMatchBreakpoints()
  const { ethereum, provider } = useEthereum()
  // const location = useLocation()

  const fetch = async () => {
    setLoading(true)
    const m: any = await memberInfoApi()
    const m2: any = await getMenmberInfoApi()
    const r: any = await inviteRecordApi(10000, 1)
    const i: any = await invitationMemberApi()
    setLoading(false)
    if (m) {
      const { address: addr } = m
      setAddress(addr)
      setInvitationLink(`${window.location.origin}/?inviter=${addr}`)
    }
    if (m2) {
      const { discordId } = m2
      setDCValue(discordId)
    }
    if (r && r.records && !!r.records.length) {
      setRecordList(addId4Array(r.records))
    }

    if (i && Object.keys(i).length > 0) {
      setInviteCounts(i)
    }
  }

  useEffect(() => {
    fetch()
  }, [])

  const dcChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value
    if (val === '') {
      setSaveActive(false)
      setDCValue('')
    } else {
      // string + # + number
      const reg: any = /^[a-zA-Z\d]+#+[0-9]+$/
      if (reg.test(val) && val.length >= 2 && val.length <= 40) {
        setSaveActive(true)
      } else {
        setSaveActive(false)
      }
    }
    setDCValue(val)
  }
  const onDCSave = async () => {
    // string + # + number
    const reg: any = /^[a-zA-Z\d]+#+[0-9]+$/
    if (dcValue.length < 2 || dcValue.length > 40) {
      return OpenNotification('Characters length must be between 2 and 40.', 'warning')
    }
    if (!reg.test(dcValue)) {
      return OpenNotification('Please enter the correct Discord ID', 'warning')
    }
    const d = await updateMenmberInfoApi({ discordId: dcValue })
    if (d) {
      OpenNotification('Your Discord ID is saved.', 'success')
      setEditDC(true)
    }
  }

  const onEditDC = () => {
    setEditDC(false)
  }
  const columns: any = [
    {
      title: 'NO.',
      width: isMobile ? '20%' : '10%',
      render: (item: iRecord) => (
        <Box className="w100 fx ai-start">
          <Text className="fz14 fw400 white87">{Number(item.id) + 1}</Text>
        </Box>
      )
    },
    {
      title: isMobile ? 'INVITED' : 'INVITED WALLET ADDRESS',
      width: '45%',
      render: (item: iRecord) => (
        <Row className="w100 fx jc-start">
          <Text className="white87 fw400" fontSize={['12px', '12px', '14px', '14px']}>
            {!isDesktop && !isTablet && !isMobile && item.invitee}
            {isDesktop && item.invitee}
            {isTablet && splitAddress(item.invitee, 10, 10)}
            {isMobile && splitAddress(item.invitee, 4, 4)}
          </Text>
          {item.isWhale && <Image src={whale} height={12} width={18} className="ml16" />}
        </Row>
      )
    },
    {
      title: isMobile ? 'TIME' : 'FIRST CONNECT TIME',
      dataIndex: 'firstLogin',
      width: isMobile ? '35%' : '40%',
      render: (i: number) => (
        <Box className="w100 fx jc-end beyondHide">
          <Text className="fz14 white87 fw400">{someTimesAgo(i)}</Text>
        </Box>
      )
    }
  ]

  return (
    <Box className="w100 fx jc-ct">
      <Container className="fx-col ">
        {process.env.REACT_APP_PEWEE_ENV !== 'prod' && <InvitationEntrance />}
        {isMobile || isTablet ? (
          <Col className="w100 ai-ct">
            <Text
              className="fw600 white87 tc"
              mb="24px"
              mt={['32px', '32px', '40px', '40px']}
              fontSize={['20px', '22px', '24px', '24px']}
            >
              Your Invitation Information
            </Text>
            <InvitaInfoWrapper>
              <Col className="ai-ct">
                <Image src={avator} height={120} width={120} className="cicle" />
                {/* <LifttimePass className="center">Lifetime Pass</LifttimePass> */}
              </Col>
            </InvitaInfoWrapper>
            <Col>
              <InvitaFormWrapper
                width="340px"
                height="142px"
                mt="32px"
                className="fx-col jc-ct body_wrapper"
                px={['12px', '12px', '24px', '24px']}
              >
                <FormRowMobile name="Wallet Address" mt="0px">
                  <CopyContentMobile content={address} />
                </FormRowMobile>
                <Divider />
                <FormRowMobile name="Discord ID">
                  {isEditDC ? (
                    <Row className="" px="12px">
                      <Text width="262px" height="42px" className="fx ai-ct beyondHide fz14 fw400 white87" mr="10px">
                        {dcValue}
                      </Text>
                      <Image src={pencil} height={14} width={14} className="click" onClick={() => setEditDC(false)} />
                    </Row>
                  ) : (
                    <Row>
                      <AntInput
                        bordered
                        value={dcValue}
                        onChange={dcChange}
                        placeholder="It should be between 2 and 40"
                        className="mask_input w100 borderRadius20"
                        suffix={
                          <Image
                            src={isSaveActive ? saveActive : save}
                            onClick={onDCSave}
                            height={14}
                            width={14}
                            className="ml8 click"
                          />
                        }
                      />
                    </Row>
                  )}
                </FormRowMobile>
                <Divider />
                <FormRowMobile name="Invitation Link">
                  <CopyContentMobile content={invitationLink} />
                </FormRowMobile>
              </InvitaFormWrapper>
              <Box mt="16px" onClick={() => share2Twitter(invitationLink)}>
                <InviteBtn>
                  SHARE
                  <Image src={twitterWhite} height={14} width={17} className="ml8" />
                </InviteBtn>
              </Box>
            </Col>
          </Col>
        ) : (
          <Col className="w100">
            <Text
              className="fw600 white87 tc"
              mb="24px"
              mt={['32px', '32px', '40px', '40px']}
              fontSize={['20px', '22px', '24px', '24px']}
            >
              Your Invitation Information
            </Text>
            <Row className="body_wrapper w100 fx-row ai-ct jc-sb" py="40px" px={['24px', '24px', '90px', '90px']}>
              <InvitaInfoWrapper>
                <Col className="ai-ct">
                  <Image src={avator} height={140} width={140} className="cicle" />
                  {/* <LifttimePass className="center">Lifttime Pass</LifttimePass> */}
                </Col>
              </InvitaInfoWrapper>
              <InvitaFormWrapper height="180px" className="fx-col jc-ct" ml={['10px', '20px', '40px', '60px']}>
                <FormRow name="Wallet Address" mt="0px">
                  <Box width="140px">
                    <CopyContent content={address} />
                  </Box>
                </FormRow>
                <FormRow name="Discord ID">
                  {isEditDC ? (
                    <Row>
                      <Text width="262px" height="40px" className="fx ai-ct beyondHide fz14 fw400 white87" mr="10px">
                        {dcValue}
                      </Text>
                      <Image src={pencil} height={14} width={14} className="click" onClick={() => setEditDC(false)} />
                    </Row>
                  ) : (
                    <Row>
                      <AntInput
                        bordered
                        value={dcValue}
                        onChange={dcChange}
                        placeholder="It should be between 2 and 40"
                        className="mask_input discord_input w100"
                      />
                      <Box width="72px" onClick={isSaveActive ? () => onDCSave() : () => null}>
                        <InviteBtn
                          ml="10px"
                          style={{
                            background: isSaveActive ? '#00B2B5' : '#252525',
                            color: isSaveActive ? '#fff' : 'rgba(255, 255, 255, 0.38)'
                          }}
                        >
                          SAVE
                        </InviteBtn>
                      </Box>
                    </Row>
                  )}
                </FormRow>
                <FormRow name="Invitation Link">
                  <Row>
                    <Box width="262px" className="">
                      <CopyContent content={invitationLink} />
                    </Box>
                    <Box width="120px" onClick={() => share2Twitter(invitationLink)}>
                      <InviteBtn ml="10px" px={['20px', '20px', '20px', '20px']}>
                        SHARE
                        <Image src={twitterWhite} height={14} width={17} className="ml8" />
                      </InviteBtn>
                    </Box>
                  </Row>
                </FormRow>
              </InvitaFormWrapper>
            </Row>
          </Col>
        )}

        <Text
          mt={['32px', '32px', '40px', '40px']}
          mb={['16px', '16px', '24px', '24px']}
          className="fz14 fw400 white87"
        >
          {inviteCounts.invitation > 0
            ? `You are the No.${inviteCounts.rank} inviter and have invited ${inviteCounts.invitation} people to join us. Thanks for your support！`
            : `Let's share pewee to your friend and you will have a chance to get the reward! Thanks for your support！`}
        </Text>

        <RecordTable
          className="w100 invite_rank_record"
          height="416px"
          background="rgba(255, 255, 255, 0.04)"
          mb={['32px', '32px', '60px', '60px']}
        >
          <AntTable
            className={isMobile || isTablet ? 'hide_scrollbar invite_rank_table' : 'invite_rank_table'}
            locale={{ emptyText: ' ' }}
            rowKey={(record) => record.id}
            pagination={false}
            columns={columns}
            dataSource={recordList}
            scroll={{ y: 370 }}
          />
          {!recordList.length && <NoData isLoading={isLoading} />}
        </RecordTable>
      </Container>
    </Box>
  )
}
