import React, { useRef, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'

import { Flex, Box, Grid, Text, Image } from '@/packages/'
import { someTimesAgo } from '@/utils/tools'
import { Row, Col, NFTImage } from '@/components/Layout/Layout'
import { listingIn1min } from '@/assets/img'
import { useMatchBreakpoints } from '@/hooks/useMatchBreakpoints'

interface iCardItem {
  imageUrl: string
  tokenId: string
  leftCompnent?: React.ReactNode
  rightCompnent?: React.ReactNode
  isFirst?: boolean
}

const TokenIdStyle = styled(Box)`
  background: rgba(255, 255, 255, 0.12);
  border-radius: 0px 0px 6px 6px;
  backdrop-filter: blur(10px);
`
const ItemContainer = styled(Box)`
  height: 126px;
  width: 100%;
  background: rgb(24, 24, 27);
`
const BodyContainer = styled(Box)``
export const ListCardItem: React.FC<iCardItem> = ({ imageUrl, tokenId, leftCompnent, rightCompnent, isFirst }) => {
  const { isMobile } = useMatchBreakpoints()
  return (
    <ItemContainer className="fx-col" pl="16px" pt="16px" mt={isFirst ? '0px' : '12px'}>
      <BodyContainer className="fx-row ai-ct jc-sb">
        <Row className="w100 ">
          <Col>
            <NFTImage src={imageUrl} height={isMobile ? 50 : 64} width={isMobile ? 50 : 64} classN="radius60" />
            <TokenIdStyle width={isMobile ? 50 : 64} height={isMobile ? 18 : 30} className="center">
              <Text fontSize={['12px', '12px', '14px', '14px']} className="fw400 white87">
                {tokenId}
              </Text>
            </TokenIdStyle>
          </Col>
          <Row className="w100 fx jc-sb" ml="10px">
            {leftCompnent}
            {rightCompnent}
          </Row>
        </Row>
      </BodyContainer>
    </ItemContainer>
  )
}

const TraderIn1MinContainer = styled(Box)`
  height: 28px;
  background: #1e2229;
  padding-left: 8px;
  max-width: 150px;
`
export const TraderIn1Min: React.FC<{ content: string; count: number }> = ({ content, count }) => {
  return (
    <Box>
      {count > 25 ? (
        <TraderIn1MinContainer className="fx-row ai-ct" mb="4px">
          <Image src={listingIn1min} height={12} width={12} />
          <Text color="#fff" fontSize={12} ml="6px">
            {content}
          </Text>
        </TraderIn1MinContainer>
      ) : (
        <Box height="28px" />
      )}
    </Box>
  )
}

const TextBox = styled(Box)`
  position: relative;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  transform: scale(1);
  box-shadow: rgb(76 181 93) 0px 0px 0px 0px;
`
interface iTitleDesc {
  title: string
  subTitle: string
  statusType: boolean
}

export const TitleDesc: React.FC<iTitleDesc> = ({ title, subTitle, statusType }) => {
  return (
    <Col>
      <Box className="fx-row ai-ct jc-sb" mb="10px" mt="10px">
        <Text color="#fff" fontSize="16px" />
        {statusType ? (
          <Row className="click ai-ct">
            <Text fontSize="12px" color="#00B2B5" ml="10px" mr="6px">
              LIVE FEED
            </Text>
            <TextBox className="col5 show_status" />
          </Row>
        ) : (
          <Row className="click ai-ct">
            <Text fontSize="12px" color="#FFE800" ml="10px" mr="6px">
              LIVE FEED PAUSED
            </Text>
            <TextBox className="col6" />
          </Row>
        )}
      </Box>
    </Col>
  )
}
