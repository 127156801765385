import { toNonExponential, unitTime, timeFormate2, splitAddress } from '@/utils/tools'
import {
  ChartBgColor,
  axisLine,
  axisTick,
  splitLine,
  tooltipBd1,
  tooltipBd2,
  tooltipBg,
  nameTextStyle,
  axisLabelDecimal,
  tooltipTextColor
} from './types'

function add0(list: Array<string>) {
  // const list = ['0','0.05','0.1','0.15','0.234','0.25']
  let len = 1
  let index = 0
  list.forEach((item: string, idx: number) => {
    if (item.length > len) {
      len = item.length
      index = idx
    }
  })

  const newList: Array<string> = []
  list.forEach((item: string) => {
    const times = len - item.length
    if (item !== '0') {
      if (times > 0) {
        const aa = '0'.repeat(times)
        newList.push(`${item}${aa}`)
      } else {
        newList.push(item)
      }
    } else {
      newList.push('0')
    }
  })

  return newList
}

const yList: Array<string> = []

export const TradesOption = (params: any, markPrice?: number): any => {
  const { volumes, trades, dates, coin } = params
  const TradeSource: any[] = []

  // const VolumesSource: any[] = []
  // volumes.forEach((v: any, i: number) => VolumesSource.push([dates[i], v]))
  // const _dates = dateList2(dates)

  trades.forEach((t: any, i: number) => {
    TradeSource.push([t.dataTime, t.price, t])
  })

  // for (let j = 0; j < trades.length; j++) {
  //   const tDate = trades[j].dataTime
  //   const tPrice = trades[j].price
  //   for (let i = 0; i < dates.length; i++) {
  //     if (tDate > dates[i] && tDate < dates[i + 1]) {
  //       TradeSource.push([dates[i], tPrice, trades[j]])
  //     }
  //   }
  // }

  // console.log('TradeSource', TradeSource)
  return {
    backgroundColor: ChartBgColor,
    tooltip: {
      backgroundColor: tooltipBg,
      borderColor: tooltipBd2,
      borderRadius: 12,
      trigger: 'item',
      // axisPointer: {
      //   type: 'cross',
      //   crossStyle: {
      //     color: '#A3A4A7'
      //   }
      // },
      formatter(value: any) {
        const item: any = value.value[2]
        const temp = `
          <div style="padding: 4px 6px; color: ${tooltipTextColor}">
            <div class="fx-row jc-sb mt5">
              <div id="tooltip">Token ID</div>
              <div id="tooltip" >#${
                item.nftToken
                  ? item.nftToken.tokenId.length > 10
                    ? splitAddress(item.nftToken.tokenId)
                    : item.nftToken.tokenId
                  : '--'
              }</div>
            </div>
            <div class="fx-row jc-sb mt5">
              <div id="tooltip">Sold</div>
              <div id="tooltip" >Ξ ${item?.price ? toNonExponential(item?.price) : 0}</div>
            </div>
            <img src="${
              item?.nftToken?.imageUrl
            }" style="width: 110px; height: 110px; margin-top: 10px;border-radius: 6px;"/>
          </div>
        `
        return temp
      }
    },
    grid: {
      left: '6%',
      right: 50,
      top: 30,
      bottom: 6
    },
    xAxis: [
      {
        type: 'category',
        axisLine,
        axisTick,
        // axisPointer: {
        //   label: { show: false }
        // },
        axisLabel: {
          show: true,
          textStyle: {
            color: 'rgba(255, 255, 255, 0.6)',
            fontSize: 14
          },
          formatter(value: any) {
            return `${timeFormate2(Number(value))}`
          }
        },
        data: dates,
        min: dates[0],
        max: dates[dates.length - 1],
        interval: dates[1] - dates[0]
      }
    ],
    yAxis: [
      {
        nameTextStyle: {
          color: 'rgba(255, 255, 255, 0.6)',
          fontSize: 14,
          fontWeight: 700,
          padding: 40
        },
        // axisPointer: {
        //   show: true,
        //   snap: true,
        //   label: {
        //     show: true,
        //     precision: 3,
        //     color: '#121212',
        //     fontWeight: 400,
        //     backgroundColor: '#fff',
        //     formatter: (f: any) => {
        //       return `Floor ${f.value.toFixed(3)}`
        //     }
        //   }
        // },
        nameLocation: 'center',
        type: 'value',
        name: 'PRICE Ξ',
        axisLabel: {
          formatter: (value: number) => axisLabelDecimal(value),
          textStyle: {
            color: 'rgba(255, 255, 255, 0.6)',
            fontSize: 14
          }
        },
        splitLine,
        axisTick,
        axisLine
      }
    ],

    series: [
      {
        name: 'Trade Price',
        type: 'scatter',
        symbolSize: 8,
        color: '#077274',
        data: TradeSource,
        markLine: {
          silent: true,
          symbol: 'none',
          precision: 4,
          label: {
            show: true,
            position: 'start',
            color: '#121212',
            fontWeight: 400,
            backgroundColor: '#fff',
            padding: [4, 6, 4, 6],
            borderRadius: 10,
            formatter: (f: any) => {
              return `${f.value.toFixed(3)}`
            }
          },
          lineStyle: {
            color: '#fff'
          },
          data: [
            {
              name: 'Floor Price',
              yAxis: markPrice
            }
          ]
        }
      }
    ]
  }
}
