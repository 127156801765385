export const errorCode: any = {
  '000000': '成功',
  '000001': '系统错误',
  '000002': '请求频率过快，请稍后再试',
  '000003': '非法参数',
  '000004': '登录超时',
  '000005': '请求失败',
  '000006': '请求失败',
  '000007': '不支持的请求方式',
  '000008': '请求参数缺失',
  '000009': '请求参数有误',
  '000012': '文件上传出错',
  '000013': '文件不存在',
  '000014': '上传的文件不能为空',
  '000015': '用户权限不合法',
  '000016': '您不是管理员用户，没有权限操作！',
  '000017': '输入的语言类型参数不合法',
  '600009': '钱包不存在',
  '600010': '可用余额不足',
  '600011': '获取地址错误',
  '600012': '手续费错误',
  '600013': '无用户',
  '600014': '请提供币种',
  '600015': '金额不能小于零',
  '600016': '用户申请提币记录完成',
  '600017': '用户资金账户出问题了',
  '600018': '用户提现失败',
  '600019': '参数错误',
  '600020': '服务不可用。如：服务不可用或请求超时等',
  '600021': '站内信： 查询用户不存在',
  '500000': 'NFT地址不存在',
  '330001': '当前币种不存在',
  '330002': '当前币种不允许提现',
  '330003': '手续费币种不存在',
  '330004': '链不存在',
  '330005': '币种已经存在',
  '330006': '链已存在',
  // '330006': '无法修改为另一币种',
  '990000': '登录url不支持',
  '990001': '未授权',
  '990002': '登出成功',
  '990003': '用户密码无效',
  '990004': '不存在该用户',
  '990005': '账户已过期',
  '990006': '用户证书未找到',
  '990007': '用户认证服务过期',
  '990008': '钱包地址不能为空',
  '990009': '不支持该认证渠道',
  '990010': '邮箱不能为空',
  '990011': '邮箱验证码不能为空',
  '990012': '邮箱验证码已过期',
  '990013': '邮箱验证码错误',
  '990014': '认证频道不能为空',
  '990015': '钱包地址无效',
  '990016': '邮箱无效',
  '300000': 'NFT地址不能为空',
  // '300000': 'NFT地址不存在',
  '400001': '用户已登出，请重新登录',
  '400002': '用户不存在',
  '400003': '密码找不到',
  '400004': '邮箱不能为空',
  '400005': '验证码不能为空',
  '400006': '密码不能为空',
  '400007': '邮箱已经被注册',
  '400008': '钱包地址不能为空',
  '400009': '邮箱或者密码错误',
  '400010': '邮箱不正确',
  '400011': '验证码已过期',
  '400012': '验证码不正确',
  '400013': '上级不存在',
  '400014': '没有管理员权限',
  '400015': '用户被禁用',
  '400016': '成地址已经被注册功',
  '400017': '密码不正确',

  '400018': '原密码不正确',
  '400019': '登录用户地址与传入地址不匹配',
  '400020': '请先购买NFT会员',
  '400021': '管理员不存在',
  '400022': '邮箱已被绑定',
  '1110001': '数值必须大于零'
  // '1110001': '价格不匹配'
}
