import React, { useState, useEffect, useImperativeHandle, useRef } from 'react'
import { SwitchButton } from '@/components/SwitchButton'
import { Row } from '@/components/Layout/Layout'
import { Box, Text } from '@/packages'

interface iChartLegend {
  bgColor?: string
  title: string
  onChange: (v: boolean) => void
  tooltip?: React.ReactNode
  type: string // dot / line
  cRef?: any
}
export const ChartLegend: React.FC<iChartLegend> = ({ cRef, bgColor, title, onChange, tooltip, type }) => {
  const switchRef: any = useRef()

  useImperativeHandle(cRef, () => ({
    resetStatus: () => {
      switchRef.current && switchRef.current.resetOpenVal()
    }
  }))
  return (
    <Row mr="24px">
      <SwitchButton onChange={onChange} defaultStatus sRef={switchRef}>
        {type === 'dot' && <Box width="6px" height="6px" className="circle" background={bgColor} />}
        {type === 'line' && <Box width="10px" height="2px" background={bgColor} />}
        <Text color="rgba(255, 255, 255, 0.87)" fontSize="14px" ml="6px" mr="10px">
          {title}
        </Text>
        {tooltip}
      </SwitchButton>
    </Row>
  )
}
