import * as echarts from 'echarts'

import { timeFormate, unitTime, toNonExponential, timeFormate2 } from '@/utils/tools'
import { ChartBgColor, grid, axisLine, axisTick, nameTextStyle, splitLine, axisLabel2, tooltipBg } from './types'

export const GasBurnOptions = (params: any): any => {
  const { nftGas, totalGas, dates } = params
  return {
    backgroundColor: ChartBgColor, // 图表背景色
    tooltip: {
      backgroundColor: tooltipBg,
      borderRadius: 12,
      borderWidth: 0,
      padding: 0,
      borderColor: 'transparent',
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      },
      formatter(value: any) {
        let temp = ''
        const time = unitTime(value[0].axisValue)
        if (value.length === 1) {
          temp = `
            <div class="custom_tooltip_container" style="padding: 10px">
              <div id="tooltip" style="color: rgb(30,47,50);margin-bottom: 5px">${time}</div>
              <div class="fx-row jc-sb" style="margin-top: 5px;">
                <div class="fx-row ai-ct">
                <div style="width: 10px; height: 2px; border-radius: 1px; background:${value[0].color} "></div>
                  <div id="tooltip" style="color: rgb(30,47,50); margin-left: 6px">${value[0].seriesName}</div>
                </div>
                <div id="tooltip" style="color: rgb(30,47,50);">${toNonExponential(value[0].value)}</div>
              </div>
            </div>
          `
        }
        if (value.length === 2) {
          temp = `
            <div class="custom_tooltip_container" style="padding: 10px">
              <div id="tooltip" style="color: rgb(30,47,50);margin-bottom: 5px">${time}</div>
              <div class="fx-row jc-sb" style="margin-top: 5px;">
                <div class="fx-row ai-ct">
                  <div style="width: 10px; height: 2px; border-radius: 1px; background:${value[0].color} "></div>
                  <div id="tooltip" style="color: rgb(30,47,50);margin-left: 6px">${value[0].seriesName}</div>
                </div>
                <div id="tooltip" style="color: rgb(30,47,50);">${toNonExponential(value[0].value)}</div>
              </div>
              <div class="fx-row jc-sb" style="margin-top: 5px;">
                <div class="fx-row ai-ct">
                  <div style="width: 10px; height: 2px; border-radius: 1px; background:${value[1].color} "></div>
                  <div id="tooltip" style="color: rgb(30,47,50); margin-left: 6px">${value[1].seriesName}</div>
                </div>
                <div id="tooltip" style="color: rgb(30,47,50);">${toNonExponential(value[1].value)}</div>
              </div>
            </div>
          `
        }
        return temp
      }
    },
    xAxis: {
      name: '',
      data: dates,
      axisLabel: {
        show: true,
        textStyle: {
          color: 'rgba(255, 255, 255, 0.6)',
          fontSize: 14
        },
        formatter(value: any) {
          // if (granularity.toLocaleLowerCase() === '1d') {
          //   return `${timeFormate2(Number(value))}`
          // }
          return `${timeFormate(Number(value), true)}`
        }
      },
      axisLine,
      axisTick
    },
    yAxis: [
      {
        type: 'value',
        name: '',
        nameTextStyle,
        splitLine,
        // min: 0,
        // max: getYAxisMax(crypto.list),
        axisLabel: axisLabel2
      },
      {
        type: 'value',
        name: '',
        nameTextStyle,
        splitLine,
        // min: 0,
        // max: getYAxisMax(nft.list, 1),
        axisLabel: axisLabel2
      }
    ],
    grid,
    series: [
      {
        name: 'NFTs Burned',
        type: 'line',
        smooth: true,
        yAxisIndex: 1,
        lineStyle: {
          width: 2,
          color: '#34C77B'
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: '#1f382e'
            },
            {
              offset: 1,
              color: '#1b1c1b'
            }
          ])
        },
        data: nftGas,
        color: '#34C77B'
      },
      {
        name: 'Total Burned',
        type: 'line',
        smooth: true,
        lineStyle: {
          width: 2,
          color: '#a4a4a4'
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: '#2b2b2b'
            },
            {
              offset: 1,
              color: '#1f1f1f'
            }
          ])
        },
        data: totalGas,
        color: '#A4A4A4'
      }
    ]
  }
}
