import React, { useEffect } from 'react'
import ReactGA from 'react-ga'
import { useLocation } from 'react-router'

interface googleProps {
  init: boolean
  children: React.PropsWithChildren<any>
}
export const GoogleAnalytics = (props: googleProps) => {
  const location = useLocation()
  useEffect(() => {
    if (props.init) {
      ReactGA.pageview(location.pathname + location.search)
    }
  }, [props.init, location])
  return props.children
}
