import React, { useState, useEffect } from 'react'
import { ethers } from 'ethers'

interface iState {
  provider: any
  ethereum: any
}
export const useEthereum = (): iState => {
  const [obj, setObj] = useState<iState>({ provider: null, ethereum: null })
  useEffect(() => {
    const { ethereum } = window as any
    if (typeof ethereum !== 'undefined') {
      const pd = new ethers.providers.Web3Provider(ethereum)
      setObj({ provider: pd, ethereum })
    } else {
      console.log('MetaMask didnot install')
    }
  }, [])
  // console.log('obj', obj)
  return obj
}
