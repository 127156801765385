import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { Box, Text, Image, Grid } from '@/packages/'
import { Row, Col, LoadingItemWrapper, ListingLoadWrapper, ETHValue } from '@/components/Layout/Layout'
import { openseaIcon, eth, openseaWhite, openseaActive } from '@/assets/img'
import { beyondStr, someTimesAgo, openWindow, toNonExponential } from '@/utils/tools'
import { ListingUnderFloorApi } from '@/request/Api'
import { useEthereum } from '@/hooks/useEthereum'
import { useAppContext } from '@/hooks/useAppContext'
import { QuickBuyVisibleAction } from '@/store/actions/LiveviewAction'
import { iListingRes, iWhalesActivityParams } from '@/request/types'
import { TitleDesc } from './component'
import { HoverWrapper } from '@/components/Layout/HoverWrapper'
import { Chart } from '@/components/Charts/Chart'
import { ListingUnderFloorOption } from '@/components/Charts/options/index'
import { ColorRank } from '@/components/ColorRank'
import { NoData } from '@/components/NoData'
import { useMatchBreakpoints } from '@/hooks/useMatchBreakpoints'
import { OpenNotification, NotMainNetNotice } from '@/components/OpenNotification'
import { isMainNet } from '@/utils/chain'

interface iFloorItem {
  listing: iListingRes
  belowRate: number
  slug: string
  nftName: string
  dates: any
  floors: any
  supply: number
}

const ListingUnderFloorBuyButton = styled(Box)<{ active: boolean }>`
  height: 34px;
  border-radius: 20px;
  background: ${({ active }) => (active ? 'rgba(0, 178, 181, 0.08)' : 'rgba(255, 255, 255, 0.12)')};
  border: ${({ active }) => (active ? '1px solid rgba(0, 178, 181, 1)' : '1px solid rgba(255, 255, 255, 0.12)')};
`
const ListingUnderFloorItem: React.FC<iFloorItem> = ({ slug, belowRate, listing, nftName, dates, floors, supply }) => {
  const { state, dispatch } = useAppContext()
  const [isHover, setHover] = useState(false)
  const [chartList, setChartList] = useState<any>()
  const navigate = useNavigate()
  const { nftTokenVO, price, dateTime, rank } = listing
  const { imageUrl, tokenId } = nftTokenVO
  const { ethereum } = useEthereum()

  useEffect(() => {
    setChartList(ListingUnderFloorOption(floors, dates))
  }, [])

  const onBuy = async () => {
    const net = await isMainNet()
    if (net) {
      try {
        const account = await ethereum.request({ method: 'eth_requestAccounts' })
        if (account && account.length > 0) {
          QuickBuyVisibleAction(listing, 'listingUnderFloor', dispatch)
        } else {
          OpenNotification('Please connect your wallet', 'warning')
        }
      } catch (error) {
        OpenNotification('Please connect your wallet', 'warning')
      }
    } else {
      NotMainNetNotice()
    }
  }
  const handleDetail = () => {
    nftTokenVO && navigate(`/trending/liveview/${nftTokenVO.nftId}`)
  }
  const onOpensea = (event: any) => {
    event.stopPropagation()
    openWindow(`https://opensea.io/collection/${slug}`)
  }
  return (
    <Grid
      gridTemplateColumns={['1fr 1.5fr', '1fr 1.5fr', '1fr 1.5fr', '1fr 1fr']}
      className="jc-sb"
      background="rgb(24,24,27)"
      height="92px"
      px={['4px', '12px', '24px', '24px']}
      mb="6px"
    >
      <Row className="">
        <Col className="ai-ct click">
          <Image src={imageUrl} width={52} height={52} className="radius60" onClick={handleDetail} />
          <Box className="radius06 center " width="52px" height="18px" background="rgba(255, 255, 255, 0.12)">
            <Text className="fz12 white87">{`#${beyondStr(tokenId, 4)}`}</Text>
          </Box>
        </Col>
        <Box className="fx-col click" ml={['4px', '8px', '16px', '16px']} onClick={handleDetail}>
          <Text className="fz14 white87">{`${beyondStr(nftName, 10)}`}</Text>
          <ColorRank rankValue={rank} supplyVal={supply} />
        </Box>
      </Row>
      <Grid className="fx-row ai-ct" gridTemplateColumns={['2fr 3fr', '2fr 3fr', '2fr 3fr', '2fr 3fr']}>
        <Box className="" height="40px" width="80px">
          {chartList && <Chart option={chartList} />}
        </Box>
        <Col className="ai-end">
          <Row>
            <ETHValue value={toNonExponential(price, 4)} />
            <ListingUnderFloorBuyButton
              px="16px"
              ml="6px"
              className="center"
              active={isHover}
              onMouseOver={() => setHover(true)}
              onMouseOut={() => setHover(false)}
              onClick={onBuy}
            >
              <Text
                color={isHover ? 'rgba(0, 178, 181, 1)' : 'rgba(255, 255, 255, 0.87)'}
                mr="8px"
                className="click fz12 fw600"
              >
                BUY
              </Text>
              <Image
                src={isHover ? openseaActive : openseaWhite}
                height={16}
                width={18}
                className="click"
                onClick={onOpensea}
              />
            </ListingUnderFloorBuyButton>
          </Row>
          <Row mt="6px" className="beyondHide">
            <Text className="fz12 fw400 white38">Listed</Text>
            <Text className="fz12 fw400 white87" mx="4px">
              {`${toNonExponential(belowRate * 100, 2)}% below floor`}
            </Text>
            <Text className="fz12 fw400 white38">{someTimesAgo(dateTime)}</Text>
          </Row>
        </Col>
      </Grid>
    </Grid>
  )
}
const ListingUnderFloorMobile: React.FC<iFloorItem> = ({
  slug,
  belowRate,
  listing,
  nftName,
  dates,
  floors,
  supply
}) => {
  const { state, dispatch } = useAppContext()
  const [isHover, setHover] = useState(false)
  const [chartList, setChartList] = useState<any>()
  const navigate = useNavigate()
  const { nftTokenVO, price, dateTime, rank } = listing
  const { imageUrl, tokenId } = nftTokenVO
  const { ethereum } = useEthereum()

  useEffect(() => {
    setChartList(ListingUnderFloorOption(floors, dates))
  }, [])

  const onBuy = async () => {
    try {
      const account = await ethereum.request({ method: 'eth_requestAccounts' })
      if (account && account.length > 0) {
        QuickBuyVisibleAction(listing, 'listingUnderFloor', dispatch)
      } else {
        OpenNotification('Please connect your wallet', 'warning')
      }
    } catch (error) {
      OpenNotification('Please connect your wallet', 'warning')
    }
  }
  const handleDetail = () => {
    nftTokenVO && navigate(`/trending/liveview/${nftTokenVO.nftId}`)
  }
  const onOpensea = (event: any) => {
    event.stopPropagation()
    openWindow(`https://opensea.io/collection/${slug}`)
  }
  return (
    <Row className="" background="rgb(24,24,27)" height="92px" px={['4px', '12px', '24px', '24px']} mb="6px">
      <Col className="ai-ct click">
        <Image src={imageUrl} width={52} height={52} className="radius60" onClick={handleDetail} />
        <Box className="radius06 center " width="52px" height="18px" background="rgba(255, 255, 255, 0.12)">
          <Text className="fz12 white87">{`#${beyondStr(tokenId, 4)}`}</Text>
        </Box>
      </Col>
      <Col className="w100">
        <Row className="w100 jc-sb ">
          <Col ml={['4px', '8px', '16px', '16px']} className=" click" onClick={handleDetail}>
            <Text className="fz14 white87">{`${beyondStr(nftName, 10)}`}</Text>
            <ColorRank rankValue={rank} supplyVal={supply} />
          </Col>
          <Row>
            <ETHValue value={toNonExponential(price, 4)} />
            <ListingUnderFloorBuyButton
              px="26px"
              ml="6px"
              className="center"
              active={isHover}
              onMouseOver={() => setHover(true)}
              onMouseOut={() => setHover(false)}
              onClick={onBuy}
            >
              <Text
                color={isHover ? 'rgba(0, 178, 181, 1)' : 'rgba(255, 255, 255, 0.87)'}
                mr="8px"
                className="click fz12 fw600"
              >
                BUY
              </Text>
              {/* <Image
                src={isHover ? openseaActive : openseaWhite}
                height={16}
                width={18}
                className="click"
                onClick={onOpensea}
              /> */}
            </ListingUnderFloorBuyButton>
          </Row>
        </Row>
        <Row className="w100 jc-sb ">
          <Row mt="6px" className="" ml={['4px', '8px', '16px', '16px']}>
            <Text className="fz12 fw400 white38">Listed</Text>
            <Text className="fz12 fw400 white87" mx="4px">
              {`${toNonExponential(belowRate * 100, 2)}% below floor`}
            </Text>
            <Text className="fz12 fw400 white38">{someTimesAgo(dateTime)}</Text>
          </Row>
          <Box className="" height="20px" width="40px" mt="10px">
            {chartList && <Chart option={chartList} />}
          </Box>
        </Row>
      </Col>
    </Row>
  )
}
export const ListingUnderFloor = (wsSocket: any) => {
  const [list, setList] = useState<Array<any>>([])
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(20)
  const [isLoading, setLoading] = useState<boolean>(true)
  const [statusType, setStatusType] = useState<boolean>(true)
  const [query, setQuery] = useState<iWhalesActivityParams>({
    pageIndex: 1,
    pageSize
  })
  const [isInit, setInit] = useState(true)
  const [isPause, setPause] = useState<boolean>(false)
  const listRef: any = useRef()
  const pauseRef: any = useRef()

  const { isMobile } = useMatchBreakpoints()

  const fetch = async () => {
    setLoading(true)

    const d: any = await ListingUnderFloorApi(query)
    setInit(false)
    setLoading(false)
    if (d && d.records && d.records.length > 0) {
      const combime = [...list, ...d.records]
      setList(combime)
    }
  }
  useEffect(() => {
    fetch()
  }, [pageIndex])
  useEffect(() => {
    listRef.current = list
    pauseRef.current = isPause
    if (wsSocket && wsSocket.socket) {
      if (isInit) {
        try {
          setTimeout(() => {
            wsSocket.socket.client.subscribe(`/topic/listing-under-floor`, (res: any) => {
              const d = JSON.parse(res.body)
              if (!pauseRef.current) {
                listRef.current.unshift(d)
                setList([...listRef.current])
              }
            })
          }, 10100)
        } catch (error) {
          console.log('connect error', error)
        }
      }
    }
  }, [list, isPause])

  const onListingFloorBottom = () => {
    if (!isLoading) {
      if (pageIndex < 5) {
        const p = JSON.parse(JSON.stringify(query))
        const newIndex = pageIndex + 1
        setPageIndex(newIndex)
        p.pageIndex = newIndex
        setQuery(p)
      }
    }
  }
  const mouseOver = () => {
    if (wsSocket && wsSocket.socket) {
      try {
        setPause(true)
        setStatusType(false)
      } catch (error) {
        console.log('error', error)
      }
    }
  }
  const mouseLeave = () => {
    setPause(false)
    setStatusType(true)
  }
  return (
    <Box className="w100">
      <Box mt={['0', '0', '-60px', '-60px']}>
        <TitleDesc
          title="Listings Under Floor"
          subTitle="Snipe the cheapest token under floor."
          statusType={statusType}
        />
      </Box>
      <HoverWrapper mouseOver={mouseOver} mouseLeave={mouseLeave}>
        <ListingLoadWrapper onContainerBottom={onListingFloorBottom} height="490px">
          {!!list.length &&
            list.map((item: any) => {
              const { listing, belowRate, collection, dates, floors } = item
              const { slug, supply } = collection
              return (
                <div>
                  {isMobile ? (
                    <ListingUnderFloorMobile
                      key={Math.random()}
                      listing={listing}
                      belowRate={belowRate}
                      slug={slug}
                      nftName={collection.name}
                      dates={dates}
                      floors={floors}
                      supply={supply}
                    />
                  ) : (
                    <ListingUnderFloorItem
                      key={Math.random()}
                      listing={listing}
                      belowRate={belowRate}
                      slug={slug}
                      nftName={collection.name}
                      dates={dates}
                      floors={floors}
                      supply={supply}
                    />
                  )}
                </div>
              )
            })}
          {!isLoading && !list.length && <NoData isLoading={false} />}
          {isLoading && <LoadingItemWrapper height={92} count={5} />}
        </ListingLoadWrapper>
      </HoverWrapper>
    </Box>
  )
}
