import { valumeUnit4Chart, unitTime } from '@/utils/tools'

export interface iLegendData {
  name: string
  icon: string
}

export const ChartBgColor: string = 'rgb(24,24,27)' // 图表背景色

export const tooltipBg: string = '#cbcbcb'
export const tooltipBd1: string = 'rgb(147,147,147)'
export const tooltipBd2: string = 'rgb(0,178,181)'

export const tooltipTextColor: string = 'rgb(30,47,50)'

export const dotSize: number = 8 //  散点图 的散点大小

export const tooltip = {
  backgroundColor: 'rgba(15, 15, 18, 1)',
  borderColor: 'rgba(15, 15, 18, 0.8)',
  trigger: 'axis',
  axisPointer: {
    type: 'shadow'
  },
  formatter(value: any) {
    const time = unitTime(value[0].axisValue)
    const temp = `
      <div style="padding: 4px 6px;">
        <div style="font-size: 14px; font-weight: 600; color: #FFFFFF; line-height: 21px;">${time}</div>
        <div style="font-size: 14px;  font-weight: 600; color: #FFFFFF; line-height: 21px;">Floor: Ξ ${
          value[2].value || 0
        }</div>
        <div style="font-size: 14px;  font-weight: 600; color: #FFFFFF; line-height: 21px;">Avg Price: Ξ ${
          value[1].value || 0
        }</div>
        <div style="font-size: 14px;  font-weight: 600; color: #FFFFFF; line-height: 21px;">Volume: Ξ ${
          value[0].value || 0
        }</div>
      </div>
    `
    return temp
  }
}

export const LegendStyle = (data: Array<iLegendData>) => {
  return {
    data,
    left: '5%',
    bottom: 'bottom',
    textStyle: {
      fontSize: 10,
      color: '#E5E1E6'
    },
    itemHeight: 8,
    itemWidth: 12
  }
}
export const nameTextStyle = {
  color: 'rgba(255, 255, 255, 0.6)',
  fontSize: 14,
  fontWeight: 700,
  padding: 20
}
export const axisLabel2 = {
  show: true,
  textStyle: {
    color: 'rgba(255, 255, 255, 0.6)',
    fontSize: 14
  },
  formatter: (value: number) => valumeUnit4Chart(value)
}
export const axisLabel = {
  // X Y 轴文字样式
  show: true,
  textStyle: {
    color: 'rgba(255, 255, 255, 0.6)',
    fontSize: 14
  }
}

export const axisLine = {
  // X Y 轴刻度线样式
  lineStyle: {
    color: '#4E4E4E',
    height: 1
  }
}

export const axisTick = {
  // X轴刻度 是否显示
  show: false
}

export const splitLine = {
  // X Y轴的分割线
  show: true,
  lineStyle: {
    color: 'rgba(255, 255, 255, 0.12)',
    type: 'solid' // dashed
  }
}

export const grid = {
  left: 50,
  right: 50,
  top: 30,
  bottom: 50,
  containLabel: true
}
export const fullGrid = {
  left: 0,
  right: 0,
  top: 10,
  bottom: 0,
  containLabel: true
}
// 柱状图
export const barWidth: number = 20 // 柱状图固定宽度
export const barColor: string = '#00B2B5' // 柱状图颜色
export const barColor2: string = '#5F6165' // 柱状图颜色
export const barBgColor = {
  // 柱状图背景色
  color: 'rgba(255, 255, 255, 0.1)'
}

// 折线图
export const lineColor1: string = '#FFE800'
export const lineColor2: string = '#00B2B5'

// 饼图
export const pieColor1: string = '#C4C0FF'
export const pieColor2: string = '#FFD7EC'
export const pieColor3: string = '#472539'
export const pieColor4: string = '#603B50'
export const pieColor5: string = '#FFB4A9'
export const pieLabelLine = {
  normal: {
    length: 50,
    color: '#fff'
  }
}
export const pieLabel = {
  align: 'left',
  color: '#fff',
  fontSize: 10
}

// axisLabel 保留4位小数 小数位数不够补0
export const axisLabelDecimal = (value: number, precision: number = 4) => {
  const idx = `${value}`.indexOf('.')
  if (idx !== -1) {
    // const precision = 4
    const prev = `${value}`.substring(0, idx + 1)
    const lest = `${value}`.substring(idx + 1)
    const times = precision - lest.length
    if (lest.length < precision + 1) {
      // 小数位小于3位 在后面补0
      const add0Str: string = '0'.repeat(times)
      return `${prev}${lest}${add0Str}`
    }
  } else {
    return value
  }
}
