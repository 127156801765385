import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Popover, Modal } from 'antd'
import html2canvas from 'html2canvas'
import Web3 from 'web3'
import { Flex, Box, Text, Image } from '@/packages'
import {
  wallet,
  icon,
  walletConected,
  walletDisconnect,
  eth,
  pewee,
  download,
  twitter1,
  walletNoData,
  avator
} from '@/assets/img'
import { splitAddress, locaStoreageGetItem, locaStoreageRemoveItem, beyondStr } from '@/utils/tools'
import { walletLoginApi, walletInfApi, uploadBase64ImageApi } from '@/request/Api'
import { useEthereum } from '@/hooks/useEthereum'
import { useAppContext } from '@/hooks/useAppContext'
import { useConnectWallet } from '@/hooks/useConnectWallet'
import { Col } from '@/components/Layout/Layout'
import { OpenNotification } from '@/components/OpenNotification'

const DisConnect = styled(Box)`
  width: 42px;
  height: 42px;
  background: #00b2b5;
  border-radius: 50%;
  margin-left: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Chain = styled(Box)``
const Connected = styled(Box)`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  gap: 10px;
  width: 137px;
  height: 40px;
  background: #04dddd;
  border: 1px solid #04dddd;
  border-radius: 2px;
  flex: none;
  order: 3;
  flex-grow: 0;
`
const AddressText = styled(Text)`
  font-size: 14px;
  color: ${(props) => props.theme.colors.primary2};
`
const ChainName = styled(Text)`
  font-size: 14px;
  color: ${(props) => props.theme.colors.bg2};
`
interface iBlueList {
  imageUrl: string
  name: string
  nftId: number
}
interface iMostList {
  nftId: number
  tokenId: string
  value: number
  imageUrl: string
}
interface iBoxItem {
  winningRate: number
  owner: string
  totalSpend: number
  totalProfits: number
  collectionCount: number
  nftCount: number
  volume: number
  blueChipList: Array<iBlueList>
  maxWinItem?: {
    nftId?: number
    value?: number
    imageUrl?: string
    name?: string
    tokenId?: string
  }
  maxLossItem?: {
    nftId?: number
    value?: number
    imageUrl?: string
    name?: string
    tokenId?: string
  }
}
const Wrappers = styled(Box)`
  position: absolute;
  top: 28px;
  right: 0;
  width: 900px;
  height: 470px;
  padding: 20px 110px 14px;
  /* display: none; */
`
const WraInfoBoxt = styled(Box)``
const WraInfoTopBox = styled(Box)`
  width: 154px;
  height: 26px;
  border-radius: 22px;
  background: rgba(0, 0, 0, 0.3);
`
const WraImgPro = styled(Box)`
  height: 17px;
  margin-bottom: 23px;
  padding-right: 14px;
  position: relative;
  z-index: 10;
  top: 50px;
  right: 20px;
`
const WarAddress = styled(Box)`
  height: 22px;
  color: rgba(255, 255, 255, 0.87);
  font-size: 14px;
  position: absolute;
  top: 30px;
  left: 40px;
  z-index: 99;
`
const BtmBoxBtm = styled(Box)`
  font-size: 12px;
  font-weight: bold;
`
const NoBlue = styled(Box)`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
  margin: 0 auto;
`

const BoxTextSpen = styled(Box)`
  font-size: 12px;
  display: inline-block;
`
const BoxTextSpenT = styled(Box)`
  font-size: 24px;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.87);
`

const BigBox = styled(Box)`
  width: 680px;
  height: 100px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 22.5237px;
`

const Nadata = styled(Box)`
  text-algin: center;
  position: relative;
  width: 446px;
  height: 108px;
  padding-top: 40px;
  margin: 0 auto;
`
const ImgCont = styled(Box)`
  position: relative;
  width: 60px;
  height: 22px;
`

const Moduels: React.FC<iBoxItem> = (contents) => {
  const {
    winningRate,
    owner,
    totalSpend,
    totalProfits,
    collectionCount,
    nftCount,
    volume,
    blueChipList,
    maxWinItem,
    maxLossItem
  } = contents
  const [imagebg, setImage] = useState('')
  const [bgImgSer, setbgImgSer] = useState('')
  const [twiiterImag, setTwiiterImag] = useState('')
  const openTwitter = () => {
    const metaArr = [
      'twitter:url',
      'https://www.pewee.tools',
      'twitter:site',
      'https://www.pewee.tools',
      'twitter:title',
      'Beta test is opening to apply in discord',
      'twitter:description',
      'Winning Rate：Only flipped cactivity from OS will be counted(ETH ONLY)',
      'twitter:card',
      'summary_large_image',
      'twitter:image',
      twiiterImag
    ]
    let metaParams = metaArr.toString()
    // 需要encode两次 因为浏览器会自动decode一次，另一次是服务端会decode
    metaParams = encodeURIComponent(encodeURIComponent(metaParams))
    const title = 'Here is my NFT journey, go check at pewee.tools @peweetools'
    window.open(
      `https://twitter.com/share?text=${title}&url=https://www.pewee.tools/bls/api/v1/common/twitter/share/html?meta=${metaParams}`
    )
  }
  const downloadImg = () => {
    const alink = document.createElement('a')
    alink.href = bgImgSer
    alink.download = 'pewee wallet.png'
    alink.click()
  }
  useEffect(() => {
    html2canvas(document.querySelector('.canvanImg') as HTMLElement, {
      width: 900, // 设置canvas的宽度
      scale: 1, // 缩放
      useCORS: true,
      height: 470 // 设置截图的长度
    })
      .then(function (canvas: any) {
        const base64Data = canvas.toDataURL('image/png')
        setbgImgSer(base64Data)
        fetch(base64Data)
      })
      .catch((error: any) => {
        console.log('html2canvas', error)
      })
  }, [])
  const fetch = async (img: any) => {
    const imgBgUrl: any = await uploadBase64ImageApi({ image: img })
    setTwiiterImag(imgBgUrl)
  }

  return (
    <Box>
      <WraImgPro className="fx ai-ct jc-end">
        {twiiterImag ? <Image height={30} width={30} src={twitter1} className="click" onClick={openTwitter} /> : ''}
        <Image
          height={30}
          width={30}
          src={download}
          style={{ marginLeft: '30px' }}
          className="click"
          onClick={downloadImg}
        />
      </WraImgPro>
      <Wrappers className="bg19 canvanImg">
        <WarAddress className="fx ai-ct">
          <Image src={pewee} height={22} width={22} />
          <Box className="ml4">{splitAddress(owner)}</Box>
        </WarAddress>
        <Text
          color="rgba(255, 255, 255, 0.87)"
          className="center"
          fontSize="30px"
          fontWeight={700}
          mb="28px"
          lineHeight="35px"
        >
          MY NFT JOURNEY
        </Text>
        <Box className="fx ai-ct jc-sa w674 mb20">
          <Box className="w108">
            <Text color="rgba(255, 255, 255, 0.38)" className="center" fontSize="18px" fontWeight={500} mb="4px">
              Collections
            </Text>
            <Text color="rgba(255, 255, 255, 0.87)" className="center" fontSize="24px" fontWeight={500}>
              {collectionCount}
            </Text>
          </Box>
          <Box className="w108">
            <Text color="rgba(255, 255, 255, 0.38)" className="center" fontSize="18px" fontWeight={500} mb="4px">
              NFTs
            </Text>
            <Text color="rgba(255, 255, 255, 0.87)" className="center" fontSize="24px" fontWeight={500}>
              {nftCount}
            </Text>
          </Box>
          <Box className="w108">
            <Text color="rgba(255, 255, 255, 0.38)" className="center" fontSize="18px" fontWeight={500} mb="4px">
              Volume
            </Text>
            <Box className="center">
              <Image src={eth} height={15} width={8} />
              <Text color="rgba(255, 255, 255, 0.87)" className="center" fontSize="24px" fontWeight={500} ml="4px">
                {volume ? volume.toFixed(4) : 0}
              </Text>
            </Box>
          </Box>
          <Box className="w108">
            <Text color="rgba(255, 255, 255, 0.38)" className="center" fontSize="18px" fontWeight={500} mb="4px">
              Blue Chips
            </Text>
            {blueChipList.length > 0 ? (
              <BtmBoxBtm className="center">
                <ImgCont>
                  {!!blueChipList.length &&
                    blueChipList.map((item: iBlueList, index) =>
                      index < 3 ? (
                        <Image
                          height={22}
                          width={22}
                          src={item.imageUrl}
                          className={index === 0 ? 'circle zx10' : index === 1 ? 'circle zx8' : 'circle zx6'}
                        />
                      ) : (
                        ''
                      )
                    )}
                </ImgCont>

                <BoxTextSpenT>… {blueChipList.length}</BoxTextSpenT>
              </BtmBoxBtm>
            ) : (
              <NoBlue />
            )}
          </Box>
        </Box>
        <BigBox className="fx ai-ct jc-sb mb20">
          <Box className="tc w250 bor">
            <Text color="rgba(255, 255, 255, 0.38)" className="center" fontSize="14px" fontWeight={500}>
              Winning Rate
            </Text>
            <Text
              className={winningRate > 50 ? 'col7' : winningRate > 20 ? 'col8' : 'col9'}
              fontSize="34px"
              lineHeight="32px"
              fontWeight={700}
            >
              {winningRate !== 0 ? `${winningRate}%` : '-- %'}
            </Text>
          </Box>
          <Box className="fx ai-ct jc-sb ml20">
            <Box className="w200">
              <Text color="rgba(255, 255, 255, 0.38)" className="center" fontSize="14px" fontWeight={500} mb="4px">
                Total Spend
              </Text>
              <Box className="center">
                <Image src={eth} height={15} width={8} />
                <Text color="rgba(255, 255, 255, 0.87)" className="center" fontSize="24px" fontWeight={500} ml="4px">
                  {totalSpend ? totalSpend.toFixed(4) : 0}
                </Text>
              </Box>
            </Box>
            <Box className="w200">
              <Text color="rgba(255, 255, 255, 0.38)" className="center" fontSize="14px" fontWeight={500} mb="4px">
                Total Profits
              </Text>
              <Box className="center">
                <Image src={eth} height={15} width={8} />
                <Text color="rgba(255, 255, 255, 0.87)" className="center" fontSize="24px" fontWeight={500} ml="4px">
                  {totalProfits ? totalProfits.toFixed(4) : 0}
                </Text>
              </Box>
            </Box>
          </Box>
        </BigBox>
        <Box
          className={
            maxWinItem && maxLossItem
              ? 'fx ai-ct jc-sb p100'
              : (maxWinItem && !maxLossItem) || (!maxWinItem && maxLossItem)
              ? 'center p100'
              : 'fx ai-ct jc-sb p100'
          }
        >
          {maxWinItem || maxLossItem ? (
            <WraInfoBoxt className={maxWinItem && maxLossItem ? 'fx ai-ct jc-sb w100' : 'center w100'}>
              {maxWinItem ? (
                <Box className="tc w160">
                  <WraInfoTopBox className="center">
                    <Image height={12} width={10} src={icon} />
                    <Text color="rgba(255, 255, 255, 0.38)" fontSize="12px" fontWeight={500} ml="6px">
                      Most Profitable Trade
                    </Text>
                  </WraInfoTopBox>
                  {maxWinItem.imageUrl ? (
                    <Image
                      height={38}
                      width={38}
                      src={maxWinItem.imageUrl}
                      style={{ borderRadius: '50%', marginTop: '12px' }}
                    />
                  ) : (
                    ''
                  )}
                  <Box className={maxWinItem.name ? 'fx ai-ct jc-sb h22 mt5' : 'center h22 mt5'}>
                    {maxWinItem.name ? (
                      <Text color="rgba(255, 255, 255, 0.87)" fontSize="18px" fontWeight={500}>
                        {beyondStr(maxWinItem.name, 8)}
                      </Text>
                    ) : (
                      ''
                    )}

                    <Box className="fx ai-ct">
                      <Image src={eth} height={12} width={6} />
                      <Text color="rgba(255, 255, 255, 0.38)" fontSize="12px" fontWeight={500} ml="4px">
                        {maxWinItem.value ? maxWinItem.value.toFixed(4) : 0}
                      </Text>
                    </Box>
                  </Box>
                </Box>
              ) : (
                ''
              )}
              {maxLossItem ? (
                <Box className="tc w160">
                  <WraInfoTopBox className="center">
                    <Image height={12} width={10} src={icon} />
                    <Text color="rgba(255, 255, 255, 0.38)" fontSize="12px" fontWeight={500} ml="6px">
                      Most Loss Trade
                    </Text>
                  </WraInfoTopBox>
                  {maxLossItem.imageUrl ? (
                    <Image
                      height={38}
                      width={38}
                      src={maxLossItem.imageUrl}
                      style={{ borderRadius: '50%', marginTop: '12px' }}
                    />
                  ) : (
                    ''
                  )}
                  <Box className={maxLossItem.name ? 'fx ai-ct jc-sb h22 mt5' : 'center h22 mt5'}>
                    {maxLossItem.name ? (
                      <Text color="rgba(255, 255, 255, 0.87)" fontSize="18px" fontWeight={500}>
                        {beyondStr(maxLossItem.name, 8)}
                      </Text>
                    ) : (
                      ''
                    )}
                    <Box className="fx ai-ct">
                      <Image src={eth} height={12} width={6} />
                      <Text color="rgba(255, 255, 255, 0.38)" fontSize="12px" fontWeight={500} ml="4px">
                        {maxLossItem.value ? maxLossItem.value.toFixed(4) : 0}
                      </Text>
                    </Box>
                  </Box>
                </Box>
              ) : (
                ''
              )}
            </WraInfoBoxt>
          ) : (
            <Nadata>
              <Image
                src={walletNoData}
                height={108}
                width={114}
                style={{ position: 'absolute', zIndex: 1, top: '4px', left: '162px' }}
              />
              <Text
                color="rgba(255, 255, 255, 0.78)"
                className="center"
                fontSize="18px"
                textAlign="center"
                fontWeight={500}
                style={{ position: 'relative', zIndex: 2 }}
              >
                Immediately use Pewee Quick Buy to become a Sniper with high win rate！
              </Text>
            </Nadata>
          )}
        </Box>
      </Wrappers>
    </Box>
  )
}
const defaultcontent = {
  winningRate: 0,
  totalSpend: 0,
  totalProfits: 0,
  collectionCount: 0,
  nftCount: 0,
  volume: 0,
  owner: '',
  blueChipList: [],
  maxWinItem: {
    nftId: 0,
    tokenId: '',
    imageUrl: '',
    value: 0,
    name: ''
  },
  maxLossItem: {
    nftId: 0,
    tokenId: '',
    imageUrl: '',
    value: 0,
    name: ''
  }
}

const WalletInfoPopover: React.FC<{ onWalletInfo: () => void }> = ({ onWalletInfo }) => {
  const logout = () => {
    locaStoreageRemoveItem('tokens')
    setTimeout(() => {
      window.location.reload()
    }, 100)
  }

  return (
    <Col>
      <Box className="fx-row ai-ct click" background="#0F0F12" width="218px" height="52px" onClick={onWalletInfo}>
        <Text color="#FFFFFF" fontSize="14px" ml="12px">
          Wallet Info
        </Text>
      </Box>
      <Box mt="8px" className="fx-row ai-ct click" background="#0F0F12" width="218px" height="52px" onClick={logout}>
        <Text color="#FFFFFF" fontSize="14px" ml="12px">
          Sign Out
        </Text>
      </Box>
    </Col>
  )
}
export const Wallet = () => {
  const [account, setAccount] = useState('')
  const [walletStatus, setWallettStatus] = useState(false)
  const [content, setContent] = useState<iBoxItem>(defaultcontent)
  const { state, dispatch } = useAppContext()
  const [visible1, setVisible1] = useState(false)
  const [visible2, setVisible2] = useState(false)
  const [walletVisible, setWalletVisible] = useState<boolean>(false)
  const { ethereum, provider } = useEthereum()

  const checkConnection = async () => {
    const web3: any = new Web3(ethereum)
    const addr = await web3.eth.getAccounts()
    if (addr && !!addr.length) {
      if (getToken()) {
        setWallettStatus(true)
        const dataCont: any = await walletInfApi()
        setContent(dataCont || defaultcontent)
      }
    } else {
      setWallettStatus(false)
    }
  }

  const accountsChange = () => {
    ethereum.on('accountsChanged', (accounts: Array<string>) => {
      if (accounts.length === 0) {
        setWallettStatus(false)
        // console.log('Account disconnected')
        // openNotification('Wallet connection disconnected,please login again', '')
        // locaStoreageRemoveItem('tokens')
        // setTimeout(() => {
        //   window.location.reload()
        // }, 300)
      }
    })
  }

  useEffect(() => {
    if (ethereum) {
      checkConnection()
      accountsChange()
    }
  }, [ethereum])

  useEffect(() => {
    if (state?.NavReducer?.accessAuth) {
      if (state.NavReducer.accessAuth === true) {
        setWallettStatus(true)
      }
      if (state.NavReducer.accessAuth === false) {
        setWallettStatus(false)
      }
    }
  }, [state?.NavReducer?.accessAuth])

  const getToken = () => {
    const t = locaStoreageGetItem('tokens')
    return t?.accessToken || null
  }

  const onVisibleChange1 = (v: boolean) => {
    if (v === false) {
      setVisible1(false)
    }
  }
  const onVisibleChange2 = (v: boolean) => {
    if (v === false) {
      setWalletVisible(false)
      setVisible1(false)
    }
  }
  const onCilckImg = () => {
    setVisible1(true)
    setWalletVisible(false)
  }
  const onWalletInfo = () => {
    setVisible1(false)
    setWalletVisible(true)
    checkConnection()
  }
  const onConnectWallet = async () => {
    if (ethereum) {
      const addr = await ethereum.request({ method: 'eth_requestAccounts' })
      if (addr && addr.length) {
        setWallettStatus(true)
      } else {
        setWallettStatus(false)
      }
    } else {
      OpenNotification('Please install Metamask first.', 'warning')
    }
  }

  return (
    <Box className="fx-row jc-end" mt="-8px">
      {walletStatus ? (
        <Flex flexDirection="row">
          <Popover
            destroyTooltipOnHide
            visible={visible1}
            content={<WalletInfoPopover onWalletInfo={onWalletInfo} />}
            onVisibleChange={onVisibleChange1}
            trigger="click"
            placement="bottomRight"
          >
            <Image height={42} width={42} src={avator} className="circle click" onClick={onCilckImg} />
          </Popover>
          <Modal
            closable={false}
            onCancel={() => setWalletVisible(false)}
            visible={walletVisible}
            footer={null}
            width={900}
            maskClosable
            wrapClassName="walletModul"
          >
            <Moduels {...content} />
          </Modal>
        </Flex>
      ) : (
        <Box background="#00B2B5" width="42px" height="42px" className="circle center click" onClick={onConnectWallet}>
          <Image height={18} width={18} src={walletDisconnect} className="circle click" onClick={onCilckImg} />
        </Box>
      )}
    </Box>
  )
}
