import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useNavigate, useLocation } from 'react-router-dom'
import { Box, Text, Image } from '@/packages/'
import { SearchApi } from '@/request/Api'
import useDebounce from '@/hooks/useDebounce'
import { Row, Col, NFTImage, LoadingItemWrapper } from '@/components/Layout/Layout'
import { useAppContext } from '@/hooks/useAppContext'
import useOnClickOutside from '@/hooks/useOnClickOutside'
import { splitAddress } from '@/utils/tools'
import { inputSearch } from '@/assets/img'

interface iSearchResultItem {
  onDetail: () => void
}
export interface iResult {
  imageUrl: string
  name: string
  nftId: number
  totalVolume: number
  type: string
  address: string
}

export const ModalBody = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 364px;
  width: 100%;
  overflow: scroll;
  position: absolute;
  right: 0;
  top: 44px;
  background: #1a1d23;
`
const ItemWrapper = styled(Box)`
  width: 100%;
  height: 62px;
`
const CollectionBtn = styled(Box)`
  width: 88px;
  height: 24px;
  background: #00b2b5;
  font-size: 12px;
  color: #111218;
  line-height: 15px;
  cursor: pointer;
  font-weight: 600;
`
const InputWrapper = styled(Box)`
  position: relative;
  width: 100%;
`
const Mask = styled(Box)`
  position: absolute;
  right: 0;
  width: 100%;
  height: 100%;
  background: #ccc;
`
export const SearchResultItem: React.FC<iSearchResultItem & iResult> = ({
  imageUrl,
  name,
  nftId,
  totalVolume,
  type,
  address,
  onDetail
}) => {
  const navigate = useNavigate()
  const [over, setOver] = useState<boolean>(false)
  const location = useLocation()
  const handleDetail = (nId: number) => {
    onDetail()
    if (location && location.pathname.includes('/trending/liveview/')) {
      if (nId) {
        navigate(`/trending/liveview/${nId}`)
        window.location.reload()
      }
    } else {
      nId && navigate(`/trending/liveview/${nId}`)
    }
  }
  const onMouseOver = () => {
    setOver(true)
  }
  const onMouseLeave = () => {
    setOver(false)
  }
  const showType = (t: string) => {
    let tp: string = ''
    switch (t) {
      case 'wallet':
        tp = 'Wallet'
        break
      default:
        tp = 'Collection'
        break
    }
    return tp
  }
  return (
    <ItemWrapper
      className="fx-row ai-ct jc-sb click"
      p="14px 8px"
      background={over ? '#111218' : '#1A1D23'}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      onClick={() => handleDetail(nftId)}
    >
      <Row>
        <NFTImage src={imageUrl} width={36} height={36} classN="circle" onHandle={() => handleDetail(nftId)} />
        <Col ml={10}>
          {name && (
            <Text color="#fff" fontSize={14}>
              {name}
            </Text>
          )}
          {address && (
            <Text color="#A0A1A3" fontSize={14}>
              {splitAddress(address, 6, 4)}
            </Text>
          )}
        </Col>
      </Row>
      <CollectionBtn className="center">{showType(type)}</CollectionBtn>
    </ItemWrapper>
  )
}

export const SearchInput = () => {
  // const { visible, toggle } = useModal()
  const [value, setValue] = useState<string>('')
  const [result, setResult] = useState<Array<iResult>>([])
  const debouncedValue = useDebounce<string>(value, 500)
  const [isLoading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [inputVisible, setInputVisible] = useState(false)
  const { state, dispatch } = useAppContext()
  const myRef: any = useRef()
  useOnClickOutside(myRef, () => setVisible(false))
  useEffect(() => {
    if (state?.NavReducer?.accessAuth) {
      if (state.NavReducer.accessAuth === true) {
        setInputVisible(true)
      }
      if (state.NavReducer.accessAuth === false) {
        setInputVisible(false)
      }
    }
  }, [state?.NavReducer?.accessAuth])
  const fetch = async (keyword: string) => {
    const d: any = await SearchApi(keyword)
    setLoading(false)
    if (d) {
      setResult(d)
    }
  }
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value
    if (val) {
      setValue(val)
      setVisible(true)
      setLoading(true)
    } else {
      setValue('')
      setVisible(false)
      setLoading(false)
    }
  }
  useEffect(() => {
    if (debouncedValue) {
      fetch(value)
    }
  }, [debouncedValue])
  // onFocus: (event: React.FocusEvent<HTMLInputElement>) => void
  const onCancel = () => {
    // toggle()
  }
  const onDetail = () => {
    setVisible(false)
  }
  const onFocus = () => {
    if (value) {
      setVisible(true)
      setLoading(false)
    }
  }

  return (
    <InputWrapper>
      {inputVisible && (
        <Box height="40px" className="fx-row" style={{ position: 'relative' }}>
          <Image src={inputSearch} height={16} width={16} className="input_search_img" />
          <input
            onFocus={onFocus}
            value={value}
            onChange={onChange}
            placeholder="Search by address, name or slug…"
            className="search_input"
          />
          {visible && (
            <ModalBody className="hide_scrollbar" ref={myRef}>
              {result &&
                !!result.length &&
                result.map((item: iResult) => (
                  <SearchResultItem
                    key={Math.random().toString()}
                    imageUrl={item.imageUrl}
                    name={item.name}
                    nftId={item.nftId}
                    totalVolume={item.totalVolume}
                    type={item.type}
                    address={item.address}
                    onDetail={onDetail}
                  />
                ))}
              {isLoading && (
                <Box className="w100">
                  <LoadingItemWrapper height={62} mt={0} count={5} />
                </Box>
              )}
            </ModalBody>
          )}
        </Box>
      )}
    </InputWrapper>
  )
}
