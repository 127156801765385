import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Box, Text, Image } from '@/packages/'
import { toNonExponential, beyondStr } from '@/utils/tools'
import { eth } from '@/assets/img'

export interface iBoxItemNftInfo {
  imageUrl: string
  name: string
  nftAddress: string
  nftId: number
  rank: number
  tokenId: string
}

export interface iBoxItem {
  price: number
  nftTokenVO: iBoxItemNftInfo
  royalty: number
  chain?: string
}

const ModuleBox = styled(Box)``
const ContImgBox = styled(Box)`
  width: 80px;
  height: 20px;
  background: #474747;
  border-radius: 0px 0px 6px 6px;
  text-align: center;
  line-height: 20px;
  color: #fff;
  font-size: 14px;
`
const ContTable = styled(Box)`
  height: 364px;
  margin-top: 36px;
  border-radius: 12px;
  background: #2e2e2e;
`
const ContTableLiOne = styled(Box)`
  width: 146px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  color: #fff;
  border-radius: 12px 12px 0 0;
`
const ContTableLi = styled(Box)`
  width: 146px;
  height: 54px;
  text-align: center;
  line-height: 54px;
  color: #fff;
`
export const ListingProfit: React.FC<iBoxItem> = ({ price, nftTokenVO, royalty }) => {
  return (
    <Box>
      <ModuleBox>
        <Text fontSize={14} color="#fff" fontWeight="normal" mb="24px">
          Profit Calculator helps you estimate the profits(doesn’t include gas fee)
        </Text>
        <Box className="fx" height="84px">
          <Box mr="16px">
            <Image height={80} width={80} src={nftTokenVO.imageUrl} className="brt6" />
            <ContImgBox>#{beyondStr(nftTokenVO.tokenId, 4)}</ContImgBox>
          </Box>
          <Box>
            <Box className="fx ai-ct marginBmin" height="20px">
              <Text fontSize={16} color="rgba(255, 255, 255, 0.6)" fontWeight="normal">
                Rank：
              </Text>
              <Text color="#727272" fontSize={16} fontWeight={600}>
                {nftTokenVO.rank ? nftTokenVO.rank : ''}
              </Text>
            </Box>
            <Box className="fx ai-ct" height="20px">
              <Text fontSize={16} color="#fff" fontWeight={400}>
                Buy Price
              </Text>
              <Box className="fx ai-ct" marginLeft="8px">
                <Image height={12} width={6} src={eth} />
                <Text fontSize={16} color="#fff" fontWeight={400} ml="4px">
                  {price}
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
        <ContTable>
          <Box className="fx ai-ct jc-sb" height="40px" background="#474747" borderRadius="12px 12px 0 0">
            <ContTableLiOne>Pnl (Pnl %)</ContTableLiOne>
            <ContTableLiOne>Sell Price</ContTableLiOne>
            <ContTableLiOne>Royalty Cost</ContTableLiOne>
          </Box>
          <Box className="fx ai-ct jc-sb boxBr">
            <ContTableLi>Ξ {`${price * 0} (0%)`}</ContTableLi>
            <ContTableLi>Ξ {toNonExponential(((1 + 0) * price) / (1 - royalty / 10000), 3)}</ContTableLi>
            <ContTableLi>
              Ξ {toNonExponential((((1 + 0) * price) / (1 - royalty / 10000)) * (royalty / 10000), 3)}
            </ContTableLi>
          </Box>
          <Box className="fx ai-ct jc-sb boxBr">
            <ContTableLi>Ξ {`${toNonExponential(price * 0.2, 3)} (20%)`}</ContTableLi>
            <ContTableLi>Ξ {toNonExponential(((1 + 0.2) * price) / (1 - royalty / 10000), 3)}</ContTableLi>
            <ContTableLi>
              Ξ {toNonExponential((((1 + 0.2) * price) / (1 - royalty / 10000)) * (royalty / 10000), 3)}
            </ContTableLi>
          </Box>
          <Box className="fx ai-ct jc-sb boxBr">
            <ContTableLi>Ξ {`${toNonExponential(price * 0.5, 3)} (50%)`}</ContTableLi>
            <ContTableLi>Ξ {toNonExponential(((1 + 0.5) * price) / (1 - royalty / 10000), 3)}</ContTableLi>
            <ContTableLi>
              Ξ {toNonExponential((((1 + 0.5) * price) / (1 - royalty / 10000)) * (royalty / 10000), 3)}
            </ContTableLi>
          </Box>
          <Box className="fx ai-ct jc-sb boxBr">
            <ContTableLi>Ξ {`${toNonExponential(price * 0.8, 3)} (80%)`}</ContTableLi>
            <ContTableLi>Ξ {toNonExponential(((1 + 0.8) * price) / (1 - royalty / 10000), 3)}</ContTableLi>
            <ContTableLi>
              Ξ {toNonExponential((((1 + 0.8) * price) / (1 - royalty / 10000)) * (royalty / 10000), 3)}
            </ContTableLi>
          </Box>
          <Box className="fx ai-ct jc-sb boxBr">
            <ContTableLi>Ξ {`${toNonExponential(price * 1, 3)} (100%)`}</ContTableLi>
            <ContTableLi>Ξ {toNonExponential(((1 + 1) * price) / (1 - royalty / 10000), 3)}</ContTableLi>
            <ContTableLi>
              Ξ {toNonExponential((((1 + 1) * price) / (1 - royalty / 10000)) * (royalty / 10000), 3)}
            </ContTableLi>
          </Box>
          <Box className="fx ai-ct jc-sb">
            <ContTableLi>Ξ {`${toNonExponential(price * 2, 3)} (200%)`}</ContTableLi>
            <ContTableLi>Ξ {toNonExponential(((1 + 2) * price) / (1 - royalty / 10000), 3)}</ContTableLi>
            <ContTableLi>
              Ξ {toNonExponential((((1 + 2) * price) / (1 - royalty / 10000)) * (royalty / 10000), 3)}
            </ContTableLi>
          </Box>
        </ContTable>
      </ModuleBox>
    </Box>
  )
}
