import { iDashbordState, AppActionType, iHotCollection } from '@/types/store'
import {
  MARKET_CAP,
  HOT_MINTS,
  HOT_COLLECTION,
  FEED_LIST,
  CHANGE_PERIOD,
  REFRESH_TABLE,
  TRENDING_PRICE,
  MINTS_PRICE,
  ADD_ALERT,
  MEMBER_INFO
} from '@/store/common'

export const initHotCollection = {
  pageIndex: 1,
  pageSize: 10,
  records: [],
  total: 0
}
export const initHotMints = {}
export const initMarketCap = {
  coin: {},
  marketCap: {},
  marketCaps: [],
  volume: {},
  valumes: [],
  dates: []
}

export const initFeedList = {}

export const dashInitState: any = {
  marketCap: initMarketCap,
  hotMints: initHotMints,
  hotCollection: initHotCollection,
  feedList: initFeedList,
  period: {
    tabName: 'Trending',
    trendingPeriod: '15m',
    mintsPeriod: '15m',
    watchListPeriod: '15m'
  },
  refreshTable: {
    activeTable: '',
    Trending: 0,
    Mints: 0,
    WatchList: 0
  },
  trendingPrice: '',
  isMintPrice: 0,
  alertList: [],
  memberInfo: null
}

const HOTCOLLECTION = (state: iDashbordState, hotCollection: iHotCollection) => {
  const { pageIndex, pageSize, records, total } = hotCollection
  return {
    ...state,
    hotCollection: {
      pageIndex,
      pageSize,
      records,
      // records: [...state.hotCollection.records, ...records],
      total
    }
  }
}
const ALERTLIST = (state: iDashbordState, alertData: any) => {
  // add close closeAll
  const key: Array<string> = Object.keys(alertData)
  let aList: Array<any> = []
  if (key[0] === 'add') {
    aList = [alertData.add, ...state.alertList]
  }
  if (key[0] === 'close') {
    const _alertList: Array<any> = state.alertList
    const idx: number = state.alertList.findIndex((al: any) => al.nft.nftAddresses === alertData.close)
    _alertList.splice(idx, 1)
    aList = [..._alertList]
  }
  if (key[0] === 'closeAll') {
    aList = []
  }
  return {
    ...state,
    alertList: aList
  }
}

export function DashbordReducer(state = dashInitState, action: AppActionType): iDashbordState {
  const {
    marketCap,
    hotMints,
    hotCollection,
    feedList,
    period,
    refreshTable,
    trendingPrice,
    isMint,
    alertData,
    memberInfo
  } = action.payload
  switch (action.type) {
    case MARKET_CAP:
      return {
        ...state,
        marketCap
      }
    case HOT_MINTS:
      return {
        ...state,
        hotMints
      }
    case HOT_COLLECTION:
      return HOTCOLLECTION(state, hotCollection)
    case FEED_LIST:
      return {
        ...state,
        feedList
      }
    case CHANGE_PERIOD:
      return {
        ...state,
        period: {
          tabName: period.tabName,
          trendingPeriod: period.trendingPeriod || state.period.trendingPeriod,
          mintsPeriod: period.mintsPeriod || state.period.mintsPeriod,
          watchListPeriod: period.watchListPeriod || state.period.watchListPeriod
        }
      }
    case REFRESH_TABLE:
      const { refreshTableName, refreshTime } = refreshTable
      return {
        ...state,
        refreshTable: {
          activeTable: refreshTableName,
          Trending: refreshTableName === 'Trending' ? refreshTime : state.refreshTable.Trending,
          Mints: refreshTableName === 'Mints' ? refreshTime : state.refreshTable.Mints,
          WatchList: refreshTableName === 'WatchList' ? refreshTime : state.refreshTable.WatchList
        }
      }
    case TRENDING_PRICE:
      return {
        ...state,
        trendingPrice
      }
    case MINTS_PRICE:
      return {
        ...state,
        isMintPrice: isMint
      }
    case MEMBER_INFO:
      return {
        ...state,
        memberInfo
      }
    case ADD_ALERT:
      return ALERTLIST(state, alertData)
    default:
      return state
  }
}
