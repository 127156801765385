import { iLiveview, AppActionType } from '@/types/store'
import {
  PRICE_ESTIMATE,
  NFT_INFO,
  LISTING_DEPTH,
  NFT_ID_CONTEXT,
  CRT_SUPPLY,
  LISTING_FILTER,
  QUICK_BUY_VISIBLE,
  TRAIT_VISIBLE,
  RARITY_RANK,
  LISTING_TOTAL,
  CRT_NFT_ADDRESS,
  WS_FLOOR_PRICE,
  WS_SOLD_OUT
} from '@/store/common'

export const liveviewInitState: any = {
  priceEstimate: {},
  nftInfo: {},
  listingDepth: {},
  crtNftId: 0,
  crtSupply: 0,
  buyInfo: null,
  quickBuyFrom: '',
  trait: {
    visible: false,
    traitQuery: []
  },
  rankFilter: {
    min: 0,
    max: 0
  },
  listingTotal: 0,
  crtNFTAddr: '',
  wsFloorPrice: null,
  wsSoldOut: null
}

export function LiveViewReducer(state = liveviewInitState, action: AppActionType): iLiveview {
  const {
    priceEstimate,
    nftInfo,
    listingDepth,
    crtNftId,
    crtSupply,
    listingFilterOptions,
    buyInfo,
    quickBuyFrom,
    trait,
    rankFilter,
    total,
    crtNFTAddr,
    wsFloorPrice,
    wsSoldOut
  } = action.payload
  switch (action.type) {
    case RARITY_RANK:
      return {
        ...state,
        rankFilter
      }
    case PRICE_ESTIMATE:
      return {
        ...state,
        priceEstimate
      }
    case NFT_INFO:
      return {
        ...state,
        nftInfo
      }
    case LISTING_DEPTH:
      return {
        ...state,
        listingDepth
      }
    case NFT_ID_CONTEXT:
      return {
        ...state,
        crtNftId
      }
    case CRT_SUPPLY:
      return {
        ...state,
        crtSupply
      }
    case CRT_NFT_ADDRESS:
      return {
        ...state,
        crtNFTAddr
      }
    case LISTING_FILTER:
      return {
        ...state,
        listingFilterOptions
      }
    case QUICK_BUY_VISIBLE:
      return {
        ...state,
        buyInfo,
        quickBuyFrom
      }
    case TRAIT_VISIBLE:
      return {
        ...state,
        trait
      }
    case LISTING_TOTAL:
      return {
        ...state,
        listingTotal: total
      }
    case WS_FLOOR_PRICE:
      return {
        ...state,
        wsFloorPrice
      }
    case WS_SOLD_OUT:
      return {
        ...state,
        wsSoldOut
      }
    default:
      return state
  }
}
