import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Box, Text, Image, Grid } from '@/packages/'
import { Col } from '@/components/Layout/Layout'
import { okIcon } from '@/assets/img'
import { useMatchBreakpoints } from '@/hooks/useMatchBreakpoints'

const BigContBox = styled(Box)``
const ContonBox = styled(Box)`
  margin: 0 auto;
`
const RoadmapImg = styled(Box)`
  position: absolute;
  top: 120px;
`
const Phase1Box = styled(Box)`
  width: 100%;
  height: 100%;
  border-radius: 30px;
  background: rgba(255, 255, 255, 0.08);
`
const Phase2Box = styled(Box)`
  width: 364px;
  height: 242px;
  border-radius: 30px;
  background: rgba(255, 255, 255, 0.08);
`
const Phase3Box = styled(Box)`
  width: 348px;
  height: 242px;
  border-radius: 30px;
  background: rgba(255, 255, 255, 0.08);
`
const DotLasttwo = styled(Box)`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #fff;
`
export const Roadmap = (scrollNum: any) => {
  const { isMobile, isTablet } = useMatchBreakpoints()
  return (
    <Box className="c-sb w100 relative" height={['520px', '600px', '840px', '990px']}>
      <Box className="w100 bg26 pa" top={['87px', '120px']} height={['175px', '348px', '522px', '694px']} />

      <Col className="mxw relative">
        {isMobile ? (
          <Text
            fontSize={['20px', '34px', '47px', '60px']}
            fontWeight={700}
            color="#fff"
            textAlign="center"
            height={['26px', '44px', '62px', '80px']}
            marginBottom={['10px', '12px', '14px', '16px']}
            className={
              scrollNum.scrollNum > 2000 && scrollNum.scrollNum < 3000 ? 'isMobileRoadTitle' : 'isMobileRoadTitleNo'
            }
          >
            Roadmap
          </Text>
        ) : (
          <Text
            fontSize={['20px', '34px', '47px', '60px']}
            fontWeight={700}
            color="#fff"
            textAlign="center"
            height={['26px', '44px', '62px', '80px']}
            marginBottom={['10px', '12px', '14px', '16px']}
            className={scrollNum.scrollNum > 4900 && scrollNum.scrollNum < 5800 ? 'roadTitle' : 'roadTitleNo'}
          >
            Roadmap
          </Text>
        )}

        <ContonBox className="w700 col2 mb50 h70 tc ft20" />
        <Box className="relative w100" height={['385px', '478px', '710px']}>
          {isMobile ? (
            <Box
              className={
                scrollNum.scrollNum > 2100 && scrollNum.scrollNum < 3200
                  ? 'isMobilePhase1Text pa'
                  : 'isMobilePhase1TextNo pa'
              }
              left={['-10px', 0, 0, '270px']}
            >
              <Box
                className="relative"
                left={['0px', '0px', '0px', '128px']}
                paddingLeft={['36px', '72px', '88px', '128px']}
              >
                <Box
                  className="pa lf bm"
                  width={['48px', '88px', '120px', '170px']}
                  height={['50px', '92px', '134px', '178px']}
                >
                  <Image src="https://peweetools.s3.us-west-2.amazonaws.com/png/Phase2.png" className="rw100 rh100" />
                </Box>

                <Text
                  fontSize={['12px', '15px', '20px', '24px']}
                  marginBottom={['4px', '8px', '14px', '20px']}
                  fontWeight={700}
                  color="#fff"
                >
                  Phase 1
                </Text>
                <Box
                  paddingTop={['5px', '10px', '15px', '20px']}
                  paddingBottom={['5px', '10px', '15px', '20px']}
                  paddingLeft={['8px', '11px', '14px', '16px']}
                  width={['158px', '230px', '312px']}
                  // height={['104px', '166px', '242px']}
                  background="rgba(255, 255, 255, 0.08)"
                  borderRadius={['8px', '16px', '24px', '30px']}
                >
                  <Text
                    fontSize={['12px', '15px', '18px', '20px']}
                    fontWeight={400}
                    color="rgba(255, 255, 255, 0.5)"
                    mb={['8px', '16px', '24px', '30px']}
                  >
                    Promised Function Done
                  </Text>
                  <Box className="fx ai-ct" marginBottom={['5px', '10px', '15px', '20px']}>
                    <Box
                      width={['7px', '10px', '12px', '14px']}
                      height={['7px', '10px', '12px', '14px']}
                      marginLeft={['2px', '5px', '8px', '11px']}
                      marginRight={['2px', '5px', '8px', '11px']}
                    >
                      <Image src={okIcon} className="rh100 rw100" />
                    </Box>

                    <Text fontSize={['12px', '15px', '18px', '20px']} fontWeight={700} className="mtxt" color="#fff">
                      Snipe Pro
                    </Text>
                  </Box>
                  <Box className="fx ai-ct" marginBottom={['5px', '10px', '15px', '20px']}>
                    <Box
                      width={['7px', '10px', '12px', '14px']}
                      height={['7px', '10px', '12px', '14px']}
                      marginLeft={['2px', '5px', '8px', '11px']}
                      marginRight={['2px', '5px', '8px', '11px']}
                    >
                      <Image src={okIcon} className="rh100 rw100" />
                    </Box>
                    <Text fontSize={['12px', '15px', '18px', '20px']} fontWeight={700} className="mtxt" color="#fff">
                      Live View
                    </Text>
                  </Box>
                  <Box className="fx ai-ct" marginBottom={['5px', '10px', '15px', '20px']}>
                    <Box
                      width={['7px', '10px', '12px', '14px']}
                      height={['7px', '10px', '12px', '14px']}
                      marginLeft={['2px', '5px', '8px', '11px']}
                      marginRight={['2px', '5px', '8px', '11px']}
                    >
                      <Image src={okIcon} className="rh100 rw100" />
                    </Box>
                    <Text fontSize={['12px', '15px', '18px', '20px']} fontWeight={700} className="mtxt" color="#fff">
                      Whales Activity
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              className={scrollNum.scrollNum > 5400 && scrollNum.scrollNum < 6500 ? 'phase1Text pa' : 'phase1TextNo pa'}
              left={['-20px', 0, 0, '270px']}
            >
              <Box
                className="relative"
                left={['0px', '0px', '0px', '128px']}
                paddingLeft={['36px', '72px', '88px', '128px']}
              >
                <Box
                  className="pa lf bm"
                  width={['48px', '88px', '120px', '170px']}
                  height={['50px', '92px', '134px', '178px']}
                >
                  <Image src="https://peweetools.s3.us-west-2.amazonaws.com/png/Phase2.png" className="rw100 rh100" />
                </Box>

                <Text
                  fontSize={['12px', '15px', '20px', '24px']}
                  marginBottom={['4px', '8px', '14px', '20px']}
                  fontWeight={700}
                  color="#fff"
                >
                  Phase 1
                </Text>
                <Box
                  paddingTop={['5px', '10px', '15px', '20px']}
                  paddingBottom={['5px', '10px', '15px', '20px']}
                  paddingLeft={['8px', '11px', '14px', '16px']}
                  width={['158px', '230px', '312px']}
                  // height={['104px', '166px', '242px']}
                  background="rgba(255, 255, 255, 0.08)"
                  borderRadius={['8px', '16px', '24px', '30px']}
                >
                  <Text
                    fontSize={['12px', '15px', '18px', '20px']}
                    fontWeight={400}
                    color="rgba(255, 255, 255, 0.5)"
                    mb={['8px', '16px', '24px', '30px']}
                  >
                    Promised Function Done
                  </Text>
                  <Box className="fx ai-ct" marginBottom={['5px', '10px', '15px', '20px']}>
                    <Box
                      width={['7px', '10px', '12px', '14px']}
                      height={['7px', '10px', '12px', '14px']}
                      marginLeft={['2px', '5px', '8px', '11px']}
                      marginRight={['2px', '5px', '8px', '11px']}
                    >
                      <Image src={okIcon} className="rh100 rw100" />
                    </Box>

                    <Text fontSize={['12px', '15px', '18px', '20px']} fontWeight={700} className="mtxt" color="#fff">
                      Snipe Pro
                    </Text>
                  </Box>
                  <Box className="fx ai-ct" marginBottom={['5px', '10px', '15px', '20px']}>
                    <Box
                      width={['7px', '10px', '12px', '14px']}
                      height={['7px', '10px', '12px', '14px']}
                      marginLeft={['2px', '5px', '8px', '11px']}
                      marginRight={['2px', '5px', '8px', '11px']}
                    >
                      <Image src={okIcon} className="rh100 rw100" />
                    </Box>
                    <Text fontSize={['12px', '15px', '18px', '20px']} fontWeight={700} className="mtxt" color="#fff">
                      Live View
                    </Text>
                  </Box>
                  <Box className="fx ai-ct" marginBottom={['5px', '10px', '15px', '20px']}>
                    <Box
                      width={['7px', '10px', '12px', '14px']}
                      height={['7px', '10px', '12px', '14px']}
                      marginLeft={['2px', '5px', '8px', '11px']}
                      marginRight={['2px', '5px', '8px', '11px']}
                    >
                      <Image src={okIcon} className="rh100 rw100" />
                    </Box>
                    <Text fontSize={['12px', '15px', '18px', '20px']} fontWeight={700} className="mtxt" color="#fff">
                      Whales Activity
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
          {isMobile ? (
            <Box
              left={isMobile || isTablet ? '' : '-80px'}
              right={isMobile || isTablet ? 0 : ''}
              top={['100px', '', '200px']}
              className={
                scrollNum.scrollNum > 2200 && scrollNum.scrollNum < 3200
                  ? 'isMobilePhaseText pa  tp200'
                  : 'isMobilePhaseTextNo pa  tp200'
              }
            >
              <Box paddingLeft={['36px', '72px', '88px', '128px']} className="relative">
                <Box
                  className="pa lf bm"
                  width={['48px', '88px', '120px', '170px']}
                  height={['50px', '92px', '134px', '178px']}
                >
                  <Image src="https://peweetools.s3.us-west-2.amazonaws.com/png/Phase1.png" className="rw100 rh100" />
                </Box>
                <Text
                  fontSize={['12px', '15px', '20px', '24px']}
                  marginBottom={['4px', '8px', '14px', '20px']}
                  fontWeight={700}
                  color="#fff"
                >
                  Phase 2
                </Text>
                <Box
                  paddingTop={['5px', '10px', '15px', '20px']}
                  paddingBottom={['5px', '10px', '15px', '20px']}
                  paddingLeft={['8px', '11px', '14px', '16px']}
                  width={['150px', '230px', '364px']}
                  // height={['134px', '166px', '242px']}
                  background="rgba(255, 255, 255, 0.08)"
                  borderRadius={['8px', '16px', '24px', '30px']}
                >
                  <Text
                    fontSize={['12px', '15px', '18px', '20px']}
                    fontWeight={400}
                    color="rgba(255, 255, 255, 0.5)"
                    mb={['8px', '16px', '24px', '30px']}
                  >
                    Advanced Update
                  </Text>
                  <Box className="fx ai-ct" marginBottom={['5px', '10px', '15px', '20px']}>
                    <Box
                      width={['7px', '10px', '12px', '14px']}
                      height={['7px', '10px', '12px', '14px']}
                      marginLeft={['2px', '5px', '8px', '11px']}
                      marginRight={['2px', '5px', '8px', '11px']}
                    >
                      <Image src={okIcon} className="rh100 rw100" />
                    </Box>
                    <Text fontSize={['12px', '15px', '18px', '20px']} fontWeight={500} className="mtxt" color="#fff">
                      Listing Under Floor
                    </Text>
                  </Box>
                  <Box className="fx ai-ct" marginBottom={['5px', '10px', '15px', '20px']}>
                    <Box
                      width={['7px', '10px', '12px', '14px']}
                      height={['7px', '10px', '12px', '14px']}
                      marginLeft={['2px', '5px', '8px', '11px']}
                      marginRight={['2px', '5px', '8px', '11px']}
                    >
                      <Image src={okIcon} className="rh100 rw100" />
                    </Box>
                    <Text fontSize={['12px', '15px', '18px', '20px']} fontWeight={700} className="mtxt" color="#fff">
                      Floor Alert Trigger
                    </Text>
                  </Box>
                  <Box className="fx ai-ct" marginBottom={['5px', '10px', '15px', '20px']}>
                    <Box
                      width={['7px', '10px', '12px', '14px']}
                      height={['7px', '10px', '12px', '14px']}
                      marginLeft={['2px', '5px', '8px', '11px']}
                      marginRight={['2px', '5px', '8px', '11px']}
                    >
                      <Image src={okIcon} className="rh100 rw100" />
                    </Box>
                    <Text fontSize={['12px', '15px', '18px', '20px']} fontWeight={700} className="mtxt" color="#fff">
                      Pending Transactions Track
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              left={isMobile || isTablet ? '' : '-80px'}
              right={isMobile || isTablet ? 0 : ''}
              top={['100px', '', '200px']}
              className={
                scrollNum.scrollNum > 5200 && scrollNum.scrollNum < 6500
                  ? 'phaseText pa  tp200'
                  : 'phaseTextNo pa  tp200'
              }
            >
              <Box paddingLeft={['36px', '72px', '88px', '128px']} className="relative">
                <Box
                  className="pa lf bm"
                  width={['48px', '88px', '120px', '170px']}
                  height={['50px', '92px', '134px', '178px']}
                >
                  <Image src="https://peweetools.s3.us-west-2.amazonaws.com/png/Phase1.png" className="rw100 rh100" />
                </Box>
                <Text
                  fontSize={['12px', '15px', '20px', '24px']}
                  marginBottom={['4px', '8px', '14px', '20px']}
                  fontWeight={700}
                  color="#fff"
                >
                  Phase 2
                </Text>
                <Box
                  paddingTop={['5px', '10px', '15px', '20px']}
                  paddingBottom={['5px', '10px', '15px', '20px']}
                  paddingLeft={['8px', '11px', '14px', '16px']}
                  width={['150px', '230px', '364px']}
                  // height={['134px', '166px', '242px']}
                  background="rgba(255, 255, 255, 0.08)"
                  borderRadius={['8px', '16px', '24px', '30px']}
                >
                  <Text
                    fontSize={['12px', '15px', '18px', '20px']}
                    fontWeight={400}
                    color="rgba(255, 255, 255, 0.5)"
                    mb={['8px', '16px', '24px', '30px']}
                  >
                    Advanced Update
                  </Text>
                  <Box className="fx ai-ct" marginBottom={['5px', '10px', '15px', '20px']}>
                    <Box
                      width={['7px', '10px', '12px', '14px']}
                      height={['7px', '10px', '12px', '14px']}
                      marginLeft={['2px', '5px', '8px', '11px']}
                      marginRight={['2px', '5px', '8px', '11px']}
                    >
                      <Image src={okIcon} className="rh100 rw100" />
                    </Box>
                    <Text fontSize={['12px', '15px', '18px', '20px']} fontWeight={500} className="mtxt" color="#fff">
                      Listing Under Floor
                    </Text>
                  </Box>
                  <Box className="fx ai-ct" marginBottom={['5px', '10px', '15px', '20px']}>
                    <Box
                      width={['7px', '10px', '12px', '14px']}
                      height={['7px', '10px', '12px', '14px']}
                      marginLeft={['2px', '5px', '8px', '11px']}
                      marginRight={['2px', '5px', '8px', '11px']}
                    >
                      <Image src={okIcon} className="rh100 rw100" />
                    </Box>
                    <Text fontSize={['12px', '15px', '18px', '20px']} fontWeight={700} className="mtxt" color="#fff">
                      Floor Alert Trigger
                    </Text>
                  </Box>
                  <Box className="fx ai-ct" marginBottom={['5px', '10px', '15px', '20px']}>
                    <Box
                      width={['7px', '10px', '12px', '14px']}
                      height={['7px', '10px', '12px', '14px']}
                      marginLeft={['2px', '5px', '8px', '11px']}
                      marginRight={['2px', '5px', '8px', '11px']}
                    >
                      <Image src={okIcon} className="rh100 rw100" />
                    </Box>
                    <Text fontSize={['12px', '15px', '18px', '20px']} fontWeight={700} className="mtxt" color="#fff">
                      Pending Transactions Track
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
          {isMobile ? (
            <Box
              className={
                scrollNum.scrollNum > 2300 && scrollNum.scrollNum < 3300
                  ? 'isMobilePhase2Text pa'
                  : 'isMobilePhase2TextNo pa'
              }
              left={isMobile || isTablet ? '37px' : ''}
              right={isMobile || isTablet ? '' : '0px'}
              bottom={isMobile || isTablet ? '5px' : '0px'}
            >
              <Box
                className="relative"
                left={['0px', '0px', '0px', '0']}
                paddingLeft={['36px', '72px', '88px', '128px']}
              >
                <Box
                  className="pa lf bm"
                  width={['48px', '88px', '120px', '170px']}
                  height={['50px', '92px', '134px', '178px']}
                >
                  <Image src="https://peweetools.s3.us-west-2.amazonaws.com/png/Phase3.png" className="rw100 rh100" />
                </Box>
                <Text
                  fontSize={['12px', '15px', '20px', '24px']}
                  marginBottom={['4px', '8px', '14px', '20px']}
                  fontWeight={700}
                  color="#fff"
                >
                  Phase 3
                </Text>
                <Box
                  paddingTop={['5px', '10px', '15px', '20px']}
                  paddingBottom={['5px', '10px', '15px', '20px']}
                  paddingLeft={['8px', '11px', '14px', '16px']}
                  width={['200px', '230px', '312px']}
                  // height={['104px', '166px', '242px']}
                  background="rgba(255, 255, 255, 0.08)"
                  borderRadius={['8px', '16px', '24px', '30px']}
                >
                  <Text
                    fontSize={['12px', '15px', '18px', '20px']}
                    fontWeight={400}
                    color="rgba(255, 255, 255, 0.5)"
                    mb={['8px', '16px', '24px', '30px']}
                  >
                    Multidimensional Expansion
                  </Text>
                  <Box className="fx ai-ct" marginBottom={['5px', '10px', '15px', '20px']}>
                    <Box
                      width={['5px', '5px', '6px', '8px']}
                      height={['5px', '5px', '6px', '8px']}
                      marginTop={['0px', '2px', '2px', '10px']}
                      marginLeft={['6px', '6px', '14px', '14px']}
                      marginRight={['6px', '6px', '14px', '14px']}
                    >
                      <DotLasttwo />
                    </Box>
                    <Text fontSize={['12px', '15px', '18px', '20px']} fontWeight={500} color="#fff">
                      Alert System
                    </Text>
                  </Box>
                  <Box className="fx ai-ct" marginBottom={['5px', '10px', '15px', '20px']}>
                    <Box
                      width={['5px', '5px', '6px', '8px']}
                      height={['5px', '5px', '6px', '8px']}
                      marginTop={['0px', '2px', '2px', '10px']}
                      marginLeft={['6px', '6px', '14px', '14px']}
                      marginRight={['6px', '6px', '14px', '14px']}
                    >
                      <DotLasttwo />
                    </Box>
                    <Text fontSize={['12px', '15px', '18px', '20px']} fontWeight={700} color="#fff">
                      Multi-market Aggregator
                    </Text>
                  </Box>
                  <Box className="fx ai-ct" marginBottom={['5px', '10px', '15px', '20px']}>
                    <Box
                      width={['5px', '5px', '6px', '8px']}
                      height={['5px', '5px', '6px', '8px']}
                      marginTop={['0px', '2px', '2px', '10px']}
                      marginLeft={['6px', '6px', '14px', '14px']}
                      marginRight={['6px', '6px', '14px', '14px']}
                    >
                      <DotLasttwo />
                    </Box>
                    <Text fontSize={['12px', '15px', '18px', '20px']} fontWeight={700} color="#fff">
                      Mint Bot
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              className={scrollNum.scrollNum > 5600 && scrollNum.scrollNum < 6500 ? 'phase2Text pa' : 'phase2TextNo pa'}
              left={isMobile || isTablet ? '37px' : ''}
              right={isMobile || isTablet ? '' : '0px'}
              bottom={isMobile || isTablet ? '24px' : '0px'}
            >
              <Box
                className="relative"
                left={['0px', '0px', '0px', '0']}
                paddingLeft={['36px', '72px', '88px', '128px']}
              >
                <Box
                  className="pa lf bm"
                  width={['48px', '88px', '120px', '170px']}
                  height={['50px', '92px', '134px', '178px']}
                >
                  <Image src="https://peweetools.s3.us-west-2.amazonaws.com/png/Phase3.png" className="rw100 rh100" />
                </Box>
                <Text
                  fontSize={['12px', '15px', '20px', '24px']}
                  marginBottom={['4px', '8px', '14px', '20px']}
                  fontWeight={700}
                  color="#fff"
                >
                  Phase 3
                </Text>
                <Box
                  paddingTop={['5px', '10px', '15px', '20px']}
                  paddingBottom={['5px', '10px', '15px', '20px']}
                  paddingLeft={['8px', '11px', '14px', '16px']}
                  width={['200px', '230px', '312px']}
                  // height={['104px', '166px', '242px']}
                  background="rgba(255, 255, 255, 0.08)"
                  borderRadius={['8px', '16px', '24px', '30px']}
                >
                  <Text
                    fontSize={['12px', '15px', '18px', '20px']}
                    fontWeight={400}
                    color="rgba(255, 255, 255, 0.5)"
                    mb={['8px', '16px', '24px', '30px']}
                  >
                    Multidimensional Expansion
                  </Text>
                  <Box className="fx ai-ct" marginBottom={['5px', '10px', '15px', '20px']}>
                    <Box
                      width={['5px', '5px', '6px', '8px']}
                      height={['5px', '5px', '6px', '8px']}
                      marginTop={['0px', '2px', '2px', '10px']}
                      marginLeft={['6px', '6px', '14px', '14px']}
                      marginRight={['6px', '6px', '14px', '14px']}
                    >
                      <DotLasttwo />
                    </Box>
                    <Text fontSize={['12px', '15px', '18px', '20px']} fontWeight={500} color="#fff">
                      Alert System
                    </Text>
                  </Box>
                  <Box className="fx ai-ct" marginBottom={['5px', '10px', '15px', '20px']}>
                    <Box
                      width={['5px', '5px', '6px', '8px']}
                      height={['5px', '5px', '6px', '8px']}
                      marginTop={['0px', '2px', '2px', '10px']}
                      marginLeft={['6px', '6px', '14px', '14px']}
                      marginRight={['6px', '6px', '14px', '14px']}
                    >
                      <DotLasttwo />
                    </Box>
                    <Text fontSize={['12px', '15px', '18px', '20px']} fontWeight={700} color="#fff">
                      Multi-market Aggregator
                    </Text>
                  </Box>
                  <Box className="fx ai-ct" marginBottom={['5px', '10px', '15px', '20px']}>
                    <Box
                      width={['5px', '5px', '6px', '8px']}
                      height={['5px', '5px', '6px', '8px']}
                      marginTop={['0px', '2px', '2px', '10px']}
                      marginLeft={['6px', '6px', '14px', '14px']}
                      marginRight={['6px', '6px', '14px', '14px']}
                    >
                      <DotLasttwo />
                    </Box>
                    <Text fontSize={['12px', '15px', '18px', '20px']} fontWeight={700} color="#fff">
                      Mint Bot
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Col>
    </Box>
  )
}
