import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Box, Text, Image, Grid } from '@/packages/'
import { ContentWrapper, Col, Row } from '@/components/Layout/Layout'
import { arrows, arrowsrg, light } from '@/assets/img'
import { useMatchBreakpoints } from '@/hooks/useMatchBreakpoints'

const ContonBox = styled(Box)`
  margin: 0 auto;
`
const Textcont = styled(Box)`
  display: inline-block;
  margin-right: 6px;
`
const Dot = styled(Box)`
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  margin: 7px 10px 0;
`
const PassItemLf = styled(Box)`
  width: 248px;
  height: 450px;
`
const MobileText = styled(Box)`
  width: 285px;
  margin: 27px auto 0;
`
export const PassUtilities = (scrollNum: any) => {
  const { isMobile, isTablet } = useMatchBreakpoints()
  console.log('当前滚动位置', scrollNum.scrollNum)
  return (
    <Box
      className={
        scrollNum.scrollNum > 1000 && scrollNum.scrollNum < 2000
          ? 'passCodeEffect relative w100'
          : 'passCodeTest relative w100'
      }
    >
      <Box
        className="absolute bg33"
        width={['120px', '310px', '500px', '690px']}
        height={['308px', '570px', '832px', '1094px']}
      />
      <Box className="c-sb w100" height={['700px', '900px', '892px', '990px']}>
        <Col className="mxw relative">
          <Box className="title">
            <Text
              fontSize={['20px', '34px', '47px', '60px']}
              fontWeight={700}
              color="#fff"
              textAlign="center"
              height={['26px', '44px', '62px', '80px']}
              marginTop={['40px', '54px', '67px', '80px']}
              marginBottom={['10px', '12px', '14px', '16px']}
            >
              PASS UTILITIES
            </Text>
            <Box
              className="col2 tc m"
              width={['320px', '446px', '572px', '700px']}
              height={['54px', '59px', '65px', '70px']}
              marginBottom={['22px', '54px', '86px', '120px']}
            >
              <Text fontSize={['12px', '14px', '17px', '20px']} className="white87 fw400">
                Pewee pass is <Textcont className="mtxt">a life-time</Textcont>
                pass of exclusive benefits for holders including full access to the most useful analytical tool, alpha
                community and special rewards.
              </Text>
            </Box>
          </Box>

          <Grid
            height={['50px', '65px', '80px', '95px']}
            width={['285px', '400px', '515px', '630px']}
            className="fx ai-ct jc-sb bg32 m"
            paddingLeft={['20px', '29px', '38px', '46px']}
            paddingRight={['20px', '29px', '38px', '46px']}
            marginBottom={['22px', '52px', '82px', '110px']}
            gridTemplateColumns={['1fr 1fr 1fr', '1fr 1fr 1fr', '1fr 1fr 1fr', '1fr 1fr 1fr']}
          >
            <Box className="tc">
              <Text
                fontSize={['10px', '12px', '14px', '16px']}
                fontWeight={400}
                marginBottom={['2px', '2px', '4px', '4px']}
                color="#FFFFFF"
              >
                Mint Date
              </Text>
              <Text fontSize={['14px', '18px', '22px', '26px']} fontWeight={500} className="mtxt">
                October
              </Text>
            </Box>
            <Box className="tc">
              <Text
                fontSize={['10px', '12px', '14px', '16px']}
                fontWeight={400}
                marginBottom={['2px', '2px', '4px', '4px']}
                color="#FFFFFF"
              >
                Supply
              </Text>
              <Text fontSize={['14px', '18px', '22px', '26px']} fontWeight={500} color="rgba(255, 255, 255, 0.2)">
                TBA
              </Text>
            </Box>
            <Box className="tc">
              <Text
                fontSize={['10px', '12px', '14px', '16px']}
                fontWeight={400}
                marginBottom={['2px', '2px', '4px', '4px']}
                color="#FFFFFF"
              >
                Price
              </Text>
              <Text fontSize={['14px', '18px', '22px', '26px']} fontWeight={500} color="rgba(255, 255, 255, 0.2)">
                TBA
              </Text>
            </Box>
          </Grid>
          <Box className="fx ai-ct jc-sb">
            <Box
              className="codeLeft"
              paddingTop={['0', '0', '27px', '40px']}
              width={['0px', '0', '168px', '248px']}
              height={['0', '0', '300px', '450px']}
            >
              <PassItemLf className="rw100 rh100">
                <Box marginBottom={['0', '0', '32px', '48px']}>
                  <Text fontSize={['0px', '0px', '12px', '18px']} fontWeight={700} color="#fff" mb="4px">
                    Life-time Premium Pass
                  </Text>
                  <Box className="fx">
                    <Dot />
                    <Text
                      fontSize={['0px', '0px', '12px', '18px']}
                      fontWeight={400}
                      color="rgba(255, 255, 255, 0.5)"
                      lineHeight={[0, 0, '14px', '20px']}
                      width={['0px', '0px', '144px', '200px']}
                    >
                      Free Unlimited Access to Pewee Tools
                    </Text>
                  </Box>
                </Box>
                <Box marginBottom={['0', '0', '32px', '48px']}>
                  <Text fontSize={['0px', '0px', '12px', '18px']} fontWeight={700} color="#fff" mb="4px">
                    Holder Rewards From Pewee’s Profit
                  </Text>
                  <Box className="fx mb4">
                    <Dot />
                    <Text
                      fontSize={['0px', '0px', '12px', '18px']}
                      fontWeight={400}
                      color="rgba(255, 255, 255, 0.5)"
                      lineHeight={[0, 0, '14px', '20px']}
                      width={['0px', '0px', '144px', '200px']}
                    >
                      NFT Airdrop
                    </Text>
                  </Box>
                  <Box className="fx mb4">
                    <Dot />
                    <Text
                      fontSize={['0px', '0px', '12px', '18px']}
                      fontWeight={400}
                      color="rgba(255, 255, 255, 0.5)"
                      lineHeight={[0, 0, '14px', '20px']}
                      width={['0px', '0px', '144px', '200px']}
                    >
                      Exclusive Raffles
                    </Text>
                  </Box>
                  <Box className="fx">
                    <Dot />
                    <Text
                      fontSize={['0px', '0px', '12px', '18px']}
                      fontWeight={400}
                      color="rgba(255, 255, 255, 0.5)"
                      lineHeight={[0, 0, '14px', '20px']}
                      width={['0px', '0px', '144px', '200px']}
                    >
                      Refer to Earn
                    </Text>
                  </Box>
                </Box>
                <Box>
                  <Text fontSize={['0px', '0px', '12px', '18px']} fontWeight={700} color="#fff" mb="4px">
                    Exclusive WL/Airdrop for Upcoming Projects
                  </Text>
                  <Box className="fx">
                    <Dot />
                    <Text
                      fontSize={['0px', '0px', '12px', '18px']}
                      fontWeight={400}
                      color="rgba(255, 255, 255, 0.5)"
                      lineHeight={[0, 0, '14px', '20px']}
                      width={['0px', '0px', '144px', '200px']}
                    >
                      Special Involvement of Projects Under Pewee Labs
                    </Text>
                  </Box>
                </Box>
              </PassItemLf>
            </Box>

            <Box
              className="codeImgLeft"
              width={['0px', '0px', '75px', '112px']}
              height={['0px', '0px', '210px', '314px']}
            >
              <Image src={arrows} className="rw100 rh100" />
            </Box>
            <Box
              className="rotating"
              width={['182px', '292px', '400px', '508px']}
              height={['180px', '270px', '360px', '450px']}
            >
              <Image src="https://peweetools.s3.us-west-2.amazonaws.com/png/abxzImg.png" className="rw100 rh100" />
            </Box>
            <Box
              className="codeImgright"
              width={['0px', '0px', '75px', '112px']}
              height={['0px', '0px', '210px', '314px']}
            >
              <Image src={arrowsrg} className="rw100 rh100" />
            </Box>
            <Box
              className="codeRight"
              width={['0px', '0', '168px', '248px']}
              height={['0', '0', '300px', '450px']}
              paddingTop={[0, 0, '46px', '68px']}
            >
              <PassItemLf>
                <Box marginBottom={[0, 0, '76px', '114px']}>
                  <Text fontSize={['0px', '0px', '12px', '18px']} fontWeight={700} color="#fff" mb="4px">
                    Community Benefits
                  </Text>
                  <Box className="fx" marginBottom={[0, 0, '7px', '10px']}>
                    <Dot />
                    <Text
                      fontSize={['0px', '0px', '12px', '18px']}
                      fontWeight={400}
                      color="rgba(255, 255, 255, 0.5)"
                      lineHeight={[0, 0, '14px', '20px']}
                      width={['0px', '0px', '144px', '200px']}
                    >
                      Exclusive WL
                    </Text>
                  </Box>
                  <Box className="fx" marginBottom={[0, 0, '7px', '10px']}>
                    <Dot />
                    <Text
                      fontSize={['0px', '0px', '12px', '18px']}
                      fontWeight={400}
                      color="rgba(255, 255, 255, 0.5)"
                      lineHeight={[0, 0, '14px', '20px']}
                      width={['0px', '0px', '144px', '200px']}
                    >
                      Alpha Call
                    </Text>
                  </Box>
                  <Box className="fx">
                    <Dot />
                    <Text
                      fontSize={['0px', '0px', '12px', '18px']}
                      fontWeight={400}
                      color="rgba(255, 255, 255, 0.5)"
                      lineHeight={[0, 0, '14px', '20px']}
                      width={['0px', '0px', '144px', '200px']}
                    >
                      Pewee Event Invitation
                    </Text>
                  </Box>
                </Box>
                <Box>
                  <Text
                    fontSize={['0px', '0px', '12px', '18px']}
                    fontWeight={700}
                    color="#fff"
                    mb="4px"
                    className="tsj"
                  >
                    Higher Priority of Vote Right
                  </Text>
                  <Box className="fx" marginBottom={[0, 0, '7px', '10px']}>
                    <Dot />
                    <Text
                      fontSize={['0px', '0px', '12px', '18px']}
                      fontWeight={400}
                      color="rgba(255, 255, 255, 0.5)"
                      lineHeight={[0, 0, '14px', '20px']}
                      width={['0px', '0px', '144px', '200px']}
                    >
                      Upcoming Functions
                    </Text>
                  </Box>
                  <Box className="fx">
                    <Dot />
                    <Text
                      fontSize={['0px', '0px', '12px', '18px']}
                      fontWeight={400}
                      color="rgba(255, 255, 255, 0.5)"
                      lineHeight={[0, 0, '14px', '20px']}
                      width={['0px', '0px', '144px', '200px']}
                    >
                      Community Events
                    </Text>
                  </Box>
                </Box>
              </PassItemLf>
            </Box>
          </Box>
          {isMobile || isTablet ? (
            <MobileText
              className={
                scrollNum.scrollNum > 200 && scrollNum.scrollNum < 1200 ? 'passMobileTextEffect' : 'passMobileTextTest'
              }
            >
              <Box className="fx ai-ct" marginBottom="10px">
                <Image src={light} width={32} height={32} />
                <Text fontSize="14px" ml="4px" color="#fff">
                  Life-time Premium Pass
                </Text>
              </Box>
              <Box className="fx ai-ct" marginBottom="10px">
                <Image src={light} width={32} height={32} />
                <Text fontSize="14px" ml="4px" color="#fff">
                  Holder Rewards From Pewee’s Profit
                </Text>
              </Box>
              <Box className="fx ai-ct" marginBottom="10px">
                <Image src={light} width={32} height={32} />
                <Text fontSize="14px" ml="4px" color="#fff">
                  Exclusive WL/Airdrop for Upcoming Projects
                </Text>
              </Box>
              <Box className="fx ai-ct" marginBottom="10px">
                <Image src={light} width={32} height={32} />
                <Text fontSize="14px" ml="4px" color="#fff">
                  Community Benefits
                </Text>
              </Box>
              <Box className="fx ai-ct">
                <Image src={light} width={32} height={32} />
                <Text fontSize="14px" ml="4px" color="#fff">
                  Higher Priority of Vote Rights
                </Text>
              </Box>
            </MobileText>
          ) : (
            ''
          )}
        </Col>
      </Box>
    </Box>
  )
}
