import { el } from 'date-fns/locale'
import { iTrendingbordState, AppActionType, iTrendingParams, iTrendingDay } from '@/types/store'
import { TRENDING_TABLE } from '@/store/common'

export const initHotCollection = {
  pageIndex: 1,
  pageSize: 10,
  records: [],
  total: 0
}
export const initHotMints = {}

export const initMarketCap = {
  trendingtable: {
    collection: '',
    dateFloors: [],
    floor: '',
    avgPrice: '',
    trades: 0,
    nbcp: '',
    dateVolumes: []
  },
  granularity: '1d'
}

// export const initFeedList = {}
export const trendInitState: any = {
  marketCap: initMarketCap,
  trendingCollection: initHotCollection
}
const HOTCOLLECTION = (state: iTrendingbordState, trendingCollection: iTrendingParams, granularity: string) => {
  const { pageIndex, pageSize, records, total } = trendingCollection
  let listData = []
  if (granularity === initMarketCap.granularity) {
    listData = [...state.trendingCollection.records, ...records]
  } else {
    initMarketCap.granularity = granularity
    listData = records
  }
  // cons
  // const list = []
  // if()
  // if ()
  return {
    ...state,
    trendingCollection: {
      pageIndex,
      pageSize,
      records: listData,
      total
    }
  }
}
export function TrendingDashbordReducer(state = trendInitState, action: AppActionType): iTrendingbordState {
  const { trendingtable, granularity } = action.payload
  switch (action.type) {
    case TRENDING_TABLE:
      return HOTCOLLECTION(state, trendingtable, granularity)
    default:
      return state
  }
}
