import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Box, Text, Grid } from '@/packages/'
import { Col } from '@/components/Layout/Layout'
import { useMatchBreakpoints } from '@/hooks/useMatchBreakpoints'

const ContonBox = styled(Box)`
  margin: 0 auto;
`
const BgBox = styled(Box)`
  width: 100%;
  height: 100%;
  position: relative;
`
const DotLast = styled(Box)`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #fff;
`
const CodeBox = styled(Box)`
  width: 100%;
  height: 100%;
  // overflow: hidden;
  // backface-visibility: hidden;
  // position: absolute;
  // transition: transform 0.6s linear;
  // // border-radius: 30px;
  // cursor: pointer;
`
const MobileDot = styled(Box)`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #fff;
`
export const Major = (scrollNum: any) => {
  const { isMobile, isTablet } = useMatchBreakpoints()
  const [isRotated, setIsRotated] = React.useState(false)
  return (
    <Box
      className={
        scrollNum.scrollNum > 2000 && scrollNum.scrollNum < 2900
          ? 'majorTextEffect c-sb w100'
          : 'majorTextTest c-sb w100'
      }
      height={['540px', '893px', '1246px', '1600px']}
    >
      <Col className="mxw relative">
        <Box className="title">
          <Text
            fontSize={['20px', '33px', '47', '60px']}
            fontWeight={700}
            color="#fff"
            textAlign="center"
            marginBottom={['10px', '12px', '14px', '16px']}
            height={['27px', '44px', '62px', '80px']}
            marginTop={['42px', '72px', '102px', '132px']}
          >
            MAJOR FUNCTIONS
          </Text>
          <Box
            className="col2 tc m"
            width={['320px', '446px', '572px', '700px']}
            height={['54px', '59px', '65px', '70px']}
            marginBottom={['22px', '54px', '86px', '120px']}
          >
            <Text fontSize={['12px', '14px', '17px', '20px']} className="white87 fw400">
              Pewee tools is an NFT analytical tool to make purchase easier with three key features: effective data,
              in-depth analysis and agile execution.
            </Text>
          </Box>
        </Box>

        <Box className="fx jc-sb relative" marginTop={['32px', '46px', '61px', '75px']}>
          {isMobile ? (
            <Box
              className={`card ${isRotated ? 'rotated ' : ''}`}
              onClick={() => setIsRotated(!isRotated)}
              height={['170px', '248px', '341px', '435px']}
              width={['175px', '266px', '358px', '450px']}
              marginRight={['8px', '52px', '96px', '140px']}
            >
              <Box
                className="front bg20 codeBox"
                paddingTop={['37px', '56px', '77px', '96px']}
                height={['170px', '248px', '341px', '435px']}
                width={['175px', '266px', '358px', '450px']}
                paddingLeft={['18px', '23px', '28px', '32px']}
              >
                <CodeBox>
                  <Text fontSize={['16px', '24px', '32px', '40px']} fontWeight={700} color="#fff">
                    Effective
                  </Text>
                  <Text fontSize={['16px', '24px', '32px', '40px']} fontWeight={700} color="#fff">
                    Data
                  </Text>
                  <Box className="pa bm5 rg4 bg34" width="40px" height="10px" />
                </CodeBox>
              </Box>
              <Box
                className="back bg30 codeBox"
                height={['170px', '248px', '341px', '435px']}
                width={['175px', '266px', '358px', '450px']}
                paddingTop={['14px', '18px', '22px', '26px']}
                paddingBottom={['14px', '18px', '22px', '26px']}
              >
                <CodeBox>
                  <Box className="fx">
                    {/* // {isMobile ? <MobileDot /> : <DotLast className="m10" />} */}
                    <Box
                      width={['5px', '5px', '6px', '8px']}
                      height={['5px', '5px', '6px', '8px']}
                      marginTop={['5px', '5px', '10px', '10px']}
                      marginLeft={['6px', '6px', '14px', '14px']}
                      marginRight={['6px', '6px', '14px', '14px']}
                    >
                      <DotLast />
                    </Box>
                    <Text
                      fontSize={['12px', '15px', '19px', '22px']}
                      fontWeight={700}
                      color="#fff"
                      lineHeight={['14px', '19px', '24px', '29px']}
                      marginBottom={['5px', '14px', '22px', '30px']}
                    >
                      Watch List/Alert
                    </Text>
                  </Box>
                  <Box className="fx">
                    <Box
                      width={['5px', '5px', '6px', '8px']}
                      height={['5px', '5px', '6px', '8px']}
                      marginTop={['5px', '5px', '10px', '10px']}
                      marginLeft={['6px', '6px', '14px', '14px']}
                      marginRight={['6px', '6px', '14px', '14px']}
                    >
                      <DotLast />
                    </Box>
                    <Text
                      fontSize={['12px', '15px', '19px', '22px']}
                      fontWeight={700}
                      color="#fff"
                      lineHeight={['14px', '19px', '24px', '29px']}
                      marginBottom={['5px', '14px', '22px', '30px']}
                    >
                      Listings Under Floor
                    </Text>
                  </Box>
                  <Box className="fx">
                    <Box
                      width={['5px', '5px', '6px', '8px']}
                      height={['5px', '5px', '6px', '8px']}
                      marginTop={['5px', '5px', '10px', '10px']}
                      marginLeft={['6px', '6px', '14px', '14px']}
                      marginRight={['6px', '6px', '14px', '14px']}
                    >
                      <DotLast />
                    </Box>
                    <Text
                      fontSize={['12px', '15px', '19px', '22px']}
                      fontWeight={700}
                      color="#fff"
                      lineHeight={['14px', '19px', '24px', '29px']}
                      marginBottom={['5px', '14px', '22px', '30px']}
                    >
                      Upcoming Calendar
                    </Text>
                  </Box>
                  <Box className="fx">
                    <Box
                      width={['5px', '5px', '6px', '8px']}
                      height={['5px', '5px', '6px', '8px']}
                      marginTop={['5px', '5px', '10px', '10px']}
                      marginLeft={['6px', '6px', '14px', '14px']}
                      marginRight={['6px', '6px', '14px', '14px']}
                    >
                      <DotLast />
                    </Box>
                    <Text
                      fontSize={['12px', '15px', '19px', '22px']}
                      fontWeight={700}
                      color="#fff"
                      lineHeight={['14px', '19px', '24px', '29px']}
                      marginBottom={['5px', '14px', '22px', '30px']}
                    >
                      Bluechip index
                    </Text>
                  </Box>
                  <Box className="fx">
                    <Box
                      width={['5px', '5px', '6px', '8px']}
                      height={['5px', '5px', '6px', '8px']}
                      marginTop={['5px', '5px', '10px', '10px']}
                      marginLeft={['6px', '6px', '14px', '14px']}
                      marginRight={['6px', '6px', '14px', '14px']}
                    >
                      <DotLast />
                    </Box>
                    <Text
                      fontSize={['12px', '15px', '19px', '22px']}
                      fontWeight={700}
                      color="#fff"
                      lineHeight={['14px', '19px', '24px', '29px']}
                      marginBottom={['5px', '14px', '22px', '30px']}
                    >
                      Whales Track
                    </Text>
                  </Box>
                  <Box className="fx">
                    <Box
                      width={['5px', '5px', '6px', '8px']}
                      height={['5px', '5px', '6px', '8px']}
                      marginTop={['5px', '5px', '10px', '10px']}
                      marginLeft={['6px', '6px', '14px', '14px']}
                      marginRight={['6px', '6px', '14px', '14px']}
                    >
                      <DotLast />
                    </Box>
                    <Text
                      fontSize={['12px', '15px', '19px', '22px']}
                      fontWeight={700}
                      color="#fff"
                      lineHeight={['14px', '19px', '24px', '29px']}
                      marginBottom={['5px', '14px', '22px', '30px']}
                    >
                      Profit Calculator
                    </Text>
                  </Box>
                  <Box className="fx">
                    <Box
                      width={['5px', '5px', '6px', '8px']}
                      height={['5px', '5px', '6px', '8px']}
                      marginTop={['5px', '5px', '10px', '10px']}
                      marginLeft={['6px', '6px', '14px', '14px']}
                      marginRight={['6px', '6px', '14px', '14px']}
                    >
                      <DotLast />
                    </Box>
                    <Text
                      fontSize={['12px', '15px', '19px', '22px']}
                      fontWeight={700}
                      color="#fff"
                      lineHeight={['14px', '19px', '24px', '29px']}
                    >
                      Multi-market Aggregator
                    </Text>
                  </Box>
                </CodeBox>
              </Box>
            </Box>
          ) : (
            <Box
              className={`card ${isRotated ? 'rotated ' : ''}`}
              onMouseOver={() => setIsRotated(true)}
              onMouseLeave={() => setIsRotated(false)}
              height={['155px', '248px', '341px', '435px']}
              width={['175px', '266px', '358px', '450px']}
              marginRight={['8px', '52px', '96px', '140px']}
            >
              <Box
                className="front bg20 codeBox"
                paddingTop={['37px', '56px', '77px', '96px']}
                height={['155px', '248px', '341px', '435px']}
                width={['175px', '266px', '358px', '450px']}
                paddingLeft={['18px', '23px', '28px', '32px']}
              >
                <CodeBox>
                  <Text fontSize={['16px', '24px', '32px', '40px']} fontWeight={700} color="#fff">
                    Effective
                  </Text>
                  <Text fontSize={['16px', '24px', '32px', '40px']} fontWeight={700} color="#fff">
                    Data
                  </Text>
                </CodeBox>
              </Box>
              <Box
                className="back bg30 codeBox"
                height={['155px', '248px', '341px', '435px']}
                width={['175px', '266px', '358px', '450px']}
                paddingTop={['14px', '18px', '22px', '26px']}
              >
                <CodeBox>
                  <Box className="fx">
                    {/* // {isMobile ? <MobileDot /> : <DotLast className="m10" />} */}
                    <Box
                      width={['5px', '5px', '6px', '8px']}
                      height={['5px', '5px', '6px', '8px']}
                      marginTop={['5px', '5px', '10px', '10px']}
                      marginLeft={['6px', '6px', '14px', '14px']}
                      marginRight={['6px', '6px', '14px', '14px']}
                    >
                      <DotLast />
                    </Box>
                    <Text
                      fontSize={['12px', '15px', '19px', '22px']}
                      fontWeight={700}
                      color="#fff"
                      lineHeight={['14px', '19px', '24px', '29px']}
                      marginBottom={['5px', '14px', '22px', '30px']}
                    >
                      Watch List/Alert
                    </Text>
                  </Box>
                  <Box className="fx">
                    <Box
                      width={['5px', '5px', '6px', '8px']}
                      height={['5px', '5px', '6px', '8px']}
                      marginTop={['5px', '5px', '10px', '10px']}
                      marginLeft={['6px', '6px', '14px', '14px']}
                      marginRight={['6px', '6px', '14px', '14px']}
                    >
                      <DotLast />
                    </Box>
                    <Text
                      fontSize={['12px', '15px', '19px', '22px']}
                      fontWeight={700}
                      color="#fff"
                      lineHeight={['14px', '19px', '24px', '29px']}
                      marginBottom={['5px', '14px', '22px', '30px']}
                    >
                      Listings Under Floor
                    </Text>
                  </Box>
                  <Box className="fx">
                    <Box
                      width={['5px', '5px', '6px', '8px']}
                      height={['5px', '5px', '6px', '8px']}
                      marginTop={['5px', '5px', '10px', '10px']}
                      marginLeft={['6px', '6px', '14px', '14px']}
                      marginRight={['6px', '6px', '14px', '14px']}
                    >
                      <DotLast />
                    </Box>
                    <Text
                      fontSize={['12px', '15px', '19px', '22px']}
                      fontWeight={700}
                      color="#fff"
                      lineHeight={['14px', '19px', '24px', '29px']}
                      marginBottom={['5px', '14px', '22px', '30px']}
                    >
                      Upcoming Calendar
                    </Text>
                  </Box>
                  <Box className="fx">
                    <Box
                      width={['5px', '5px', '6px', '8px']}
                      height={['5px', '5px', '6px', '8px']}
                      marginTop={['5px', '5px', '10px', '10px']}
                      marginLeft={['6px', '6px', '14px', '14px']}
                      marginRight={['6px', '6px', '14px', '14px']}
                    >
                      <DotLast />
                    </Box>
                    <Text
                      fontSize={['12px', '15px', '19px', '22px']}
                      fontWeight={700}
                      color="#fff"
                      lineHeight={['14px', '19px', '24px', '29px']}
                      marginBottom={['5px', '14px', '22px', '30px']}
                    >
                      Bluechip index
                    </Text>
                  </Box>
                  <Box className="fx">
                    <Box
                      width={['5px', '5px', '6px', '8px']}
                      height={['5px', '5px', '6px', '8px']}
                      marginTop={['5px', '5px', '10px', '10px']}
                      marginLeft={['6px', '6px', '14px', '14px']}
                      marginRight={['6px', '6px', '14px', '14px']}
                    >
                      <DotLast />
                    </Box>
                    <Text
                      fontSize={['12px', '15px', '19px', '22px']}
                      fontWeight={700}
                      color="#fff"
                      lineHeight={['14px', '19px', '24px', '29px']}
                      marginBottom={['5px', '14px', '22px', '30px']}
                    >
                      Whales Track
                    </Text>
                  </Box>
                  <Box className="fx">
                    <Box
                      width={['5px', '5px', '6px', '8px']}
                      height={['5px', '5px', '6px', '8px']}
                      marginTop={['5px', '5px', '10px', '10px']}
                      marginLeft={['6px', '6px', '14px', '14px']}
                      marginRight={['6px', '6px', '14px', '14px']}
                    >
                      <DotLast />
                    </Box>
                    <Text
                      fontSize={['12px', '15px', '19px', '22px']}
                      fontWeight={700}
                      color="#fff"
                      lineHeight={['14px', '19px', '24px', '29px']}
                      marginBottom={['5px', '14px', '22px', '30px']}
                    >
                      Profit Calculator
                    </Text>
                  </Box>
                  <Box className="fx">
                    <Box
                      width={['5px', '5px', '6px', '8px']}
                      height={['5px', '5px', '6px', '8px']}
                      marginTop={['5px', '5px', '10px', '10px']}
                      marginLeft={['6px', '6px', '14px', '14px']}
                      marginRight={['6px', '6px', '14px', '14px']}
                    >
                      <DotLast />
                    </Box>
                    <Text
                      fontSize={['12px', '15px', '19px', '22px']}
                      fontWeight={700}
                      color="#fff"
                      lineHeight={['14px', '19px', '24px', '29px']}
                    >
                      Multi-market Aggregator
                    </Text>
                  </Box>
                </CodeBox>
              </Box>
            </Box>
          )}

          {isMobile ? (
            <Box
              paddingTop={['30px', '100px', '170px', '240px']}
              width={['150px', '350px', '410px', '530px']}
              className={
                scrollNum.scrollNum > 600 && scrollNum.scrollNum < 1700
                  ? 'isMobileTextBoxEffect'
                  : 'isMobileTextBoxTest'
              }
              paddingLeft={isMobile ? '6px' : ''}
            >
              <Box className="fx">
                <Box
                  width={['5px', '5px', '6px', '8px']}
                  height={['5px', '5px', '6px', '8px']}
                  marginTop={['7px', '10px', '10px', '10px']}
                  marginLeft={['6px', '6px', '14px', '14px']}
                  marginRight={['6px', '6px', '14px', '14px']}
                >
                  <DotLast />
                </Box>
                <Text
                  fontSize={['15px', '20px', '25px', '30px']}
                  fontWeight={400}
                  color="#fff"
                  lineHeight={['20px', '28px', '34px', '40px']}
                >
                  Price Estimate
                </Text>
              </Box>
              <Text
                fontSize={['13px', '18px', '24px', '28px']}
                fontWeight={500}
                color="rgba(255, 255, 255, 0.5)"
                marginTop={['5px', '12px', '18px', '24px']}
              >
                Compare current listings with fair price generated by the trendline to find undervalued items.
              </Text>
            </Box>
          ) : (
            <Box
              paddingTop={['30px', '100px', '170px', '240px']}
              width={['150px', '350px', '410px', '530px']}
              className="textBox"
              paddingLeft={isMobile ? '6px' : ''}
            >
              <Box className="fx">
                <Box
                  width={['5px', '5px', '6px', '8px']}
                  height={['5px', '5px', '6px', '8px']}
                  marginTop={['7px', '10px', '10px', '10px']}
                  marginLeft={['6px', '6px', '14px', '14px']}
                  marginRight={['6px', '6px', '14px', '14px']}
                >
                  <DotLast />
                </Box>
                <Text
                  fontSize={['15px', '20px', '25px', '30px']}
                  fontWeight={400}
                  color="#fff"
                  lineHeight={['20px', '28px', '34px', '40px']}
                >
                  Price Estimate
                </Text>
              </Box>
              <Text
                fontSize={['13px', '18px', '24px', '28px']}
                fontWeight={500}
                color="rgba(255, 255, 255, 0.5)"
                marginTop={['5px', '12px', '18px', '24px']}
              >
                Compare current listings with fair price generated by the trendline to find undervalued items.
              </Text>
            </Box>
          )}
        </Box>
        {isMobile ? (
          <Box
            width={['350px', '640px', '930px', '1218px']}
            height={['176px', '319px', '462px', '605px']}
            left={[0, '-23px', '-46px', '-70px']}
            marginTop={['25px', '43px', '61px', '80px']}
            className={isMobile || isTablet ? 'm' : ''}
          >
            <BgBox
              className={
                scrollNum.scrollNum > 700 && scrollNum.scrollNum < 1900
                  ? 'bg21 isMobileBackImg'
                  : 'bg21 isMobileBackImgNo'
              }
            />
          </Box>
        ) : (
          <Box
            width={['350px', '640px', '930px', '1218px']}
            height={['176px', '319px', '462px', '605px']}
            left={[0, '-23px', '-46px', '-70px']}
            marginTop={['25px', '43px', '61px', '80px']}
            className={isMobile || isTablet ? 'm' : ''}
          >
            <BgBox
              className={scrollNum.scrollNum > 2300 && scrollNum.scrollNum < 3500 ? 'bg21 backImg' : 'bg21 backImgNo'}
            />
          </Box>
        )}
      </Col>
    </Box>
  )
}
