import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { init, dispose } from 'klinecharts'
import { Box, Text, Image } from '@/packages'
import { CardTitle, Row } from '@/components/Layout/Layout'
import { RealtimeFloorApi, floorKLineApi } from '@/request/Api'
import { ifloorKLineParams } from '@/request/types'
import { toNonExponential, timeFormate2 } from '@/utils/tools'
import { eth } from '@/assets/img'
import { TabListSelected, iTabItem } from '@/components/TabListSelected'
import { WsFloorPriceAction } from '@/store/actions/LiveviewAction'
import { useAppContext } from '@/hooks/useAppContext'
import { NoData } from '@/components/NoData'
import { useMatchBreakpoints } from '@/hooks/useMatchBreakpoints'

const upColor: string = '#34C77B'
const downColor: string = '#EB484C'
const textStyle = {
  show: true,
  color: '#a4a4a4',
  weight: 400,
  size: 12
}
const noChangeColor: string = '#a4a4a4'

interface iKline {
  openTime: number
  closeTime: number
  open: number
  high: number
  low: number
  close: number
  volume: number
  trades: number
  ma10: number
  ma20: number
  change: number
  amplitude: number
  increase: boolean
}
interface iCandleLine {
  close: number
  high: number
  low: number
  open: number
  timestamp: number
  volume: number
  change: number
  amplitude: number
  ma10: number
  ma20: number
  increase: boolean
}

const maTechnicalIndicator = {
  name: 'MA',
  calcParams: [10, 20],
  plots: [
    { key: 'ma10', title: 'MA10: ', type: 'line', color: '#00B2B5' },
    { key: 'ma20', title: 'MA20: ', type: 'line', color: '#FFE800' }
  ],
  regeneratePlots: (params: Array<any>) => {
    return params.map((p: any, i: number) => {
      return { key: `ma${i + 1}`, title: `MA${p}: `, type: 'line' }
    })
  },
  calcTechnicalIndicator: (kLineDataList: Array<any>, { params, plots }: any) => {
    return kLineDataList.map((kLineData, i) => {
      return { ma10: kLineData.ma10, ma20: kLineData.ma20 }
    })
  }
}

export const FloorPriceKLine = (wsSocket: any) => {
  const params = useParams()
  const NFTID = Number(params.nftId)
  const [query, setQuery] = useState<ifloorKLineParams>({
    interval: '1d',
    nftId: NFTID,
    limit: 100
  })
  const [period, setPeriod] = useState<Array<iTabItem>>([
    { id: 0, name: '1h', active: false },
    { id: 1, name: '6h', active: false },
    { id: 2, name: '1d', active: true }
  ])
  const [isLoading, setLoading] = useState<boolean>(true)

  const [klineList, setKlineList] = useState<Array<iCandleLine>>([])
  const { isMobile } = useMatchBreakpoints()
  const { state, dispatch } = useAppContext()

  const chart: any = useRef()
  const paneId: any = useRef()
  const queryRef: any = useRef()

  const fetch = async () => {
    setLoading(true)
    const d: any = await floorKLineApi(query)
    setLoading(false)
    if (d && !!d.length && d[0]?.close !== null) {
      const dList: Array<iCandleLine> = []

      d.forEach((item: iKline) => {
        dList.push({
          close: item.close,
          high: item.high,
          low: item.low,
          open: item.open,
          timestamp: item.openTime,
          volume: item.volume,
          change: toNonExponential(item.change),
          amplitude: item.amplitude,
          increase: item.increase,
          ma10: item.ma10,
          ma20: item.ma20
        })
      })
      const lastKline = dList[dList.length - 1]
      WsFloorPriceAction(dispatch, lastKline)

      setKlineList(dList)
      chart.current = init('klineId', {
        // 网格线
        grid: {
          show: true,
          // 网格水平线
          horizontal: {
            show: true,
            size: 1,
            color: 'rgba(255, 255, 255, 0.12)',
            style: 'solid'
          },
          // 网格垂直线
          vertical: {
            show: false
          }
        },

        candle: {
          tooltip: {
            showRule: isMobile ? 'none' : 'always',
            labels: ['', 'Open: ', 'Close: ', 'High: ', 'Low: ', 'Change: ', 'Amplitude: '],
            values: (kLineData: iCandleLine) => {
              // console.log('kLineData', kLineData)
              const { close, high, low, open, timestamp, volume, change, amplitude, increase } = kLineData
              let c: { value: string; color: string } = {
                value: '--',
                color: 'rgba(255, 255, 255, 0.87)'
              }
              if (increase) {
                c = { value: `+${change}`, color: '#34C77B' }
              } else {
                c = { value: `-${change}`, color: '#EB484C' }
              }
              if (change === 0) c = { value: '0', color: 'rgba(255, 255, 255, 0.87)' }
              // if (change > 0) c = { value: `+${change}`, color: '#34C77B' }
              // if (change < 0) c = { value: `-${change}`, color: '#EB484C' }
              return [
                { value: timeFormate2(timestamp) },
                { value: toNonExponential(open), color: '#34C77B' },
                { value: toNonExponential(close), color: '#34C77B' },
                { value: toNonExponential(high), color: '#34C77B' },
                { value: toNonExponential(low), color: '#34C77B' },
                c,
                { value: `${(amplitude * 100).toFixed(2)}%`, color: '#34C77B' }
              ]
            },
            text: {
              size: 12,
              weight: 400,
              color: 'rgba(255, 255, 255, 0.87)'
              // marginLeft: 0,
              // marginTop: 2,
              // marginRight: 10,
              // marginBottom: 0
            }
          },
          bar: {
            upColor,
            downColor,
            noChangeColor
          },
          priceMark: {
            show: true,
            // 最高价标记
            high: textStyle,
            // 最低价标记
            low: textStyle,
            // 最新价标记
            last: {
              show: true,
              upColor,
              downColor,
              noChangeColor,
              line: {
                show: true,
                style: 'dash',
                size: 2
              },
              text: {
                show: true,
                size: 12,
                paddingLeft: 4,
                paddingRight: 4,
                paddingTop: 4,
                paddingBottom: 4,
                color: '#121212',
                weight: 400,
                borderRadius: 10
              }
            }
          }
        },
        xAxis: {
          show: true,
          height: null,
          // x轴线
          axisLine: {
            show: true,
            color: '#4E4E4E',
            size: 1
          },
          // x轴分割文字
          tickText: textStyle,
          // x轴分割线
          tickLine: {
            show: false
          }
        },
        yAxis: {
          show: true,
          // y轴线
          axisLine: {
            show: false
          },
          // y轴分割文字
          tickText: textStyle,
          // y轴分割线
          tickLine: {
            show: false
          }
        }
      })

      chart.current.createTechnicalIndicator('MA', false, { id: 'candle_pane' })

      chart.current.overrideTechnicalIndicator(
        {
          name: 'MA', // pricePrecision
          calcParams: [10, 20],
          precision: 3,
          shouldFormatBigNumber: false,
          styles: {
            margin: {
              top: isMobile ? 0 : 0.35
            },
            line: {
              size: 2,
              colors: ['#00B2B5', '#FFE800']
            }
          },
          extendData: () => 99999
        },
        'candle_pane'
      )

      // chart.current.addTechnicalIndicatorTemplate(maTechnicalIndicator)
      chart.current.setPriceVolumePrecision(4, 4)
      chart.current && chart.current.createTechnicalIndicator('MA', true, { id: 'candle_pane' })
      chart.current.applyNewData(dList)
      return () => dispose('klineId')
    }
  }

  useEffect(() => {
    fetch()
  }, [query])

  useEffect(() => {
    queryRef.current = query
    try {
      setTimeout(() => {
        wsSocket.socket.client.subscribe(`/topic/floor-kline-${queryRef.current.interval}-${NFTID}`, (res: any) => {
          const d = JSON.parse(res.body)
          console.log('ws data==kline>>>>>>>>', d)
          WsFloorPriceAction(dispatch, d) //
          chart.current.updateData({
            close: d.close,
            high: d.high,
            low: d.low,
            open: d.open,
            timestamp: d.openTime,
            volume: d.volume,
            change: d.change,
            amplitude: d.amplitude
          })
        })
      }, 10100)
    } catch (error) {
      console.log('connect error', error)
    }
  }, [])

  const onChangePeriod = (val: iTabItem) => {
    const _period = JSON.parse(JSON.stringify(period))
    _period.forEach((_p: iTabItem) => {
      _p.active = _p.name === val.name
    })
    setPeriod(_period)
    setQuery((q: ifloorKLineParams) => ({ ...q, interval: val.name }))
  }

  return (
    <Box mt="24px" className="body_wrapper w100" height="362px" px={['4px', '16px', '24px', '24px']}>
      <Row className="w100 jc-sb" mt="16px">
        <CardTitle mb="0">Floor Price</CardTitle>
        <Row>
          <Text color="rgba(255, 255, 255, 0.87)" fontSize="12px" mr="10px">
            Interval:
          </Text>
          <TabListSelected ml={10} ftsz={12} list={period} onChange={onChangePeriod} selectedList={false} />
        </Row>
      </Row>

      <Box height="300px" className="w100" id="klineId">
        {klineList.length === 0 && <NoData isLoading={isLoading} />}
      </Box>
    </Box>
  )
}
