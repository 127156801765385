import { useState, useEffect } from 'react'

export const useChainChanged = (): string => {
  const [chainId, setChainId] = useState<string>('')
  const getChainId = async () => {
    // @ts-ignore
    const cId = await window?.ethereum.request({ method: 'eth_chainId' })
    setChainId(cId)
  }
  useEffect(() => {
    getChainId()
  }, [])
  // @ts-ignore
  if (window?.ethereum) {
    // @ts-ignore
    window?.ethereum.on('chainChanged', (cId: string) => {
      setChainId(cId)
    })
  }
  return chainId
}
