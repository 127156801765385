import React, { useState, useEffect, useRef, useCallback } from 'react'
import styled from 'styled-components'
import { Flex, Box, Grid, Text, Input, Image } from '@/packages/'
import { useAppContext } from '@/hooks/useAppContext'
import { Col, Row, BgWrapper } from '@/components/Layout/Layout'
import { ListingFilterAction, TraitModalAction } from '@/store/actions/LiveviewAction'
import { delQueryFalse, isEmptyObj, delEmptyObj } from '@/utils/tools'
import { traits, traitsActive } from '@/assets/img'
import { NumberInput } from '@/components/NumberInput'

interface iMinMax {
  minChange: (value: string) => void
  maxChange: (value: string) => void
  title: string
  minRef: any
  maxRef: any
  min: number
  max: number
}
const MinMaxInput: React.FC<iMinMax> = ({ title, minChange, maxChange, minRef, maxRef, min, max }) => {
  return (
    <Box ml={[0, 0, 12, 32]} className="" width="150px">
      <Text fontSize={14} color="#fff">
        {title}
      </Text>
      <Row mt="4px" className="jc-sb">
        <NumberInput
          min={min}
          max={max}
          placeholder="Min"
          onChange={minChange}
          inputRef={minRef}
          classN="filter_input_style"
        />
        <NumberInput
          min={min}
          max={max}
          placeholder="Max"
          onChange={maxChange}
          inputRef={maxRef}
          classN="filter_input_style"
        />
      </Row>
    </Box>
  )
}

const TraitBtn: React.FC<{ onClick: React.MouseEventHandler<HTMLElement>; value: number }> = ({ onClick, value }) => {
  return (
    <Box
      onClick={onClick}
      px="16px"
      className="click fx-row ai-ct"
      height="40px"
      width="140px"
      borderRadius="20px"
      background={value !== 0 ? '#00B2B5' : 'rgba(255, 255, 255, 0.12)'}
    >
      <Text fontSize="16px" color={value !== 0 ? '#fff' : 'rgba(255, 255, 255, 0.87)'} fontWeight={600} mr="6px">
        TRAITS
      </Text>
      <Image src={value > 0 ? traitsActive : traits} height={13} width={11} />
      {value > 0 ? (
        <Box width="20px" height="20px" background="#fff" className="fx ai-ct jc-ct circle" marginLeft="10px">
          <Text fontSize="12px" color="#00B2B5">
            {value}
          </Text>
        </Box>
      ) : (
        <Text fontSize="12px" color="rgba(255, 255, 255, 0.87)" ml="24px">
          {value}
        </Text>
      )}
    </Box>
  )
}

export const FilterBtn = styled(Box)`
  width: 64px;
  height: 30px;
  background: rgba(255, 255, 255, 0.12);
  border-radius: 20px;
  font-weight: 600;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.38);
`

export const FilterListing = () => {
  const { state, dispatch } = useAppContext()
  const [minPrice, setMinPrice] = useState<number | string>(0)
  const [maxPrice, setMaxPrice] = useState<number | string>(0)
  const [minRank, setMinRank] = useState<number | string>(0)
  const [maxRank, setMaxRank] = useState<number | string>(0)
  const [tokenId, setTokenId] = useState<number | string>(0)
  const [traitCount, setTraitCount] = useState(0)
  const [traitQueries, setTraitQueries] = useState<Array<[]>>([])
  const tokenIdRef: any = useRef()
  const minRefPrice: any = useRef()
  const maxRefPrice: any = useRef()
  const minRefRank: any = useRef()
  const maxRefRank: any = useRef()

  const onChangeMin = (val: string) => {
    setMinPrice(val ? Number(val) : '')
  }
  const onChangeMax = (val: string) => {
    setMaxPrice(val ? Number(val) : '')
  }
  const onChangeRankMin = (val: string) => {
    setMinRank(val ? Number(val) : '')
  }
  const onChangeRankMax = (val: string) => {
    setMaxRank(val ? Number(val) : '')
  }
  const onChangeTokenId = (val: string) => {
    setTokenId(val ? Number(val) : '')
  }

  const onClear = () => {
    tokenIdRef.current && tokenIdRef.current.clearInputValue()
    minRefPrice.current && minRefPrice.current.clearInputValue()
    maxRefPrice.current && maxRefPrice.current.clearInputValue()
    minRefRank.current && minRefRank.current.clearInputValue()
    maxRefRank.current && maxRefRank.current.clearInputValue()

    if (traitQueries.length > 0) {
      ListingFilterAction({ traitQueries }, dispatch)
    } else {
      ListingFilterAction({}, dispatch)
    }
  }
  const onSave = useCallback(() => {
    fetch()
  }, [minPrice, maxPrice, minRank, maxRank, tokenId, traitQueries])

  useEffect(() => {
    fetch()
  }, [traitQueries])

  const fetch = async () => {
    const p = {
      tokenId,
      priceRange: {
        min: minPrice,
        max: maxPrice
      },
      rankRange: {
        min: minRank,
        max: maxRank
      },
      traitQueries
    }
    if (isEmptyObj(delEmptyObj(delQueryFalse(p)))) {
      ListingFilterAction(delQueryFalse(p), dispatch)
    } else {
      ListingFilterAction({}, dispatch)
    }
  }

  useEffect(() => {
    if (state?.LiveViewReducer?.trait) {
      if (state.LiveViewReducer.trait) {
        const { traitQuery, visible } = state.LiveViewReducer.trait
        if (traitQuery.length) {
          const m = new Map()
          traitQuery.forEach((item: any) => {
            if (!m.has(item.traitType)) {
              m.set(item.traitType, item.trait)
            }
          })
          // setTraitCount(m.size)
          setTraitCount(traitQuery.length)
          setTraitQueries(traitQuery)
        }
        if (!traitQuery.length && !visible) {
          setTraitCount(0)
          setTraitQueries([])
        }
      }
    }
  }, [state?.LiveViewReducer?.trait])

  const onTrait = () => {
    TraitModalAction({ visible: true, traitQuery: [] }, dispatch)
  }

  return (
    <Grid
      py={['12px', '12px', '12px', '24px']}
      className="fx-row relative ai-ct body_wrapper"
      gridTemplateColumns={['1fr 5fr 5fr 3fr 1fr 1fr']}
    >
      <TraitBtn value={traitCount} onClick={onTrait} />
      <MinMaxInput
        min={0}
        max={100000}
        title="PRICE"
        minChange={onChangeMin}
        maxChange={onChangeMax}
        minRef={minRefPrice}
        maxRef={maxRefPrice}
      />
      <MinMaxInput
        min={1}
        max={100000}
        title="RANK"
        minChange={onChangeRankMin}
        maxChange={onChangeRankMax}
        minRef={minRefRank}
        maxRef={maxRefRank}
      />

      <Box ml={[0, 0, 12, 32]} className="" width="72px">
        <Text fontSize={14} color="#fff">
          TOKEN ID
        </Text>
        <Row mt="4px" className="jc-sb">
          <NumberInput
            min={1}
            max={100000}
            fixed={0}
            placeholder="ID #"
            onChange={onChangeTokenId}
            inputRef={tokenIdRef}
            classN="filter_input_style"
          />
        </Row>
      </Box>

      <FilterBtn className="center click" ml={[0, 0, 4, 16]} mr="6px" onClick={onSave}>
        SAVE
      </FilterBtn>
      <FilterBtn className="center click" mr="6px" onClick={onClear}>
        CLEAR
      </FilterBtn>
    </Grid>
  )
}
