import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { Flex, Box, Grid } from '@/packages'
import { useAppContext } from '@/hooks/useAppContext'
import { HoverWrapper } from '@/components/Layout/HoverWrapper'
import { OverView } from './OverView'
import { Listing } from './Listing/index'
import { Sales } from './Sales'
import { PriceEstimate } from './PriceEstimate'
import { ListingDepth } from './ListingDepth'
import { RarityScore } from './RarityScore'
import { RealtimeFloor } from './RealtimeFloor'
import { FloorPriceKLine } from './FloorPriceKLine'
import { Trades } from './Trades'
import { RankTrades } from './RankTrades'
import { FilterListing } from './FilterListing'
import { FilterListingMobile } from './FilterListingMobile'
import { QuickBuy } from './QuickBuy'
import { Trait } from './Trait'
import { ContentWrapper } from '@/components/Layout/Layout'
import { isRankApi } from '@/request/Api'
import { PeweeWS } from '@/utils/ws'
import { useMatchBreakpoints } from '@/hooks/useMatchBreakpoints'

export const Sniper = () => {
  const { state, dispatch } = useAppContext()
  const [isRank, setRank] = useState<number>(0)
  const [socket, setSocket] = useState<any>(null)
  const { isMobile, isTablet } = useMatchBreakpoints()
  const params = useParams()

  const isRankFetch = async () => {
    const d: any = await isRankApi(Number(params.nftId))
    if (d && (d.rank === 0 || d.rank === 1)) {
      setRank(1)
    } else {
      setRank(2)
    }
  }
  const createWs = () => {
    if (socket === null) {
      const ws: any = new PeweeWS()
      setSocket(ws)
    }
  }
  const disWsConnect = () => {
    if (socket) {
      try {
        socket?.disConnect()
        setSocket(null)
      } catch (error) {
        console.log('disconnect ws error', error)
      }
    }
  }
  useEffect(() => {
    isRankFetch()
    createWs()
  }, [])
  useEffect(() => {
    return () => disWsConnect()
  }, [socket])
  // console.log('isRank', isRank)
  return (
    <Box className="">
      <ContentWrapper>
        <Grid
          className="w100"
          height="88px"
          mt="16px"
          gridColumnGap={['0', '0', '0', '24px']}
          gridTemplateColumns={['1fr', '1fr', '1fr', '8fr 5fr']}
        >
          {isMobile || isTablet ? <FilterListingMobile /> : <FilterListing />}
          {socket && <OverView socket={socket} />}
        </Grid>
      </ContentWrapper>

      <ContentWrapper>
        <Grid
          className="w100"
          gridColumnGap={['0', '0', '0', '24px']}
          gridTemplateColumns={['1fr', '1fr', '1fr', '8fr 5fr']}
          mt={[50, 50, 50, 10]}
        >
          <Grid className="w100" gridTemplateColumns={['1fr', '1fr', '1fr', '1fr 1fr']} gridColumnGap={[0, 0, 0, 24]}>
            {socket && <Listing socket={socket} />}
            {socket && <Sales socket={socket} />}
          </Grid>
          <Grid className="" gridTemplateColumns={['1fr', '1fr', '1fr', '1fr']}>
            {socket && <FloorPriceKLine socket={socket} />}
            <ListingDepth />
          </Grid>
        </Grid>
      </ContentWrapper>
      {isMobile || isTablet ? null : (
        <ContentWrapper>
          <Grid className="w100">
            {isRank === 1 && <Trades />}
            {isRank === 2 && <RankTrades />}
            {isRank === 2 && <PriceEstimate />}
          </Grid>
        </ContentWrapper>
      )}
      <QuickBuy />
      <Trait />
    </Box>
  )
}
