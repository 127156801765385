import React, { useState, ChangeEvent } from 'react'
import { Modal, Button } from 'antd'
import styled from 'styled-components'

import { Flex, Box, Input, Image, Text, Grid } from '@/packages/'
import { openseaDark, search2 } from '@/assets/svg'
import { NFTItem } from '@/components'

interface iProps {
  visible: boolean
  onCancel: () => void
}

const ModalBody = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 364px;
`

const ModalBox = styled(Box)`
  width: 344px;
  height: 363px;
  background: #1a1d23;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
`

export const SearchModal: React.FC<iProps> = ({ visible, onCancel }) => {
  const [searchVal, setSearchVal] = useState('')
  const handleChange = (val: string) => {
    console.log('Input', val)
  }

  return (
    <Modal
      visible={visible}
      footer={null}
      width={344}
      onCancel={onCancel}
      closable={false}
      bodyStyle={{ backgroundColor: 'rgba(0, 0, 0, .5)' }}
    >
      <ModalBody className="">
        {/* <InputWrapper>
          <Image width={36} height={36} src={search2} className="search_icon" />
          <Input px={64} placeholder="Search" onChange={handleChange} w={480} />
        </InputWrapper> */}
      </ModalBody>
    </Modal>
  )
}
