import React from 'react'
import { AppActionType, iRefreshTable } from '@/types/store'
import { MarketCapApi, HotMintsApi, HotCollectionsApi, FeedListApi } from '@/request/Api'
import {
  MARKET_CAP,
  HOT_MINTS,
  HOT_COLLECTION,
  FEED_LIST,
  CHANGE_PERIOD,
  REFRESH_TABLE,
  TRENDING_PRICE,
  MINTS_PRICE,
  ADD_ALERT,
  MEMBER_INFO
} from '@/store/common'
import { iFeedPrams, iHotCollectionsParams, iChangePeriod } from '@/request/types'

export const MarketCapAction = async (dispatch: React.Dispatch<AppActionType>) => {
  const data = await MarketCapApi()
  return dispatch({
    type: MARKET_CAP,
    payload: {
      marketCap: data
    }
  })
}

export const HotMintsAction = async (dispatch: React.Dispatch<AppActionType>) => {
  const data = await HotMintsApi()
  return dispatch({
    type: HOT_MINTS,
    payload: {
      hotMints: data
    }
  })
}
export const HotCollectionAction = async (dispatch: React.Dispatch<AppActionType>, p: iHotCollectionsParams) => {
  const data = await HotCollectionsApi(p)
  return dispatch({
    type: HOT_COLLECTION,
    payload: {
      hotCollection: data
    }
  })
}
export const FeedListAction = async (params: iFeedPrams, dispatch: React.Dispatch<AppActionType>) => {
  const data = await FeedListApi(params)
  return dispatch({
    type: FEED_LIST,
    payload: {
      feedList: data
    }
  })
}

export const ChangePeriodAction = (params: iChangePeriod, dispatch: React.Dispatch<AppActionType>) => {
  return dispatch({
    type: CHANGE_PERIOD,
    payload: {
      period: params
    }
  })
}

export const RefreshTableAction = (params: iRefreshTable, dispatch: React.Dispatch<AppActionType>) => {
  return dispatch({
    type: REFRESH_TABLE,
    payload: {
      refreshTable: params
    }
  })
}

export const TrendingInputAction = (price: string, dispatch: React.Dispatch<AppActionType>) => {
  return dispatch({
    type: TRENDING_PRICE,
    payload: {
      trendingPrice: price
    }
  })
}

export const MintsPriceAction = (isMint: number, dispatch: React.Dispatch<AppActionType>) => {
  return dispatch({
    type: MINTS_PRICE,
    payload: {
      isMint
    }
  })
}

export const AddAlertAction = (data: any, dispatch: React.Dispatch<AppActionType>) => {
  return dispatch({
    type: ADD_ALERT,
    payload: {
      alertData: data
    }
  })
}
export const MemberInfoAction = async (dispatch: React.Dispatch<AppActionType>, memberInfo: any) => {
  return dispatch({
    type: MEMBER_INFO,
    payload: {
      memberInfo
    }
  })
}
