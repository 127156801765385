import React, { useEffect, useState, useMemo } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { Table as AntTable } from 'antd'
import { InvitationBar } from './components'
import { ContentWrapper, Col, Row } from '@/components/Layout/Layout'
import { Flex, Box, Grid, Text, Image } from '@/packages/'
import { twitterWhite, rank1, rank2, rank3, rank4, rank5, whale, arrowRightGray } from '@/assets/img'
import { NoData } from '@/components/NoData'
import { inviteRankApi, rollingMsgApi, memberInfoApi } from '@/request/Api'
import { useMatchBreakpoints } from '@/hooks/useMatchBreakpoints'
import { useAppContext } from '@/hooks/useAppContext'
import useCopyToClipboard from '@/hooks/useCopyToClipboard'
import { openWindow, share2Twitter, splitAddress } from '@/utils/tools'
import { OpenNotification } from '@/components/OpenNotification'
import './index.css'

const PublicTestInvitation = styled(Box)``
const InvitationScroll = styled(Box)``
const InvitationLink = styled(Box)``
const RankTable = styled(Box)``
export const InviteBtn = styled(Box)`
  background: #00b2b5;
  font-weight: 600;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 20px;
  color: #fff;
  cursor: pointer;
`

const InvitationScrollColor = styled(Box)`
  border-left: 4px solid rgb(0, 177, 180);
  border-radius: 12px;
`
const InvitationScrollContent = styled(Box)`
  border-radius: 12px;
  font-weight: 400;
  font-size: 14px;
`
const RankText = styled(Text)`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: rgba(255, 255, 255, 0.87);
`
const Rewards = styled(Box)`
  background: rgba(0, 178, 181, 0.08);
  border-radius: 0px 34px 34px 0px;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #00b2b5;
`
const SelfWallet = styled(Box)`
  background: rgba(0, 178, 181, 0.08);
  border: 1px solid rgba(0, 178, 181, 0.38);
  border-radius: 38px;
  color: #00b2b5;
  font-weight: 400;
  font-size: 10px;
  margin-left: 16px;
  padding: 2px 6px 2px 6px;
  cursor: pointer;
`
interface iRankTextWrapper {
  l: string
  r: string
}
interface iMsgList {
  content: string
  time?: number
  title?: any
}
interface iRankList {
  invitation: number | string
  inviter: string | string
  inviterId: number
  isSelf: boolean
  isWhale: boolean
  rank: number | string
  rowNum: number
}
const RankCellImg: React.FC<{ rank: number | string }> = ({ rank }) => {
  let imgUrl = ''
  switch (rank) {
    case 1:
      imgUrl = rank1
      break
    case 2:
      imgUrl = rank2
      break
    case 3:
      imgUrl = rank3
      break
    case 4:
      imgUrl = rank4
      break
    case 5:
      imgUrl = rank5
      break
    default:
      break
  }
  return (
    <div>
      <Box className="pl20">
        {rank > 5 || typeof rank === 'string' ? (
          <Text className="fw400 white87" fontSize={['12px', '12px', '14px', '14px']}>
            {rank}
          </Text>
        ) : (
          <Image src={imgUrl} width={22} height={21} />
        )}
      </Box>
    </div>
  )
}
const RankTextWrapper: React.FC<iRankTextWrapper> = ({ l, r }) => {
  return (
    <Grid gridTemplateColumns={['1fr 2.5fr', '1fr 8fr', '1fr 8fr', '1fr 8fr']} className="jc-sb" mt="10px">
      <RankText>{l}</RankText>
      <RankText>{r}</RankText>
    </Grid>
  )
}

export const Invite = () => {
  const [isLoading, setLoading] = useState(true)
  const [invitationLink, setInvitationLink] = useState<string>('')
  const [rankList, setRankList] = useState<Array<any>>([])
  const [msgList, setMsgList] = useState<Array<iMsgList>>([])
  const { isMobile, isTablet, isDesktop } = useMatchBreakpoints()
  const { state, dispatch } = useAppContext()
  const [value, copy] = useCopyToClipboard()
  const RollingLength = 100
  const navigate = useNavigate()

  const fetch = async () => {
    setLoading(true)

    const d: any = await inviteRankApi()
    const m: any = await memberInfoApi()
    setLoading(false)
    if (d) {
      // ①    若当前用户为排行榜前7名，则列表展示前7名钱包邀请信息，中间省略的折叠样式不显示；
      // ②    若当前用户非排行榜前7名，则展示排名前5的钱包邀请信息、当前用户钱包邀请信息、当前用户的前一名钱包地址邀请信息。
      const list = JSON.parse(JSON.stringify(d))
      // const list: Array<iRankList> = [
      //   {
      //     invitation: 1,
      //     inviter: '0x5362123123456781...629363829102837263',
      //     inviterId: 1,
      //     isSelf: false,
      //     isWhale: false,
      //     rank: 1,
      //     rowNum: 1
      //   },
      //   {
      //     invitation: 1,
      //     inviter: '0x5362123123456781...629363829102837263',
      //     inviterId: 1,
      //     isSelf: false,
      //     isWhale: false,
      //     rank: 2,
      //     rowNum: 2
      //   },
      //   {
      //     invitation: 1,
      //     inviter: '0x5362123123456781...629363829102837263',
      //     inviterId: 1,
      //     isSelf: true,
      //     isWhale: false,
      //     rank: 3,
      //     rowNum: 3
      //   },
      //   {
      //     invitation: 1,
      //     inviter: '0x5362123123456781...629363829102837263',
      //     inviterId: 1,
      //     isSelf: false,
      //     isWhale: false,
      //     rank: 3,
      //     rowNum: 4
      //   },
      //   {
      //     invitation: 1,
      //     inviter: '0x5362123123456781...629363829102837263',
      //     inviterId: 1,
      //     isSelf: false,
      //     isWhale: false,
      //     rank: 5,
      //     rowNum: 5
      //   },
      //   {
      //     invitation: 1,
      //     inviter: '0x5362123123456781...629363829102837263',
      //     inviterId: 1,
      //     isSelf: false,
      //     isWhale: false,
      //     rank: 5,
      //     rowNum: 6
      //   },
      //   {
      //     invitation: 1,
      //     inviter: '0x5362123123456781...629363829102837263',
      //     inviterId: 1,
      //     isSelf: false,
      //     isWhale: false,
      //     rank: 7,
      //     rowNum: 7
      //   },
      //   {
      //     invitation: 1,
      //     inviter: '0x5362123123456781...629363829102837263',
      //     inviterId: 1,
      //     isSelf: false,
      //     isWhale: true,
      //     rank: 8,
      //     rowNum: 8
      //   }
      // ]
      const _list = JSON.parse(JSON.stringify(list)) // 返回的数据 最长是8
      list.forEach((item: any, idx: number) => {
        if (item.isSelf) {
          if (item.rowNum > 7) {
            // 非排行榜前8名
            const top5 = list.splice(0, 5)
            const dot = {
              rank: '...',
              inviter: '...',
              invitation: '...',
              isWhale: false,
              isSelf: false
            }
            const last2 = _list[idx - 1]
            const self = _list[idx]
            const arr = [...top5, dot, last2, self]
            setRankList(arr)
          } else {
            // 当前用户为排行榜前8名
            setRankList(list.splice(0, 8))
          }
        } else {
          setRankList(list)
        }
      })
    }

    if (m) {
      const { address: addr } = m
      setInvitationLink(`${window.location.origin}/?inviter=${addr}`)
    }
  }
  const fetchRolling = async () => {
    const m: any = await rollingMsgApi(RollingLength)
    if (m) {
      setMsgList(m)
      changeMsgLength(m.length)
    }
  }
  useEffect(() => {
    // const list = [
    //   { content: '<p style="color:#fff">111</p>' },
    //   { content: '<p style="color:#fff">22</p>' },
    //   { content: '<p style="color:#fff">33</p>' },
    //   { content: '<p style="color:#fff">44</p>' },
    //   { content: '<p style="color:#fff">55</p>' },
    //   { content: '<p style="color:#fff">66</p>' },
    //   { content: '<p style="color:#fff">77</p>' },
    //   { content: '<p style="color:#fff">88</p>' },
    //   { content: '<p style="color:#fff">99</p>' }
    // ]
    // setMsgList(list)
    // changeMsgLength(list.length)
    fetchRolling()
    fetch()
    const intervalTimer = setInterval(() => {
      fetchRolling()
    }, 1000 * 60 * 60)
    return () => clearInterval(intervalTimer)
  }, [])

  const onInviteInfo = () => {
    navigate('/invite/info')
  }

  const changeMsgLength = (len: number) => {
    document.documentElement.style.setProperty('--msgLength', `${len}`)
    document.documentElement.style.setProperty('--msgContainerHeight', `64`)
    document.documentElement.style.setProperty('--msgSpeed', `3s`)
  }

  const copyInviteLink = () => {
    copy(invitationLink)
    OpenNotification('The invitation link has been generated. Go and invite friends to join Pewee!', 'success')
  }

  const columns: any = [
    {
      title: 'RANK',
      dataIndex: 'rank',
      width: isMobile ? '20%' : '10%',
      paddingLeft: '36px',
      render: (r: number) => <RankCellImg rank={r} />
    },
    {
      title: 'WALLET ADDRESS',
      width: '50%',
      render: (item: iRankList) => (
        <Row className="w100 fx jc-start">
          <Text className="white87 fw400" fontSize={['12px', '12px', '14px', '14px']}>
            {!isDesktop && !isTablet && !isMobile && item.inviter}
            {isDesktop && item.inviter}
            {isTablet && splitAddress(item.inviter, 10, 10)}
            {isMobile && splitAddress(item.inviter, 4, 4)}
          </Text>
          {item.isWhale && <Image src={whale} height={12} width={18} className="ml16" />}
          {item.isSelf && <SelfWallet onClick={onInviteInfo}>{isMobile ? 'You' : 'Your wallet'}</SelfWallet>}
        </Row>
      )
    },
    {
      title: 'INVITATION',
      dataIndex: 'invitation',
      width: isMobile ? '30%' : '40%',
      render: (i: number) => (
        <Box className="w100 fx jc-end">
          <Text className="fz14 white87 fw400">{i}</Text>
        </Box>
      )
    }
  ]

  return (
    <ContentWrapper>
      <Col className="w100">
        <InvitationBar />
        <Grid
          mt={['16px', '16px', '40px', '40px']}
          gridTemplateColumns={['1fr', '1fr', '2fr 1fr', '2fr 1fr']}
          gridColumnGap={['0', '0', '12px', '24px']}
        >
          <PublicTestInvitation
            className="body_wrapper fx-col"
            px={['16px', '16px', '32px', '32px']}
            py={['24px', '24px', '32px', '32px']}
          >
            <Text className="fw600 white87" fontSize={['20px', '22px', '24px', '24px']}>
              Public Test Invitation
            </Text>
            <Text className="fw400 white87 fz14" mt="16px">
              Invite to Earn! Invite friends to use Pewee tools for FREE by sharing your own invitation link to get
              rewards.
            </Text>
            <Text className="fw400 white60 fz12" mt="10px">
              Note: Invited wallets should be active with trading txn. New wallets will not be valid in this event.
            </Text>
            <Rewards
              py="10px"
              pl={['16px', '22px', '32px', '32px']}
              pr={['16px', '16px', '20px', '20px']}
              width={['94px', '104px', '114px', '114px']}
              mt="32px"
              mb="6px"
            >
              REWARDS
            </Rewards>
            <RankTextWrapper l="Rank 1" r=": Earn 500 USDT & Pewee Lifetime Pass" />
            <RankTextWrapper l="Rank 2-3" r=": Each 200 USDT & Pewee Lifetime Pass" />
            <RankTextWrapper l="Rank 4-10" r=": Split 500 USDT (by percentage of contribution) & Pewee WL" />
            <RankTextWrapper l="Rank 11-20" r=": Each 50 USDT & Pewee WL" />
            <RankText mt="24px">
              Anyone who invited ≥ 5 wallets can join our Discord server for exclusive raffles:
            </RankText>
            <RankText mt="10px">Pewee Lifetime Pass x 3 • Pewee WL x 50 • 10 USDT x 50</RankText>
          </PublicTestInvitation>
          <Col>
            <InvitationScroll className="" mt={['32px', '32px', '0px', '0px']}>
              <InvitationScrollColor>
                <InvitationScrollContent
                  height={['88px', '88px', '88px', '88px']} // ['52px', '64px', '88px', '88px']
                  className="center body_wrapper"
                  px={['16px', '16px', '24px', '24px']} // ['16px', '16px', '24px', '24px']
                >
                  {msgList.length > 0 && (
                    <div className="scroll_msg_container">
                      <div className="scroll_msg_box">
                        {msgList.map((msg: iMsgList) => (
                          <span
                            key={Math.random()}
                            className="scroll_msg white87 fz14 fw600"
                            // dangerouslySetInnerHTML={{ __html: msg.content }}
                          >
                            {msg.content}
                          </span>
                        ))}
                      </div>
                    </div>
                  )}
                </InvitationScrollContent>
              </InvitationScrollColor>
            </InvitationScroll>
            <InvitationLink
              className="body_wrapper fx-col ai-ct"
              px={['16px', '16px', '24px', '24px']}
              mt={['32px', '32px', '24px', '24px']}
              py={['24px', '24px', '66px', '66px']}
            >
              <Text className="fz12 fw600 white87" mb="8px">
                YOUR INVITATION LINK
              </Text>
              <InviteBtn mt={['8px', '8px', '16px', '16px']} onClick={copyInviteLink}>
                INVITE FRIENDS
              </InviteBtn>
              <InviteBtn mt={['8px', '8px', '16px', '16px']} onClick={() => share2Twitter(invitationLink)}>
                SHARE ON TWITTER
                <Image src={twitterWhite} height={14} width={17} className="ml8" />
              </InviteBtn>
              <Row className="click" mt={['16px', '16px', '24px', '24px']} onClick={onInviteInfo}>
                <Text mr="4px" className="fz14 fw400 white60 ">
                  View Your Invitation Information
                </Text>
                <Image src={arrowRightGray} height={8} width={4} style={{ marginTop: 1 }} />
              </Row>
            </InvitationLink>
          </Col>
        </Grid>
        <RankTable
          className="w100"
          height="416px"
          mt={['32px', '32px', '40px', '40px']}
          mb={['32px', '32px', '60px', '60px']}
        >
          <AntTable
            className="invite_rank_table hide_scrollbar"
            locale={{ emptyText: ' ' }}
            rowKey={(record) => record.id}
            pagination={false}
            columns={columns}
            dataSource={rankList}
            scroll={{ y: 370 }}
          />
          {!rankList.length && <NoData isLoading={isLoading} />}
        </RankTable>
      </Col>
    </ContentWrapper>
  )
}
