import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useNavigate, useLocation } from 'react-router-dom'
import { Popover } from 'antd'
import { Flex, Box, Text, Image, Grid } from '@/packages/'
import { PeweeTooltip } from '@/components/Layout/Layout'

export const Wrapper = styled(Box)<{ h: number }>`
  height: ${({ h }) => `${h}px`};
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #111218;
  // background-color: ${(props) => props.theme.colors.main};
`

const TextLabel = styled(Text)<{ active: boolean }>`
  height: 20px;
  // border: ${({ active }) => (active ? '1px solid transparent' : 'none')};
  // border-image: ${({ active }) =>
    active ? 'linear-gradient(135deg, rgba(255, 232, 0, 1), rgba(0, 178, 181, 1)) 1 1' : 'none'};
  font-size: 16px;
  font-weight: 700;
  color: ${({ active }) => (active ? '#00B2B5' : 'rgba(255, 255, 255, 0.6)')};
  line-height: 19px;
  letter-spacing: 2px;
  cursor: pointer;
`
export const MenuItemsList = [
  { name: 'Dashboard', active: true },
  { name: 'Trending', active: false },
  { name: 'Upcoming', active: false },
  { name: 'About Us', active: false }
  // { name: 'Product', active: false }
]

interface iMenuList {
  name: string
  active: boolean
}
export const MenuItems = () => {
  const [menuList, setMenuList] = useState<Array<iMenuList>>(MenuItemsList)
  const navigate = useNavigate()
  const location = useLocation()
  const changeMenuActive = (name: string) => {
    const arr = JSON.parse(JSON.stringify(menuList))
    arr.forEach((item: iMenuList, idx: number) => {
      if (item.name === name) {
        arr[idx].active = true
      } else {
        arr[idx].active = false
      }
    })
    setMenuList(arr)
  }

  useEffect(() => {
    const path = location.pathname
    if (path.includes('/dashboard') || path === '/') changeMenuActive('Dashboard')
    if (path.includes('/trending')) changeMenuActive('Trending')
    if (path.includes('/upcoming')) changeMenuActive('Upcoming')
    if (path.includes('/aboutUs')) changeMenuActive('About Us')
    if (path.includes('/product')) changeMenuActive('Product')
  }, [location])
  const onHandle = (name: string) => {
    switch (name) {
      case 'Dashboard':
        navigate('/')
        break
      case 'Trending':
        navigate('/trending')
        break
      case 'Upcoming':
        navigate('/upcoming')
        break
      case 'About Us':
        navigate('/aboutUs')
        break
      // case 'Product':
      //   // navigate('/product')
      //   break
      default:
        navigate('/')
        break
    }
    changeMenuActive(name)
  }

  return (
    <Grid gridTemplateColumns={`repeat(${menuList.length}, 1fr)`} ml={[0, 0, 10, 50, 85]}>
      {menuList.map((item: iMenuList) => {
        if (item.name === 'Product') {
          return (
            <Popover
              key={item.name}
              content={
                <Text color="#fff" fontSize="20px">
                  Coming soon
                </Text>
              }
              trigger="click"
              placement="bottom"
            >
              <TextLabel className="center" active={item.active} onClick={() => onHandle(item.name)}>
                {item.name}
              </TextLabel>
            </Popover>
          )
        }
        return (
          <TextLabel key={item.name} className="center" active={item.active} onClick={() => onHandle(item.name)}>
            {item.name}
          </TextLabel>
        )
      })}
    </Grid>
  )
}

const MobileNavButton = styled(Box)<{ active: boolean }>`
  display: flex;
  align-items: center;
  padding-left: 20px;
  height: 40px;
  background: ${({ active }) => (active ? 'rgba(0, 178, 181, 0.06)' : '#121212')};
  border-radius: 0px 50px 50px 0px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: ${({ active }) => (active ? '#00B2B5' : '#929292')};
  margin-bottom: 10px;
  cursor: pointer;
`

interface iMobileMenuItems {
  onClickItem: () => void
}
export const MobileMenuItems: React.FC<iMobileMenuItems> = ({ onClickItem }) => {
  const [menuList, setMenuList] = useState<Array<iMenuList>>(MenuItemsList)
  const navigate = useNavigate()
  const location = useLocation()
  const changeMenuActive = (name: string) => {
    const arr = JSON.parse(JSON.stringify(menuList))
    arr.forEach((item: iMenuList, idx: number) => {
      if (item.name === name) {
        arr[idx].active = true
      } else {
        arr[idx].active = false
      }
    })
    setMenuList(arr)
  }

  useEffect(() => {
    const path = location.pathname
    if (path.includes('/dashboard') || path === '/') changeMenuActive('Dashboard')
    if (path.includes('/trending')) changeMenuActive('Trending')
    if (path.includes('/upcoming')) changeMenuActive('Upcoming')
    if (path.includes('/aboutUs')) changeMenuActive('About Us')
    if (path.includes('/product')) changeMenuActive('Product')
  }, [location])
  const onHandle = (name: string) => {
    switch (name) {
      case 'Dashboard':
        navigate('/')
        break
      case 'Trending':
        navigate('/trending')
        break
      case 'Upcoming':
        navigate('/upcoming')
        break
      case 'About Us':
        navigate('/aboutUs')
        break
      default:
        navigate('/')
        break
    }
    changeMenuActive(name)
    onClickItem()
  }

  return (
    <Box className="fx-col">
      {menuList.map((item: iMenuList) => (
        <MobileNavButton key={item.name} active={item.active} onClick={() => onHandle(item.name)}>
          {item.name}
        </MobileNavButton>
      ))}
    </Box>
  )
}
