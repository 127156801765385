import { useState, useEffect } from 'react'

export const useAccountsChange = (): Array<string> => {
  const [accounts, setAccounts] = useState<Array<string>>([])
  const getAccounts = async () => {
    // @ts-ignore
    if (window?.ethereum) {
      try {
        // @ts-ignore
        const accountsList = await window?.ethereum.request({ method: 'eth_requestAccounts' })
        if (accountsList && accountsList.length > 0) {
          setAccounts(accountsList)
        }
      } catch (error) {
        setAccounts([])
      }
    }
  }
  useEffect(() => {
    getAccounts()
  }, [])
  // @ts-ignore
  if (window?.ethereum) {
    // @ts-ignore
    window?.ethereum.on('accountsChanged', (a: Array<string>) => {
      setAccounts(a)
    })
  }
  return accounts
}
