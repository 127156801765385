import React from 'react'
import styled from 'styled-components'
import { Text, Image } from '@/packages'
import { close } from '@/assets/img'

const Title = styled(Text)`
  color: #fff;
  font-size: 35px;
  line-height: 60px;
`
const Title1 = styled(Text)`
  color: #fff;
  font-size: 25px;
  line-height: 60px;
`
const Content = styled(Text)`
  color: #fff;
  font-size: 18px;
  line-height: 24px;
`

export const PrivacyPolicy: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <div className="fx-col ai-ct w100">
      <div className="fx-row ai-ct jc-sb" style={{ width: '80%' }}>
        <div />
        <Title>Pewee User Privacy Policy</Title>
        <Image src={close} height={16} width={16} className="click" onClick={onClose} />
      </div>
      <div className="fx-col ai-start w100" style={{ width: '80%' }}>
        <Title1>This Privacy Policy is updated as of 2022 and will become effective as of 2022.</Title1>
        <div className="fx-col ai-start">
          <Title1>Table of Contents</Title1>
          <Title1>Introduction</Title1>
          <Content>I. How we collect and use your personal information</Content>
          <Content>II. How we use Cookies and similar technologies</Content>
          <Content>III. How we share, transfer and publicly disclose your personal information</Content>
          <Content>IV. How we protect your personal information</Content>
          <Content>V. Your rights</Content>
          <Content>VI. How this Privacy Policy is updated</Content>
          <Content>VII. Definitions</Content>
          <Content>VIII. How to contact us</Content>

          <Title1>Introduction</Title1>
          <Content>
            Pewee products and/or services are provided by Pewee Labs and its affiliates (hereinafter referred to as
            “we”) for users (hereinafter referred to as “you”). While providing you with high-quality products and/or
            services, we are committed to improving the transparency of information processing, enhancing the
            convenience of your information management, and ensuring the security of your information and
            communications. In order to provide you with more secure and reliable products and/or services, this Pewee
            User Privacy Policy (“Privacy Policy”) is formulated in strict compliance with laws and regulations,
            adhering to the principles of consent, minimum enough, security, openness and transparency.
          </Content>
          <Content>
            Pewee User Privacy Policy applies to (1) your use of Pewee’s website (https://mint.pewee.tools/index); (2)
            your use or acceptance of other products or services provided by Pewee. We will provide you with services
            (“Pewee Services”) through our website, App and other forms. If any product or service we provide to you has
            a separate privacy policy, the product or service shall be subject to its separate privacy policy; if our
            products or services do not have a separate privacy policy, this Policy applies.
          </Content>
          <Content>
            In particular, this Policy does not apply to the services provided to you by other third parties, which
            shall be subject to the privacy policy otherwise explained to you.
          </Content>
          <Content>
            This Privacy Policy is intended to explain to you how we collect, use, share, and protect your personal
            information and other related information. Please be sure to read and understand this Policy carefully,
            especially the terms marked in bold. By providing us with your personal information or beginning to use our
            products or services, you acknowledge and agree to the contents set forth herein. Please read this Privacy
            Policy carefully to learn more about how we collect and use information so that you can better understand
            our services and make appropriate choices. We may collect and use your sensitive personal information for
            the purpose of providing specific services to you. Sensitive personal information will be highlighted in
            bold in the following description to bring it to your attention. Please consider carefully whether to
            provide such information to us during use. We will do our best to protect your personal information.
          </Content>
          <Content>
            We may make changes to this Privacy Policy. In case of major changes (such as changes in business functions,
            purpose of use, contact information of the person in charge of personal information protection, etc.), we
            will announce the new Privacy Policy on the Pewee website (https://mint.pewee.tools/index) by page
            notification before the new Privacy Policy officially takes effect, so that you can understand the specific
            changes and make suggestions to us. If you click “Agree”, it will be regarded as your acceptance of the new
            Privacy Policy.
          </Content>
          <Title1>I. How we collect and use your personal information</Title1>
          <Content>
            (I) Information we collect and use during your use of our products or services We will only collect and use
            your personal information for the business functions described herein. The purpose of collecting your
            personal information is to provide you with products or services. You have the right to choose whether or
            not provide such information, but in most cases, if you do not, we may not be able to provide you with the
            appropriate services or respond to the questions you have. You agree and understand that, due to the wide
            variety of products and services we provide to you, and the specific products and/or service scope selected
            by different users may vary, the type and scope of personal information collected and used may vary
            accordingly, and the specific products and/or services shall prevail:
          </Content>
          <Title1>1. Account registration and login</Title1>
          <Content>
            1.1 When you register and log in, if you use the one-click login function, we will collect your wallet
            address to provide you with a fast login service.
          </Content>
          <Content>
            1.2 You can also fill in or change your account name, social identity and self-introduction according to
            your own needs to improve your information. However, if you do not provide such information, your use of the
            product and related services will not be affected.
          </Content>
          <Content>
            1.3 If you successfully register a Pewee account, we will display your username, avatar, and
            self-introduction in the Pewee App and website.
          </Content>
          <Title1>2. Provision of products and services to you</Title1>
          <Content>
            Intro2.1 Information browsing, posts, likes, favorites and share. In order to provide you with our core
            information display service and recommend articles and related information that may be of interest to you,
            we will collect your browsing history, visit date and time information. Such information is sensitive
            personal information, but it is necessary to provide Pewee’s core information display and information posts,
            and you are free to delete such information. To provide you with information display and push services that
            are more convenient and more in line with your personalized needs, in the future, we may extract your
            preference features based on your device information and your browsing and usage information on this
            platform or client, and generate indirect crowd portraits based on feature tags for display, push
            information and possible commercial advertisements. If you do not agree to receive such push information or
            withdraw your consent to the collection of personal information, you may contact us according to the contact
            details provided herein.duction
          </Content>
          <Content>
            2.2 Search. When you use Pewee’s search function, we will collect the keywords and access time of your query
            and save them locally for quick search next time. You are free to delete them by clicking the “Delete”
            button.{' '}
          </Content>
          <Content>
            2.3 Buy, inquire and use virtual property. In order to facilitate your inquiry of virtual property and
            minimize possible risks, [Account Management] will record your recharge, balance and usage.
          </Content>
          <Content>
            2.4 Payment. You can buy membership services on Pewee. When you use such function, you may need to make
            payment. During the payment process, we will collect information about your wallet account.
          </Content>
          <Title1>3. We may obtain your personal information indirectly from a third part</Title1>
          <Content>
            If you log in using the account of a third party platform, after you explicitly authorize the account
            binding, the third-party platform will synchronize your avatar used on that platform to us, so that you can
            directly log in and use Pewee. You can also unbind the account.{' '}
          </Content>
          <Title1>(II) Exceptions to authorized consent</Title1>
          <Content>
            You fully understand and agree that, in accordance with laws and regulations and relevant national
            standards, we do not need to obtain your authorized consent to collect and use your personal information
            under the following circumstances, and we may not respond to your requests for correction/modification,
            deletion, cancellation, withdrawal of consent or request for information:
          </Content>
          <Content>1.Those directly related to national security and national defense security;</Content>
          <Content>2.Those directly related to public security, public health or major public interests;</Content>
          <Content>
            3.Those directly related to criminal investigation, prosecution, trial and execution of judgment;
          </Content>
          <Content>
            4.Those intended to protect the life, property and other important legal rights and interests of you or
            other individuals but difficult to obtain your consent;
          </Content>
          <Content>5.Where the personal information collected is disclosed to the public by you;</Content>
          <Content>
            6.Where your personal information is collected from legally publicly disclosed information, such as legal
            news reports, government information disclosure and other channels;{' '}
          </Content>
          <Content>7.Those necessary for signing or performing contracts as required by you;</Content>
          <Content>
            8.Those necessary for maintaining the safe and stable operation of software and related services, such as
            finding and disposing of faults of software and related services;
          </Content>
          <Content>
            9.Where the personal information controller is a press unit and the information is necessary for legal news
            reports;
          </Content>
          <Content>
            10.Where academic research institutions de-identify the personal information contained in the results of
            academic research or description when they carry out statistical or academic research based on the public
            interest and provide such results to the public.
          </Content>
          <Content>11.Other circumstances stipulated by laws and regulations.</Content>
          <Content>
            We warrant that we will de-identify or anonymize the personal information collected according to law.
            Information that cannot be used alone or in combination with other information to identify the personal
            identity of a natural person is not personal information in the legal sense. If we combine non-personal
            information with other information to identify your personal identity, or use it in combination with your
            personal information, we will treat and protect such information as your personal information in accordance
            with this Privacy Policy during the combined use period.
          </Content>
          <Content>
            To better operate and improve our technology and services, or for changes in business strategy, when we
            collect, use or process personal information required by our products or services beyond the above scope or
            use the collected personal information for other purposes not specified herein, or when we use information
            collected for a specific purpose for another purpose, we will notify you and obtain your authorized consent
            within a reasonable period of time after obtaining your personal information or before processing your
            personal information.
          </Content>
          <Content>
            We may conduct de-identification research, statistical analysis and prediction on the information collected
            for the purpose of improving the content and layout of the Platform, providing product or service support
            for business decisions, and improving our products and services (including machine learning or model
            algorithm training using anonymous data), and such processed data can be used without notice to you and
            without your consent.
          </Content>
          <Title1>II. How we use Cookies and similar technologies</Title1>
          <Title1>(I) Cookies</Title1>
          <Content>
            To ensure the proper functioning of the website, we store small data files called cookies on your computer
            or mobile device. Cookies typically contain identifiers, site names, and some numbers and characters. With
            cookies, the website can store data about your access preferences. We will not use cookies for any purpose
            other than those stated herein. You can manage or delete cookies according to your preference. You can erase
            all cookies saved on your computer, and most web browsers have a cookie-blocking feature. However, if you do
            so, you will need to personally change your user Settings each time you visit our website.{' '}
          </Content>
          <Title1>(II) Web beacons and pixel tags</Title1>
          <Content>
            In addition to cookies, we also use other similar technologies like web beacons and pixel tags on our
            website. For example, we may send you an email containing a click URL that links to content on our website.
            If you click on this link, we will track this click to help us understand your product or service
            preferences and improve customer service. A web beacon is usually a transparent image embedded in a web site
            or email. With the help of the pixel tags in the email, we can tell if the email has been opened. If you do
            not wish your activities to be tracked in this way, you can unsubscribe from our mailing list at any time.
          </Content>
          <Title1>(III) Do Not Track</Title1>
          <Content>
            Many web browsers have a Do Not Track feature that issues Do Not Track requests to web sites. The major
            Internet standards bodies have yet to establish policies governing how sites should respond to such
            requests. But if you have Do Not Track enabled on your browser, then all of our sites respect your choice.
          </Content>
          <Title1>III. How do we share, transfer and publicly disclose your personal information</Title1>
          <Title1>(I) Sharing</Title1>
          <Content>
            We will not share your personal information with any other company, organization or individual except for
            the following circumstances:
          </Content>
          <Content>
            1.Sharing with express consent: We will share your personal information with other parties with your express
            consent.
          </Content>
          <Content>
            2.We may share your personal information in accordance with laws and regulations or mandatory requirements
            of government authorities.
          </Content>
          <Content>
            Introduc3.Sharing with our affiliates: We may share your personal information with our affiliates. We will
            only share necessary personal information and are bound by the purposes stated herein. If any affiliate
            wants to change the purpose of processing personal information, it will seek your authorized consent
            again.tion
          </Content>
          <Content>
            4.Sharing with authorized partners: Some of our services will be provided by authorized partners only for
            the purposes stated herein. We may share some of your personal information with our partners to provide
            better customer service and user experience. We will only share your personal information for lawful,
            legitimate, necessary, specific and explicit purposes and will only share such personal information as is
            necessary to provide the services. In order to better operate and improve the technology and services, you
            agree that we and our authorized partners may use the information collected for other services and purposes
            in compliance with relevant laws and regulations.
          </Content>
          <Title1>(II) Transfer</Title1>
          <Content>
            We will not transfer your personal information with any other company, organization or individual except for
            the following circumstances:
          </Content>
          <Content>
            1.Transfer with express consent: We will transfer your personal information to other parties with your
            express consent.
          </Content>
          <Content>
            2.In the case of merger, acquisition or bankruptcy liquidation, if the transfer of personal information is
            involved, we will require the new company or organization that holds your personal information to continue
            to be bound by this Privacy Policy; otherwise, we will require the company or organization to seek
            authorized consent from you again.
          </Content>
          <Title1>(III) Public disclosure</Title1>
          <Content>We will only publicly disclose your personal information under the following circumstances:</Content>
          <Content>1.With your express consent;</Content>
          <Content>
            2.Disclosure based on law: We may publicly disclose your personal information when required by laws, legal
            procedures, lawsuits or government authorities;
          </Content>
          <Content>
            3.If we determine that you have seriously violated the relevant agreements and rules of the Platform, or to
            protect the personal and property safety of users or the public from infringement, We may disclose your
            personal information in accordance with laws and regulations or with your consent, including relevant
            violations and measures taken by the Platform against you.
          </Content>
          <Title1>
            (IV) Exceptions to prior authorized consent for sharing, transfer and public disclosure of information
          </Title1>
          <Content>
            You understand and agree that we do not need your authorized consent to share, transfer or publicly disclose
            your personal information in the following circumstances:
          </Content>
          <Content>1.Those directly related to national security and national defense security;</Content>
          <Content>2.Those directly related to public security, public health or major public interests;</Content>
          <Content>
            3.Those directly related to criminal investigation, prosecution, trial and execution of judgment;
          </Content>
          <Content>
            4.Those intended to protect the life, property and other important legal rights and interests of you or
            other individuals but difficult to obtain your consent;
          </Content>
          <Content>Where the personal information collected is disclosed to the public by you</Content>
          <Content>
            6.Where your personal information is collected from legally publicly disclosed information, such as legal
            news reports, government information disclosure and other channels.
          </Content>
          <Content>
            Please acknowledge that, in accordance with applicable laws, if we take technical and other necessary
            measures to process personal information in such a way that the data recipient cannot re-identify the
            specific individual and cannot recover it, the data after such processing may be shared, transferred or
            publicly disclosed without further notice to you or your consent.
          </Content>
          <Title1>IV. How we protect your personal information</Title1>
          <Content>
            The security of your personal information is of the utmost importance to us. We will strictly abide by
            relevant laws and regulations and take reasonable and feasible measures recognized by the industry to
            protect your personal information, prevent unauthorized access, disclosure, use and modification of
            information, and avoid information damage or loss.
          </Content>
          <Title1>(I) Technical measures and data security measures</Title1>
          <Content>
            We strive to protect your personal information by employing a variety of physical, electronic and
            administrative security measures that meet industry standards. We actively establish a data classification
            and grading system, data security management standards and data security development standards to manage and
            standardize the storage and use of personal information, and ensure that no personal information irrelevant
            to the services we provide is collected. We carry out comprehensive security control over data by signing
            confidentiality agreements with information contacts, monitoring and auditing mechanisms to protect your
            personal information from unauthorized access, public disclosure, use, modification, damage or loss. We have
            used industry-standard security measures to protect your personal information from unauthorized access,
            public disclosure, use, modification, damage or loss. We will take all reasonably practicable steps to
            protect your personal information. For example, the exchange of data (such as credit card information)
            between your browser and Pewee is protected by SSL encryption; we will use encryption to ensure the
            confidentiality of data; we will use trusted protection mechanisms to protect our data from malicious
            attacks; we will deploy access control mechanisms to ensure that only authorized personnel have access to
            personal information; and we will conduct security and privacy protection training programs to enhance
            employees’ awareness of the importance of protecting personal information.
          </Content>
          <Title1>(II) Security incident handling</Title1>
          <Content>
            We will do our best to ensure the security of any information you send us, but please understand that due to
            technical limitations and the various malicious means that may exist in the Internet industry, it is
            impossible to keep information 100% secure all the time. You should understand that the systems and
            communication networks you use to access our services may have problems due to factors beyond our control.
            In order to prevent the occurrence of security incidents, we have developed a proper early warning mechanism
            and emergency plan. In the unfortunate event of a personal information security incident, we will promptly
            inform you in accordance with the requirements of laws and regulations: basic situation and possible impact
            of the security incident, disposal measures we have taken or will take, suggestions for you to prevent and
            reduce risks, and remedies for you; and we will immediately activate an emergency plan and strive to
            minimize the damage. We will promptly inform you of the event by phone, push notification and other means.
            When it is difficult to inform users one by one, we will take a reasonable and effective way to make
            announcements. Meanwhile, we will also take the initiative to report the handling of personal information
            security incidents in accordance with the requirements of regulatory authorities, and closely cooperate with
            the work of government agencies.
          </Content>
          <Title1>V. Your rights</Title1>
          <Content>
            We attach great importance to your concerns about personal information, and do our best to protect your
            rights to access, correct, delete and withdraw consent to your personal information, so that you have
            sufficient capabilities to protect your privacy and security. Your rights include:
          </Content>
          <Title1>(I) Access and correct your personal information</Title1>
          <Content>
            1.Except as provided by laws and regulations, you have the right to access and correct your personal
            information at any time.
          </Content>
          <Content>
            2.If you require access to any other personal information generated during your use of Pewee, we will
            provide it to you upon reasonable request. If you are unable to access or correct your personal information,
            you can also contact us by sending an email to service@pewee.tools. We will respond to your request for
            access within 5 days.
          </Content>
          <Content>
            (II) In response to data access, correction or deletion requests, we will verify the identity of the
            requester to ensure that he/she is legally entitled to make such requests. While we aim to respond to these
            requests free of charge, we reserve the right to charge you a reasonable fee if your requests are repetitive
            or onerous.
          </Content>
          <Title1>VI. How this Privacy Policy is updated</Title1>
          <Content>Our privacy policy is subject to change.</Content>
          <Content>
            We will not limit your rights hereunder without your express consent. We will publish any changes to this
            Privacy Policy through official channels.
          </Content>
          <Content>
            (I) For major changes, depending on the specific circumstances, we may also provide more prominent notices
            (including that we will notify you through public announcements on the Platform or even provide you with
            pop-up prompts) to explain the specific changes to these guidelines. Major changes include, but are not
            limited to:
          </Content>
          <Content>
            1.Major changes have taken place in our service model, such as the purpose of personal information
            processing, the type of personal information processed, the use of personal information, etc.;
          </Content>
          <Content>
            2.We have undergone major changes in ownership structure, organizational structure and so on, such as change
            of owner caused by business adjustment, bankruptcy merger, etc.;
          </Content>
          <Content>
            3.The main objects of personal information sharing, transfer or public disclosure have changed;
          </Content>
          <Content>
            4.Your rights to participate in personal information processing and the way in which you exercise them have
            changed significantly;
          </Content>
          <Content>
            5.Our responsible departments, contact methods and complaint channels for handling personal information
            security have changed;
          </Content>
          <Content>6.The personal information security impact assessment report indicates a high risk.</Content>
          <Content>
            (II) If you do not agree to the revised Privacy Policy, you have the right and should immediately stop using
            Pewee’s services. If you continue to use Pewee’s services, you will be deemed to accept the changes made by
            Pewee to the relevant terms of this Policy.
          </Content>
          <Title1>VII. Definitions</Title1>
          <Content>The terms used in this Privacy Policy are defined in their common sense as follows:</Content>
          <Content>
            (I) In any way, the activity of PeweeTools does not refer to investments as investment funds. Based on
            information posted on the Website, the term “investment” or “investments” shall be defined only as the
            purchase of the NFT tokens or pursuing such buy, through third party services (e.g. opensea.io). The
            activity of PeweeTools shall not be managing of any assets relating to the NFT tokens
          </Content>
          <Content>
            (II) The Account may be created by the User, by connecting Your Metamask wallet (www.metamask.io). Account
            registration is free and voluntary (optional), but necessary to use some of the functionalities available
            within the Website. The registration of the Account takes place only via the Internet (online).
          </Content>
          <Content>
            (III) Personal Information: refers to all kinds of information related to identified or identifiable natural
            persons recorded electronically or in other ways, excluding anonymized information.
          </Content>
          <Content>
            (IV) Sensitive Personal Information: refers to personal information that, once disclosed or illegally used,
            is likely to infringe upon the personal dignity of a natural person or endanger personal and property
            safety, including biometrics, religious beliefs, specific identities, medical and health, financial accounts
            and whereabouts.
          </Content>
          <Content>
            (V) Device: refers to a device that can be used to access Pewee’s products and/or services, such as a
            desktop computer, laptop, tablet or smartphone.
          </Content>
          <Content>
            (VI) IP Address: Each device connected to the Internet is assigned a number, called an Internet protocol
            (IP) address. These numbers are usually assigned by geographical region. An IP address is usually used to
            identify the location of a device when it is connected to the Internet.
          </Content>
          <Content>
            (VII) SSL (Secure Socket Layer): is a security protocol implemented over the transport communication
            protocol (TCP/IP). SSL supports all types of networks and provides three basic security services, all of
            which use public key and symmetric key technologies to secure information.
          </Content>
          <Content>
            (VIII) Information Collection Technologies: “Automatic collection” as we describe in the Pewee User Privacy
            Policy includes the following methods: A. Cookies. A cookie is a small text file that the web server places
            on the client (your computer, mobile phone, or other smart terminal) when you browse the web. When you visit
            the site again, the site recognizes your browser through the cookie. Cookies may store user preferences and
            other information. You can configure your browser to reject all cookies or to display prompts when a web
            site sends cookies. However, some web features or services may not function properly without cookies. Please
            refer to the Pewee Cookies Guide for details. B. Log files. Log files store a number of automatically
            collected data, which includes Internet protocol (IP) addresses, browser types, Internet service providers
            (ISPs), reference/exit pages, operating systems, date/time stamps, and click-stream data. C. JavaScript.
            JavaScript is a programming language for making more interactive and dynamic web pages. JavaScript can set,
            read and delete cookies.
          </Content>
          <Content>
            (IX) Affiliate: refers to any company or institution that controls or will control, is or will be controlled
            by, or is or will be under common control with a party. “Control” means the ability to directly or
            indirectly influence the management of the company mentioned, whether through ownership, voting shares,
            contracts or other means determined by law.
          </Content>
          <Content>
            (X) Algorithm: refers to the process or set of rules that a computer follows when performing a
            problem-solving operation.
          </Content>
          <Title1>VIII. How to contact us</Title1>
          <Content>
            (I) If you have any requests or comments, please contact us via service@pewee.tools, which is our only
            official email address, so we will not be responsible for your failure to use valid contact information, any
            actions or omissions.
          </Content>
          <Content>
            (II) We only issue announcements and information or post announcements in the Pewee App and website through
            the above valid contact information, so we are not liable for any loss caused by your trust in information
            not obtained through the above means.
          </Content>
          <Content>(III) If you have any questions about our privacy policy, please feel free to contact us. </Content>
          <Title1>Generally, we will reply within 5 days.</Title1>
        </div>
      </div>
    </div>
  )
}
