import React, { useEffect, useState } from 'react'
import { Text } from '@/packages/'
import { TextProps } from '@/packages/Text/types'
import { accMul, toNonExponential } from '@/utils/tools'

// 用于显示数值是增加还是减少
export interface iText extends TextProps {
  value?: number
  increase: boolean //  涨/跌true涨，flase降低
  percent?: number // 百分比
}
export const IncreaseText: React.FC<iText> = ({ value, increase, percent, ...props }) => {
  const [val, setVal] = useState<string>('0')
  useEffect(() => {
    if (value) setVal(`${value}`)
    if (percent) {
      const a = accMul(toNonExponential(percent), 100)
      setVal(increase ? `+${a}%` : `-${a}%`)
    }
  }, [value, percent])
  return (
    <Text
      fontSize="12px"
      fontWeight={400}
      color={Number(val) === 0 ? '#A0A1A3' : increase ? '#38C742' : '#F32626'}
      {...props}
    >
      {val}
    </Text>
  )
}
