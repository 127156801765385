import React from 'react'
import styled from 'styled-components'
import { Flex, Box, Input, Image, Text, Grid } from '@/packages/'
import { isAuth as isAuthUrl } from '@/assets/svg'

interface nftProps {
  imgUrl: string
  isAuth: boolean // 是否认证
  title: string
  desc: string
  w: number
  h: number
}

const ItemWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 352px;
  background: ${(props) => props.theme.colors.bg9};
  padding-left: 20px;
`
export const NFTItem: React.FC<nftProps> = ({ imgUrl, isAuth, title, desc, w, h }) => {
  return (
    <ItemWrapper height={`${h}px`} width={`${w}px`} mt="10px">
      <Image height={32} width={32} src={imgUrl} />
      <Flex flexDirection="column" ml="10px">
        <Flex flexDirection="row" alignItems="center">
          <Text fontSize="14px" color="#E0E3E3" mr="6px">
            {title}
          </Text>
          {isAuth && <Image height={16} width={16} src={isAuthUrl} />}
        </Flex>
        <Text fontSize="12px" color="#BEC8C9">
          {desc}
        </Text>
      </Flex>
    </ItemWrapper>
  )
}
