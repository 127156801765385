import React, { useState, useEffect, ChangeEvent, useRef } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { Input as AntInput, Modal } from 'antd'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import { Box, Image, Text } from '@/packages/'
import { beyondStr, someTimesAgo, openWindow, decimalNum, toNonExponential, unitTime } from '@/utils/tools'
import { WatchListAlertApi } from '@/request/Api'
import {
  isOS,
  refresh,
  website,
  websiteActive,
  inputSuffix,
  close,
  changeUp,
  changeDown,
  alertIcon,
  arrowRight,
  eth,
  clock,
  ethIcon
} from '@/assets/img'
import { Row, Col, PeweeTooltip, Divider } from '@/components/Layout/Layout'
import useDebounce from '@/hooks/useDebounce'
import { useInterval } from '@/hooks/useInterval'
import { IncreaseText, iText } from '@/components/IncreaseText'
import { iVolume } from '@/types/store'
import { ActiveIcon } from '@/components/ActiveIcon'
import { useMatchBreakpoints } from '@/hooks/useMatchBreakpoints'
import { MaskContainer } from '@/components/MaskContainer'
import { MobileInput, MobileInputEdit } from '@/components/Mobile/MobileInput'
import { InputGroup } from '@/components/Mobile/MobileInput/InputGroup'
import { SwitchButton } from '@/components/SwitchButton'
import { MobileModalFooter } from '@/components/Mobile/MobileModalFooter'
import { OpenNotification } from '@/components/OpenNotification'
import { PeweeInput } from '@/components/PeweeInput'

interface iChangeCell {
  increase: boolean
  percent: number
}
export const ChangeCell: React.FC<iChangeCell> = ({ increase, percent }) => {
  return (
    <Row className="w100 fx jc-end">
      {percent === 0 ? (
        <Image height={10} width={10} src={changeUp} />
      ) : (
        <Image height={10} width={10} src={increase ? changeUp : changeDown} />
      )}
      <Box ml={['2px', '4px', '8px', '8px']}>
        <IncreaseText increase={increase} percent={percent} />
      </Box>
    </Row>
  )
}

const AlertTriggeredMobile = styled(Box)`
  background: rgba(255, 232, 0, 0.08);
  border: 1px solid rgba(255, 232, 0, 0.38);
  border-radius: 12px;
  height: 68px;
`
interface iWatchListCollectionMobile {
  onMobileOpenAlert: (v: boolean, r: any) => void
  fetchWatchList: () => void
}
export const WatchListCollectionMobile: React.FC<iWatchListCollectionMobile & any> = (record) => {
  const { collection, floor, sales, alert, children, onMobileOpenAlert, fetchWatchList } = record
  const { imageUrl, name, nftId, verifyStatus, officialUrl } = collection
  const [alertPrice, setAlertPrice] = useState<string>('')
  const [visible, setVisible] = useState<boolean>(false)
  const [saveBtnActive, setSaveBtnActive] = useState<boolean>(false)
  const [switchActive, setSwitchActive] = useState<boolean>(false)
  const [crtAlert, setCrtAlert] = useState<any>(alert)
  const alertPriceRef: any = useRef()
  const { isMobile, isTablet } = useMatchBreakpoints()

  const navigate = useNavigate()

  useEffect(() => {
    if (alert && alert.triggered) {
      setSwitchActive(false)
      setCrtAlert(null)
      alertPriceRef.current && alertPriceRef.current.clearInputValue()
    }
  }, [])

  const onChangeSwitch = (v: boolean) => {
    setSwitchActive(v)
    onMobileOpenAlert(v, record)
    if (v === false) {
      setCrtAlert(null)
      alertPriceRef.current && alertPriceRef.current.clearInputValue()
    }
  }
  const handleDetailMobile = (nId: number) => {
    nId && navigate(`/trending/liveview/${nId}`)
  }

  const onWatchSettingVisible = (v: boolean) => {
    setVisible(v)
  }
  const handleCancel = () => {
    onWatchSettingVisible(false)
    fetchWatchList()
  }

  const onChangeAlertPrice = (v: string) => {
    setAlertPrice(v)
    setSaveBtnActive(!!v)
  }
  const onPressEnter = async () => {
    const d: any = await WatchListAlertApi({ nftId, alertPrice: Number(alertPrice) })
    if (d) {
      OpenNotification('Set alert price successfully', 'success')
      onWatchSettingVisible(false)

      // 如果设置价格是一个已经提醒过的  那么设置成功后  重新拉一次watchlist 的数据
      fetchWatchList()
    }
  }

  return (
    <Box>
      <Row className="jc-sb">
        <Row>
          {children}
          <Image
            height={40}
            width={40}
            style={{ borderRadius: '6px' }}
            src={imageUrl}
            className="circle"
            onClick={() => handleDetailMobile(nftId)}
          />
          <Row ml="10px" onClick={() => handleDetailMobile(nftId)}>
            <Col>
              <Text className="white87 beyondHide fw600" fontSize="14px" width="146px" title={name}>
                {name}
              </Text>
              <Row>
                <Text className="fz12 fw400 white38"> Floor Price</Text>
                <Text className="fz12 fw400 white60" ml="6px">
                  Ξ {toNonExponential(floor)}
                </Text>
              </Row>
            </Col>
            <Row>
              {verifyStatus === 1 && <Image src={isOS} height={16} width={16} className="ml4" />}
              {/* {officialUrl && (
                <Box ml="4px">
                  <ActiveIcon src={website} activeSrc={websiteActive} targetUrl={officialUrl} tooltip="Website" />
                </Box>
              )} */}
            </Row>
          </Row>
        </Row>
        <Row>
          {alert && alert.triggered && <Image src={alertIcon} width={22} height={22} />}
          <Image
            src={arrowRight}
            width={6}
            height={10}
            className="click ml20"
            onClick={() => onWatchSettingVisible(true)}
          />
        </Row>
      </Row>
      <Modal
        closable={false}
        className={isMobile || isTablet ? 'modalClassNameMobile' : 'modalClassName'}
        bodyStyle={{ background: 'rgb(18,18,18)', borderRadius: 12 }}
        // onCancel={handleCancel}
        visible={visible}
        wrapClassName={isMobile || isTablet ? 'wrapClassNameMobile' : ''}
        footer={null}
        maskClosable={false}
      >
        <Col className="body_wrapper w100" pt="20px">
          <Row className="jc-sb">
            <div />
            <Text className="white87 fw700" fontSize={['20px', '20px', '22px', '24px']}>
              Watchlist Settings
            </Text>
            <Image className="click" src={close} height={20} width={20} onClick={handleCancel} />
          </Row>
          <Box mt="44px">
            <InputGroup title="COLLECTION INFO">
              <MobileInputEdit
                title="Name"
                value={collection.name}
                suffix={<Image src={collection.imageUrl} height={24} width={24} className="circle" />}
              />
              <Divider />
              <MobileInputEdit title="Floor Price" prefix={<Image src={eth} height={10} width={6} />} value={floor} />
              <Divider />
              <MobileInputEdit title="Sales" value={sales} />
            </InputGroup>
            <InputGroup title="ALERT SETTINGS">
              <MobileInputEdit
                title="Alert Off/On"
                prefix={
                  <SwitchButton
                    title=""
                    onChange={onChangeSwitch}
                    // onChange={(v: boolean) => onMobileOpenAlert(v, record)}
                    defaultStatus={!!crtAlert}
                  />
                }
              />
              <Divider />
              {(!!crtAlert || switchActive) && (
                <MobileInput
                  min={0}
                  max={100}
                  prefix={<Image src={eth} height={10} width={6} />}
                  defaultVal={crtAlert && crtAlert.alertPrice}
                  title="Alert Price"
                  placeholder="Type alert price"
                  onChange={onChangeAlertPrice}
                  inputRef={alertPriceRef}
                />
              )}
            </InputGroup>
            {alert && alert.triggered && !switchActive && (
              <AlertTriggeredMobile className="fx-row ai-ct" pl="16px">
                <Image src={alertIcon} width={22} height={22} />
                <Col ml="12px">
                  <Text className="fz12 fw600 white87">ALERT TRIGGERED</Text>
                  <Row mt="4px">
                    <Image src={clock} height={16} width={16} />
                    <Text className="fz12 fw400 white60" ml="4px">
                      {unitTime(alert && alert.triggerTime)}
                    </Text>
                  </Row>
                </Col>
              </AlertTriggeredMobile>
            )}
            <MobileModalFooter onSave={onPressEnter} saveActive={saveBtnActive} />
          </Box>
        </Col>
      </Modal>
    </Box>
  )
}
export const CollectionItem: React.FC<any> = ({
  imageUrl,
  image,
  name,
  nftId,
  createDate,
  verifyStatus,
  slug,
  subTitle,
  children,
  officialUrl,
  customComp,
  hideOfficialUrl
}) => {
  const { isMobile } = useMatchBreakpoints()

  const navigate = useNavigate()
  const handleDetail = (nId: number) => {
    nId && navigate(`/trending/liveview/${nId}`)
  }

  return (
    <Box className="fx-row ai-ct click">
      {children}
      <Image
        height={isMobile ? 40 : 46}
        width={isMobile ? 40 : 46}
        src={imageUrl || image || ethIcon}
        className="circle "
        onClick={() => handleDetail(nftId)}
      />
      <Box className="fx-col" ml={['2px', '4px', '10px', '10px']}>
        <Col>
          <Row className="fx-row ai-ct">
            <Text
              width={['74px', '146px', '146px', '146px']}
              className="white87 beyondHide fw600"
              fontSize="14px"
              title={name}
              onClick={() => handleDetail(nftId)}
            >
              {name}
            </Text>
            <Row className="center">
              {verifyStatus === 1 && (
                <Box ml={['2px', '2px', '4px', '4px']} mt="6px" className="">
                  <Image src={isOS} height={16} width={16} />
                </Box>
              )}
              {!hideOfficialUrl && officialUrl && (
                <Box ml={['0px', '0px', '4px', '4px']} className="">
                  <ActiveIcon src={website} activeSrc={websiteActive} targetUrl={officialUrl} tooltip="Website" />
                </Box>
              )}
            </Row>
          </Row>
          {customComp}
        </Col>
        {!isMobile && (
          <div>
            {subTitle && (
              <Text color="rgba(255, 255, 255, 0.38)" fontSize="14px">
                {`${subTitle} ${someTimesAgo(createDate)}`}
              </Text>
            )}
          </div>
        )}
      </Box>
    </Box>
  )
}

interface iPriceInput {
  defaultVal?: number
  onBlur: (value: string) => void
  iptType: string
}

const TabItem = styled(Box)<{ isActive: boolean }>`
  cursor: pointer;
  background: ${({ isActive }) => (isActive ? 'rgba(0,178,181,0.08)' : 'transparent')};
  border: ${({ isActive }) => (isActive ? '1px solid rgba(0,178,181,0.08)' : '1px solid #fff')};
  border-radius: 20px;
  padding: 2px 8px;
`

export const FloorPriceBtn: React.FC<{ onFloorPriceBtn: (p: string) => void }> = ({ onFloorPriceBtn }) => {
  const [active, setActive] = useState<boolean>(false)
  const [visibleInput, setVisibleInput] = useState<boolean>(false)
  const [price, setPrice] = useState<string>('0.1')

  const onChange = () => {
    setActive(!active)
    onFloorPriceBtn(active ? '' : price)
  }
  const onInputBlur = (v: string) => {
    // console.log('onInputBlur', visibleInput)
    onFloorPriceBtn(v)
    setPrice(v)
    setActive(true)
    setVisibleInput(false)
  }
  const onIcon = () => {
    setVisibleInput(!active)
    setActive(!active)
  }
  return (
    <TabItem isActive={active} className="fx-row ai-ct beyondHide">
      <Text className="" fontSize="16px" color="#fff" fontWeight="500" onClick={onChange}>
        Floor Price ≤ {!visibleInput ? price : null}
      </Text>
      {!visibleInput && (
        <Image src={active ? close : inputSuffix} height={18} width={18} className="click ml10" onClick={onIcon} />
      )}
      {visibleInput && <FloorPriceInput defaultVal={Number(price)} iptType="number" onBlur={onInputBlur} />}
    </TabItem>
  )
}
export const FloorPriceInput: React.FC<iPriceInput> = ({ defaultVal, onBlur, iptType }) => {
  const [value, setValue] = useState<string>(`${defaultVal}`)

  return (
    <PeweeInput
      min={0}
      max={100}
      allowClear
      bordered
      value={value}
      handleChange={(val: string) => setValue(val)}
      onBlur={() => onBlur(value)}
      className="price_input"
    />
  )
}

// export const FloorPriceContainer:React.FC<{}> = ({}) => {
//   const onFloorPriceBtn = () => {

//   }
//   return (
//     <Box>
//       <FloorPriceBtn onFloorPriceBtn={onFloorPriceBtn}>

//       </FloorPriceBtn>
//     </Box>
//   )
// }

interface iRefreshList {
  onRefresh: () => void
  updatedTime: number
}
export const RefreshList: React.FC<iRefreshList> = ({ onRefresh, updatedTime }) => {
  const { isMobile } = useMatchBreakpoints()

  const [time, setTime] = useState<string>(formatDistanceToNow(updatedTime, { includeSeconds: true, addSuffix: true }))

  // 大于60s  每一分钟刷新一次  小于60s 一秒钟刷新一次

  useInterval(() => {
    const t = formatDistanceToNow(updatedTime, { includeSeconds: true, addSuffix: true })
    setTime(t)
  }, 1000)

  return (
    <Box className="fx-row ai-ct click" ml={['24px', '24px', '4px', '24px']} onClick={onRefresh}>
      {isMobile ? (
        <Image src={refresh} height={12} width={14} className="mr8" />
      ) : (
        <Image src={refresh} height={16} width={19} className="" />
      )}

      {!isMobile && (
        <Text className="fz12 white38" ml="10px">
          {time.includes('years') || updatedTime === 0 ? 'click to refresh' : `updated ${time}`}
        </Text>
      )}
    </Box>
  )
}

interface iFreeMint {
  onChange: (a: boolean) => void
  defaultActive: number
}
export const FreeMint: React.FC<iFreeMint> = ({ onChange, defaultActive }) => {
  const [active, setActive] = useState<boolean>(false)
  useEffect(() => {
    if (defaultActive === 1) setActive(true)
    if (defaultActive === 2) setActive(false)
  }, [])
  const onHandle = () => {
    setActive(!active)
    onChange(!active)
  }
  return (
    <Box className="fx-row ai-ct" ml="40px">
      <Text color="#fff" fontSize="12px" mr="10px">
        Mint Price
      </Text>
      <Box className="price_input center click" onClick={onHandle}>
        <Text color={active ? '#00B2B5' : '#fff'} fontSize="12px">
          Free Mint
        </Text>
      </Box>
    </Box>
  )
}

export const AlignRightCell: React.FC<{ value: number | string; noValue?: string }> = ({ value, noValue = '--' }) => {
  return (
    <Box className="w100 fx jc-end">
      <Text className="white87 fw400" fontSize={['14px', '14px', '16px', '16px']}>
        {Number(value) === 0 ? '0' : value || noValue}
      </Text>
    </Box>
  )
}

interface iAlert {
  alertPrice: string
  triggered: boolean
  triggerTime: number
}
interface iAlertPriceCell {
  alert: iAlert | null
  nftId: number
  visible: boolean
}
export const AlertPriceCell: React.FC<iAlertPriceCell> = ({ alert, nftId, visible }) => {
  const [value, setValue] = useState<string>('')
  const alertPriceInputRef: any = useRef()

  const onPressEnter = async () => {
    if (value === '') {
      await WatchListAlertApi({ nftId, alertPrice: null })
    }
    if (value) {
      const d: any = await WatchListAlertApi({ nftId, alertPrice: Number(value) })
      if (d) {
        OpenNotification('Set alert price successfully', 'success')
      }
    }
  }

  useEffect(() => {
    if (visible && alert) {
      setValue(alert.alertPrice)
    }
  }, [])

  return (
    <Box className="w100 fx jc-end">
      {visible && (
        <PeweeInput
          min={0}
          max={100}
          prefix={
            <Text color="#fff" fontSize="16px" mr="10px">
              Ξ
            </Text>
          }
          ref={alertPriceInputRef}
          value={value}
          onBlur={onPressEnter}
          handleChange={(val: string) => setValue(val)}
          placeholder="0.001"
          className="input_bg input_alert"
        />
      )}
    </Box>
  )
}
