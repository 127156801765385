import React, { useState, useEffect, useRef } from 'react'
import { Row } from '@/components/Layout/Layout'
import { Box } from '@/packages/'
import { Chart } from '@/components/Charts/Chart'
import { MktCapApi } from '@/request/Api'
import { MktCapOptions } from '@/components/Charts/options/index'
import { TitleAndPeriod } from '@/components/TitleAndPeriod'
import { iTabItem } from '@/components/TabListSelected'
import { ChartLegend } from '@/components/ChartLegend'
import { NoData } from '@/components/NoData'

export const MKTCAP = () => {
  const [granularity, setGranularity] = useState<string>('7d')
  const [chartList, setChartList] = useState<any>()
  const [data, setData] = useState<any>(null)
  const [initData, setInitData] = useState<any>(null)
  const [isLoading, setLoading] = useState<boolean>(true)

  const totalRef: any = useRef()
  const nftRef: any = useRef()

  const [period, setPeriod] = useState<Array<iTabItem>>([
    { id: 0, name: '7d', active: true },
    { id: 1, name: '30d', active: false },
    { id: 2, name: '60d', active: false }
  ])

  const fetch = async () => {
    setLoading(true)
    const d: any = await MktCapApi(granularity)
    setLoading(false)
    if (d) {
      const { coin, cryptoMarketCaps, dates, nftMarketCaps } = d
      const c: any = {
        coin,
        dates,
        crypto: {
          list: cryptoMarketCaps
        },
        nft: {
          list: nftMarketCaps
        }
      }
      setData(c)
      setInitData(c)
      setChartList(MktCapOptions(c))
      resetBtnStatus()
    }
  }
  const visibleChart = (open: boolean, type: string) => {
    const _data = JSON.parse(JSON.stringify(data))
    _data[type].list = open ? initData[type].list : []
    setData(_data)
    setChartList(MktCapOptions(_data))
  }

  useEffect(() => {
    fetch()
  }, [granularity])
  const onChangePeriod = (val: iTabItem) => {
    const _period = JSON.parse(JSON.stringify(period))
    _period.forEach((_p: iTabItem) => {
      _p.active = _p.name === val.name
    })
    setPeriod(_period)
    setGranularity(val.name)
  }
  const resetBtnStatus = () => {
    totalRef.current && totalRef.current.resetStatus()
    nftRef.current && nftRef.current.resetStatus()
  }
  return (
    <Box className="fx-col body_wrapper " mt="24px" height="486px">
      <TitleAndPeriod title="NFT & Cryptocurrency Market Cap" periodList={period} onChangePeriod={onChangePeriod} />
      <Row>
        <ChartLegend
          cRef={totalRef}
          type="line"
          bgColor="#A4A4A4"
          title="Crypto Currency Market Cap"
          onChange={(v: boolean) => visibleChart(v, 'crypto')}
        />
        <ChartLegend
          cRef={nftRef}
          type="line"
          bgColor="#34C77B"
          title="NFT Market Cap"
          onChange={(v: boolean) => visibleChart(v, 'nft')}
        />
      </Row>
      <Box className="w100" height="300px" mt="40px">
        {chartList ? <Chart option={chartList} /> : <NoData isLoading={isLoading} />}
      </Box>
    </Box>
  )
}
