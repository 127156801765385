import React, { useState, useEffect, useMemo } from 'react'
import { Text } from '@/packages/'
import { Row } from '@/components/Layout/Layout'
import { useAppContext } from '@/hooks/useAppContext'
import { getRankColor } from '@/utils/tools'

// 平台有Rank的地方，Rank颜色需要修改一下：
// Pink = 1%，Orange = 10%，Tosca = Others

// Rank或总数的1%、10%

// 几个需要修改的地方

// 【Listing under floor列表】【Listings列表】【Sales列表】【Fair Price Estimate图表悬浮文字】

interface iColorRank {
  rankValue: number | string | null
  supplyVal?: number
  onlyValue?: boolean // 仅显示rank值  不显示Rank这个文字
  fz?: string
}
export const ColorRank: React.FC<iColorRank> = ({ rankValue, supplyVal, onlyValue, fz }) => {
  const [color, setColor] = useState<string>('')
  const [rank, setRank] = useState<number>(0)
  const { state, dispatch } = useAppContext()

  useMemo(() => {
    if (state?.LiveViewReducer?.crtSupply) {
      if (rankValue) {
        const supply = supplyVal || state.LiveViewReducer.crtSupply
        setColor(getRankColor(Number(rankValue) || 0, supply))
      }
    } else {
      setColor(getRankColor(Number(rankValue) || 0, Number(supplyVal)))
    }
  }, [state?.LiveViewReducer?.crtSupply, supplyVal])

  return (
    <Row>
      {!onlyValue && <Text className="fz14 white60 fw400">Rank:</Text>}
      <Text
        className="fw600"
        fontSize={fz || '14px'}
        ml={onlyValue ? '0px' : '4px'}
        style={{ color: rankValue ? color : '#929292' }}
      >
        {rankValue || '--'}
      </Text>
    </Row>
  )
}
