import { valumeUnit, timeFormate, unitTime, toNonExponential } from '@/utils/tools'
import {
  ChartBgColor,
  axisLabel2,
  grid,
  axisLine,
  axisTick,
  splitLine,
  barColor,
  lineColor1,
  nameTextStyle,
  tooltipBg
} from './types'

export const MarketCap30DOption = (params: any): any => {
  const { coin, dates, marketCap, marketCaps, volume, volumes } = params
  return {
    backgroundColor: ChartBgColor, // 图表背景色
    tooltip: {
      backgroundColor: tooltipBg,
      borderRadius: 12,
      borderWidth: 0,
      padding: 0,
      borderColor: 'transparent',
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      },
      formatter(value: any) {
        let temp = ''
        if (value && value.length > 0) {
          if (value.length === 2) {
            // Apr 13, 10:00
            const time = unitTime(value[0].axisValue)
            temp = `
              <div  id="custom_tooltip_container">
                <div id="tooltip">${time}</div>
                <div id="tooltip" >Volume: Ξ ${valumeUnit(value[1].value)}</div>
                <div id="tooltip" >Market Cap: Ξ ${valumeUnit(value[0].value)}</div>
              </div>
            `
          }
          if (value.length === 1) {
            const time = unitTime(value[0].axisValue)
            temp = `
              <div id="custom_tooltip_container">
                <div id="tooltip">${time}</div>
                <div id="tooltip">
                  ${value[0].seriesName === 'MarketCaps' ? 'Market Cap: Ξ ' : 'Volume: Ξ '}
                  ${valumeUnit(value[0].value)}
                </div>
              </div>
            `
          }
        }
        return temp
      }
    },
    xAxis: {
      name: '',
      data: dates,
      axisLabel: {
        show: true,
        textStyle: {
          color: 'rgba(255, 255, 255, 0.6);',
          fontSize: 14
        },
        formatter(value: any) {
          return `${timeFormate(Number(value), true)}`
        }
      },
      axisLine,
      axisTick
    },
    yAxis: [
      {
        type: 'value',
        name: 'MARKET CAP Ξ',
        nameTextStyle,
        nameLocation: 'center',
        splitLine,
        axisLabel: axisLabel2
      },
      {
        type: 'value',
        name: 'Ξ VOLUME',
        nameTextStyle,
        nameLocation: 'center',
        splitLine,
        axisLabel: axisLabel2
      }
    ],
    grid,
    series: [
      {
        name: 'MarketCaps',
        type: 'bar',
        // barWidth, // 柱状图固定宽度 也可设置最大宽度 最小宽度  barMaxWidth barMinWidth
        barMinWidth: 10,
        barMaxWidth: 50,
        color: barColor,
        itemStyle: {
          borderRadius: [2, 2, 0, 0]
        },
        data: marketCaps
      },
      {
        name: 'Volumes',
        type: 'line',
        color: lineColor1,
        data: volumes,
        yAxisIndex: 1
      }
    ]
  }
}
