import React, { useState, useEffect, ChangeEvent, useImperativeHandle } from 'react'
import { Input as AntInput } from 'antd'
import { decimalNum } from '@/utils/tools'
import useDebounce from '@/hooks/useDebounce'
import { PeweeInput } from '@/components/PeweeInput'

export interface iMobileNumberInput {
  placeholder: string
  onChange: (v: string) => void
  inputRef?: any
  suffix?: React.ReactNode
  prefix?: React.ReactNode
  editValue?: string
  defaultVal?: string
  min: number
  max: number
  fixed?: number
}
export const MobileNumberInput: React.FC<iMobileNumberInput> = (props) => {
  const { placeholder, onChange, inputRef, suffix, prefix, editValue, defaultVal, min, max, fixed } = props
  const [value, setValue] = useState<string>(defaultVal || '')
  const debouncedValue = useDebounce<string>(value, 500)
  useEffect(() => {
    if (debouncedValue) {
      onChange(value)
    }
  }, [debouncedValue])

  useEffect(() => {
    if (editValue) {
      setValue(editValue)
    }
    return () => setValue('')
  }, [editValue])

  useImperativeHandle(inputRef, () => ({
    clearInputValue: () => {
      setValue('')
      onChange('')
    }
  }))

  return (
    <PeweeInput
      fixed={fixed}
      min={min}
      max={max}
      prefix={prefix}
      suffix={suffix}
      bordered
      defaultValue={defaultVal}
      value={value}
      handleChange={(val: string) => setValue(val)}
      placeholder={placeholder}
      className="mobile_input w100"
    />
  )
}
