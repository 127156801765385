import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { useAppContext } from '@/hooks/useAppContext'
import { Box, Text } from '@/packages'
import { Chart } from '@/components/Charts/Chart'
import { ListingDepthOption, ListingDepthLadderOption } from '@/components/Charts/options/index'
import { Row, Col, ListingCardTitle } from '@/components/Layout/Layout'
import { ListingDepthApi } from '@/request/Api'
import { DropdownSelect } from '@/components/'
import { SwitchButton } from '@/components/SwitchButton'
import { NoData } from '@/components/NoData'
import { useMatchBreakpoints } from '@/hooks/useMatchBreakpoints'

const Container = styled(Box)`
  height: 396px;
`

export const ListingDepth = () => {
  const { state, dispatch } = useAppContext()
  const [groupSizeVal, setGroupSizeVal] = useState(0.1)
  const [options, setOptions] = useState<any>(null)
  const [depth, setDepth] = useState<Array<any>>([])
  const [isOpen, setOpen] = useState<boolean>(false)
  const [total, setTotal] = useState<number | string>(0)
  const [isLoading, setLoading] = useState<boolean>(true)
  const { isMobile, isTablet } = useMatchBreakpoints()

  const params = useParams()
  const fetch = async () => {
    setLoading(true)
    const data: any = await ListingDepthApi({
      groupSize: groupSizeVal,
      nftId: Number(params.nftId),
      groupCount: 6
    })
    setLoading(false)
    if (data) {
      setDepth(data.depthPoints)
    }
  }

  useEffect(() => {
    fetch()
  }, [groupSizeVal])
  const groupSizeChange = (value: string) => {
    setGroupSizeVal(Number(value))
  }

  useEffect(() => {
    setOptions(isOpen ? ListingDepthLadderOption(depth) : ListingDepthOption(depth))
  }, [isOpen, depth])

  useEffect(() => {
    if (state?.LiveViewReducer?.listingTotal) {
      setTotal(state.LiveViewReducer.listingTotal)
    }
    ;() => {
      setTotal(0)
    }
  }, [state?.LiveViewReducer?.listingTotal])
  return (
    <Container
      mt="28px"
      className="body_wrapper"
      mb={isMobile || isTablet ? '24px' : '0px'}
      px={['16px', '16px', '24px', '24px']}
    >
      <Col>
        <Row mt="20px">
          <ListingCardTitle title="Listing Depth" count={Number(total)} />
        </Row>
        <Row className="ai-ct" mt="12px">
          <SwitchButton title="Ladder" onChange={() => setOpen(!isOpen)} defaultStatus={false} />
          <Row ml="40px">
            <Text color="rgba(255, 255, 255, 0.87)" fontSize="12px" mr="10px">
              Group Size:
            </Text>
            <DropdownSelect
              icon="Ξ "
              onChange={groupSizeChange}
              initVal="0.1"
              dataSource={['0.001', '0.002', '0.005', '0.01', '0.02', '0.05', '0.1', '0.2', '0.5', '1', '2', '5']}
            />
          </Row>
        </Row>
      </Col>
      <Box height="254px" className="" mt="20px">
        {depth.length > 0 ? (
          <Chart option={options} />
        ) : (
          <NoData isLoading={isLoading} msg="No listings in the project." />
        )}
      </Box>
    </Container>
  )
}
