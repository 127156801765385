import React from 'react'
import { Box, Text } from '@/packages/'

interface iInfoText {
  title: string
  value: string | number
  children?: React.ReactNode
}
export const InfoTextRow: React.FC<iInfoText> = ({ title, value, children }) => {
  return (
    <Box className="fx-row ai-ct" ml="28px">
      <Text color=" rgba(255, 255, 255, 0.38);" fontSize="14px" fontWeight={500} mr="6px">
        {title}
      </Text>
      <Text color=" rgba(255, 255, 255, 0.87)" fontSize="16px" fontWeight={700} mr="6px">
        {value}
      </Text>
      {children}
    </Box>
  )
}
