import React, { useState, useEffect } from 'react'
import { Spin } from 'antd'
import { Box, Text } from '@/packages/'
import { ContentWrapper } from '@/components/Layout/Layout'

interface iNoData {
  isLoading: boolean | undefined
  msg?: string
}

export const NoData: React.FC<iNoData> = ({ isLoading, msg }) => {
  return (
    <ContentWrapper>
      <Box className="w100 center " mt="48px">
        <Spin spinning={isLoading} />
        {isLoading === false && (
          <Text color="rgba(255, 255, 255, 0.38)" fontSize="16px">
            {msg || 'No data available'}
          </Text>
        )}
      </Box>
    </ContentWrapper>
  )
}
