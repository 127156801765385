import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Popover } from 'antd'
import { formControlLabelClasses } from '@mui/material'
import { NftInfoAttentionApi, changeReportApi } from '@/request/Api'
import { Box, Image, Text } from '@/packages/'
import {
  copyIcon,
  copyed,
  isOS,
  suspicious,
  starIcon,
  starActiveIcon,
  arrowDown,
  arrowUp,
  discordActive,
  discord,
  etherscanActive,
  etherscan,
  osActive,
  os,
  twitterActive,
  twitterUrl,
  website,
  websiteActive,
  flagActiveUrl,
  flagUrl
} from '@/assets/img'
import { Row, Col, NFTImage, PeweeTooltip } from '@/components/Layout/Layout'
import { iNFTVO } from '@/types/store/index'
import { openWindow, someTimesAgo, beyondStr, accMul, splitAddress, toNonExponential } from '@/utils/tools'
import useCopyToClipboard from '@/hooks/useCopyToClipboard'
import { NftInfoAction } from '@/store/actions/LiveviewAction'
import { useAppContext } from '@/hooks/useAppContext'
import { ActiveIcon } from '@/components/ActiveIcon'
import { NFTInfoMobile } from './NFTInfoMobile'
import { useMatchBreakpoints } from '@/hooks/useMatchBreakpoints'
import { OpenNotification } from '@/components/OpenNotification'

interface iLinkImg {
  url: string
  targetLink: string
  w?: number
  h?: number
}
interface iAddressItem {
  address: string
}

interface iWatchProject {
  watched: number
  nftId: number
}

const AddressWrapper = styled(Box)`
  background: rgba(255, 255, 255, 0.08);
  height: 30px;
  padding: 6px 12px;
  border-radius: 60px;
`
const CopyedPopover = () => {
  return (
    <Box className="fx-row ai-ct jc-ct copy_popover" height="33px" width="88px" background="#242931">
      <Image src={copyed} width={13} height={13} />
      <Text color="#929395" fontSize="13px" ml="4px">
        Copied!
      </Text>
    </Box>
  )
}
export const AddressItem: React.FC<iAddressItem> = ({ address }) => {
  const [value, copy] = useCopyToClipboard()
  const [copyVisible, setCopyVisible] = useState<boolean>(false)
  const onCopy = () => {
    copy(address)
    setCopyVisible(true)
    setTimeout(() => {
      setCopyVisible(false)
    }, 3000)
  }
  return (
    <AddressWrapper className="fx-row ai-ct" mt="10px" width={[184, 346, 224, 364]}>
      <Text fontSize="12px" color="#A0A1A3" width={[160, 322, 200, 340]} className="beyondHide">
        {address}
      </Text>
      <Popover placement="bottom" visible={copyVisible} content={<CopyedPopover />}>
        <Image
          src={copyIcon}
          onClick={address ? () => onCopy() : () => {}}
          height={16}
          width={16}
          className="ml8 click"
        />
      </Popover>
    </AddressWrapper>
  )
}

const RevealedWrapper = styled(Box)`
  border-radius: 38px;
  margin-left: 10px;
  border-width: 1px;
  border-style: solid;
`
interface iRevealedValues {
  bg: string
  bdColor: string
  content: string
  color: string
}
export const RevealedBtn: React.FC<{ rankPercent: number; report: number; revealed: number; nftId: number }> = ({
  rankPercent,
  report,
  revealed,
  nftId
}) => {
  const [flagActive, setFlagActive] = useState<boolean>(false) // 设置falg状态
  const [isFlag, setFlag] = useState<boolean>(false) // 是否显示flag图标
  const [clickable, setClickable] = useState<boolean>(false) // 是否 可点击改变状态
  const [title, setTitle] = useState<string>('')
  // rankPercent 百分比
  // report 0 => 已查询项目 隐藏 Revealed
  // 1 => 未查询项目 显示可点击Unrevealed
  // 2 => 已在查询中项目 显示 不可点击 XX% Revealed
  //   开图状态分为三种：Unrevealed、XX% Revealed、Revealed。
  // 新增报告图标：仅Unrevealed、XX% Revealed两种状态旁边有报告图标。
  // （1）未点击状态的图标：当鼠标悬停时，提示"Apply for increasing update priority."
  // （2）已点击状态的图标：当鼠标悬停时，提示"Update priority increased. Update application is in progress." (此处文案与UI图不一致)
  // 图标状态变化逻辑：
  // （1）当报告图标为已点击状态时，后台处理完该更新申请后，更新/保持原开图进度，图标即变为未点击状态。当图标变为未点击状态时，方可再次点击图标提高更新优先级。
  // （2）每个项目的图标仅支持点击一次。例如：当XYZ项目的报告图标被A用户点击后，B用户查看该XYZ项目时，报告图标仍显示为已点击状态的图标。
  // （3）图标点击后不支持再次点击取消。

  const [values, setValues] = useState<iRevealedValues>({
    bg: '',
    bdColor: '',
    content: '',
    color: ''
  })

  const changeReport = async () => {
    const d = await changeReportApi(nftId)
    if (d) {
      OpenNotification('Operation succeeded', 'success')
      setFlagActive(true)
      setClickable(false)
      setTitle('Update priority increased. Update application is in progress.')
    } else {
      OpenNotification('Operation failed', 'error')
      setFlagActive(false)
      setClickable(true)
      setTitle('Apply for increasing update priority.')
    }
  }

  useEffect(() => {
    ;() => {
      setClickable(false)
    }
  }, [])
  useEffect(() => {
    if (revealed === 0) {
      // revealed 0 未开图
      setValues({
        bg: '#252525',
        bdColor: '#474747',
        content: 'Unrevealed',
        color: 'rgba(255, 255, 255, 0.6)'
      })
      setFlag(true)
      if (report === 0 || report === 2) {
        // 0 2 不可点击
        setFlagActive(true)
        setClickable(false)
        setTitle('Update priority increased. Update application is in progress.')
      }
      if (report === 1) {
        // 1 可点击
        setClickable(true)
        setFlagActive(false)
        setTitle('Apply for increasing update priority.')
      }
    }

    // revealed === 1 & 0 < rankPercent < 1  ===>>  `${rankPercent * 100}% Revealed`
    if (revealed === 1) {
      //  1 已开图
      if (rankPercent === 1) {
        //  完全开图 100%
        setFlag(false)
        setFlagActive(false)
        setTitle('')
        setClickable(false)
        setValues({
          bg: 'rgba(0, 178, 181, 0.08)',
          bdColor: '#00b2b5',
          content: 'Revealed',
          color: '#00B2B5'
        })
      } else {
        // 部分开图
        setFlag(true)
        console.log('rankPercent111', rankPercent)
        setValues({
          bg: 'rgba(255, 135, 21, 0.08)',
          bdColor: 'rgba(255, 135, 21, 0.38)',
          content: `${accMul(rankPercent, 100)}% Revealed`,
          color: '#FF8715'
        })
        if (report === 0 || report === 2) {
          // 0 2 不可点击
          setFlagActive(true)
          setClickable(false)
          setTitle('Update priority increased. Update application is in progress.')
        }
        if (report === 1) {
          //  1 可点击
          setClickable(true)
          setFlagActive(false)
          setTitle('Apply for increasing update priority.')
        }
      }
    }
  }, [rankPercent, report, revealed, nftId])

  const handleReveale = () => {
    if (clickable) {
      changeReport()
    }
  }

  return (
    <PeweeTooltip title={title}>
      <RevealedWrapper
        px={['8px', '8px', '8px', '8px']}
        py={['4px', '4px', '4px', '4px']}
        className="fx-row ai-ct click"
        background={values.bg}
        borderColor={values.bdColor}
        onClick={handleReveale}
      >
        <Text color={values.color} className="beyondHide" fontSize={['14px', '14px', '10px', '10px']}>
          {values.content}
        </Text>
        {isFlag && (
          <Box ml={['8px', '8px', '6px', '6px']} className="center">
            <Image src={flagActive ? flagActiveUrl : flagUrl} width={8} height={10} />
          </Box>
        )}
      </RevealedWrapper>
    </PeweeTooltip>
  )
}

export const LinkImg: React.FC<iLinkImg> = ({ url, targetLink, w, h }) => {
  return (
    <Image src={url} height={h || 20} width={w || 20} onClick={() => openWindow(targetLink)} className="mr10 click" />
  )
}

export const WatchProject: React.FC<iWatchProject> = ({ watched, nftId }) => {
  const { state, dispatch } = useAppContext()
  const [isWatch, setWatch] = useState<boolean>(false)
  useEffect(() => {
    if (watched === 1) {
      setWatch(true)
    }
  }, [watched])
  const handleWatch = async () => {
    const d: any = await NftInfoAttentionApi(nftId, isWatch ? 0 : 1)
    if (d) {
      setWatch(!isWatch)
      NftInfoAction(d.nftVO, dispatch)
    }
  }

  return (
    <Box
      height={['30px', '30px', '40px', '40px']}
      width={['30px', '30px', '40px', '40px']}
      background="#2E2E2E"
      className="circle center click"
      onClick={handleWatch}
    >
      <Image src={isWatch ? starActiveIcon : starIcon} height={14} width={14} />
    </Box>
  )
}
export const NFTInfo: React.FC<iNFTVO> = (props) => {
  const [moreDesc, setMoreDesc] = useState<boolean>(false)
  const { isMobile } = useMatchBreakpoints()
  const {
    nftId,
    name,
    nftAddresses,
    imageUrl,
    largeImageUrl,
    bannerImageUrl,
    officialUrl,
    discordUrl,
    twitterUsername,
    createDate,
    slug,
    revealed,
    attention,
    verifyStatus,
    description,
    type,
    rankPercent,
    report
  } = props

  return (
    <div>
      {isMobile ? (
        <NFTInfoMobile {...props} />
      ) : (
        <Row height="234px" className="h100 fx-row ai-ct body_wrapper">
          <NFTImage src={imageUrl} height={156} width={156} iconHW={58} classN="circle" />
          <Col className="w100" ml={[10, 10, 0, 10]}>
            <Box className="fx-col ">
              <Row className="jc-sb  w100">
                <Row>
                  <Text fontSize={[24, 24, 28, 32]} color="#ffffff" className="beyondHide">
                    {beyondStr(name, 20)}
                  </Text>
                  {verifyStatus && verifyStatus === 1 ? (
                    <Image src={isOS} height={24} width={24} className="ml10" />
                  ) : // <LinkImg url={openseaLink} targetLink={`https://opensea.io/collection/${slug}`} />
                  null}
                  {type && type === 1 ? (
                    <PeweeTooltip title="This is a suspicious project.">
                      <Image src={suspicious} height={22} width={22} className="ml4" />
                    </PeweeTooltip>
                  ) : null}
                  <RevealedBtn
                    rankPercent={Number(String(rankPercent).substring(0, 5))}
                    report={report}
                    revealed={revealed}
                    nftId={nftId}
                  />
                  <Text color="rgba(255, 255, 255, 0.38)" fontSize="12px" ml="8px" mr="4px">{`Deployed ${someTimesAgo(
                    createDate
                  )}`}</Text>
                </Row>
                <WatchProject watched={attention} nftId={nftId} />
              </Row>
              <AddressItem address={nftAddresses ? nftAddresses[0] : '-'} />
              <Row mt="16px">
                <ActiveIcon
                  src={os}
                  activeSrc={osActive}
                  targetUrl={`https://opensea.io/collection/${slug}`}
                  tooltip="View on Opensea"
                />
                <ActiveIcon src={website} activeSrc={websiteActive} targetUrl={officialUrl} tooltip="Website" />
                <ActiveIcon
                  src={etherscan}
                  activeSrc={etherscanActive}
                  targetUrl={`https://etherscan.io/address/${nftAddresses}`}
                  tooltip="View on Etherscan"
                />
                <ActiveIcon
                  src={twitterUrl}
                  activeSrc={twitterActive}
                  targetUrl={`https://twitter.com/${twitterUsername}`}
                  tooltip="Twitter"
                />
                <ActiveIcon src={discord} activeSrc={discordActive} targetUrl={discordUrl} tooltip="Discord" />
              </Row>
            </Box>

            <Text
              className=""
              mt="16px"
              height="62px"
              fontSize="14px"
              color="rgba(255, 255, 255, 0.87)"
              title={description}
            >
              {beyondStr(description, moreDesc ? 260 : 66)}
              <Row className="click" onClick={() => setMoreDesc(!moreDesc)}>
                <Text mr="6px" fontSize="12px" color="rgba(255, 255, 255, 0.6)">
                  {moreDesc ? 'See less' : 'See more'}
                </Text>
                <Image src={moreDesc ? arrowUp : arrowDown} width={8} height={4} />
              </Row>
            </Text>
          </Col>
        </Row>
      )}
    </div>
  )
}
