import React, { createContext } from 'react'
import { AppActionType } from '@/types/store'
import { AppInitState } from '@/store/reducers'

export const StoreContext = createContext('store')

export const AppContext = createContext<{
  state: any
  dispatch: React.Dispatch<AppActionType>
}>({
  state: AppInitState,
  dispatch: () => undefined
})
