import axios from 'axios'
import { errorCode } from '@/utils/errorCode'
import { ObjType } from './types'
import { debounce, locaStoreageGetItem, locaStoreageRemoveItem, locaStoreageSetItem } from '@/utils/tools'
import { OpenNotification } from '@/components/OpenNotification'

// console.log('node===', process.env)

let BASE_URL: string
let LOGIN_BASE_URL: string

if (process.env.REACT_APP_PEWEE_ENV === 'dev') {
  LOGIN_BASE_URL = 'https://www.pre.xingjigangwan.com/'
  BASE_URL = '/'
}
if (process.env.REACT_APP_PEWEE_ENV === 'pre') {
  LOGIN_BASE_URL = 'https://www.pre.xingjigangwan.com/'
  BASE_URL = 'https://www.pre.xingjigangwan.com/'
}
if (process.env.REACT_APP_PEWEE_ENV === 'prod') {
  BASE_URL = 'https://www.pewee.tools/'
  LOGIN_BASE_URL = 'https://www.pewee.tools/'
}

const getToken = () => {
  const t = locaStoreageGetItem('tokens')
  return t?.accessToken || null
}

const axiosConfig: any = {
  // baseURL: BASE_URL,
  timeout: 60000
}

function refreshTokenFunc(instance: any, refreshSuccessCallback: any) {
  const url = '/au/api/v1/token/refresh'
  const t = locaStoreageGetItem('tokens')

  instance
    .post(
      url,
      { refreshToken: t.refreshToken },
      {
        headers: {
          Channel: 'WEB',
          Authorization: null
        }
      }
    )
    .then((response: any) => {
      if (response && response.status === 200) {
        const { data } = response
        if (data.code === '000000') {
          // console.log('刷新 accessToken', data.data.accessToken)
          locaStoreageSetItem('tokens', data.data)
          setTimeout(() => {
            refreshSuccessCallback()
          }, 500)
        }
        if (data.code === '000004') {
          locaStoreageRemoveItem('tokens')
          setTimeout(() => {
            window.location.reload()
          }, 100)
        }
      }
    })
}

export const Http = (apiUrl: string, sendData: ObjType, method: string, isGetQuery?: boolean) => {
  if (getToken()) {
    axiosConfig.baseURL = BASE_URL
    axios.defaults.headers.common.Authorization = `Bearer ${getToken()}`
  } else {
    axiosConfig.baseURL = LOGIN_BASE_URL
  }
  const instance: any = axios.create(axiosConfig)

  instance.interceptors.request.use(
    (config: any) => {
      return config
    },
    (err: any) => {
      return Promise.resolve(err)
    }
  )

  let _sendData = sendData
  if (method === 'delete' || isGetQuery) _sendData = { params: sendData }

  const promise = new Promise(function (resolve, reject) {
    instance[method](apiUrl, _sendData)
      .then((response: any) => {
        // console.log('response.config1111', response)
        if (response && response.status === 200) {
          const { data } = response
          // console.log('response.data', data)
          if (data.code === '000000') {
            if (data.data === 0) {
              resolve(0)
            } else {
              resolve(data.data || {})
            }
          } else if (data.code === '990004') {
            // 用户不存在
            resolve({ code: data.code, msg: data.msg })
          } else if (data.code === '000004') {
            refreshTokenFunc(instance, () => {
              window.location.reload() // View层没有触发fetch方法  新请求的数据无法在页面上更新
              // response.config.headers.Authorization = `Bearer ${getToken()}`
              // Promise.all([() => instance(response.config)].map((cb) => cb()))
            })
          } else if (data.code === '111009') {
            OpenNotification('Your favorite has exceeded the limit of 20.', 'warning')
          } else {
            console.log('errorCode==', errorCode[data.code])
            resolve(false)
          }
        } else {
          resolve(false)
        }
      })
      .catch((error: any) => {
        resolve(false)
      })
  })
  return promise
}
