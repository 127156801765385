import { DefaultTheme } from 'styled-components'
import { commonColors } from './common'

export const lightTheme: DefaultTheme = {
  isDark: false,
  colors: {
    ...commonColors,
    main: '#FFFFFF',
    primary1: '#006A6B',
    primary2: '#FFFFFF',
    primary3: '#4AFAFA',
    primary4: '#4AFAFA',
    secondary1: '#496363',
    secondary2: '#FFFFFF',
    secondary3: '#CCE8E7',
    secondary4: '#041F1F',
    tertiary1: '#4B607C',
    tertiary2: '#FFFFFF',
    tertiary3: '#D2E4FF',
    tertiary4: '#041C35',
    error1: '#BA1B1B',
    error2: '#FFFFFF',
    error3: '#FFDAD4',
    error4: '#FFDAD4',
    bg1: '#191C1C',
    bg2: '#E0E3E2',
    bg3: '#191C1C',
    bg4: '#E0E3E2',
    bg5: '#3F4848',
    bg6: '#BEC9C8',
    bg7: '#889392',
    bg8: '#4CDADB',
    bg9: '#3F494A',
    bg10: '#242929',

    // border color
    focus: '#C4C0FF'
  }
}
