import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Table as AntTable, Spin } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { Box, Text, Image } from '@/packages/'
import { CollectionItem, AlignRightCell, ChangeCell } from './components'
import { CollectionsV2Api } from '@/request/Api'
import { iCollectionsV2Prams } from '@/request/types'
import { IncreaseText, iText } from '@/components/IncreaseText'
import { Chart } from '@/components/Charts/Chart'
import { LiveView7DayVolumeOption } from '@/components/Charts/options/index'
import { Row, Col, ETHValue } from '@/components/Layout/Layout'
import { changeUp, changeDown } from '@/assets/img'
import { toNonExponential, addId4Array } from '@/utils/tools'
import { useAppContext } from '@/hooks/useAppContext'
import { NoData } from '@/components/NoData'
import { useMatchBreakpoints } from '@/hooks/useMatchBreakpoints'

export const Trending = () => {
  const [list, setList] = useState<Array<any>>([])
  const [query, setQuery] = useState<iCollectionsV2Prams>({
    pageIndex: 1,
    pageSize: 10,
    granularity: '',
    order: {
      asc: false,
      column: 'floorChange' // floor, floor change, sales, volume
    }
  })
  const [isLoading, setLoading] = useState<boolean>(true)
  const { state, dispatch } = useAppContext()
  const { isMobile, isTablet } = useMatchBreakpoints()
  let columns: any = []

  if (isTablet || isMobile) {
    columns = [
      {
        title: 'COLLECTION',
        dataIndex: 'collection',
        width: '48%',
        render: (item: any) => <CollectionItem {...item} subTitle="Deployed" hideOfficialUrl />
      },
      {
        defaultSortOrder: 'descend' as 'descend',
        title: <Text className="fw700 fz12 white beyondHide">FLOOR %</Text>,
        sorter: true,
        showSorterTooltip: false,
        width: '27%',
        sortDirections: ['descend' as 'descend', 'ascend' as 'ascend', 'descend' as 'descend'],
        render: (item: any) => (
          <Col>
            <Box className="w100 fx jc-end">
              <ETHValue value={toNonExponential(item.floor, 3)} />
            </Box>
            <ChangeCell increase={item.floorChange.increase} percent={item.floorChange.percent || 0} />
          </Col>
        )
      },

      {
        title: 'SALES',
        dataIndex: 'sales',
        width: '25%',
        render: (item: number) => <AlignRightCell value={item} />,
        sortDirections: ['descend' as 'descend', 'ascend' as 'ascend', 'descend' as 'descend'],
        sorter: true,
        showSorterTooltip: false
      }
    ]
  } else {
    columns = [
      {
        title: 'COLLECTION',
        dataIndex: 'collection',
        width: '20%',
        render: (item: any) => <CollectionItem {...item} subTitle="Deployed" />
      },
      {
        title: 'FLOOR',
        dataIndex: 'floor',
        sorter: true,
        showSorterTooltip: false,
        sortDirections: ['descend' as 'descend', 'ascend' as 'ascend', 'descend' as 'descend'],
        render: (item: number) => <AlignRightCell value={`Ξ ${toNonExponential(item)}`} />
      },
      {
        title: 'FLOOR CHANGE',
        dataIndex: 'floorChange',
        defaultSortOrder: 'descend' as 'descend',
        sortDirections: ['descend' as 'descend', 'ascend' as 'ascend', 'descend' as 'descend'],
        render: (item: iText) => <ChangeCell increase={item.increase} percent={item.percent || 0} />,
        sorter: true,
        showSorterTooltip: false
      },
      {
        title: 'SALES',
        dataIndex: 'sales',
        render: (item: number) => <AlignRightCell value={item} />,
        sortDirections: ['descend' as 'descend', 'ascend' as 'ascend', 'descend' as 'descend'],
        sorter: true,
        showSorterTooltip: false
      },
      {
        title: 'VOLUME',
        dataIndex: 'volume',
        render: (item: number) => <AlignRightCell value={`Ξ ${toNonExponential(item)}`} />,
        sortDirections: ['descend' as 'descend', 'ascend' as 'ascend', 'descend' as 'descend'],
        sorter: true,
        showSorterTooltip: false
      },
      {
        title: '7D VOLUME',
        dataIndex: 'volumes',
        render: (item: Array<number>) =>
          item ? (
            <Box className="w100 fx jc-end">
              <Box width="80px" height="40px">
                <Chart option={LiveView7DayVolumeOption(item)} />
              </Box>
            </Box>
          ) : (
            <div>--</div>
          )
      }
    ]
  }

  useEffect(() => {
    if (state.DashbordReducer.period) {
      const { tabName, trendingPeriod } = state.DashbordReducer.period
      // console.log('1111', tabName, trendingPeriod)
      tabName === 'Trending' && setQuery((q: iCollectionsV2Prams) => ({ ...q, granularity: trendingPeriod }))
    }
  }, [state?.DashbordReducer?.period])

  useEffect(() => {
    if (state.DashbordReducer.refreshTable) {
      const { activeTable } = state.DashbordReducer.refreshTable
      activeTable === 'Trending' && fetch()
    }
  }, [state?.DashbordReducer?.refreshTable])
  useEffect(() => {
    setQuery((q: iCollectionsV2Prams) => ({
      ...q,
      maxFloor: state.DashbordReducer.trendingPrice ? Number(state.DashbordReducer.trendingPrice) : null
    }))
  }, [state?.DashbordReducer?.trendingPrice])

  const fetch = async () => {
    if (query.granularity) {
      setList([])
      setLoading(true)
      const d: any = await CollectionsV2Api(query)
      setLoading(false)
      if (d && d.records && !!d.records.length) {
        // floor change 为0的数据 不显示在table
        // let newRecords: Array<any> = []
        // d.records.forEach((item: any) => item.floorChange.percent !== 0 && newRecords.push(item))
        // if (newRecords.length > 10) {
        //   newRecords = newRecords.splice(0, 11)
        // }
        setList(addId4Array(d.records))
      }
    }
  }

  useEffect(() => {
    fetch()
  }, [query])

  const handleTableChange = (pagination: any, filters: any, sorter: any, extra: any) => {
    // console.log('sorter', sorter)
    const { field, order } = sorter // order:"ascend"=> up "descend" => down
    const p = JSON.parse(JSON.stringify(query))
    p.order.column = field
    p.order.asc = order === 'ascend'
    setQuery(p)
  }

  return (
    <Box className="w100" height="420px" background="rgba(255, 255, 255, 0.04)">
      <AntTable
        locale={{ emptyText: ' ' }}
        rowKey={(record) => record.id}
        dataSource={list}
        pagination={false}
        columns={columns}
        onChange={handleTableChange}
        style={{ marginTop: 20 }}
        scroll={{ y: 370 }}
        className="hide_scrollbar"
      />
      {!list.length && <NoData isLoading={isLoading} />}
    </Box>
  )
}
