import React, { useState, useEffect, useRef, useCallback } from 'react'
import styled from 'styled-components'
import { Modal } from 'antd'
import { Flex, Box, Grid, Text, Input, Image } from '@/packages/'
import { useAppContext } from '@/hooks/useAppContext'
import { Col, Row, BgWrapper, Divider } from '@/components/Layout/Layout'
import { ListingFilterAction, TraitModalAction } from '@/store/actions/LiveviewAction'
import { delQueryFalse, isEmptyObj, delEmptyObj } from '@/utils/tools'
import { traits, arrowRight, close } from '@/assets/img'
import { NumberInput } from '@/components/NumberInput'
import { MaskContainer } from '@/components/MaskContainer'
import { MobileInput } from '@/components/Mobile/MobileInput'
import { InputGroup } from '@/components/Mobile/MobileInput/InputGroup'
import { MobileModalFooter } from '@/components/Mobile/MobileModalFooter'

export const FilterBtn = styled(Box)`
  width: 64px;
  height: 30px;
  background: rgba(255, 255, 255, 0.12);
  border-radius: 20px;
  font-weight: 600;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.38);
`

export const FilterListingMobile = () => {
  const { state, dispatch } = useAppContext()
  const [minPrice, setMinPrice] = useState<number | string>(0)
  const [maxPrice, setMaxPrice] = useState<number | string>(0)
  const [minRank, setMinRank] = useState<number | string>(0)
  const [maxRank, setMaxRank] = useState<number | string>(0)
  const [tokenId, setTokenId] = useState<number | string>(0)
  const [traitCount, setTraitCount] = useState(0)
  const [traitQueries, setTraitQueries] = useState<Array<[]>>([])
  const [filterVisible, setFilterVisible] = useState<boolean>(false)
  const [filterActive, setFilterActive] = useState<boolean>(false)
  const tokenIdRef: any = useRef()
  const minRefPrice: any = useRef()
  const maxRefPrice: any = useRef()
  const minRefRank: any = useRef()
  const maxRefRank: any = useRef()

  const [defaultQuery, setQuery] = useState<any>({
    minPrice: '',
    maxPrice: '',
    minRank: '',
    maxRank: '',
    tokenId: ''
  })
  const onChangeMin = (val: string) => {
    setMinPrice(val ? Number(val) : '')
  }
  const onChangeMax = (val: string) => {
    setMaxPrice(val ? Number(val) : '')
  }
  const onChangeRankMin = (val: string) => {
    setMinRank(val ? Number(val) : '')
  }
  const onChangeRankMax = (val: string) => {
    setMaxRank(val ? Number(val) : '')
  }
  const onChangeTokenId = (val: string) => {
    setTokenId(val ? Number(val) : '')
  }

  const onClear = () => {
    tokenIdRef.current && tokenIdRef.current.clearInputValue()
    minRefPrice.current && minRefPrice.current.clearInputValue()
    maxRefPrice.current && maxRefPrice.current.clearInputValue()
    minRefRank.current && minRefRank.current.clearInputValue()
    maxRefRank.current && maxRefRank.current.clearInputValue()
    if (traitQueries.length > 0) {
      ListingFilterAction({ traitQueries }, dispatch)
    } else {
      ListingFilterAction({}, dispatch)
    }
  }
  const onSave = useCallback(() => {
    fetch()
    onFilterVisible(false)
  }, [minPrice, maxPrice, minRank, maxRank, tokenId, traitQueries])

  useEffect(() => {
    if (minPrice || maxPrice || minRank || maxRank || tokenId) {
      onFilterActive(true)
    } else {
      onFilterActive(false)
    }
  }, [minPrice, maxPrice, minRank, maxRank, tokenId])

  useEffect(() => {
    fetch()
  }, [traitQueries])

  const fetch = async () => {
    setQuery({
      tokenId,
      minPrice,
      maxPrice,
      minRank,
      maxRank
    })
    const p = {
      tokenId,
      priceRange: {
        min: minPrice,
        max: maxPrice
      },
      rankRange: {
        min: minRank,
        max: maxRank
      },
      traitQueries
    }
    if (isEmptyObj(delEmptyObj(delQueryFalse(p)))) {
      ListingFilterAction(delQueryFalse(p), dispatch)
    } else {
      ListingFilterAction({}, dispatch)
    }
  }

  useEffect(() => {
    if (state?.LiveViewReducer?.trait) {
      if (state.LiveViewReducer.trait) {
        const { traitQuery, visible } = state.LiveViewReducer.trait
        if (traitQuery.length) {
          const m = new Map()
          traitQuery.forEach((item: any) => {
            if (!m.has(item.traitType)) {
              m.set(item.traitType, item.trait)
            }
          })
          setTraitCount(m.size)
          setTraitQueries(traitQuery)
        }
        if (!traitQuery.length && !visible) {
          setTraitCount(0)
          setTraitQueries([])
        }
      }
    }
  }, [state?.LiveViewReducer?.trait])

  const onTrait = () => {
    TraitModalAction({ visible: true, traitQuery: [] }, dispatch)
  }

  const handleCancel = () => {
    onFilterVisible(false)
    // onClear()
    // Modal.destroyAll()
  }
  const onFilterVisible = (v: boolean) => {
    setFilterVisible(v)
  }

  const onFilterActive = (v: boolean) => {
    setFilterActive(v)
  }

  return (
    <Box>
      <Row className="jc-sb body_wrapper click" px="16px" mt="16px" height="44px" onClick={() => onFilterVisible(true)}>
        <Row>
          <Image src={traits} height={14} width={12} />
          <Text className="fz14 fw400 white87" ml="6px">
            Filters
          </Text>
        </Row>
        <Image src={arrowRight} width={6} height={10} />
      </Row>
      {filterVisible ? (
        <Modal
          closable={false}
          bodyStyle={{ background: 'rgb(18,18,18)', borderRadius: 12 }}
          className="modalClassNameMobile"
          visible={filterVisible}
          wrapClassName="wrapClassNameMobile"
          footer={null}
          maskClosable={false}
        >
          <Col className="body_wrapper w100" pt="20px">
            <Row className="jc-sb">
              <div />
              <Text className="white87 fw700" fontSize={['20px', '20px', '22px', '24px']}>
                Filters
              </Text>
              <Image className="click" src={close} height={20} width={20} onClick={handleCancel} />
            </Row>
            <Box className="w100" px="20">
              <Col mt="44px">
                <InputGroup title="PRICE">
                  <MobileInput
                    min={0}
                    max={100000}
                    defaultVal={defaultQuery.minPrice}
                    title="Min.Price"
                    placeholder="Type minimum price"
                    onChange={onChangeMin}
                    inputRef={minRefPrice}
                  />
                  <Divider />
                  <MobileInput
                    min={0}
                    max={100000}
                    defaultVal={defaultQuery.maxPrice}
                    title="Max.Price"
                    placeholder="Type maximum price"
                    onChange={onChangeMax}
                    inputRef={maxRefPrice}
                  />
                </InputGroup>
                <InputGroup title="RANK">
                  <MobileInput
                    min={0}
                    max={100000}
                    defaultVal={defaultQuery.minRank}
                    title="Min.Rank"
                    placeholder="Type Rank number"
                    onChange={onChangeRankMin}
                    inputRef={minRefRank}
                  />
                  <Divider />
                  <MobileInput
                    min={0}
                    max={100000}
                    defaultVal={defaultQuery.maxRank}
                    title="Max.Rank"
                    placeholder="Type Rank number"
                    onChange={onChangeRankMax}
                    inputRef={maxRefRank}
                  />
                </InputGroup>
                <InputGroup title="TOKEN ID">
                  <MobileInput
                    min={0}
                    max={100000}
                    fixed={0}
                    defaultVal={defaultQuery.tokenId}
                    title="Token ID"
                    placeholder="Type Token ID number"
                    onChange={onChangeTokenId}
                    inputRef={tokenIdRef}
                  />
                </InputGroup>
              </Col>
            </Box>
            <MobileModalFooter onSave={onSave} saveActive />
          </Col>
        </Modal>
      ) : null}
    </Box>
  )
}
