import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { Box, Text, Image } from '@/packages/'
import { Row, Col, LoadingItemWrapper, ListingLoadWrapper, ETHValue } from '@/components/Layout/Layout'
import { openseaIcon, eth, openseaWhite, openseaActive } from '@/assets/img'
import { toNonExponential } from '@/utils/tools'

import { useEthereum } from '@/hooks/useEthereum'
import { QuickBuyVisibleAction } from '@/store/actions/LiveviewAction'
import { useAppContext } from '@/hooks/useAppContext'
import { OpenNotification, NotMainNetNotice } from '@/components/OpenNotification'
import { isMainNet } from '@/utils/chain'

const BuyBtn = styled(Box)<{ active: boolean }>`
  width: 78px;
  height: 34px;
  border-radius: 20px;
  background: ${({ active }) => (active ? 'rgba(0, 178, 181, 0.08)' : 'rgba(255, 255, 255, 0.12)')};
  border: ${({ active }) => (active ? '1px solid rgba(0, 178, 181, 1)' : '1px solid rgba(255, 255, 255, 0.12)')};
`

interface iBuyButton {
  listing: any
  from: string
  // slug: string
}
export const BuyButton: React.FC<iBuyButton> = ({ listing, from }) => {
  const [isHover, setHover] = useState(false)
  const { ethereum } = useEthereum()
  const { state, dispatch } = useAppContext()
  const { nftTokenVO, price, dateTime, rank } = listing

  const onBuy = async () => {
    const net = await isMainNet()
    if (net) {
      try {
        const account = await ethereum.request({ method: 'eth_requestAccounts' })
        if (account && account.length > 0) {
          QuickBuyVisibleAction(listing, from, dispatch) // listingUnderFloor
        } else {
          OpenNotification('Please connect your wallet', 'warning')
        }
      } catch (error) {
        OpenNotification('Please connect your wallet', 'warning')
      }
    } else {
      NotMainNetNotice()
    }
  }
  const onOpensea = (event: any) => {
    // event.stopPropagation()
    // openWindow(`https://opensea.io/collection/${slug}`)
  }
  return (
    <Row>
      <Box className="fx-row ai-ct jc-end mr8">
        <ETHValue value={toNonExponential(price, 4)} />
      </Box>
      <BuyBtn
        className="center"
        active={isHover}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        onClick={onBuy}
      >
        <Text className="click fz12 fw600" color={isHover ? 'rgba(0, 178, 181, 1)' : 'rgba(255, 255, 255, 0.87)'}>
          BUY
        </Text>
        {/* <Image
          src={isHover ? openseaActive : openseaWhite}
          height={16}
          width={18}
          className="click"
          // onClick={onOpensea}
        /> */}
      </BuyBtn>
    </Row>
  )
}
