import * as echarts from 'echarts'
import { line1Legend } from '@/assets/img'
import { iMarketCap } from '@/types/store/index'
import { dateList } from '@/utils/tools'
import {
  ChartBgColor,
  LegendStyle,
  axisLabel,
  grid,
  axisLine,
  axisTick,
  splitLine,
  barWidth,
  barColor,
  barBgColor,
  lineColor1
} from './types'

export const LiveView7DayVolumeOption = (volumes: Array<number>): any => {
  const valList: Array<number> = []
  const newVal: Array<number> = []
  volumes.forEach((item: any) => newVal.push(Number(item)))
  newVal.forEach((val: number) => valList.push(val === 0 ? Math.max(...newVal) * 0.05 : val))
  return {
    xAxis: {
      data: [],
      axisTick: {
        show: false
      },
      axisLine: {
        show: false
      }
    },
    yAxis: {
      show: false
    },
    grid: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 10
    },
    series: [
      {
        name: 'Volume',
        type: 'bar',
        itemStyle: {
          borderRadius: [2, 2, 0, 0]
        },
        barWidth: 10,
        color: '#0B7677', // 柱状图背景色
        data: valList
      }
    ]
  }
}
