import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { Flex, Box, Grid, Text } from '@/packages/'
import { ContentWrapper, Col, CardTitle, Row } from '@/components/Layout/Layout'
import { Trending } from './Trending'
import { Mints } from './Mints'
import { WatchList } from './WatchList'
import { TabListSelected, iTabItem } from '@/components/TabListSelected'
import { FloorPriceInput, FloorPriceBtn, RefreshList } from './components'
import {
  ChangePeriodAction,
  RefreshTableAction,
  TrendingInputAction,
  MintsPriceAction
} from '@/store/actions/DashbordAction'
import { useAppContext } from '@/hooks/useAppContext'
import { iChangePeriod } from '@/request/types'
import { SwitchButton } from '@/components/SwitchButton'
import { TitleDesc } from '../component'
import { useMatchBreakpoints } from '@/hooks/useMatchBreakpoints'

export const Collection = () => {
  const [period, setPeriod] = useState<Array<iTabItem>>([
    { id: 0, name: '15m', active: true },
    { id: 1, name: '1h', active: false },
    { id: 2, name: '6h', active: false },
    { id: 3, name: '1d', active: false },
    { id: 4, name: '7d', active: false }
  ])
  const [titleList, setTitleList] = useState<Array<iTabItem>>([
    { id: 0, name: 'Trending', active: true },
    { id: 1, name: 'Mints', active: false },
    { id: 2, name: 'WatchList', active: false }
  ])
  const [activeName, setActiveName] = useState<string>('Trending')
  const [periodName, setPeriodName] = useState<string>('15m')
  const [updatedTime, setUpdatedTime] = useState<number>(0)
  const { state, dispatch } = useAppContext()
  const { isMobile, isTablet } = useMatchBreakpoints()

  const onChangePeriod = (val: iTabItem) => {
    const _period = JSON.parse(JSON.stringify(period))
    _period.forEach((_p: iTabItem) => {
      _p.active = _p.name === val.name
    })
    setPeriod(_period)
    setPeriodName(val.name)
  }
  const setNewPeriod = (a: iTabItem | undefined) => {
    const { tabName, trendingPeriod, mintsPeriod, watchListPeriod } = state.DashbordReducer.period
    let val: string = ''
    const _period = JSON.parse(JSON.stringify(period))
    _period.forEach((_p: iTabItem) => {
      if (a?.name === 'Trending') {
        _p.active = _p.name === trendingPeriod
        val = trendingPeriod
      }
      if (a?.name === 'Mints') {
        _p.active = _p.name === mintsPeriod
        val = mintsPeriod
      }
      if (a?.name === 'WatchList') {
        _p.active = _p.name === watchListPeriod
        val = watchListPeriod
      }
    })
    setPeriod(_period)
    setPeriodName(val)
    a && setActiveName(a.name)
  }
  const TrendingInputChange = (price: string) => {
    TrendingInputAction(price, dispatch)
  }
  const MintsPriceChange = (isMint: boolean) => {
    MintsPriceAction(isMint ? 1 : 2, dispatch)
  }
  useEffect(() => {
    const a: iTabItem | undefined = titleList.find((t: iTabItem) => t.active)
    setNewPeriod(a)
  }, [titleList])

  useEffect(() => {
    if (state.DashbordReducer.refreshTable) {
      const { Trending: _Trending, Mints: _Mints, WatchList: _WatchList } = state.DashbordReducer.refreshTable
      if (activeName === 'Trending') setUpdatedTime(_Trending)
      if (activeName === 'Mints') setUpdatedTime(_Mints)
      if (activeName === 'WatchList') setUpdatedTime(_WatchList)
    }
  }, [state?.DashbordReducer?.refreshTable, activeName])

  useEffect(() => {
    let params: iChangePeriod = { tabName: '' }
    if (activeName === 'Trending') params = { tabName: 'Trending', trendingPeriod: periodName }
    if (activeName === 'Mints') params = { tabName: 'Mints', mintsPeriod: periodName }
    if (activeName === 'WatchList') params = { tabName: 'WatchList', watchListPeriod: periodName }
    ChangePeriodAction(params, dispatch)
  }, [activeName, periodName])

  const onRefresh = () => {
    RefreshTableAction({ refreshTableName: activeName, refreshTime: new Date().getTime() }, dispatch)
  }

  return (
    <Box className="w100" mt="40px">
      <TitleDesc
        title="Collection"
        subTitle="Discover trending NFT collections by sales. Prices updated in real time."
      />
      <ContentWrapper>
        <Grid
          gridTemplateColumns={['1fr', '1fr', '1fr 1fr', '1fr 1fr']}
          className="fx-row jc-sb ai-ct w100"
          mt="24px"
          height={['80px', '80px', '40px', '40px']}
        >
          <TabListSelected ml={24} list={titleList} onChange={(val: any) => setTitleList(val)} selectedList />
          <Row className="jc-sb">
            <Row>
              {isTablet || isMobile ? null : (
                <Text color="#fff" fontSize="12px" mr="10px">
                  Period:
                </Text>
              )}
              <Box mt={['16px', '16px', '0', '0']}>
                <TabListSelected ml={10} ftsz={12} list={period} onChange={onChangePeriod} selectedList={false} />
              </Box>
              {isTablet || isMobile ? null : (
                <>
                  {activeName === 'Trending' && <FloorPriceBtn onFloorPriceBtn={TrendingInputChange} />}
                  {activeName === 'Mints' && (
                    <SwitchButton
                      title="Free Mint"
                      onChange={MintsPriceChange}
                      defaultStatus={state.DashbordReducer.isMintPrice}
                    />
                  )}
                </>
              )}
            </Row>
            <RefreshList onRefresh={onRefresh} updatedTime={updatedTime} />
          </Row>
        </Grid>
      </ContentWrapper>

      <Box height="460px" className="hide_scrollbar" style={{ overflow: 'scroll' }} mt={['6px', '6px', '0', '0']}>
        {titleList.map((item: iTabItem) => (
          <Box key={item.id}>
            {item.id === 0 && item.active && <Trending />}
            {item.id === 1 && item.active && <Mints />}
            {item.id === 2 && item.active && <WatchList />}
          </Box>
        ))}
      </Box>
    </Box>
  )
}
