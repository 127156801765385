import React from 'react'
import { AppActionType } from '@/types/store'
import { UPCOMING_TOTAL_SUBMIT } from '@/store/common'

export const TotalSubmitAction = async (total: number, dispatch: React.Dispatch<AppActionType>) => {
  return dispatch({
    type: UPCOMING_TOTAL_SUBMIT,
    payload: {
      upcomingTotalSubmit: total
    }
  })
}
