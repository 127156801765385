import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { Flex, Box, Grid, Text, Image } from '@/packages/'
import { inviteGift } from '@/assets/img'
import { Col, Row } from '@/components/Layout/Layout'
import { inviteCountApi } from '@/request/Api'
import { useMatchBreakpoints } from '@/hooks/useMatchBreakpoints'

interface iInviteCount {
  invitees: number
  inviters: number
}
const InvitationBarWrapperBorder = styled(Box)`
  width: 100%;
  border-radius: 12px;
  border: 1px solid transparent;
  background-image: linear-gradient(#17191f, #17191f),
  radial-gradient(circle at top left, rgba(0, 177, 180, 1), rgba(123, 206, 97));
  background-origin: border-box;
  background-clip: content-box, border-box;
}

`
const InvitationBarWrapper = styled(Box)`
  background: linear-gradient(90deg, rgb(0, 177, 180) 0%, rgb(123, 206, 97) 100%);
  border-radius: 12px;
  margin: 1px;
`
export const InvitationEntrance = () => {
  const navigate = useNavigate()
  const { isMobile } = useMatchBreakpoints()
  const onInvite = () => {
    navigate('/invite')
  }
  return (
    <InvitationBarWrapperBorder>
      <InvitationBarWrapper py={['6px', '6px', '12px', '12px']}>
        {isMobile ? (
          <Col className="jc-ct" px={['10px', '10px', '10px', '10px']}>
            <Text
              className="fw600 "
              style={{ textAlign: 'center' }}
              color="rgba(255, 255, 255, 0.87)"
              fontSize={['14px', '14px', '16px', '16px']}
            >
              Invite to Earn! Invite friends to join the Public Test to earn 500 USDT & Pewee Lifetime Pass!
            </Text>
            <Box
              className="center bd4 click"
              ml={['0px', '16px', '16px', '16px']}
              borderRadius="20px"
              px="72px"
              py="10px"
              mt="10px"
              onClick={onInvite}
            >
              <Text className="fw600" color="#fff" fontSize={['12px', '14px', '16px', '16px']}>
                INVITE
              </Text>
            </Box>
          </Col>
        ) : (
          <Row className="jc-ct" px={['10px', '10px', '10px', '10px']}>
            <Text
              className="fw600 "
              style={{ textAlign: 'center' }}
              color="rgba(255, 255, 255, 0.87)"
              fontSize={['14px', '14px', '16px', '16px']}
            >
              Invite to Earn! Invite friends to join the Public Test to earn 500 USDT & Pewee Lifetime Pass!
            </Text>
            <Box
              className="center bd4 click"
              ml={['0px', '16px', '16px', '16px']}
              borderRadius="20px"
              px="72px"
              py="10px"
              onClick={onInvite}
            >
              <Text className="fw600" color="#fff" fontSize={['12px', '14px', '16px', '16px']}>
                INVITE
              </Text>
            </Box>
          </Row>
        )}
      </InvitationBarWrapper>
    </InvitationBarWrapperBorder>
  )
}
export const InvitationBar = () => {
  const [inviteCount, setCount] = useState<iInviteCount>({ inviters: 0, invitees: 0 })
  const { isMobile } = useMatchBreakpoints()

  const fetch = async () => {
    const d: any = await inviteCountApi(100, 1)
    d && setCount(d)
  }
  useEffect(() => {
    fetch()
  }, [])
  return (
    <InvitationBarWrapperBorder>
      <InvitationBarWrapper className="fx-row ai-ct jc-ct" py={['6px', '6px', '20px', '20px']}>
        {isMobile ? (
          <Text
            className="fz16 fw600 inviteMobileImg"
            color="rgba(255, 255, 255, 0.87)"
            style={{ textAlign: 'center' }}
          >
            {`${inviteCount.inviters} people have invited ${inviteCount.invitees} friends to join Pewee.`}
          </Text>
        ) : (
          <Row>
            <Row className="fz16">
              <Text className="fw600" color="rgba(255, 255, 255, 0.87)">
                {`${inviteCount.inviters} people`}
              </Text>
              <Text className="fw400" mx="10px" color="rgba(255, 255, 255, 0.87)">
                have invited
              </Text>
              <Text className="fw600" color="rgba(255, 255, 255, 0.87)">
                {`${inviteCount.invitees} friends`}
              </Text>
              <Text className="fw400" mx="10px" color="rgba(255, 255, 255, 0.87)">
                to join Pewee.
              </Text>
            </Row>
            <Image src={inviteGift} height={22} width={22} className="" />
          </Row>
        )}
      </InvitationBarWrapper>
    </InvitationBarWrapperBorder>
  )
}
