import React, { useState, useEffect, ChangeEvent, useImperativeHandle, useRef } from 'react'
import { Input as AntInput } from 'antd'

import { Image, Text, Box } from '@/packages/'
import { avator, upcomingInputHover, upcomingInputClear, upcomingInputSave } from '@/assets/img'
import { Row, Col } from '@/components/Layout/Layout'
import useDebounce from '@/hooks/useDebounce'
import { MaskWrapper } from './MaskWrapper'
import { UserAvatorPopOver } from './UserAvatorPopOver'

export interface iQuery {
  id?: number
  name: string
}
interface iInputContainer {
  title: string
  require: boolean
  placeholder: string
  onChange: (v: string) => void
  inputRef?: any
  errorMsg: string
  editValue?: string
  disabled?: number // 默认 输入框是可以直接输入  当编辑时  所有输入框不可直接输入

  query: iQuery
  isEdit?: boolean
  isModifyed: boolean
}
export const InputContainer: React.FC<iInputContainer> = ({
  editValue,
  title,
  require,
  placeholder,
  onChange,
  inputRef,
  errorMsg,
  disabled,
  query,
  isEdit,
  isModifyed
}) => {
  const [value, setValue] = useState<string>('')
  const [inputStatus, setInputStatus] = useState<string>('')
  const [inputBg, setInputBg] = useState<string>('rgb(28,28,28)')
  const [inputBd, setInputBd] = useState<string>('1px solid transparent')
  const debouncedValue = useDebounce<string>(value, 500)

  const iRef: any = useRef()

  useEffect(() => {
    if (debouncedValue) {
      onChange(value)
    }
  }, [debouncedValue])

  useImperativeHandle(inputRef, () => ({
    clearInputValue: () => {
      setValue('')
      onChange('')
    }
  }))
  useEffect(() => {
    if (isEdit) {
      setValue(editValue || '')
      setInputStatus('')
    }
  }, [isEdit])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value
    if (val === '') onChange('')
    setValue(val)
    if (isEdit) {
      setInputStatus('inputable')
    }
  }

  const onClickSave = () => {
    onChange(value)
    setInputStatus('')
  }
  const onClickClear = () => {
    setValue('')
    onChange('')
  }

  const onClickHover = () => {
    setInputStatus('inputable')
  }

  const onMouseOver = () => {
    if (isEdit && disabled === 1) {
      if (inputStatus !== 'inputable') {
        setInputStatus('hover')
      }
    }
  }
  const onMouseLeave = () => {
    // if (isEdit && inputStatus !== 'inputable' && inputStatus !== 'hover') {
    setInputStatus('')
    // }
  }
  const onInputFocus = () => {
    setInputBd('1px solid #00B2B5')
  }
  const onInputBlur = () => {}

  // console.log('isModifyed11111', isModifyed)

  return (
    <MaskWrapper title={title} require={require}>
      <Col className="">
        <Row className="w100">
          <Box className="w100" onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
            <AntInput
              bordered
              ref={iRef}
              disabled={disabled === 1 ? inputStatus !== 'inputable' : !!editValue}
              value={value}
              status={errorMsg ? 'error' : ''}
              onChange={handleChange}
              placeholder={placeholder}
              suffix={
                <div>
                  {inputStatus === 'hover' && (
                    <Image src={upcomingInputHover} height={18} width={18} className="click" onClick={onClickHover} />
                  )}
                  {inputStatus === 'inputable' && (
                    <Row>
                      <Image src={upcomingInputSave} height={18} width={18} className="click" onClick={onClickSave} />
                      <Image
                        src={upcomingInputClear}
                        height={18}
                        width={18}
                        className="click ml8"
                        onClick={onClickClear}
                      />
                    </Row>
                  )}
                </div>
              }
              className="mask_input w100"
            />
          </Box>
          <UserAvatorPopOver {...query} isModifyed={isModifyed} />
        </Row>
        {errorMsg && (
          <Text color="#D84549" className="fz14 fw500" mt="6px">
            {errorMsg}
          </Text>
        )}
      </Col>
    </MaskWrapper>
  )
}
