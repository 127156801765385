import React, { useState, useEffect, ChangeEvent, useImperativeHandle } from 'react'
import { Input as AntInput } from 'antd'
import useDebounce from '@/hooks/useDebounce'
import { decimalNum } from '@/utils/tools'
import { PeweeInput } from '@/components/PeweeInput'

interface iNumberInput {
  inputRef?: any
  classN?: string
  placeholder: string
  onChange: (v: string) => void
  fixed?: number
  min: number
  max: number
}
export const NumberInput: React.FC<iNumberInput> = ({ onChange, placeholder, inputRef, classN, fixed, min, max }) => {
  const [value, setValue] = useState<string>('')
  const debouncedValue = useDebounce<string>(value, 500)

  useEffect(() => {
    if (debouncedValue) {
      onChange(value)
    }
  }, [debouncedValue])

  useImperativeHandle(inputRef, () => ({
    clearInputValue: () => {
      setValue('')
      onChange('')
    }
  }))

  return (
    <PeweeInput
      fixed={fixed}
      min={min}
      max={max}
      bordered
      value={value}
      handleChange={(val: string) => setValue(val)}
      placeholder={placeholder}
      className={`${classN} input_style`}
    />
  )
}
