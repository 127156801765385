import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { Popover } from 'antd'
import { Flex, Box, Grid, Text, Image } from '@/packages/'
import { useAppContext } from '@/hooks/useAppContext'
import { ETHValue, Row, Col } from '@/components/Layout/Layout'
import { ListingPercentageApi, SalesCountApi, FloorCountApi, ListingCountApi } from '@/request/Api'
import { toNonExponential, accMul, accDiv } from '@/utils/tools'
import { notice, eth } from '@/assets/img/'
import { WsFloorPriceAction } from '@/store/actions/LiveviewAction'
import { useMatchBreakpoints } from '@/hooks/useMatchBreakpoints'

interface iKeyValue {
  title: string
  value?: string | number
  hideBd?: boolean
  icon?: boolean
  prevIcon?: React.ReactNode
  nextIcon?: React.ReactNode
  values?: React.ReactNode
}

interface iListingPopOver {
  listing10Min: number
  listing1H: number
  listing1D: number
}
interface iSales1DPopOver {
  sales10Min: number
  sales1H: number
}

export const KeyValue: React.FC<iKeyValue> = ({ title, value, hideBd, values, prevIcon, nextIcon }) => {
  return (
    <Col className="h100 w100 jc-start">
      <Text fontSize="12px" className="beyondHide white38" mb="6px">
        {title}
      </Text>
      {values || (
        <Row>
          {prevIcon}
          <Text fontSize={['14px', '14px', '16px', '16px']} className="white87 fw600">
            {value || '--'}
          </Text>
          {nextIcon}
        </Row>
      )}
    </Col>
  )
}

const VisibleWrapper = styled(Box)`
  width: 360px;
  height: 66px;
  background: #474747;
  border-radius: 12px;
  padding-left: 24px;
  padding-top: 12px;
`
const ListingPopOver: React.FC<iListingPopOver> = ({ listing10Min, listing1H, listing1D }) => {
  return (
    <VisibleWrapper className="fx-row">
      <KeyValue title="10M LISTINGS" value={toNonExponential(listing10Min)} />
      <KeyValue title="1H LISTINGS" value={toNonExponential(listing1H)} />
      <KeyValue title="1D LISTINGS" value={toNonExponential(listing1D)} hideBd />
    </VisibleWrapper>
  )
}
const Sales1DPopOver: React.FC<iSales1DPopOver> = ({ sales10Min, sales1H }) => {
  return (
    <VisibleWrapper className="fx-row">
      <KeyValue title="10M SALES" value={toNonExponential(sales10Min)} />
      <KeyValue title="1H SALES" value={toNonExponential(sales1H)} hideBd />
    </VisibleWrapper>
  )
}
export const OverView = (wsSocket: any) => {
  const { state, dispatch } = useAppContext()
  const [listing, setListing] = useState(0)
  const [listing10Min, setListing10Min] = useState(0)
  const [listing1H, setListing1H] = useState(0)
  const [listing1D, setListing1D] = useState(0)
  const [sales1D, setSales1D] = useState(0)
  const [sales10Min, setSales10Min] = useState(0)
  const [sales1H, setSales1H] = useState(0)
  const [reSales, setReSales] = useState('0')
  const [floorNumber, setFloorNumber] = useState(0)

  const listing10MinRef: any = useRef()
  const listing1HRef: any = useRef()
  const listing1DRef: any = useRef()

  const params = useParams()
  const NFTID = Number(params.nftId)
  const { isMobile, isTablet } = useMatchBreakpoints()
  const fetch = async () => {
    // Listing
    const l0: any = await ListingPercentageApi(NFTID)
    setListing(l0)

    const l1: any = await ListingCountApi(NFTID, {
      timeunit: 'minute',
      value: 10
    })
    setListing10Min(l1)
    const l2: any = await ListingCountApi(NFTID, {
      timeunit: 'hour',
      value: 1
    })
    setListing1H(l2)
    const l3: any = await ListingCountApi(NFTID, {
      timeunit: 'day',
      value: 1
    })
    setListing1D(l3)
    // 1D Sales
    const s0: any = await SalesCountApi(NFTID, {
      timeunit: 'day',
      value: 1
    })
    setSales1D(s0)
    const s1: any = await SalesCountApi(NFTID, {
      timeunit: 'minute',
      value: 10
    })
    setSales10Min(s1)
    const s2: any = await SalesCountApi(NFTID, {
      timeunit: 'hour',
      value: 1
    })
    setSales1H(s2)
    // Floor
    const floor: any = await FloorCountApi(NFTID)
    setFloorNumber(floor)
    WsFloorPriceAction(dispatch, {
      close: floor,
      high: 0,
      low: 0,
      open: 0,
      timestamp: 0,
      volume: 0,
      change: 0,
      amplitude: 0
    })
  }

  const fetchSales1D = async () => {
    const s0: any = await SalesCountApi(NFTID, {
      timeunit: 'day',
      value: 1
    })
    setSales1D(s0)
  }
  useEffect(() => {
    fetch()

    const intervalTimer = setInterval(() => {
      fetchSales1D()
    }, 1000 * 60 * 60)
    return () => clearInterval(intervalTimer)
  }, [])

  useEffect(() => {
    if (state?.LiveViewReducer?.crtSupply && sales1D) {
      // 24H Resale
      const s = accMul(accDiv(sales1D, state.LiveViewReducer.crtSupply), 100)
      setReSales(s.toFixed(2))
    }
  }, [state?.LiveViewReducer?.crtSupply, sales1D])

  useEffect(() => {
    if (wsSocket && wsSocket.socket) {
      try {
        setTimeout(() => {
          wsSocket.socket.client.subscribe(`/topic/listing-count-new-${NFTID}`, (res: any) => {
            const d = JSON.parse(res.body)
            console.log('ws data==overflow listing>>>>>>>>', d)
            const { oneDay, oneHour, tenMinutes } = d
            setListing10Min(tenMinutes)
            setListing1H(oneHour)
            setListing1D(oneDay)
          })

          wsSocket.socket.client.subscribe(`/topic/trade-count-new-${NFTID}`, (res: any) => {
            const d = JSON.parse(res.body)
            console.log('ws data== overflow sales>>>>>>>>', d)
            const { oneDay, oneHour, tenMinutes } = d
            setSales1D(oneDay)
            setSales10Min(tenMinutes)
            setSales1H(oneHour)
          })

          wsSocket.socket.client.subscribe(`/topic/floor-new-${NFTID}`, (res: any) => {
            const d = JSON.parse(res.body)
            console.log('ws data==overflow floor >>>>>>>>', d)
            const { dataTime, floor, nft } = d
            setFloorNumber(floor)
          })
        }, 10100)
      } catch (error) {
        console.log('connect error', error)
      }
    }
  }, [])

  return (
    <Grid
      gridTemplateColumns="repeat(4, 1fr)"
      py={['12px', '12px', '12px', '24px']}
      className="fx ai-ct body_wrapper"
      px={['16px', '16px', '24px', '24px']}
      mt={[20, 20, 20, 0]}
    >
      {isMobile || isTablet ? (
        <KeyValue title="LISTINGS" value={`${accMul(toNonExponential(listing), 100)}%`} />
      ) : (
        <Popover
          content={<ListingPopOver listing10Min={listing10Min} listing1H={listing1H} listing1D={listing1D} />}
          placement="bottom"
        >
          <Col className="jc-start h100 w100">
            <Text fontSize="12px" color="rgba(255, 255, 255, 0.38)" className="beyondHide" mb="6px">
              LISTINGS
            </Text>
            <Row>
              <Text fontSize="16px" className="white87">
                {`${accMul(toNonExponential(listing), 100)}%`}
              </Text>
              <Image src={notice} height={10} width={10} className="ml6" />
            </Row>
          </Col>
        </Popover>
      )}
      <KeyValue
        title="FLOOR"
        value={toNonExponential(floorNumber, 3)}
        prevIcon={<Image src={eth} width={6} height={12} className="mr6" />}
      />
      {isMobile || isTablet ? (
        <KeyValue title="1D SALES" value={toNonExponential(sales1D)} />
      ) : (
        <Popover content={<Sales1DPopOver sales10Min={sales10Min} sales1H={sales1H} />} placement="bottom">
          <Col className="jc-start h100 w100">
            <Text fontSize="12px" color="rgba(255, 255, 255, 0.38)" className="beyondHide" mb="6px">
              1D SALES
            </Text>
            <Row>
              <Text fontSize="16px" color="rgba(255, 255, 255, 0.87)">
                {toNonExponential(sales1D)}
              </Text>
              <Image src={notice} height={10} width={10} className="ml6" />
            </Row>
          </Col>
        </Popover>
      )}
      <KeyValue title="24H RESALE" value={`${toNonExponential(reSales)}%`} hideBd />
    </Grid>
  )
}
