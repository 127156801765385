import React, { useState, useEffect, useRef, useCallback } from 'react'
import styled from 'styled-components'
import { Flex, Box, Grid, Text, Input, Image } from '@/packages/'

import { Col, Row } from '@/components/Layout/Layout'

const FooterButton = styled(Box)`
  height: 40px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

interface iMobileModalFooter {
  // cancel?: string
  isOk?: string
  // onCancel: () => void
  onSave: () => void
  saveActive: boolean
}
export const MobileModalFooter: React.FC<iMobileModalFooter> = ({ isOk, onSave, saveActive }) => {
  return (
    <Row className="w100 jc-sb" mb="40px" mt="34px">
      <div />
      {/* <FooterButton className="bd4" onClick={onCancel} width={['124px', '124px', '164px', '164px']}>
        <Text className="fw700 fz16 white87 click">{cancel || 'CLOSE'}</Text>
      </FooterButton> */}
      <FooterButton
        width={['124px', '124px', '164px', '164px']}
        background={saveActive ? '#00B2B5' : 'rgba(255, 255, 255, 0.38)'}
        onClick={saveActive ? () => onSave() : () => null}
      >
        <Text className="fw700 fz16" color={saveActive ? '#FFFFFF' : 'rgba(255, 255, 255, 0.38)'}>
          {isOk || 'SAVE'}
        </Text>
      </FooterButton>
    </Row>
  )
}
